import React, { useContext, useEffect, useState } from "react";
import {
  numberInputOnWheelPreventChange,
  currencyFormatter,
  extractURLAndEncodedFilename,
  // downloadFileFromS3,
} from "../../utils/helper";

import downloadFileFromS3 from "../../utils/helper";

const defaultPart = {
  cncMaterial: "",
  cncSurfaceFinish: "",
  cncHeatTreatment: "",
  cncTolerance: "",
  cncParkMarking: "",
  cncReviewMyDesign: "",
  cncInternalCorner: "",
  threeDTechnology: "",
  threeDMachine: "",
  threeDMaterial: "",
  threeDPostProcessing: "",
  noOfThread: 0,
  qty: 1,
  notes: '',
  partNotes: '',
  description: '',
  partName: '',
  selectedPartFile: null,
  selected3DPartFile: null,
  materialType: '',
  surfaceFinish: '',
  subMaterial: '',
  surfaceTreatment: '',
  tolerance: '',
  partMarking: '',
  layer: '',
};



import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchRFQById,
  getAllSuppliers,
  updateRFQPartsData,
  createSupplierRFQ,
  getSupplierRFQsForRFQ,
  handleUpdateRfQ,
  sendRFQToSupplier,
  markRFQAsSent,
  deleteRFQ,
  downloadRfq,
  createPurchaseOrder,
  updateRFQAdminComment,
  cancelRFQ,
  deleteFileFromRFQ,
  getPOBySupplierRFQId,
  uploadRFQDocumentByAdmin,
  activateRFQ,
} from "../../utils/actions/supplierActions";
import Sidebar from "../Sidebar";
import {
  fetchQuoteById,
  getEightXAddress,
  getMaterial,
  getOrderForQuotationId,
} from "../../utils/actions/allactions";
import downloadImg from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import fileSaver from "file-saver";
import Switch from "react-input-switch";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import useDocumentTitle from "../../utils/useDocumentTitle";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import { Loading } from "../../components/Loading";
import { DeleteIcon, DownloadIcon } from "../../components/Icons";
import RFQTableMasterMaterial from "../../components/RfqDetailsTableComponent/RFQTableMasterMaterial";
import { maxFileUploadSize } from "../../utils/constant";
import { getAllMaterialsList } from "../../api/helperapi/helperApi";
import { uploadFileToRFQ } from "../../api/supplier/rfqs/adminRfqApi";
import { fetchOrganzationDetails } from "../../api/customer/quotation/commonQuotationApi";

const RFQDetails = () => {
  const token = localStorage.getItem("Token");
  const navigate = useNavigate();
  useDocumentTitle("RFQ Details");
  const { _id } = useParams();
  const [rFQData, setRFQData] = useState(null);
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partMarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [layer, setLayer] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierRFQs, setSupplierRFQs] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showRfqModal, setShowRfqModal] = useState(false);
  const [rFQtoShow, setRFQToShow] = useState(null);
  const { setSelectedChatRefId, selectedChatRefId, setChatType } =
    useContext(ChatRefContext);
  const [eightXAddress, setEightXAddress] = useState([]);
  const [isPOAlreadyExists, setIsPoAlreadyExist] = useState(false);
  const [showDeclineDescription, setShowDeclineDescription] = useState(false);
  const [declinedRFQData, setDeclinedRFQData] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [isRFQUpdatable, setIsRFQUpdatable] = useState(true);
  const [isPartsNameChanged, setIsPartsNameChanged] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [selectedRFQForMail, setSelectedRFQForMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewRowCreated, setNewRowCreated] = useState(false);
  const [fullMastersData, setFullMastersData] = useState({});
  const [isAnySupplierRFQCreated, setIsAnySupplierRFQCreated] = useState(false);

  useEffect(() => {
    if (isNewRowCreated) {
      const fetchDataforNewRow = async () => {
        try {
          setIsLoading(true);
          let formData = new FormData();
          formData.append("updatedRFQ", JSON.stringify(rFQData));

          let updatedRFQResponse = await updateRFQPartsData(formData, token);
          if (updatedRFQResponse?.data?.status) {
            setIsPartsNameChanged(false);
            toast.success("New Row Created Successful");
            fetchData();
          } else {
            toast.error(updatedRFQResponse?.data?.data?.message);
          }

        } catch (error) {
          toast.error("Unable To Created New Row At The Moment");
        } finally {
          setIsLoading(false);
          setNewRowCreated(false);
        }
      }
      fetchDataforNewRow();
    }
  }, [isNewRowCreated]);
  console.log("rFQData :",rFQData);
  const handleNewRowCreating = () => {
    if (isPOAlreadyExists) {
      toast.error("Po is Already Created");
      return;
    }
    if (isAnySupplierRFQCreated) {
      const confirmProceed = window.confirm("Supplier RFQ is already created. Are you sure you want to add a new row?");
      if (!confirmProceed) return;
    }
    setRFQData((rfqData) => {
      const newData = {
        ...rfqData,
        partsData: [...rfqData.partsData, { ...defaultPart }]
      };
      return newData;
    })
    setNewRowCreated(true);
  }
  const handleMaterialMasterChange = (e, key, index) => {
    setRFQData((rfqData) => {
      const newData = { ...rfqData };
      newData.partsData[index][key] = e.target.value
      return newData;
    })
  };
  const handleClone = (index) => {
    setRFQData((rfqData) => {
      const newPartsData = rfqData.partsData[index];
      delete newPartsData._id;
      const newData = {
        ...rfqData,
        partsData: [...rfqData.partsData, newPartsData],
      };
      return newData;
    });
    setNewRowCreated(true);
  }

  useEffect(() => {
    (async () => {
      try {
        await fetchData();
        setIsLoading(true);
        const materialResponse = await getMaterial(token);
        const materialListResponse = await getAllMaterialsList();
        console.log('MaterialListResponse', materialListResponse)
        if (materialResponse.status) {
          setMaterial(materialResponse.data.material);
          setSubMaterial(materialResponse.data.submaterial);
          setPartMarking(materialResponse.data.partMarking);
          setSurfaceFinish(materialResponse.data.surafcefinish);
          setSurfaceTreatment(materialResponse.data.surfacetreat);
          setTolerance(materialResponse.data.tolerance);
          setLayer(materialResponse.data.layer);
          setFullMastersData(materialListResponse);
        } else {
          toast.error("Something went wrong while fetching the materials");
        }
        setIsLoading(false);
      } catch (err) {
        console.log(
          "Error in 83 RFQ Details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
        toast.error("Something went wrong while loading the page");
        setIsLoading(false);
      }
    })()
  }, []);

  const handleCloseCaptchaModal = () => {
    setShowCaptchaModal(false);
    setSelectedRFQForMail("");
  };

  const handleSendRFQEmail = () => {
    try {
      handleSendRFQMail(selectedRFQForMail);
      setSelectedRFQForMail("");
    } catch (err) {
      console.log("err", err);
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const showSupplierDrawer = (RefId) => {
    setSelectedChatRefId(RefId);
    setChatType("supplier");
  };

  const onClose = () => {
    setChatType(null);
    setSelectedChatRefId(null);
  };

  const handleRFQModelShow = (id, status) => {
    if (status == "Active Order") {
      setIsRFQUpdatable(false);
    }
    if (status == "Unapproved") {
      setIsRFQUpdatable(false);
    }
    if (status == "Cancelled") {
      setIsRFQUpdatable(false);
    }
    supplierRFQs.forEach((item) => {
      if (item._id == id) {
        setRFQToShow(item);
        setShowRfqModal(true);
      }
    });
  };

  const handleModalClose = () => {
    let localRFQPartsData = [...rFQtoShow.partsData];
    localRFQPartsData.forEach((item) => {
      item.price = 0;
    });
    let totalPrice = 0;

    setRFQToShow({
      ...rFQtoShow,
      partsData: localRFQPartsData,
      price: totalPrice,
    });

    setRFQToShow(null);
    setShowRfqModal(false);
  };
  const handleDeleteRow = (index) => {
    setRFQData((rfq) => {
      return {
        ...rfq,
        partsData: [...rfq.partsData].filter((_, idx) => idx != index)
      };
    });
  }
  const fetchSecondaryData = (quoteId) => {
    getEightXAddress(token)
      .then((addressResponse) => {
        setEightXAddress(addressResponse.data);
      })
      .catch((error) => {
        // Handle error here
        console.error(error);
      });

    fetchQuoteById(quoteId, token)
      .then((quoteResponse) => {
        if (quoteResponse?.data.status) {
          setSelectedQuote(quoteResponse.data.data);
        } else {
          throw new Error("Something went wrong while fetching quote data.");
        }
      })
      .catch((error) => {
        // Handle error here
        console.error(error);
      });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const rfqResponse = await fetchRFQById(_id, token);
      if (rfqResponse.status) {
        setRFQData(rfqResponse.data.response);
        setIsPoAlreadyExist(rfqResponse.data.isPoCreated);
        fetchSecondaryData(rfqResponse.data?.response.quotationId);
        setIsAnySupplierRFQCreated(rfqResponse?.data?.isSupplierRFQCreated);
      }

      const supplierRFQResponse = await getSupplierRFQsForRFQ(token, _id);
      if (supplierRFQResponse.status) {
        setSupplierRFQs(supplierRFQResponse.data);
        let supplierRFQForPO = "";
        supplierRFQResponse.data.forEach((item) => {
          if (item.isSelected) {
            supplierRFQForPO = item._id;
          }
        });

        if (supplierRFQForPO.length > 0) {
          let poResponse = await getPOBySupplierRFQId(token, supplierRFQForPO);
          if (poResponse.status) {
            setPurchaseOrder(poResponse.data);
          }
        }
      };
      const supResponse = await getAllSuppliers(token);
      if (supResponse.status) {
        let filteredSupplierData = supResponse.data.filter(
          (item) => item.status == "Active"
        );
        setSupplierList(filteredSupplierData);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(
        "Error in 187 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Something went wrong while fetching RFQ data.");
    }
  };

  // console.log(rFQData);

  const handleCreatePurchaseOrder = async (rfqSelectedForPO) => {
    try {
      let formData = new FormData();
      const organizationResponse = await fetchOrganzationDetails(token);
      let organizationData = organizationResponse?.data;
      if (!organizationData.organizationAddress) {
        toast.error("Organization address not found. Please update in organization setup menu.");
        return;
      }

      formData.append("rfqData", JSON.stringify(rfqSelectedForPO));
      formData.append("shippingAddress", JSON.stringify({ ...organizationData?.organizationAddress, postalCode: organizationData?.organizationAddress?.zipCode, firstName: organizationData?.organizationName }));
      const poResponse = await createPurchaseOrder(formData, token);
      if (poResponse?.data?.status) {
        toast.success(poResponse?.data?.message);
        navigate(
          `/admin/purchase-order-details/${poResponse?.data?.data?._id}`
        );
      } else {
        toast.error(poResponse?.data?.message);
      }
    } catch (err) {
      console.log(
        "Error in 257 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleUpdateRFQPartData = async () => {

    let userResponse;

    if (rFQData.rfqStatus == 'cancelled') {
      userResponse = confirm("Do You Want To Move RFQ To Inprogress Status");
    }
    setIsLoading(true);
    try {
      if (userResponse) {
        const response = await activateRFQ(token, rFQData._id);
        if (!response.success) {
          console.log(response);
          setIsLoading(false);
          toast.error("Unable To Change RFQ Status");
          return;
        }
      };


      let data = {
        id: rFQData._id,
        adminComment: rFQData.adminComment,
      };
      await updateRFQAdminComment(token, data);

      let isPartNamesValid = validatePartsName();
      let isQuantityNotValid = validateQuantity();
      if (isQuantityNotValid) {
        toast.error("Quantity Should Not Be Zero");
        return;
      }
      if (!isPartNamesValid) {
        toast.error("Part name for  all line item is a mandatory field.");
        return;
      }
      const newRfqData = { ...rFQData };
      delete newRfqData.rfqStatus;
      let formData = new FormData();
      formData.append("updatedRFQ", JSON.stringify(newRfqData));

      let updatedRFQResponse = await updateRFQPartsData(formData, token);
      if (updatedRFQResponse?.data?.status) {
        setIsPartsNameChanged(false);
        await fetchData();
        toast.success(updatedRFQResponse?.data?.message);
      } else {
        toast.error(updatedRFQResponse?.data?.data?.message);
      }
    } catch (err) {
      console.log(
        "Error in 287 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      )
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedFileRemove = async (index, fileType) => {
    let adminResponse = confirm(
      "Once the file is deleted, the cannot be accessed again. Are you sure you want to update the RFQ?"
    );
    if (!adminResponse) {
      return;
    }
    let data = {
      rfqId: rFQData._id,
      fileType,
      partIndex: index,
    };

    let response = await deleteFileFromRFQ(data, token);

    if (response.status) {
      setRFQData({ ...rFQData, partsData: response?.data?.partsData });
      toast.success("File Deleted successfully");
    } else {
      toast.error("Something went wrong while uploading files.");
    }
  };


  const handleFileUpload = async (event, index, fileType) => {
    try {
      let formData = new FormData();
      console.log('cam ehere')
      formData.append("rfqId", rFQData?._id);
      formData.append("fileType", fileType);
      if (fileType == "2D") {
        formData.append("selected2DPartFile", event.target.files[0]);
      } else {
        formData.append("selected3DPartFile", event.target.files[0]);
      }
      formData.append("partIndex", index);

      let response = await uploadFileToRFQ(formData, token);

      if (response.status) {
        setRFQData({ ...rFQData, partsData: response?.data?.partsData });
        toast.success("File Uploaded successfully");
      } else {
        toast.error("Something went wrong while uploading files.");
      }
    } catch (err) {
      console.log(
        "Error in 316 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handlePartNameChange = (e, index) => {
    let localRFQPartsData = [...rFQData.partsData];
    localRFQPartsData[index].partName = e.target.value;
    setIsPartsNameChanged(true);
    setRFQData({ ...rFQData, partsData: localRFQPartsData });
  };

  const handlePartNotesChange = (e, index) => {
    let localRFQPartsData = [...rFQData.partsData];
    localRFQPartsData[index].partNotes = e.target.value;
    setIsPartsNameChanged(true);
    setRFQData({ ...rFQData, partsData: localRFQPartsData });
  };

  const handleRFQItemQtyChange = (e, i) => {
    // if (e.target.value == 0) {
    //   toast.error("Quantity Cannot Be 0");
    //   return;
    // }
    let localRFQPartsData = rFQData;
    localRFQPartsData.partsData[i].qty = e.target.value;
    setRFQData({ ...localRFQPartsData });
  };

  const handleRFQItemDescChange = (event, i) => {
    let localRFQPartsData = rFQData;
    localRFQPartsData.partsData[i].description = event.target.value;
    setRFQData({ ...localRFQPartsData });
  };

  const handleAddSupplier = async () => {
    if (isPOAlreadyExists) {
      toast.error("Purchase order is already created for this quotation.");
      return;
    }
    if (!selectedSupplier) {
      toast.error("Select a valid supplier");
      return;
    }
    const isSupplierSelected = supplierRFQs.some(
      (rfq) => rfq.supplierId._id == selectedSupplier._id
    );
    if (isSupplierSelected) {
      toast.error("Supplier is already selected.");
      return;
    }
    handleCreateSupplierRFQ();
  };

  const validatePartsName = () => {
    try {
      let partsData = rFQData.partsData;
      let isValid = true;

      for (let i = 0; i < partsData.length; i++) {
        if (partsData[i]?.partName?.length == 0 || !partsData[i].partName) {
          isValid = false;
          break;
        }
      }
      return isValid;
    } catch (err) {
      toast.error(err.message);
      return false;
    }
  };

  const validateQuantity = () => {
    const partsData = rFQData.partsData;
    return partsData.some((item) => Number(item.qty) <= 0)
  }
  const handleCreateSupplierRFQ = async () => {
    try {
      let isPartNamesValid = validatePartsName();
      if (!isPartNamesValid) {
        toast.error("Part name for  all line item is a mandatory field.");
        return;
      }
      if (isPartNamesValid && isPartsNameChanged) {
        toast.error("Please update RFQ before going ahead.");
        return;
      }
      let data = {
        supplierId: selectedSupplier._id,
        rfqId: _id,
      };
      const supplierRFQResponse = await createSupplierRFQ(token, data);
      console.log("Suppied RFQ response: ", supplierRFQResponse);
      if (supplierRFQResponse.data.status) {
        toast.success("Supplier added successfully");
        setSupplierRFQs([...supplierRFQs, supplierRFQResponse.data.data]);
        if (rFQData.rfqStatus === 'cancelled') {
          setRFQData({ ...rFQData, rfqStatus: 'in-progress' });
        }
      }
    } catch (err) {
      // console.log(
      //   "Error in 371 RFQ Details on " +
      //   moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
      //   err
      // );
      console.log("Error :", err);
      toast.error(err.message);
    }
  };

  const handleUpdateRfQClick = async () => {
    try {
      let adminResponse = confirm(
        'Are you sure you want to update the RFQ information. Once updated, the RFQ will be moved to "Under review" status.'
      );
      if (adminResponse) {
        let isRFQDataValid = validateRFQData();
        if (isRFQDataValid) {
          let dataToSend = {
            partsData: rFQtoShow.partsData,
            leadTime: rFQtoShow.leadTime,
            price: rFQtoShow.price,
            _id: rFQtoShow._id,
          };
          let updatedRFQResponse = await handleUpdateRfQ(
            { ...dataToSend },
            token
          );
          let localRFQForQuotation = [...supplierRFQs];
          for (let i = 0; i < localRFQForQuotation.length; i++) {
            if (
              localRFQForQuotation[i]._id == updatedRFQResponse.data.data._id
            ) {
              localRFQForQuotation[i] = updatedRFQResponse.data.data;
              break;
            }
          }
          if (updatedRFQResponse.data.status) {
            toast.success(updatedRFQResponse.data.message);
            setSupplierRFQs(localRFQForQuotation);
            setShowRfqModal(false);
          } else {
            toast.error(updatedRFQResponse.data.data.message);
          }
        } else {
          toast.error("Item price and lead time are required fields.");
        }
      }
    } catch (err) {
      console.log(
        "Error in 405 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleDeleteRFQ = async (id) => {
    try {
      let adminResponse1 = true;
      if (supplierRFQs.length == 1) {
        adminResponse1 = confirm(
          "If you delete this supplier, the RFQ will no longer be valid"
        );
      }
      if (adminResponse1) {
        const adminResponse = confirm(
          "Are you sure you want to delete this RFQ?"
        );
        if (adminResponse) {
          const deleteResponse = await deleteRFQ(id, token);
          if (deleteResponse.data.status) {
            let localRFQForQuotation = [...supplierRFQs];
            localRFQForQuotation = localRFQForQuotation.filter(
              (item) => item._id != id
            );
            setSupplierRFQs([...localRFQForQuotation]);
            toast.success(deleteResponse.data.message);
          } else {
            toast.error(deleteResponse.data.message);
          }
        }
      }
    } catch (err) {
      console.log("Error in delete rfq", err.message);
    }
  };

  const handleRFQDownload = async (rfqData) => {
    try {
      let response = await downloadRfq(rfqData._id, token);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `RFQ-${rfqData.supplierRFQRefId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(
        "Error in handleRFQDownload on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const validateRFQData = () => {
    let isDataValid = true;
    let isPriceAvailable = false;
    for (let i = 0; i < rFQtoShow.partsData.length; i++) {
      if (rFQtoShow.partsData[i].price > 0) {
        isPriceAvailable = true;
        break;
      }
    }

    if (isPriceAvailable) {
      isDataValid = true;
    } else {
      isDataValid = false;
    }

    if (!rFQtoShow.leadTime) {
      isDataValid = false;
    }
    return isDataValid;
  };

  const handleRFQItemPriceChange = (event, index) => {
    let localRFQPartsData = [...rFQtoShow.partsData];
    localRFQPartsData[index].price = event.target.value;

    let totalPrice = localRFQPartsData?.reduce((acc, item) => {
      return acc + item.price * item.qty;
    }, 0);

    setRFQToShow({
      ...rFQtoShow,
      partsData: localRFQPartsData,
      price: totalPrice,
    });
  };

  const handleSendRFQMail = async (rFQId) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to send this RFQ to supplier?"
      );
      if (adminResponse) {
        let response = await sendRFQToSupplier(rFQId, token);
        let localRFQForQuotation = [...supplierRFQs];
        for (let i = 0; i < supplierRFQs.length; i++) {
          if (localRFQForQuotation[i]._id == response.data._id) {
            localRFQForQuotation[i] = response.data;
          }
        }
        setSupplierRFQs([...localRFQForQuotation]);
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(
        "Error in 505 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleMarkAsSentRFQ = async (supplierRFQId) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want mark this RFQ as sent?"
      );
      if (adminResponse) {
        let response = await markRFQAsSent(supplierRFQId, token);
        let localRFQForQuotation = [...supplierRFQs];
        for (let i = 0; i < supplierRFQs.length; i++) {
          if (localRFQForQuotation[i]._id == response.data._id) {
            localRFQForQuotation[i] = response.data;
          }
        }
        setSupplierRFQs([...localRFQForQuotation]);
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(
        "Error in 505 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleSwitchClick = async (rfqData) => {
    try {
      if (rfqData.price <= 0 || !rfqData.price) {
        toast.error("The price for parts is not updated.");
        return;
      }

      let isOrderAlreadyCreated;
      supplierRFQs.forEach((item) => {
        if (item.isSelected) {
          isOrderAlreadyCreated = true;
        }
      });

      if (isOrderAlreadyCreated) {
        toast.error("Purchase Order for this RFQ is already created.");
        return;
      }

      let adminResponse = confirm(
        `Are you sure you want to select ${rfqData?.supplierId?.companyName}? A Draft Purchase Order will be created on confirmation.`
      );
      if (!adminResponse) {
        return;
      }
      handleCreatePurchaseOrder(rfqData);
    } catch (err) {
      console.log(
        "Error in 523 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleCancelRFQ = async () => {

    if (isPOAlreadyExists) {
      toast.error("Purchase Order Is Already Created Unable to Cancel");
      return;
    }
    try {
      if (rFQData.rfqStatus == "cancelled") {
        toast.error("The RFQ is already cancelled");
        return;
      }

      const adminResponse = confirm(
        `Are you sure you want to cancel for ${rFQData.projectName}? Note : All supplier RFQs will get cancelled for this project as well.`
      );
      if (!adminResponse) {
        return;
      }
      const cancelResponse = await cancelRFQ(_id, token);
      if (cancelResponse.status) {
        setRFQData((pre) => ({ ...pre, rfqStatus: "cancelled" }));
        toast.success(cancelResponse.message);
      }
    } catch (err) {
      console.log(
        "Error in 582 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleShowDeclinedDetails = (itemId) => {
    let declinedRFQData = supplierRFQs.filter((item) => item._id == itemId);
    setDeclinedRFQData(declinedRFQData[0]);
    setShowDeclineDescription(true);
  };

  const handleDeclineModalClose = () => {
    setShowDeclineDescription(false);
  };

  const handleRFQDocumentUpload = async (event, supplierRFQId, index) => {
    try {
      let formData = new FormData();
      formData.append("supplierRFQId", supplierRFQId);
      formData.append("rfqDocument", event.target.files[0]);

      let response = await uploadRFQDocumentByAdmin(token, formData);
      if (response.status) {
        // setRFQData({...rFQData,partsData:response?.data?.partsData})
        let localSupplierRFQs = [...supplierRFQs];
        localSupplierRFQs[index] = response.data;
        setSupplierRFQs(localSupplierRFQs);
        toast.success("RFQ document uploaded successfully");
      } else {
        toast.error("Something went wrong while uploading files.");
      }
    } catch (err) {
      console.log(
        "Error in 316 RFQ Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      {isLoading ? <Loading /> : ""}
      <Sidebar />
      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendRFQEmail}
        buttonText="Send Email"
      />
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDeclineDescription}
        onHide={handleDeclineModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            RFQ Decline Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="issue-description-div">
            <div>
              <h5>Reason to decline : </h5>
              <p>{declinedRFQData.declineReason}</p>
              <h5>Description : </h5>
              <p>{declinedRFQData.declineDescription}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleDeclineModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-3">RFQ Details</h1>
          <div className="right-container">
            <h5 style={{ textAlign: "left" }}>
              Project Name : {rFQData?.projectName}{" "}  {rFQData?.rfqStatus == "cancelled" ? "(Cancelled)" : ""}
            </h5>
            {/* {rFQData?.partsData?.length > 0 ? (
              <div style={{ margin: "30px 0px", textAlign: "left" }}>
                <h5 className="font-medium  my-1">Documentation required : </h5>
                <div className="!flex justify-between px-2 flex-wrap ">
                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-cc"
                      name="checkbox"
                      value={rFQData?.isConformanceCertificateRequested}
                      checked={rFQData?.isConformanceCertificateRequested}
                      onChange={() => {
                        setRFQData({
                          ...rFQData,
                          isConformanceCertificateRequested:
                            !rFQData?.isConformanceCertificateRequested,
                        });
                      }}
                    />
                    <label htmlFor="sign-check-cc">
                      Certificate of conformance
                    </label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-dr"
                      name="checkbox"
                      value={rFQData?.isDimensionalReportRequested}
                      checked={rFQData?.isDimensionalReportRequested}
                      onChange={() => {
                        setRFQData({
                          ...rFQData,
                          isDimensionalReportRequested:
                            !rFQData?.isDimensionalReportRequested,
                        });
                      }}
                    />
                    <label htmlFor="sign-check-dr" style={{ fontSize: "15px" }}>
                      Dimensional Inspection Report
                    </label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-qr"
                      name="checkbox"
                      value={rFQData?.isQrTrackingRequested}
                      checked={rFQData?.isQrTrackingRequested}
                      onChange={() => {
                        setRFQData({
                          ...rFQData,
                          isQrTrackingRequested:
                            !rFQData?.isQrTrackingRequested,
                        });
                      }}
                    />
                    <label htmlFor="sign-check-qr" style={{ fontSize: "15px" }}>
                      QR Tracking
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )} */}
            <div className="quotes-table">
              <table className="table">
                <thead>
                  <tr style={{ fontSize: "0.9rem", border: "1px solid gray" }}>
                    <th scope="col">Part Name </th>
                    <th scope="col">3D File</th>
                    <th scope="col">2D File</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {rFQData?.partsData?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={{ fontSize: "0.9rem" }}
                        className="!bg-white"
                      >
                        <td>
                          <p>
                            {item.partName}
                          </p>
                        </td>

                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          <div>
                            {item?.selected3DPartFile?.name ? (
                              <div>
                                <a
                                  className="downloadables"
                                  href={URL.createObjectURL(
                                    item?.selected3DPartFile
                                  )}
                                  download={item?.selected3DPartFile?.name}
                                >
                                  {(
                                    item?.selected3DPartFile?.name ||
                                    "File Not Available"
                                  )?.length > 25
                                    ? (item?.selected3DPartFile?.name).substring(
                                      0,
                                      25
                                    ) + "..."
                                    : item?.selected3DPartFile?.name ||
                                    "File Not Available"}
                                  <img
                                    src={downloadImg}
                                    style={{ height: "15px" }}
                                  />
                                </a>{" "}
                                &nbsp; &nbsp;
                                <img
                                  src={deleteImageEnabled}
                                  style={{ height: "15px" }}
                                  onClick={() => {
                                    handleSelectedFileRemove(index, "3D");
                                  }}
                                />

                              </div>
                            ) : item?.selected3DPartFile?.originalname ? (
                              <div className=" inline-block w-[150px]">
                                <p
                                  className="text-primaryColor inline-block"
                                  onClick={() =>
                                    download(
                                      item?.selected3DPartFile?.originalname,
                                      `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                      item?.selected3DPartFile?.path
                                    )
                                  }
                                >
                                  {(
                                    item?.selected3DPartFile?.originalname ||
                                    "File Not Available"
                                  )?.length > 25
                                    ? (item?.selected3DPartFile?.originalname).substring(
                                      0,
                                      25
                                    ) + "..."
                                    : item?.selected3DPartFile?.originalname ||
                                    "File Not Available"}
                                  {/* <DownloadIcon additionalClass={'!h-4 !inline-block'} /> */}

                                  <p>
                                    <span
                                      onClick={() =>
                                        download(
                                          item?.selected3DPartFile?.originalname,
                                          `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                          item?.selected3DPartFile?.path
                                        )
                                      }

                                    >

                                      <DownloadIcon additionalClass={'!h-4 !inline-block'} />
                                    </span>
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectedFileRemove(index, "3D");
                                      }}

                                      className="inline-block">
                                      <DeleteIcon additionalClasses={'!h-4 text-red-500 !inline-block'} />

                                    </span>

                                  </p>
                                  {item?.selected3DPartFile &&
                                    <img
                                      src={`${process.env.REACT_APP_SUPPLIER_BACKEND}/${item?.selected3DPartFile?.path}`}
                                      alt={item?.selected3DPartFile?.originalname || "Uploaded File"}
                                      className="mt-1 w-[100px] h-auto"
                                    />}
                                </p>

                              </div>
                            ) : (
                              <div style={{ width: "150px", color: "red" }}>
                                <label
                                  className="white-btn"
                                  htmlFor={`upload-3d-${index}`}
                                  style={{ color: "red" }}
                                >
                                  Upload 3D File for{" "}
                                  {(
                                    selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name
                                  )?.length > 20
                                    ? (
                                      selectedQuote?.partsData[index]?.file
                                        ?.filename ||
                                      selectedQuote?.partsData[index]?.file
                                        ?.originalname ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.path ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.name
                                    ).substring(0, 20) + "..."
                                    : selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name}{" "}
                                  {` : Line ${index + 1}`}
                                </label>
                                <input
                                  className="border px-1"

                                  id={`upload-3d-${index}`}
                                  type="file"
                                  onChange={(e) => {
                                    let filesForCheck = Array.from(e.target.files);
                                    let isAllFilesValid = true;
                                    filesForCheck.forEach(file => {
                                      if (file.size > 10485760) {
                                        isAllFilesValid = false;
                                      }
                                    })

                                    if (!isAllFilesValid) {
                                      toast.error("File size should be less than 10 MB");
                                      return;
                                    }

                                    handleFileUpload(e, index, "3D");
                                  }}
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                    width: "0px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </td>

                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          <div>
                            {item?.selectedPartFile?.name ? (
                              <div className="inline-block w-[150px]">
                                <a
                                  className="inline-block text-primaryColor"
                                  href={URL.createObjectURL(item?.selectedPartFile)}
                                  download={item?.selectedPartFile?.name}
                                >
                                  {(item?.selectedPartFile?.name || "File Not Available").length > 25
                                    ? item?.selectedPartFile?.name.substring(0, 25) + "..."
                                    : item?.selectedPartFile?.name || "File Not Available"}
                                </a>
                                <img
                                  src={`${process.env.REACT_APP_SUPPLIER_BACKEND}/${item?.selectedPartFile?.path}`}
                                  alt={item?.selectedPartFile?.name || "Uploaded File"}
                                  className="mt-2 w-[150px] h-auto"
                                />
                                <a
                                  className="text-[dodgerblue] inline-block"
                                  href={URL.createObjectURL(item?.selectedPartFile)}
                                  download={item?.selectedPartFile?.name}
                                >
                                  <DownloadIcon additionalClass={"!h-4"} />
                                </a>
                                <div
                                  onClick={() => {
                                    handleSelectedFileRemove(index, "2D");
                                  }}
                                  className="text-red-500 inline-block"
                                >
                                  <DeleteIcon additionalClasses={"!h-4"} />
                                </div>
                              </div>
                            ) : item?.selectedPartFile?.originalname ? (
                              <div className="inline-block w-[150px] ">
                                <span
                                  className="text-primaryColor"
                                  onClick={() =>
                                    download(
                                      item?.selectedPartFile?.originalname,
                                      `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                      item?.selectedPartFile?.path
                                    )
                                  }
                                >
                                  {(
                                    item?.selectedPartFile?.originalname || "File Not Available"
                                  ).length > 25
                                    ? item?.selectedPartFile?.originalname.substring(0, 25) + "..."
                                    : item?.selectedPartFile?.originalname || "File Not Available"}
                                </span>

                                <div
                                  onClick={() =>
                                    download(
                                      item?.selectedPartFile?.originalname,
                                      `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                      item?.selectedPartFile?.path
                                    )
                                  }
                                  className="text-[dodgerblue] inline-block mt-1"
                                >
                                  <DownloadIcon additionalClass={"!h-4"} />
                                </div>
                                <div
                                  className="cursor-pointer inline-block mt-1"
                                  onClick={() => {
                                    handleSelectedFileRemove(index, "2D");
                                  }}
                                >
                                  <DeleteIcon additionalClasses={"text-red-500 h-4"} />
                                </div>
                                {item?.selectedPartFile &&
                                  <img
                                    src={`${process.env.REACT_APP_SUPPLIER_BACKEND}/${item?.selectedPartFile?.path}`}
                                    alt={item?.selectedPartFile?.originalname || "Uploaded File"}
                                    className="mt-1 w-[100px] h-auto"
                                  />}
                              </div>
                            ) : (
                              <div style={{ width: "150px", color: "red" }}>
                                <label
                                  className="white-btn"
                                  htmlFor={`upload-2d-${index}`}
                                  style={{ color: "red" }}
                                >
                                  Upload 2D File for{" "}
                                  {(selectedQuote?.partsData[index]?.file?.filename ||
                                    selectedQuote?.partsData[index]?.file?.originalname ||
                                    selectedQuote?.partsData[index]?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]?.selectedFile?.name)
                                    ? (
                                      selectedQuote?.partsData[index]?.file?.filename ||
                                      selectedQuote?.partsData[index]?.file?.originalname ||
                                      selectedQuote?.partsData[index]?.selectedFile?.path ||
                                      selectedQuote?.partsData[index]?.selectedFile?.name
                                    ).substring(0, 20) + "..."
                                    : selectedQuote?.partsData[index]?.file?.filename ||
                                    selectedQuote?.partsData[index]?.file?.originalname ||
                                    selectedQuote?.partsData[index]?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]?.selectedFile?.name}{" "}
                                  {` : Line ${index + 1}`}
                                </label>
                                <input
                                  id={`upload-2d-${index}`}
                                  type="file"
                                  onChange={(e) => {
                                    let filesForCheck = Array.from(e.target.files);
                                    let isAllFilesValid = true;
                                    filesForCheck.forEach((file) => {
                                      if (file.size > 10485760) {
                                        isAllFilesValid = false;
                                      }
                                    });

                                    if (!isAllFilesValid) {
                                      toast.error("File size should be less than 10 MB");
                                      return;
                                    }

                                    handleFileUpload(e, index, "2D");
                                  }}
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                    width: "0px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </td>


                        <td
                          style={{
                            textAlign: "left",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            minWidth: "200px",
                          }}
                        >
                          {rFQData.selectedProcess > 2 ? (
                            <>
                              <textarea
                                className="border px-1"

                                col="10"
                                row="5"
                                style={{ width: "100%" }}
                                value={item.description ? item.description : ""}
                                placeholder="Enter description"
                                onChange={(e) =>
                                  handleRFQItemDescChange(
                                    e,
                                    index,
                                    "description"
                                  )
                                }
                              />
                            </>
                          ) : (
                            <>
                              <div
                                className="desc-part"
                                style={{ minWidth: "220px" }}
                              >
                                <input
                                  style={{ width: "100%" }}
                                  className="border px-1"
                                  value={
                                    item.description ? item.description : ""
                                  }
                                  placeholder="Enter description"
                                  onChange={(e) =>
                                    handleRFQItemDescChange(
                                      e,
                                      index,
                                      "description"
                                    )
                                  }
                                />
                                {rFQData.selectedProcess == 1 ?
                                  <>
                                    {item?.cncMaterial ? (
                                      <>
                                        <li>
                                          Material :
                                          {
                                            fullMastersData?.cncMaterialData?.find(
                                              (val) => val._id === item.cncMaterial
                                            )?.cnc_material || ""
                                          }
                                        </li>
                                        <li>
                                          Grade :
                                          {
                                            fullMastersData?.cncMaterialData?.find(
                                              (val) => val._id === item.cncMaterial
                                            )?.cnc_grade || ""
                                          }
                                        </li>
                                      </>
                                    ) : null}
                                    {item?.cncSurfaceFinish ? (
                                      <li> Finish :
                                        {
                                          fullMastersData?.cncSurfaceFinishData?.find(
                                            (val) => val._id === item.cncSurfaceFinish
                                          )?.cnc_surface_finish || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncHeatTreatment ? (
                                      <li> Treatment:
                                        {
                                          fullMastersData?.cncHeatTreatmentData?.find(
                                            (val) => val._id === item.cncHeatTreatment
                                          )?.cnc_heat_treatment || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncTolerance ? (
                                      <li> Tolerance :
                                        {
                                          fullMastersData?.cncToleranceData?.find(
                                            (val) => val._id === item.cncTolerance
                                          )?.cnc_tolerance || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncParkMarking ? (
                                      <li> Marking :
                                        {
                                          fullMastersData?.cncPartMarkingData?.find(
                                            (val) => val._id === item.cncParkMarking
                                          )?.cnc_part_marking || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncInternalCorner ? (
                                      <li>Internal corner :
                                        {
                                          fullMastersData?.cncInternalCornerData?.find(
                                            (val) => val._id === item.cncInternalCorner
                                          )?.cnc_internal_corner || ""
                                        }
                                      </li>
                                    ) : null}

                                  </>
                                  :
                                  <>

                                    {item?.threeDTechnology ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDTechnologyData?.find(
                                            (val) => val._id === item.threeDTechnology
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDMachine ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDMachineData?.find(
                                            (val) => val._id === item.threeDMachine
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDMaterial ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDMaterialData?.find(
                                            (val) => val._id === item.threeDMaterial
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDPostProcessing ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDPostProcessingData?.find(
                                            (val) => val._id === item.threeDPostProcessing
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}


                                  </>}
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          <input
                            className="p-1 border max-w-[80px]"
                            value={item.qty}
                            placeholder="Quantity"
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleRFQItemQtyChange(e, index)}
                          />
                        </td>
                        <td className="flex justify-between px-2  items-center border-t border-gray-300 border-b-0 border-b-transparent">
                          <button className="px-2 py-0.5 text-sm bg-red-500 min-w-[70px] text-white rounded mx-1" onClick={() => handleDeleteRow(index)}>Del</button>
                          <button className="px-2 py-0.5 text-sm bg-yellow-400 min-w-[70px] text-white rounded " onClick={() => handleClone(index)}>Clone</button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ textAlign: "left" }}>
                <h6 className="font-medium my-1">Admin Comments :&nbsp;&nbsp;</h6>
                <textarea
                  rows="3"
                  className="p-1"
                  style={{ width: "100%" }}
                  placeholder="Enter comment here"
                  value={rFQData?.adminComment}
                  onChange={(e) =>
                    setRFQData({ ...rFQData, adminComment: e.target.value })
                  }
                ></textarea>
              </div>

              <div style={{ textAlign: "left", marginBottom: "20px" }}>
                <button
                  className="common-supplier-button"
                  onClick={handleUpdateRFQPartData}
                >
                  Update RFQ Data{" "}
                </button>
                <button className="common-supplier-button" onClick={handleNewRowCreating}>
                  Add Line Item
                </button>
                <button
                  className="common-supplier-button"
                  onClick={() =>
                    navigate(`/admin/admin-quote/${rFQData?.quotationId}`)
                  }
                >
                  Go To Quotation{" "}
                </button>

                {rFQData?.rfqStatus != 'cancelled' && <button
                  className="common-supplier-button"
                  onClick={handleCancelRFQ}
                >
                  Cancel RFQ{" "}
                </button>}
                {purchaseOrder._id ? (
                  <>
                    <button
                      className="common-supplier-button"
                      onClick={() =>
                        navigate(
                          `/admin/purchase-order-details/${purchaseOrder._id}`
                        )
                      }
                    >
                      Go To PO{" "}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
                flexWrap: "wrap",
              }}
            >
              <div>
                <Form.Select
                  onChange={(e) =>
                    setSelectedSupplier(JSON.parse(e.target.value))
                  }
                  defaultValue="default"
                  style={{ minWidth: "400px", marginTop: "10px" }}
                >
                  <option disabled value="default">
                    Select a supplier
                  </option>
                  {supplierList.map((item, i) => {
                    return (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.companyName}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
              <div>
                <button
                  onClick={handleAddSupplier}
                  className="common-supplier-button"
                  style={{ minWidth: "150px", marginTop: "10px" }}
                >
                  Add Supplier
                </button>
              </div>
            </div>

            <br />
            {supplierRFQs.length > 0 ? (
              <>
                <table className="supplierTable">
                  <thead>
                    <tr>
                      <th>Choose Supplier</th>
                      <th>Supplier</th>
                      <th>Cost</th>
                      <th>LeadTime</th>
                      <th>Unit Price</th>
                      <th>Days since RFQ</th>
                      <th>RFQ Document</th>
                      <th>Download RFQ</th>
                      <th>Email</th>
                      <th>Chat</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supplierRFQs.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td
                            style={{
                              textAlign: "center",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "30px",
                            }}
                          >
                            <Switch
                              className="custom-switch"
                              on="true"
                              off="false"
                              value={item?.isSelected?.toString()}
                              onChange={() => handleSwitchClick(item)}
                            />
                          </td>
                          <td>
                            {item?.supplierId?.companyName}&nbsp;&nbsp; (
                            {item.supplierId.supplierRefId})
                          </td>
                          <td>
                            {item.price
                              ? currencyFormatter(item.price.toFixed(2))
                              : "NA"}
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "150px",
                            }}
                          >
                            {item?.leadTime ? (
                              <>
                                <p style={{ marginBottom: "0px" }}>
                                  {item?.leadTime + " Days"}{" "}
                                </p>
                                {`(${Math.floor(item?.leadTime / 7)}-${Math.floor(item?.leadTime / 7) + 1
                                  } weeks)`}
                              </>
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                handleRFQModelShow(item._id, item.status);
                              }}
                            >
                              Add/Edit
                            </button>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.receivedDate ? (
                              <>
                                {Math.abs(
                                  moment(item.receivedDate).diff(
                                    item?.quoteSubmitDate,
                                    "days"
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                {Math.abs(
                                  moment(item.createdAt).diff(
                                    item?.quoteSubmitDate,
                                    "days"
                                  )
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            {item.rfqDocument ? (
                              <>
                                <div>
                                  <a
                                    className="text-primaryColor inline-block"
                                    onClick={() =>
                                      download(
                                        item.rfqDocument.originalname,
                                        `${process.env.REACT_APP_SUPPLIER_BACKEND}/${item.rfqDocument.path}`
                                      )
                                    }
                                    download={item.rfqDocument.filename}
                                  >
                                    <span>
                                      {item.rfqDocument.originalname}
                                    </span>
                                    <DownloadIcon additionalClass={'!h-4 inline-block'} />
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <label
                                  className="rfq-doc-label text-center"
                                  htmlFor={`rfq-doc-${i}`}
                                >
                                  Upload
                                </label>
                                <input
                                  id={`rfq-doc-${i}`}
                                  type="file"
                                  onChange={(e) => {

                                    let filesForCheck = Array.from(e.target.files);
                                    let isAllFilesValid = true;
                                    filesForCheck.forEach(file => {
                                      if (file.size > maxFileUploadSize) {
                                        isAllFilesValid = false;
                                      }
                                    })

                                    if (!isAllFilesValid) {
                                      toast.error("File size should be less than 10 MB");
                                      return;
                                    }
                                    handleRFQDocumentUpload(e, item?._id, i);
                                  }}
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                    width: "0px",
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td>
                            <button onClick={() => handleRFQDownload(item)}>
                              Download
                            </button>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              minWidth: "150px",
                            }}
                          >
                            {" "}
                            {item.isReceived ? (
                              <>
                                RFQ Sent on{" "}
                                {moment(item.receivedDate).format(
                                   "MMM DD, YYYY"
                                )}
                                <p style={{ fontWeight: "bolder" }}>
                                  ({item.status})
                                  <br />
                                  {item.isDeclined ? (
                                    <>
                                      <button
                                        onClick={() =>
                                          handleShowDeclinedDetails(item._id)
                                        }
                                        style={{
                                          minWidth: "90px",
                                        }}
                                      >
                                        View Reason
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.poCreationDate ? (
                                    <>
                                      {" "}
                                      Po created on{" "}
                                      {moment(item.poCreationDate).format(
                                       "MMM DD, YYYY"
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    setSelectedRFQForMail(item._id);
                                    setShowCaptchaModal(true);
                                  }}
                                  style={{
                                    minWidth: "90px",
                                  }}
                                >
                                  Send RFQ
                                </button>

                                <button
                                  onClick={() => handleMarkAsSentRFQ(item._id)}
                                  style={{
                                    minWidth: "90px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Mark As Sent
                                </button>
                              </>
                            )}
                          </td>
                          <td >
                            <Chat
                              RefId={item?.supplierRFQRefId}
                              open={item?.supplierRFQRefId == selectedChatRefId}
                              onDrawerClose={onClose}
                            />
                            {/* <button
                              onClick={() =>
                                showSupplierDrawer(item?.supplierRFQRefId)
                              }
                              // style={{ width: "100px", textAlign: "center" }}

                            > */}
                            <ChatButton
                              RefId={item?.supplierRFQRefId}
                              showDrawer={() =>
                                showSupplierDrawer(item?.supplierRFQRefId)
                              }
                              color={"white"}
                              additionalClass={'!bg-[#DC712B] hover:bg-[#bd5612]  w-max px-2 !h-8'}
                            />
                            {/* </button> */}
                          </td>
                          <td>
                            {!item?.isSelected ? <>
                              <button onClick={() => handleDeleteRFQ(item._id)}>
                                Delete
                              </button>
                            </> :
                              <>
                                PO Created
                              </>}

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </span>

      <Modal
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        show={showRfqModal}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            RFQ Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="version-tabs" style={{ overflowX: "auto" }}>
            <table className="qoute-version-table">
              <thead>
                <tr className="heading-row">
                  <th scope="col">Part Name</th>
                  <th scope="col">Attachment 1</th>
                  <th scope="col">Attachment 2</th>
                  <th scope="col">Description</th>
                  <th scope="col">Part Notes</th>
                  <th scope="col">Supplier Notes</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {rFQtoShow?.partsData?.length > 0 &&
                  rFQtoShow.partsData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ minWidth: "80px" }}>
                          <p>{item?.partName ? item?.partName : "Not found"}</p>
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            minWidth: "120px",
                          }}
                        >
                          <div
                            className="prd-thumb downloadables"
                            style={{ color: "dodgerblue" }}
                          >
                            <p style={{ color: "dodgerblue" }}>
                              {item.selectedPartFile ? (
                                <>
                                  <div >
                                    <span>{item?.selectedPartFile?.originalname}</span>
                                    <span

                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        let url = `${process.env.REACT_APP_SUPPLIER_BACKEND}/${item.selectedPartFile.path}`;
                                        download(
                                          item?.selectedPartFile?.originalname,
                                          url
                                        );
                                      }}
                                    >
                                      <DownloadIcon />
                                    </span>
                                  </div>
                                </>
                              ) : (
                                "File Not Available"
                              )}
                            </p>
                          </div>
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            minWidth: "120px",
                          }}
                        >
                          <div
                            className="prd-thumb downloadables"
                            style={{ color: "dodgerblue" }}
                          >
                            <p style={{ color: "dodgerblue" }}>
                              {item.selected3DPartFile ? (
                                <>
                                  <div>
                                    <span>{item?.selected3DPartFile?.originalname}</span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        let url = `${process.env.REACT_APP_API_ENDPOINT}/${item.selected3DPartFile.path}`;
                                        download(
                                          item?.selected3DPartFile?.originalname,
                                          url
                                        );
                                      }}
                                    >
                                      <DownloadIcon />
                                    </span>
                                  </div>
                                </>
                              ) : (
                                "File Not Available"
                              )}
                            </p>
                          </div>
                        </td>
                        <td style={{ minWidth: "250px" }}>
                          {rFQtoShow?.rfqId?.selectedProcess >= 3 ? (
                            <>{item.description}</>
                          ) : (
                            <>
                              <div className="desc-part">
                                {item?.description ? (
                                  <li>{item?.description}</li>
                                ) : (
                                  ""
                                )}

                                {rFQtoShow.selectedProcess == 1 ?
                                  <>
                                    {item?.cncMaterial ? (
                                      <>
                                        <li>
                                          {
                                            fullMastersData?.cncMaterialData?.find(
                                              (val) => val._id === item.cncMaterial
                                            )?.cnc_material || ""
                                          }
                                        </li>
                                        <li>
                                          {
                                            fullMastersData?.cncMaterialData?.find(
                                              (val) => val._id === item.cncMaterial
                                            )?.cnc_grade || ""
                                          }
                                        </li>
                                      </>
                                    ) : null}
                                    {item?.cncSurfaceFinish ? (
                                      <li> Finish :
                                        {
                                          fullMastersData?.cncSurfaceFinishData?.find(
                                            (val) => val._id === item.cncSurfaceFinish
                                          )?.cnc_surface_finish || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncHeatTreatment ? (
                                      <li> Treatment:
                                        {
                                          fullMastersData?.cncHeatTreatmentData?.find(
                                            (val) => val._id === item.cncHeatTreatment
                                          )?.cnc_heat_treatment || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncTolerance ? (
                                      <li> Tolerance :
                                        {
                                          fullMastersData?.cncToleranceData?.find(
                                            (val) => val._id === item.cncTolerance
                                          )?.cnc_tolerance || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncParkMarking ? (
                                      <li> Marking :
                                        {
                                          fullMastersData?.cncPartMarkingData?.find(
                                            (val) => val._id === item.cncParkMarking
                                          )?.cnc_part_marking || ""
                                        }
                                      </li>
                                    ) : null}
                                    {item?.cncInternalCorner ? (
                                      <li>Internal corner :
                                        {
                                          fullMastersData?.cncInternalCornerData?.find(
                                            (val) => val._id === item.cncInternalCorner
                                          )?.cnc_internal_corner || ""
                                        }
                                      </li>
                                    ) : null}

                                  </>
                                  :
                                  <>

                                    {item?.threeDTechnology ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDTechnologyData?.find(
                                            (val) => val._id === item.threeDTechnology
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDMachine ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDMachineData?.find(
                                            (val) => val._id === item.threeDMachine
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDMaterial ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDMaterialData?.find(
                                            (val) => val._id === item.threeDMaterial
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}

                                    {item?.threeDPostProcessing ? (
                                      <li>
                                        {
                                          fullMastersData?.threeDPostProcessingData?.find(
                                            (val) => val._id === item.threeDPostProcessing
                                          )?.name || ""
                                        }
                                      </li>
                                    ) : null}


                                  </>}
                              </div>
                            </>
                          )}
                        </td>
                        <td style={{ minWidth: "150px" }}>
                          <p>{item?.partNotes}</p>
                        </td>
                        <td style={{ minWidth: "50px" }}>{item?.notes}</td>
                        <td>
                          <div className="amt">
                            <input
                              className="border p-1"
                              placeholder="Cost"
                              value={item.price ? item.price : ""}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) => {
                                handleRFQItemPriceChange(e, index);
                              }}
                            // disabled={!isRFQUpdatable}
                            />
                          </div>
                        </td>
                        <td>
                          <p className="amt  p-1">{item?.qty}</p>{" "}
                        </td>
                        <td style={{ minWidth: "90px" }}>
                          <div className="amt">
                            <p className="un-fld">
                              {currencyFormatter(
                                Number((item.qty * item.price) || 0).toFixed(2)
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                <tr className="leadTimeRow">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td id="leadTimeTitle">Lead Time</td>
                  <td>
                    <div style={{ maxWidth: "80px" }}>
                      <input
                        className="un-fld border p-1"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        value={rFQtoShow?.leadTime ? rFQtoShow?.leadTime : ""}
                        placeholder="Days"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onChange={(e) =>
                          setRFQToShow({
                            ...rFQtoShow,
                            leadTime: e.target.value,
                          })
                        }
                      // disabled={!isRFQUpdatable}
                      />
                      <p>
                        {rFQtoShow?.leadTime
                          ? `(${Math.floor(rFQtoShow?.leadTime / 7)}-${Math.floor(rFQtoShow?.leadTime / 7) + 1
                          } weeks)`
                          : "NA"}{" "}
                      </p>
                    </div>
                  </td>
                </tr>

                <tr className="leadTimeRow">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td id="leadTimeTitle">Total Cost</td>
                  <td>
                    <div className="amt">
                      <p>
                        {rFQtoShow?.price
                          ? currencyFormatter(
                            Number(rFQtoShow?.price).toFixed(2)
                          )
                          : ""}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isRFQUpdatable ? (
            <>
              <Button
                className="req-quote-btn supplierButton"
                onClick={handleUpdateRfQClick}
              >
                Update RFQ
              </Button>
            </>
          ) : (
            <></>
          )}
          <Button
            className="req-quote-btn supplierButton"
            onClick={handleModalClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RFQDetails;
