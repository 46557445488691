import React, { useEffect, useState } from 'react'
import { fetchDashboardDataForMake } from '../utils/actions/adminActions';
import { toast } from 'react-toastify';
import { QuotationsIcon, SalesIcon } from '../components/Icons';
import FlipNumbers from 'react-flip-numbers'
import PieChart, { colorMap } from './AdminCharts/PieCharts';
import AdminPieChart from './AdminCharts/PieCharts';
import LineChartComponent from './AdminCharts/LineCharts';
import { processType, quoteVersionDescriptions } from '../utils/constant';
import SkeltonLoadingComponent from '../components/LoadingComponents/SkeltonLoadingComponent';
import axios from 'axios';
import { currencyFormatter } from '../utils/helper';

const AdminCustomerComponentNew = (
  {
    selectedMonth,
    selectedYear,
    allCompany,
    allCustomers,
  }
) => {
  let token = localStorage.getItem("Token");
  const [loading, setLoading] = useState(false);
  const [ceddTrackerData, setCeddTrackerData] = useState();
  const [customerOrderSummaryData, setCustomerOrderSummaryData] = useState();
  const [leadTimeTrackerData, setLeadTimeTrackerData] = useState();
  const [localProcessSelectedSummaryData, setlocalProcessSelectedSummaryData] = useState();
  const [localQuotationDescriptionSummaryData, setlocalQuotationDescriptionSummaryData] = useState({});
  const handleNavigate = (path) => {
    window.open(`${window.location.origin}${path}`, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterDataToFetch = {
          selectedYears: [...selectedYear.filter((item) => item.checked).map(item => item.value)],
          selectedMonths: [...selectedMonth.filter(item => item.checked && item.id>0).map(item => item.id)],
          selectedCompanys: [...allCompany.filter(item => item.checked).map(item => item.id)],
          selectedUsers: [...allCustomers.filter(item => item.checked).map(item => item.id)],
        };
        if(filterDataToFetch.selectedMonths.length==0 || filterDataToFetch.selectedYears.length==0)return;
        setLoading(true);

        const response = await fetchDashboardDataForMake(token, filterDataToFetch);
        if (response.success) {
          const data = response.data;
          // console.log(data);
          const { ceddTrackerData, customerOrderSummaryData, leadTimeTrackerData, localProcessSelectedSummaryData, localQuotationDescriptionSummaryData } = data;
          setCeddTrackerData(ceddTrackerData);
          setCustomerOrderSummaryData(customerOrderSummaryData);
          setLeadTimeTrackerData(leadTimeTrackerData);
          setlocalProcessSelectedSummaryData(localProcessSelectedSummaryData);
          setlocalQuotationDescriptionSummaryData(localQuotationDescriptionSummaryData);
        }
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setLoading(false);
          toast.error("Unable to load customer data. Please refresh or contact support if the issue persists.");
        }
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear, allCompany, allCustomers]);
  return (
    !loading ? <div className='my-2'>
      <div className='items grid ms:grid-col-2 md:grid-cols-3 lg:grid-cols-5 gap-2 '>
        <div className=' bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3'>
          <div className="basis-4/5 mx-2 flex flex-col ms-2  ">
            <p className="text-[16px] font-bold lg:text-xl  " >Quotation</p>
            <p className="flex justify-start ">
              <div className='  my-2 font-bold' >
                <FlipNumbers
                  className='border border-green-500'
                  height={20}
                  width={20}
                  color="black"
                  background="white"
                  play
                  duration={2}
                  perspective={120}
                  numbers={String(customerOrderSummaryData?.quotations || 0)}
                />
              </div>
            </p>
          </div>

          <div className='basis-2/5 lg:basis-3/5  text-white flex items-center justify-center aspect-square rounded-full ' style={{ backgroundColor: colorMap['Quotation'] }}>
            <QuotationsIcon />
          </div>
        </div>
        <div className=' bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3'>
          <div className="basis-4/5 mx-2 flex flex-col">
            <p className="text-[16px] font-bold  lg:text-xl ">In progress  </p>
            <p className="flex justify-start ">
              <div className='  my-2 font-bold'>
                <FlipNumbers
                  className='border border-green-500'
                  height={20}
                  width={20}
                  color="black"
                  background="white"
                  play
                  duration={2}
                  perspective={120}
                  numbers={String(customerOrderSummaryData?.salesOrders || 0)}
                />
              </div>
            </p>
          </div>

          <div className='basis-2/5 lg:basis-3/5 text-white  flex items-center justify-center aspect-square rounded-full bg-gray-200' style={{ backgroundColor: colorMap["Sales"] }}>
            <SalesIcon />
          </div>
        </div>
        <div className=' bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3' >
          <div className="basis-4/5 mx-2 flex flex-col   ">
            <p className="text-[16px] font-bold  lg:text-xl "> Shipped </p>
            <p className="flex justify-start ">
              <div className='  my-2 font-bold'>
                <FlipNumbers
                  className='border border-green-500'
                  height={20}
                  width={20}
                  color="black"
                  background="white"
                  play
                  duration={2}
                  perspective={120}
                  numbers={String(customerOrderSummaryData?.shippedOrders || 0)}
                />
              </div>
            </p>
          </div>

          <div className='basis-2/5 lg:basis-3/5   flex items-center justify-center aspect-square rounded-full text-white' style={{ backgroundColor: colorMap["Shipped"] }}>
            <QuotationsIcon />
          </div>
        </div>
        <div className=' bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3'>
          <div className="basis-4/5 mx-2 flex flex-col   ">
            <p className="text-[16px] font-bold  lg:text-xl ">  Completed</p>
            <p className="flex justify-start ">
              <div className='  my-2 font-bold'>
                <FlipNumbers
                  className='border border-green-500'
                  height={20}
                  width={20}
                  color="black"
                  background="white"
                  play
                  duration={2}
                  perspective={120}
                  numbers={String(customerOrderSummaryData?.completedOrders || 0)}
                />
              </div>
            </p>
          </div>

          <div className='basis-2/5  lg:basis-3/5  flex items-center justify-center aspect-square rounded-full text-white' style={{ backgroundColor: colorMap["Completed"] }}>
            <QuotationsIcon />
          </div>
        </div>
        <div className=' bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3'>
          <div className="basis-4/5 mx-2 flex flex-col   ">
            <p className="text-[16px] font-bold lg:text-xl  ">Issues </p>
            <p className="flex justify-start ">
              <div className='  my-2 font-bold'>
                <FlipNumbers
                  className='border border-green-500'
                  height={20}
                  width={20}
                  color="black"
                  background="white"
                  play
                  duration={2}
                  perspective={120}
                  numbers={String(customerOrderSummaryData?.issueSalesOrders || 0)}
                />
              </div>
            </p>
          </div>

          <div className='basis-2/5  lg:basis-3/5  flex items-center justify-center aspect-square rounded-full text-white' style={{ backgroundColor: colorMap["Issues"] }}>
            <QuotationsIcon />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-2 my-2' >
        <div className='bg-white col-span-full  lg:col-span-4  gap-2 ' >
          <div>
            <h3 className='text-center min-h-10 flex items-center justify-center   font-bold bg-slate-200   '>Orders Summary</h3>

            <div className='flex items-center justify-between  bg-primaryColor px-2 text-white min-h-10'>
              <span className='font-bold'>Category</span>
              <span className='font-bold'>No of Orders</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium items' onClick={() =>
                handleNavigate("/admin/quotes")
              }>Quotations</span>
              <span className=''>{customerOrderSummaryData?.quotations || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium items' onClick={() =>
                handleNavigate("/admin/orders")
              }>In Progress Orders</span>
              <span className=''>{customerOrderSummaryData?.salesOrders || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium items' onClick={() =>
                handleNavigate("/admin/orders")
              }>Shipped Orders</span>
              <span className=''>{customerOrderSummaryData?.shippedOrders || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium items' onClick={() =>
                handleNavigate("/admin/orders")
              }>Orders Completed</span>
              <span className=''>{customerOrderSummaryData?.completedOrders || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium items ' onClick={() =>
                handleNavigate("/admin/orders")
              }>Sales Order Issues</span>
              <span className=''>{customerOrderSummaryData?.issueSalesOrders || 0}</span>
            </div>
          </div>
          <div>
            <h3 className='text-center min-h-10  flex items-center justify-center   font-bold bg-slate-200   '>CEDD Tracker          </h3>

            <div className='flex items-center justify-between  bg-primaryColor px-2 text-white min-h-10'>
              <span className='font-bold'>Category</span>
              <span className='font-bold'>No of Orders</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium ' onClick={() =>
                handleNavigate("/admin/quotes")
              }>CEDD Overdue :</span>
              <span className=''>{ceddTrackerData?.ceddOverDue || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium ' onClick={() =>
                handleNavigate("/admin/quotes")
              }>Less than 7 Days :</span>
              <span className=''>{ceddTrackerData?.lessThan7Days || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium ' onClick={() =>
                handleNavigate("/admin/quotes")
              }>7-14 Days :</span>
              <span className=''>{ceddTrackerData?.lessThan14Days || 0}</span>
            </div>
            <div className='flex items-center justify-between  border-b   px-2  min-h-10'>
              <span className='font-medium ' onClick={() =>
                handleNavigate("/admin/quotes")
              }>Greater than 14 Days :</span>
              <span className=''>{ceddTrackerData?.greaterThan14Days || 0}</span>
            </div>
          </div>
        </div>
        <div className='bg-white col-span-full lg:col-span-8 border '>
          <h3 className=' min-h-10 flex items-center justify-center   font-bold  bg-slate-200   '>Process Selected Summary</h3>
          <div className='grid grid-cols-4 px-2 font-bold   border-b-2 bg-primaryColor pt-2 text-white min-h-10'>
            <h5>Process</h5>
            <h5>No. of Order(s)</h5>
            <h5 className='text-right'>Total Sales Value (Inc Tax)</h5>
            <h5 className='text-right'>Total Sales Value (Ex Tax)</h5>
          </div>
          {
            localProcessSelectedSummaryData?.map((item, idx) => {
              return <div className='grid grid-cols-4 px-2 font-medium my-2 h-7 text-sm border-b'>
                <p className='font-bold'>{processType[idx].name} {idx <= 1 && <span>(Automated)</span>}</p>
                <p>{localProcessSelectedSummaryData[idx]?.numberOfOrder || 0}</p>
                <p className='text-right'> {currencyFormatter(localProcessSelectedSummaryData[idx]?.totalSalesValue  || 0 ) }</p>
                <p className='text-right'> {currencyFormatter(localProcessSelectedSummaryData[idx]?.totalSalesValueWithoutTax || 0) }</p>
              </div>
            })
          }
          <div className='grid grid-cols-4 px-2 font-medium my-2 h-7 text-sm '>
            <p className='font-bold'>Total</p>
            <p className='font-bold'>{localProcessSelectedSummaryData?.reduce((pre, item) => pre + (item.numberOfOrder), 0) || 0}</p>
            <p className='font-bold text-right'> {currencyFormatter(localProcessSelectedSummaryData?.reduce((pre, item) => pre + (item.totalSalesValue), 0) || 0)}</p>
            <p className='font-bold text-right'> {currencyFormatter(localProcessSelectedSummaryData?.reduce((pre, item) => pre + (item.totalSalesValueWithoutTax), 0) || 0)}</p>

          </div>


        </div>
        <div className='bg-white col-span-full lg:col-span-6 border '>
          <h3 className='text-center min-h-10 flex items-center justify-center   font-bold  bg-slate-200 '>Quotation Description Summary</h3>
          <div className='flex items-center justify-between  bg-primaryColor px-2 text-white min-h-10'>
            <span className='font-bold'>Category</span>
            <span className='font-bold'>No of Orders</span>
          </div>
          {
            quoteVersionDescriptions.map((item, idx) => {
              return <div className='flex items-center justify-between   px-2 border-b min-h-10 font-medium'>
                <span >{item.value}</span>
                <span> {localQuotationDescriptionSummaryData[item.id] || 0}</span>
              </div>
            })
          }
        </div>
        <div className='bg-white col-span-full lg:col-span-6 border max-h-max'>
          <h3 className='text-center min-h-10 flex items-center justify-center   font-bold bg-slate-200  '>Lead Time Tracker</h3>
          <div className='flex items-center justify-between  bg-primaryColor px-2 text-white min-h-10'>
            <span className='font-bold'>Category</span>
            <span className='font-bold'>Average Data</span>
          </div>
          <div className='flex items-center justify-between   px-2  min-h-10'>
            <span className='font-bold'>Quotations (days):</span>
            <span className='font-bold'>{leadTimeTrackerData?.quotations || 0}</span>
          </div>
          <div className='flex items-center justify-between   px-2  min-h-10'>
            <span className='font-bold'>Sales Orders (days):</span>
            <span className='font-bold'>{leadTimeTrackerData?.salesOrders || 0}</span>
          </div>
          <div className='flex items-center justify-between   px-2  min-h-10'>
            <span className='font-bold'>Late Orders :</span>
            <span className='font-bold'>
              {leadTimeTrackerData?.lateOrders
                ? leadTimeTrackerData?.lateOrders
                : 0}{" "}
              /{" "}
              {leadTimeTrackerData?.totalOrdersForLateOrders
                ? leadTimeTrackerData?.totalOrdersForLateOrders
                : 0}
            </span>
          </div>



        </div>
      </div>
    </div> :
      <div>

        <SkeltonLoadingComponent />

      </div>
  )
}

export default AdminCustomerComponentNew