import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header/header';
import QuotationLoader from './CreateQuoteComponents/QuotationLoader/QuotationLoader';
import {
  CREATE_QUOTE_ACTION,
  useCreateQuoteContext
} from '../../context/create.quote.context';
import { useNavigate, useParams, useLocation, Link, useSearchParams } from "react-router-dom";
import { getFileExtension, getPriorityShippingMethod, isShowPriceAccordingToProcess, isValidExtension, validateUser } from '../../utils/helper';
import LeftSection from './CreateQuoteComponents/LeftSection/LeftSection';
import CenterSection from './CreateQuoteComponents/CenterSection/CenterSection';
import RightSection from './CreateQuoteComponents/RightSection/RightSection';
import { toast } from 'react-toastify';
import { commanFields, isValidEmail, maxFileUploadSize } from '../../utils/constant';
import { firebaseSignOnLoginSuccess } from "../../utils/firebase";
import moment from "moment";
import { config } from '../../environment/development';

// IMG Import
import logo from "../../assets/static/logo/logo.png";
import { getAllMachineDataWithPopulation, getAllTechnology, getSeq } from "../../utils/actions/adminActions";
import { resetCreateQuoteState, setCommanValues, setSameForAllField, setValues, updateSelectedProcess } from '../../context/create.quote.actions';
import { postApi, fetchApi } from "../../utils/actions/commanActions";

import fileSaver from "file-saver";
import { getDefaultAddressOfUser } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../../components/ForwardToPurchaserModal/index"

import {
  getMaterial,
  quoteSaveMail,
  getAddressById,
  downloadPdf,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
  performAnalysisForFile,
} from "../../utils/actions/allactions";

import Chat from "../../Chat";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import ShareModalComponent from "../../components/ShareComponent/ShareModalComponent";
import TargetCostUserModal from "../../components/TargetCostUserModal/TargetCostUserModal";
import TermsOfUseModal from './CreateQuoteComponents/TermsOfUseModal/TermsOfUseModal';
import SignInModal from '../../components/LoggedInModal/SignInModal';
import { checkCreateUser, handleUpdateLogFlag } from '../../utils/actions/userActions';
import TopSection from './CreateQuoteComponents/TopSection/TopSection';
import FullScreenFileDrop from '../../components/FullScreenFileDrop/FullScreenFileDrop';
import { downloadQuotationPdf } from '../../api/customer/quotation/commonQuotationApi';
import DownloadQuotationModal from '../../components/DownloadQuotationComponent/DownloadQuotationModal';



const CreateInstantQuotation = () => {
  const { quoteId } = useParams();
  const [isThreeSecondLoading, setIsThreeSecondLoading] = useState(true)
  let _id = quoteId;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let token = localStorage.getItem('Token') || queryParams.get("token");
  const myRef = useRef();
  const userEmail = localStorage.getItem("email");
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const [isLoading, setIsLoading] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const [isGlobeLoader, setIsGlobeLoader] = useState();
  const [selectedQuote, setSelectedQuote] = useState();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allFilteredUsers, setAllFilteredUsers] = useState([]);
  const [userIdToEditQuote, setUserIdToEditQuote] = useState({})
  const [isValidUser, setIsValidUser] = useState(true);
  const [isAdminShare, setIsAdminShare] = useState(false)
  const [matSubMatMap, setMatSubMatMap] = useState(new Map());
  const [showQuoteUpdateModal, setShowQuoteUpdateModal] = useState(false);
  const [dataForQuote, setDataForQuote] = useState(null);
  const [isSaveForFunctionalityState, setIsSaveForFunctionalityState] = useState(null);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  const funcName = searchParams.get("functionalityName");
  const [masterThreeDTechnologyData, setMasterThreeDTechnologyData] = useState([]);
  const [minCostTechMachineMaterialPostProcess, setMinCostTechMachineMaterialPostProcess] = useState({});
  const [isInchSelected, setIsInchSelected] = useState(false);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);
  const [teamModalShow, setTeamModalShow] = useState(false);
  let projectName = createQuoteState?.commanValues?.projectName;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isUserLoggOut, setIsUserLoggOut] = useState(false);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLogin') == "true" ? true : false)
  const [twoDFiles, setTwoDFiles] = useState([])
  const [isSamplePartUploaded, setIsSamplePartUploaded] = useState();
  const [selectedQuoteRef, setSelectedQuoteRef] = useState("");
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [isFunctionalityPending, setIsFunctionalityPending] = useState(false);
  const [pendingArgument, setPendingArgument] = useState(null);
  const [isRequestReviewPending, setIsRequestReviewPending] = useState(false);
  const [isShowDownloadQuotationModal, setIsShowDownloadQuotationModal] = useState(false);
  const [isDqPending, setIsDqPending] = useState(false);
  const [isPtcPending, setIsPtcPending] = useState(false);
  const [email, setEmail] = useState(() => {
    return localStorage.getItem("email") || "";
  });

  const [isQuoteUpdated, setIsQuoteUpdated] = useState(false);
  const [isLoadedInIframe, setIsLoadedInIframe] = useState(false)
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("Token") && queryParams.get("token")) {
      localStorage.setItem("Token", queryParams.get("token"))
    }
    if (!localStorage.getItem("email") && queryParams.get("email")) {
      setEmail(queryParams.get("email"));
    }
  }, [])


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsThreeSecondLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (quoteId) {
      setIsFirstTimeLoad(false);
    }

    if (window.self !== window.top) {
      console.log('This app is inside an iframe');
      setIsLoadedInIframe(true);
    } else {
      setIsLoadedInIframe(false);
      console.log('This app is not inside an iframe');
    }
  }, [quoteId]);



  const [isUserInfoReceived, setIsUserInfoReceived] = useState(false);

  const [allCncMachineData, setAllCncMachineData] = useState(null);
  const [isFirstFileUploaded, setIsFirstFileUploaded] = useState(false);
  const [quotationUpdatePendingForMissingItem, setQuotationUpdatePendingForMissingItem] = useState(false);

  // let cncError = "We are unable to fetch quotation for some of the selections at the moment. Please sent quote for review, Our applications engineering team will review and get back to you shortly."
  let currentAcceptableProcess = [
    "CNC Machining",
    "3D Printing",
    "Vacuum Casting",
    "Sheet Metal Fabrication",
    "Injection Molding/Casting",
    "Complete Assembly",
    "Reverse Engineering"
  ];



  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (!defaultUnit) {
      localStorage.setItem("defaultUnit", "inch");
    }
    [1, 2, 3].map((item) => {
      localStorage.removeItem(String(item));
    });
    return () => {
      [1, 2, 3].map((item) => {
        localStorage.removeItem(String(item));
      });
    }
  }, []);

  useEffect(() => {
    if (quotationUpdatePendingForMissingItem) {


      //values check
      const selectedProcess = createQuoteState.commanValues.selectedProcess;
      if (selectedProcess == 1) {
        const isAnyDropDownValueIsMissing = createQuoteState.values.some(v => {
          return (!v.cncMaterial
            || !v.cncInternalCorner ||
            !v.cncParkMarking ||
            !v.cncReviewMyDesign
            || !v.cncSurfaceFinish
            || !v.cncTolerance)
        }

        )
        if (isAnyDropDownValueIsMissing) return;
      }
      if (selectedProcess == 2) {
        const isAnyValueIsMissing = createQuoteState.values.some(v => {
          return (
            !v.threeDMachine
            || !v.threeDMaterial
            || !v.threeDPostProcessing
            || !v.threeDTechnology
          );
        });
        if (isAnyValueIsMissing) return;
      };

      setQuotationUpdatePendingForMissingItem(false);
      setIsLoading(false);
      (async () => {
        try {

          await handleQuoteUpdate();

        } catch (error) {

        }
      })()
    }

  }, [quotationUpdatePendingForMissingItem, createQuoteState.values])

  useEffect(() => {
    console.log("IsProcess Changed", createQuoteState.commanValues.isProcessChanged);
    if (createQuoteState.commanValues.isProcessChanged && quoteId) {

      (async () => {
        try {
          const selectedProcess = createQuoteState.commanValues.selectedProcess;
          console.log("Selected Process :", selectedProcess);
          if (selectedProcess == 1) {
            console.log("CNC Machining");
            const isAnyDropDownValueIsMissing = createQuoteState.values.some(v => {
              return (!v.cncMaterial ||
                !v.cncInternalCorner ||
                !v.cncParkMarking ||
                !v.cncReviewMyDesign ||
                !v.cncSurfaceFinish ||
                !v.cncTolerance)
            }
            );
            if (isAnyDropDownValueIsMissing) {
              setQuotationUpdatePendingForMissingItem(true);
              setIsLoading(true);
            }
            else {
              await handleQuoteUpdate();
            }

          }
          else if (selectedProcess == 2) {
            console.log("3D Printing");
            const isAnyPartOutOfSpec = createQuoteState.values.some(v => v.isOutOfSpec == true);
            const isAnyValueIsMissing = createQuoteState.values.some(v => {
              return (
                !v.threeDMachine
                || !v.threeDMaterial
                || !v.threeDPostProcessing
                || !v.threeDTechnology
              );
            }
            )
            if (isAnyPartOutOfSpec || isAnyValueIsMissing) {
              setQuotationUpdatePendingForMissingItem(true);
              setIsLoading(true);
            }
            else {
              await handleQuoteUpdate();
            }

          }
          else {
            await handleQuoteUpdate();
          }


        } catch (error) {

        }
      })()
    }


  }, [createQuoteState.commanValues.isProcessChanged, quoteId]);


  useEffect(() => {
    console.log('use data', isUserInfoReceived, isFirstFileUploaded)
    if (isUserInfoReceived && isFirstFileUploaded) {
      (async () => {
        setIsProcessing(true);
        try {
          const responseApiCall = await saveApiCall(null, "new");
          console.log("Response From SaveApiCall: " + JSON.stringify(responseApiCall));
          setIsProcessing(false)
          setIsUserInfoReceived(false);
          navigate(`/create-instant-quotation/${responseApiCall._id}`);

        } catch (error) {
          console.log("Error IN main User Effect :", error);
          setIsProcessing(false)
        } finally {
          setIsProcessing(false)
        }
      })()
    }

  }, [isUserInfoReceived, isFirstFileUploaded]);

  console.log("createQuoteState Changed Status :", createQuoteState?.commanValues?.isQuoteChanged);


  useEffect(() => {
    setIsSignInModalOpen(false);
    (async () => {
      try {
        let cncAllMachineDataResponse = await getAllMachineDataWithPopulation()
        if (!cncAllMachineDataResponse || !cncAllMachineDataResponse.success) {
          throw new Error();
        };
        const { data } = cncAllMachineDataResponse;
        setAllCncMachineData(data);
      } catch (error) {
        console.log("Error IN main User Effect :", error);
      }
    })()

  }, [])

  useEffect(() => {
    if (location.state) {
      const { pathname, isReset } = location.state;
      if (pathname) {
        setIsNavigationPending(true);
        setIsSignInModalOpen(true);
      }
      if (isReset) {
        createQuoteDispatch({
          type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
        });

      }
    }

  }, [location.state]);



  useEffect(() => {
    let isValidUserLoggedIn = validateUser(token);
    setIsLoggedIn(isValidUserLoggedIn)
    firebaseSignOnLoginSuccess();
  }, [])



  useEffect(() => {
    (async () => {
      const response = await getSeq();


      console.log("Respnse From Seq :", response);


      if (response || response.success) {
        const { data: { seq } } = response;
        createQuoteDispatch({
          type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NUMBER,
          payload: seq,
        });

      }
    })()
  }, [])


  useEffect(() => {
    if (funcName == 'chat') {
      if (selectedQuoteRef) setIsChatOpen(true);
    }
    if (funcName == 'ftp') {
      setIsForwardToPurchaserModalOpen(true);
    }
    if (funcName == 'teams') {
      setTeamModalShow(true);
    }
    if (funcName == 'share' && selectedQuote) {
      setIsShareModalOpen(true);
    }
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [selectedQuoteRef, location.search, selectedQuote]);



  useEffect(() => {
    if (funcName == 'dq' && selectedQuote && createQuoteState?.values?.length && isAllDataLoaded) {
      setIsShowDownloadQuotationModal(true);
    }
  }, [selectedQuote]);


  const handleDownloadQuotation = async () => {
    // console.log("Handle Download Quotation Is Triggered");
    try {
      const id = localStorage.getItem('_id');
      if (!selectedQuote) {
        toast.error("The quote cannot be downloaded at the moment. Please try again later or contact support if the issue persists.");
        return;
      }

      const response = await getDefaultAddressOfUser(id);
      if (!response || !response?.success) {
        throw new Error();
      }
      const defaultAddress = response?.data;

      const selectedShipMethod = selectedQuote?.selectedShipMethod;

      let data = {
        quoteId: selectedQuote?._id,
        selectedShipMethod,
        shippingAddress: defaultAddress.shippingAddress,
        RefId: selectedQuote?.RefId,
        price: selectedShipMethod == 1 ? selectedQuote?.price1 : selectedShipMethod == 2 ? selectedQuote?.price2 : selectedQuote?.price3
      };

      let pdfResponse = await downloadQuotationPdf(data);

      const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedQuote?.RefId}_V${selectedQuote.version}_${data.price}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.log("Error 2 in catch", error);
      toast.error("The quote cannot be downloaded at the moment. Please try again later or contact support if the issue persists.");
    }

  }


  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getAllTechnology(token);
        if (!response || !response.success) {
          toast.error("Failed to retrieve 3D technology.");
          return;
        }
        const { data } = response;

        console.log("Data Main Data :", data);
        setMasterThreeDTechnologyData(data);
        setIsLoading(false);

      } catch (error) {
        console.log("Error in useEffect", error)
        setIsLoading(false);
        toast.error("Failed to retrieve 3D  technology.");
      }
    })()

  }, [])


  useEffect(() => {
    if (masterThreeDTechnologyData.length && quoteId) {
      fetchData();
    }
  }, [quoteId, masterThreeDTechnologyData])



  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (defaultUnit == 'inch') {
      setIsInchSelected(true);
      return;
    }
    setIsInchSelected(false);
    // fetchMaterialData()
  }, [])



  console.log('createQUote state update commanValues 11 createQUote state', createQuoteState);

  const handleNewVersionClick = async () => {

    const isQuoteChanged = createQuoteState?.commanValues?.isQuoteChanged;

    if (isQuoteChanged) {
      const userResponse = confirm("You have made changes to the quote. Do you want to save the changes before creating a new version?");
      if (userResponse) {
        await handleQuoteUpdate();
      }
    }

    try {
      const response = await saveApiCall(null, "new");
      const id = response?._id;
      navigate(`/create-instant-quotation/${id}`);
    } catch (error) {

    }
  }

  const fetchData = async () => {
    try {
      setIsProcessing(true);

      if (!quoteId) {
        setIsProcessing(false);
        return;
      }

      const response = await fetchDataFromApi(`comman/fetch-quote-byId/${quoteId}`);
      const userId = localStorage.getItem("_id");

      setSelectedQuote(response);
      if (userEmail?.split('@')[1] != response?.userId?.email?.split("@")[1] && userEmail?.split('@')[1] != "8xparts.com") {
        setIsValidUser(false);
      }
      setSelectedQuoteRef(response.RefId);
      setUserIdToEditQuote(response.userId._id);
      if (response) {
        if (response.partsData.length > 0) {
          const updatedCommanValues = { ...createQuoteState?.commanValues }
          updatedCommanValues.price1 = response.price1
          updatedCommanValues.price2 = response.price2
          updatedCommanValues.price3 = response.price3
          updatedCommanValues.selectedShipMethod = response.selectedShipMethod
          updatedCommanValues.myorders = response.myorders;
          updatedCommanValues.leadTime1 = response.leadTime1;
          updatedCommanValues.leadTime2 = response.leadTime2;
          updatedCommanValues.leadTime3 = response.leadTime3;
          updatedCommanValues.isUpdatedByAdmin = response.isUpdatedByAdmin;
          updatedCommanValues.isRepeatOrderQuotation = response.isRepeatOrderQuotation;
          updatedCommanValues.shippingCharge1 = response.shippingCharge1;
          updatedCommanValues.shippingCharge2 = response.shippingCharge2;
          updatedCommanValues.shippingCharge3 = response.shippingCharge3;
          updatedCommanValues.shippingDays1 = response.shippingDays1;
          updatedCommanValues.shippingDays2 = response.shippingDays2;
          updatedCommanValues.shippingDays3 = response.shippingDays3;
          updatedCommanValues.projectName = response.projectName;
          updatedCommanValues.createdAt = response.createdAt;
          updatedCommanValues.version = response.version;
          updatedCommanValues.selectedProcess = response.selectedProcess;
          updatedCommanValues.orderCertificationsCost = response.orderCertificationsCost;
          updatedCommanValues.certifications = response.certifications;
          updatedCommanValues.isQuoteChanged = false;




          if (response.selectedProcess == 1 || response.selectedProcess == 2) {
            const showPriceValue = isShowPriceAccordingToProcess(response);
            console.log("showPriceValue :", showPriceValue);
            updatedCommanValues.hidePrice = !isShowPriceAccordingToProcess(response);
          }
          else {
            if (updatedCommanValues?.price1 || updatedCommanValues?.price2 || updatedCommanValues?.price3) {
              updatedCommanValues.hidePrice = false;
            }
            else {
              updatedCommanValues.hidePrice = true;
            }
          }

          if (response) {
            setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState)
            setValues(response.partsData, createQuoteDispatch, createQuoteState)
            setIsInvalidFiles(response.isInvalidFiles)
          }

          let twoFileDataFetched = {};
          response.partsData.forEach((item, i) => {
            if (item?.twoDFile) {
              twoFileDataFetched = { ...twoFileDataFetched, [i]: { file: item?.twoDFile, fileName: item?.twoDFile?.originalname || item?.twoDFile?.filename } }
            }
          });

          setTwoDFiles({ ...twoFileDataFetched });
        }
      }
      setIsProcessing(false);
      setIsAllDataLoaded(true);
    } catch (err) {
      console.log("ERror ing our here", err)
      setIsProcessing(false);
      let msg = err && err.message ? err.message : "An error occurred while fetching quotes.";
      toast.error(msg)
    } finally {
      setIsProcessing(false);
    }
  }

  const onSelectfile = async (filesData, ext) => {
    try {
      setIsProcessing(true);
      let initialLengthOfLines = createQuoteState?.length || 0;


      if (!createQuoteState?.values?.length) {
        setShowTermsOfUseModal(true);
      }

      if (!(filesData && filesData.length > 0)) {
        toast.error("Please select a valid file. (Max File Size : 100MB ) For Large Files Please Send Email At ");
        return;
      }

      // Process all files concurrently using Promise.all
      const analysisResults = await Promise.all(
        filesData.map(async (file, index) => {
          try {

            const response = await performAnalysisForFile(file);

            console.log('response from analysis: ', response);

            const analysisData = {
              s3QrFileUrl: response?.filesS3Data || null,
              jsonFileUrl: response?.quaoarResponseFiles?.[0]?.json || null,
              thumbnail: response?.quaoarResponseFiles?.[1]?.png || null,
              gltfPlainFileUrl: response?.quaoarResponseFiles?.[2]?.glb?.[0] || null,
              gltfColoredFileUrl: response?.quaoarResponseFiles?.[2]?.glb?.[1] || null,
              stagingPartsData: response?.stagingPartResponse || "",
            };

            // Debugging the analysisData
            console.log('analysis data:', analysisData);

            const result = {
              ...commanFields,
              selectedFile: file,
              thumbnail: analysisData.thumbnail,
              s3QrFileUrl: analysisData.s3QrFileUrl,
              jsonFileUrl: analysisData.jsonFileUrl,
              gltfPlainFileUrl: analysisData.gltfPlainFileUrl,
              gltfColoredFileUrl: analysisData.gltfColoredFileUrl,
              stagingPartsDataId: analysisData.stagingPartsData,
              partFileName: file?.path || file?.name,
            };

            // Debugging the returned object
            console.log('analysis returned object:', result);

            return result;

          } catch (error) {
            console.error(`Error analyzing file ${file?.name || file?.path}:`, error);
            toast.error(`Failed to analyze file: ${file?.name || file?.path}`);
            // throw new Error(`Failed to analyze file: ${file?.name || file?.path}`);
            // return null;
          }
        })
      );



      // Filter out any undefined or null results (if any files failed)
      const successfulResults = analysisResults.filter(result => result !== undefined);

      // Add new analysis results to the state values
      let valueCollection = [...createQuoteState?.values || [], ...successfulResults];



      // Add the newly selected files to the files state
      const filesCollection = [
        ...files,
        ...filesData
      ];
      console.log("Value Collection :",valueCollection);
      updateLinesWithDefaultSelection(valueCollection, filesCollection)
      // console.log("updatedValuesCollection",valueCollection);

      // Update state
      console.log("File To Send Backend :", filesCollection);
      setFiles(filesCollection);

      if (valueCollection.length > 0) {
        setValues(valueCollection, createQuoteDispatch, createQuoteState);
      }

      if (!initialLengthOfLines) {
        setIsFirstFileUploaded(true)
      }
      if (quoteId) {
        const updatedCommanValues = { ...createQuoteState.commanValues };
        updatedCommanValues.isProcessChanged = true;
        setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState);
      }




    } catch (err) {
      console.error("Unexpected error:", err);
      setIsProcessing(false);
      const message = err?.message || "An unexpected error occurred during file selection.";
      toast.error(message);
    } finally {
      setIsProcessing(false);
    }
  };

  console.log('called allCNCMachineData', allCncMachineData);
  const updateLinesWithDefaultSelection = (lineItems) => {
    console.log("Called")
    for (let i = 0; i < lineItems.length; i++) {
      if (createQuoteState?.commanValues?.selectedProcess == 1) {


        let cncInfoFilled = true;
        let isOutOfSpec = false;
        try {
          let selectedMachine;
          console.log('line item',lineItems[i])
          if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_MILLING') {
            selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_MILLING')
          }
          else if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE') {
            selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_LATHE')
          }
          else {
            selectedMachine = allCncMachineData?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
            isOutOfSpec = true;
            console.log("Out Of Spec Machine is Selected");
          }

    
          if (selectedMachine) {
            let selectedCNCMaterial = selectedMachine?.cnc_materials.find((item) => item?.cnc_material?.toLowerCase() == 'Aluminum'.toLowerCase());
            if (selectedCNCMaterial) {
              let selectedSurfaceFinish = selectedCNCMaterial?.cnc_surface_finish.find(item=> item?.cnc_surface_finish?.toLowerCase()?.includes('as machined'));
              let selectedHeatTreatment = selectedCNCMaterial?.cnc_heat_treatment.find(item=> item?.cnc_heat_treatment?.toLowerCase()?.includes('not required'));
              let selectedTolerance = selectedCNCMaterial?.cnc_tolerance.find(item=> item?.cnc_tolerance?.toLowerCase()?.includes('medium'));
              let selectedInternalCorner = selectedCNCMaterial?.cnc_internal_corners.find(item=> item?.cnc_internal_corner?.toLowerCase()?.includes('2mm'));
              let selectedReviewMyDesign = selectedCNCMaterial?.cnc_review_my_design.find(item => item.cnc_design_review == false);
              let selectedPartmarking = selectedCNCMaterial?.cnc_part_marking.find(item => item.cnc_part_marking == false)

              lineItems[i].cncMaterial = selectedCNCMaterial?._id || null;
              lineItems[i].cncSurfaceFinish = selectedSurfaceFinish?._id || null;
              lineItems[i].cncHeatTreatment = selectedHeatTreatment?._id || null;
              lineItems[i].cncTolerance = selectedTolerance?._id || null;
              lineItems[i].cncInternalCorner = selectedInternalCorner?._id || null;
              lineItems[i].cncReviewMyDesign = selectedReviewMyDesign?._id || null;
              lineItems[i].cncParkMarking = selectedPartmarking?._id || null;
              lineItems[i].isOutOfSpec = isOutOfSpec;
              console.log("Line Item after update", lineItems[i])
            }
            else {
              cncInfoFilled = false;
            }
          }
          else {
            cncInfoFilled = false;
          }

        }
        catch (error) {
          console.log('Error at the following', error)
          cncInfoFilled = false;
        }


        if (!cncInfoFilled) {
          lineItems[i].cncMaterial = null;
          lineItems[i].cncSurfaceFinish = null;
          lineItems[i].cncHeatTreatment = null;
          lineItems[i].cncTolerance = null;
          lineItems[i].cncInternalCorner = null;
          lineItems[i].cncReviewMyDesign = null;
          lineItems[i].cncParkMarking = null;

        }
      }
      else if (createQuoteState?.commanValues?.selectedProcess == 2) {

        let isThreeInfoFilled = true;

        const partXDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDx_mm);
        const partYDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDy_mm);
        const partZDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDz_mm);

        let selectedThreeDTechnology;
        let selectedThreeDMachine;
        let selectedThreeDMaterial;
        let selectedThreeDSurfaceFinish;


        // This code is use to sort technology based on name, first we try to select mjf as a default technology
        const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
          return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
        });


        OuterLoop:
        for (let tech of sortedTechnologyData) {
          const { machines, _id } = tech;
          for (const machine of machines) {
            //machine dim
            const { dimensions: { xDim, yDim, zDim } } = machine;
            const machinesDimensions = [xDim, yDim, zDim];
            machinesDimensions.sort((a, b) => a - b);
            //partsDim
            const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
            partsDimensions.sort((a, b) => a - b);


            if (
              machinesDimensions[0] >= partsDimensions[0]
              && machinesDimensions[1] >= partsDimensions[1]
              && machinesDimensions[2] >= partsDimensions[2]
            ) {
              selectedThreeDTechnology = tech;
              selectedThreeDMachine = machine;
              lineItems[i].isOutOfSpec = false;
              break OuterLoop;
            }
          }
        }


        // if the MJF is not selected then use SLA as a default technologu
        if (!selectedThreeDTechnology || !selectedThreeDMachine) {
          const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
            return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
          });
          OuterLoop:
          for (let tech of sortedTechnologyData) {
            const { machines, _id } = tech;
            for (const machine of machines) {
              //machine dim
              const { dimensions: { xDim, yDim, zDim } } = machine;
              const machinesDimensions = [xDim, yDim, zDim];
              machinesDimensions.sort((a, b) => a - b);
              //partsDim
              const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
              partsDimensions.sort((a, b) => a - b);


              if (
                machinesDimensions[0] >= partsDimensions[0]
                && machinesDimensions[1] >= partsDimensions[1]
                && machinesDimensions[2] >= partsDimensions[2]
              ) {
                selectedThreeDTechnology = tech;
                selectedThreeDMachine = machine;
                lineItems[i].isOutOfSpec = false;
                break OuterLoop;
              }
            }
          }
        }

        if (!selectedThreeDTechnology || !selectedThreeDMachine) {
          console.log("On Out Of Spec ");
          //handle outOfSpec
          OuterLoop:
          for (let tech of masterThreeDTechnologyData) {
            const { machines, _id } = tech;
            for (const machine of machines) {
              //machine dim
              const { dimensions: { xDim, yDim, zDim } } = machine;
              const machinesDimensions = [xDim, yDim, zDim];
              machinesDimensions.sort((a, b) => a - b);


              if (
                machinesDimensions[0] == 0
                && machinesDimensions[1] == 0
                && machinesDimensions[2] == 0
              ) {
                selectedThreeDTechnology = tech;
                selectedThreeDMachine = machine;
                lineItems[i].isOutOfSpec = true;
                break OuterLoop;
              }
            }
          }
        }



        selectedThreeDMaterial = selectedThreeDMachine?.materials[0];
        selectedThreeDSurfaceFinish = selectedThreeDMaterial?.postProcess[0];

        // console.log("Selected ThreeD Technology :", selectedThreeDTechnology);
        // console.log("Selected ThreeD Machine :", selectedThreeDMachine);
        // console.log("Selected ThreeD Material :", selectedThreeDMaterial);
        // console.log("Selected ThreeD Surface Finish :", selectedThreeDSurfaceFinish);




        if (!selectedThreeDTechnology
          || !selectedThreeDMachine
          || !selectedThreeDMaterial
          || !selectedThreeDSurfaceFinish) {
          isThreeInfoFilled = false;
        }
        else {
          lineItems[i].threeDTechnology = selectedThreeDTechnology?._id || null;
          lineItems[i].threeDMachine = selectedThreeDMachine?._id || null;
          lineItems[i].threeDMaterial = selectedThreeDMaterial?._id || null;
          lineItems[i].threeDPostProcessing = selectedThreeDSurfaceFinish?._id || null;
        }


      }
    }

  }




  const handleIsUserLogin = () => {
    const isLogin = localStorage.getItem("isLoggedIn");
    if (!isLogin) {
      return false;
    }
    return true
  };

  const withUserLogin = (fn) => {
    return (...args) => {
      const isLoggedIn = handleIsUserLogin();
      // console.log("is User logged In", isLoggedIn);
      if (isLoggedIn) {
        fn(...args);
      }
      else {
        setIsSignInModalOpen(true);
        setIsFunctionalityPending(true);
        setPendingArgument(...args);
      }
    }
  }


  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote?.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong. Please try again later or contact support if the issue persists.")
    }
  }

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong. Please try again later or contact support if the issue persists.");
      }
    } catch (e) {
      console.log("Error 64353  in catch", e);

      toast.error(e.message || "Something went wrong. Please try again later or contact support if the issue persists.");
    }
  };

  const handleAuthUser = async () => {
    try {
      if (isNavigationPending) {
        const { pathname } = location.state;
        navigate(pathname);
      }
      if (isFunctionalityPending) {
        helperQuotation(pendingArgument);
      }
      else if (isPtcPending) {
        onSubmit(null, { ptc: true });
      }
      else if (isRequestReviewPending) {
        onSubmit(null, { saveQuote: true });
      }
      else if (isDqPending) {
        setIsShowDownloadQuotationModal(true);
      }
    } catch (error) {
      console.log("Error 64353  in catch", error);
      // toast.error(error.message || "Something went wrong. Please try again later or contact support if the issue persists.");
    }

  }

  const helperQuotation = async ({ functionalityName }) => {
    let newId = quoteId;
    if (!quoteId) {
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response._id}?functionalityName=${functionalityName}`);
    }
    else {
      if (functionalityName == 'chat') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsShareModalOpen(false)
        setIsChatOpen(true);
      }
      if (functionalityName == 'ftp') {
        setIsForwardToPurchaserModalOpen(true);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      if (functionalityName == 'teams') {
        setTeamModalShow(true);
        setIsForwardToPurchaserModalOpen(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      if (functionalityName == 'dq') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        setIsDqPending(true);

      }
      if (functionalityName == 'share') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(true)
      }
      if (functionalityName == 'move-to-progress') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        handleMoveToProgress();
      }
      if (functionalityName == 'archive') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        handleArchiveQuotation();
      }
      if (functionalityName == 'stc') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        setIsTargetCostModalOpen(true);
      }
    }
  };



  const handleDraftQuoteFunctionality = withUserLogin(helperQuotation);

  const updateValueOnChange = (index, key, value, name) => {
    console.log(index, key, value, name)
    return new Promise(async (resolve, reject) => {
      try {


        const updatedValues = [...createQuoteState?.values];
        if (createQuoteState?.commanValues?.sameForAllField) {


          if (key == 'Notes' || key == 'finalCost' || key == "description" || key == "noOfThread" || key == "Qty") {
            const singleErrors = updatedValues[index].errors || {}
            singleErrors[key] = false
            updatedValues[index][key] = value
            if (!value) {
              singleErrors[key] = true
            }
            updatedValues[index].errors = { ...singleErrors }

          }
          else {
            console.log("Most Updated Value :", updatedValues);
            for (let v = 0; v < updatedValues.length; v++) {
              const singleErrors = updatedValues[v].errors || {}
              singleErrors[key] = false
              updatedValues[v][key] = value
            }
          }
        } else {
          const singleErrors = updatedValues[index]?.errors || {}
          singleErrors[key] = false
          updatedValues[index][key] = value
          updatedValues[index].errors = { ...singleErrors }
        }

        if (key === 'Qty') {
          let localValueCollection = [...createQuoteState?.values];

          let totalQuantityOfItems = 0;
          for (let i = 0; i < localValueCollection.length; i++) {
            totalQuantityOfItems += localValueCollection[i].Qty;
          }
        }

        if (key == 'threeDTechnology') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMachine') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMaterial') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDPostProcessing') {
          // updatedValuesm 4
          updatedValues[index][key] = value;
        }
        if (key == 'isOutOfSpec') {
          updatedValues[index][key] = value;
        }
        //cnc keys
        if (key == 'cncMaterialName') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncMaterial') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncTolerance') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncParkMarking') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncReviewMyDesign') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncSurfaceFinish') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncHeatTreatment') {
          updatedValues[index][key] = value;
        }
        if (key == 'cncInternalCorner') {
          updatedValues[index][key] = value;
        }
        if (key == 'twoDFile') {
          updatedValues[index][key] = value;
        }
        setValues(updatedValues, createQuoteDispatch, createQuoteState)
        return resolve(updatedValues)
      } catch (err) {
        console.log("Error 1 65464", err)
        return reject(err)
      }
    });
  }


  const updateCommanValueOnChange = (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        const updatedValues = { ...createQuoteState?.commanValues };
        console.log("Updated Values Before update", updatedValues);
        updatedValues[key] = value;
        console.log("createQUote state update commanValues 2", updatedValues);
        setCommanValues(updatedValues, createQuoteDispatch, createQuoteState);
        if (key === "sameForAllField") {
          if (value) {
            console.log("Came here 3")
            setSameForAllField(true, createQuoteDispatch, createQuoteState)
          } else {
            console.log("Came here 4")
            setSameForAllField(false, createQuoteDispatch, createQuoteState)
          }
          handleSameForAllField(key, value);
        }

        return resolve(updatedValues)
      } catch (err) {
        console.log("Error 5 in catch", err);
        return reject(err)
      }
    })
  }


  let handleSameForAllField = (key, value) => {
    try {
      let status = value;
      if (key !== 'sameForAllField') {
        return;
      }
      else if (status == true) {
        let updatedValues = [...createQuoteState?.values]
        if (updatedValues.length <= 1) {
          return;
        }
        else if (updatedValues.length > 1) {
          for (let v = 1; v < updatedValues.length; v++) {
            updatedValues[v].MaterialType = updatedValues[0].MaterialType;
            updatedValues[v].SubMaterial = updatedValues[0].SubMaterial;
            updatedValues[v].layer = updatedValues[0].layer;
            updatedValues[v].partMarking = updatedValues[0].partMarking;
            updatedValues[v].surfaceTreatment = updatedValues[0].surfaceTreatment;
            updatedValues[v].surfaceFinish = updatedValues[0].surfaceFinish;
            updatedValues[v].tolerance = updatedValues[0].tolerance;
            updatedValues[v].threeDTechnology = updatedValues[0].threeDTechnology;
            updatedValues[v].threeDMachine = updatedValues[0].threeDMachine;
            updatedValues[v].threeDMaterial = updatedValues[0].threeDMaterial;
            updatedValues[v].threeDPostProcessing = updatedValues[0].threeDPostProcessing;
          }
          setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        }
      }
      else if (status == false) {
        return;
      }

    } catch (error) {
      console.log("Error 35436  in catch", error);

      return error
    }
  }

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setAllFilteredUsers(allUsers);
  }

  const searchUser = (e) => {
    let localFilteredUser = allUsers.filter(item => (item.firstName + " " + item.lastName).toUpperCase().includes((e.target.value).toUpperCase()));
    setAllFilteredUsers(localFilteredUser);
  }

  const handleQuoteUpdate = async () => {
    //check if repeat order 
    if (repeatOrder) {
      //create a new versino and navigate to next version
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response?._id}`);
      return;
    }

    try {

      const result = await saveApiCall();
      setSelectedQuote(result);
      setSelectedQuoteRef(result.RefId);
      setUserIdToEditQuote(result.userId._id);
      if (result?.partsData?.length) {
        const updatedCommanValues = { ...createQuoteState?.commanValues };
        updatedCommanValues.price1 = result?.price1;
        updatedCommanValues.price2 = result.price2
        updatedCommanValues.price3 = result.price3
        updatedCommanValues.selectedShipMethod = result.selectedShipMethod
        updatedCommanValues.myorders = result.myorders;
        updatedCommanValues.leadTime1 = result.leadTime1;
        updatedCommanValues.leadTime2 = result.leadTime2;
        updatedCommanValues.leadTime3 = result.leadTime3;
        updatedCommanValues.isUpdatedByAdmin = result.isUpdatedByAdmin;
        updatedCommanValues.isRepeatOrderQuotation = result.isRepeatOrderQuotation;
        updatedCommanValues.shippingCharge1 = result.shippingCharge1;
        updatedCommanValues.shippingCharge2 = result.shippingCharge2;
        updatedCommanValues.shippingCharge3 = result.shippingCharge3;
        updatedCommanValues.shippingDays1 = result.shippingDays1;
        updatedCommanValues.shippingDays2 = result.shippingDays2;
        updatedCommanValues.shippingDays3 = result.shippingDays3;
        updatedCommanValues.projectName = result.projectName;
        updatedCommanValues.version = result.version;
        updatedCommanValues.selectedProcess = result.selectedProcess;
        updatedCommanValues.orderCertificationsCost = result.orderCertificationsCost;
        updatedCommanValues.isQuoteChanged = false;
        updatedCommanValues.isProcessChanged = false;

        updatedCommanValues.hidePrice = !isShowPriceAccordingToProcess(result);

        setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState);
        setValues(result.partsData, createQuoteDispatch, createQuoteState);
      };
      setIsQuoteUpdated(true);


    } catch (error) {
      console.log("cam Errror is :", error);
      toast.error("Something went wrong while updating the quotation. Please try again later or contact support if the issue persists.");
    }
  }


  const handleQuoteCreateUpdate = (updateType, dataFromOnSubmit, isSaveForFunctionalityFromOnSubmit) => {
    console.log("Update Type :", updateType);
    setShowQuoteUpdateModal(false);
    let data;
    let isSaveForFunctionality;

    if (!updateType) {
      data = dataFromOnSubmit;
      isSaveForFunctionality = isSaveForFunctionalityFromOnSubmit;
    } else {
      data = dataForQuote;
      isSaveForFunctionality = isSaveForFunctionalityState;
    }

    if (createQuoteState?.commanValues?.selectedProcess == 2) {
      data = dataFromOnSubmit;
      console.log("Data :", data);
    }

    return new Promise(async (resolve, reject) => {
      try {
        if (isSaveForFunctionality?.functionalityName == 'Chat' && isAdminUser) {
          alert("Chat and Manual quotation functionality is not available for admin user.Please save quote using save button to create a RFQ");
          return;
        }
        if (isSaveForFunctionality?.functionalityName == 'Share') {
          setIsAdminShare(true);
        }


        if (createQuoteState?.values.length == 0) {
          toast.error("A quotation must have at least one line item to be updated. Please add a line item before updating.");
          return;
        }

        switch (true) {
          case (createQuoteState?.commanValues?.selectedProcess == 1):
            const cncRes = await validateCNCData()
            if (cncRes) {
              break;
            }
            return resolve(cncRes)
          case (createQuoteState?.commanValues?.selectedProcess == 2):
            const threeDRes = await validateThreeDPrinting()
            if (threeDRes) {
              break;
            }
            return resolve(threeDRes)
          case (createQuoteState?.commanValues?.selectedProcess == 3):
            const sheetRes = await validateSheetAndInjection()
            if (sheetRes) {
              break;
            }
            return resolve(sheetRes)
          case (createQuoteState?.commanValues?.selectedProcess == 4):
            const injectionRes = await validateSheetAndInjection()
            if (injectionRes) {
              break;
            }
            return resolve(injectionRes)
          case (createQuoteState?.commanValues?.selectedProcess > 4):
            const otherRes = await validateSheetAndInjection()
            if (otherRes) {
              break;
            }

          default:
            return resolve(true)
        }
        let response = null;
        if (!isAdminUser) {
          if (updateType === 'isRepeatOrder' || updateType == 'ptc') {
            response = await saveApiCall(null, "new");
            console.log("Response Response :", response);
            navigate("/get-instant-quote/checkout/" + response._id)
          } else if (updateType === "isRepeatOrderWithUpdate") {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && dataFromOnSubmit?.saveQuote) {
              navigate('/saved-quote')
            }
            else {
              navigate("/get-instant-quote/checkout/" + response._id)
            }
          } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
              navigate('/saved-quote')
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
              navigate("/get-instant-quote/checkout/" + response._id)
            } else {
              navigate('/saved-quote')
            }
          } else {
            response = await saveApiCall(null, updateType)
            toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate('/saved-quote')
            }
          }
          return resolve(response)
        } else {
          if (!quoteId) {
            setShowUserModal(true);
          } else {
            let response = await saveApiCall(null, updateType);

            toast.success("Quotation is Updated Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate(`/admin/admin-quote/${response._id}`);
            }
          }

        }
      } catch (err) {
        console.log("error in erere", err)
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message)
          return;
        }
        let message = err && err.message ? err.message : "Something went wrong while executing save operation query "
        toast.error(message)
        return reject(err)
      }
    })
  }





  const onSubmit = (e, data, isSaveForFunctionality) => {
    const isLoggedInUser = handleIsUserLogin();
    console.log("Ig Logged IN User :", isLoggedInUser);
    if (!isLoggedInUser) {
      setIsSignInModalOpen(true);
      if (data.saveQuote) {
        setIsRequestReviewPending(true);
        setIsPtcPending(false);
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      else {
        setIsRequestReviewPending(false);
        setIsPtcPending(true);
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      return;
    }

    if (quoteId) {
      // debugger
      if (repeatOrder) {
        handleQuoteCreateUpdate("isRepeatOrder")
      } else if (isRepeatOrderWithUpdate) {
        handleQuoteCreateUpdate("isRepeatOrderWithUpdate")
      } else if (createQuoteState?.commanValues.myorders) {
        handleQuoteCreateUpdate("new")
      } else if (data.ptc) {
        if (createQuoteState?.commanValues.myorders) {
          handleQuoteCreateUpdate("new", data)
        } else {
          if (quoteId) {
            navigate(`/get-instant-quote/checkout/${quoteId}`);
          }
        }
      } else if (data.saveQuote) {
        handleQuoteCreateUpdate("", data, isSaveForFunctionality);
      }
      else {
        setShowQuoteUpdateModal(true)
        setIsSaveForFunctionalityState(isSaveForFunctionality)
        setDataForQuote(data);
      }
    } else {
      handleQuoteCreateUpdate("", data, isSaveForFunctionality);
    }
  }

  const validateCNCData = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType'];
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v]?.selectedFile?.name || updatedValues[v]?.file?.filename)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            }
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }
        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        myRef?.current?.scrollIntoView();
        return resolve(isValidated)
      } catch (err) {

        console.log("error in catch erere34")
        return reject(err)
      }
    })
  }
  const validateThreeDPrinting = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType']
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v].selectedFile?.name)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            };
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }

        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        return resolve(isValidated)
      } catch (err) {

        console.log('error in error sdfsd', err)
        return reject(err)
      }
    })
  }
  const validateSheetAndInjection = () => {
    let isValidated = true;
    let errorMessage = "";
    return new Promise((resolve, reject) => {
      try {
        createQuoteState?.values?.map((value) => {
          if (createQuoteState?.commanValues.errors.sheetAndInjectionQuantity) {
            isValidated = false
          }
          else if (value.Qty < 1 || !value.Qty) {
            isValidated = false;
            errorMessage = 'Please enter valid value for quantity';
          }
          if (!(value.file || value.selectedFile)) {
            if (value.description.length == 0) {
              isValidated = false;
              errorMessage = "Line items without uploaded files must have a description.";
            }
          }
        })
        if (!isValidated) {
          toast.error(errorMessage)
        }
        return resolve(isValidated)
      } catch (err) {
        console.log('error in carther', err)
        return reject(err)
      }
    })
  }

  const isValidFileName = (name) => {
    console.log('name for extension', name)
    const type = getFileExtension(name)?.toUpperCase();
    if (type === "STP" || type === "STEP" || type === "IGES" || type === "OBJ" || type === "STL") return true;

    return false;
  }


  const saveApiCall = async (user, updateType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        console.log("CNC Updated Values:", createQuoteState?.values);

        const user_id = isAdminUser && quoteId && userIdToEditQuote
          ? userIdToEditQuote
          : localStorage.getItem("_id");

        const formData = new FormData();
        const updatedCommanValues = { ...createQuoteState?.commanValues };
        // Append JSON data
        // formData.append('twoDFilesData', JSON.stringify(twoDFiles));
        // formData.append('threeDFilesData', JSON.stringify(files));
        formData.append('partsData', JSON.stringify(createQuoteState?.values));
        formData.append('selectedProcess', updatedCommanValues.selectedProcess);
        formData.append('userId', user_id);
        formData.append('selectedShipMethod', updatedCommanValues.selectedShipMethod);
        formData.append('sheetAndInjectionDiscription', updatedCommanValues.sheetAndInjectionDiscription);
        formData.append('sheetAndInjectionQuantity', updatedCommanValues.sheetAndInjectionQuantity);
        formData.append('sameForAllField', updatedCommanValues.sameForAllField);
        formData.append('hidePrice', updatedCommanValues.hidePrice);
        formData.append('isInvalidFiles', isInvalidFiles);
        formData.append('projectName', projectName);
        formData.append('isQrTrackingRequested', updatedCommanValues.isQrTrackingRequested);
        formData.append('isDimensionalReportRequested', updatedCommanValues.isDimensionalReportRequested);
        formData.append('isConformanceCertificateRequested', updatedCommanValues.isConformanceCertificateRequested);
        formData.append('certifications', JSON.stringify(updatedCommanValues.certifications));
        formData.append('updateTypeForQuote', updateType);
        formData.append('isRepeatOrderQuotation', repeatOrder);
        formData.append("hidePrice", updatedCommanValues?.hidePrice);
        formData.append("isLoggedIn", isLoggedIn == "true" ? true : false);

        // Append files
        // console.log("Files :", files);

        // files.forEach((fileObj,idx) => {
        //   if (fileObj.selectedFile) {
        //     formData.append(`selectedFile_${idx}`, fileObj.selectedFile);
        //   } else {
        //     formData.append(`selectedFile_${idx}`, fileObj);
        //   }
        // });
        createQuoteState?.values.forEach((item, idx) => {
          if (item?.selectedFile && !item.selectedFile?.key) {
            formData.append(`selectedFile_${idx}`, item.selectedFile);
          }
        });


        const userToken = token || localStorage.getItem("Token");
        const apiUrl = quoteId
          ? `comman/update-quote/${quoteId}`
          : 'comman/save-quote';

        const response = await postApi(apiUrl, formData, userToken);
        console.log('API Response:', response);

        if (!response?.status) {
          throw new Error(response?.message || "Something went wrong");
        }

        if (quoteId) {
          setIsLoading(false);
          return resolve(response.data);
        } else {
          navigate(`/create-instant-quotation/${response?.data?._id}`);
          setIsLoading(false);
          return resolve(response?.data);
        }
      } catch (err) {
        console.error("Error in Save API Call:", err);
        setIsLoading(false);
        return reject(err);
      }
    });
  };



  const fetchDataFromApi = (endPoint, payload = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        console.log("Error 4 5645654", err)
        return reject(err);
      }
    });
  };

  const handleDelete = (index) => {
    try {
      let afterFltr = values.filter((val, i) => i !== index)
      let fileName = (values[index]?.selectedFile?.name || values[index]?.file?.originalname) + index;
      if (matSubMatMap.get(fileName)) {
        let localMatSubMap = matSubMatMap;
        localMatSubMap.delete(fileName);
        setMatSubMatMap(localMatSubMap);
      }

      setValues([...afterFltr], createQuoteDispatch, createQuoteState);

      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      try {
        let local2DFiles = { ...twoDFiles };
        delete local2DFiles[index];
        for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {
          // console.log('local2D fiels at i-1',local2DFiles[i - 1])
          // console.log('local2D fiels at i',local2DFiles[i])
          local2DFiles[i - 1] = local2DFiles[i];
          delete local2DFiles[i];
        }
        setTwoDFiles({ ...local2DFiles });
      } catch (err) {
        console.log('err', err);
      }
    }
    catch (err) {
      console.log("err", err)
    }
  }


  const OnDropFunction = (files) => {
    try {
      const validFiles = files.filter(file => file.size <= maxFileUploadSize);

      if (validFiles.length > 0) {
        onSelectfile(validFiles);
      }

      if (validFiles.length < files.length) {
        toast.error("Some files were too large and have been excluded (max size: 100MB).");
      }
    } catch (e) {
      console.log("Error in OnDropFunction", e);
      return e;
    }
  };



  const handleOnProceedTOU = async () => {

    const isLoggedInUser = handleIsUserLogin();
    if (!email) {
      toast.error("Please enter your email to continue.");
      return;
    }
    if (email && email.split("@")[1] == '8xparts.com') {
      toast.error("The requested domain is not allowed.");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    const response = await checkCreateUser(email);
    console.log("Response :", response);
    if (!response || !response.success) {
      throw new Error();
    };
    const { data } = response;
    const { _id, token } = data;
    if (_id) {
      localStorage.setItem("_id", _id);
    }
    if (token) {
      localStorage.setItem("Token", JSON.stringify(token));
    }

    setIsUserInfoReceived(true);
    setShowTermsOfUseModal(false)
  }

  const handleOnCancelTOU = () => {
    setShowTermsOfUseModal(false)
    resetCreateQuoteState(createQuoteState?.commanValues?.selectedProcess, createQuoteDispatch, createQuoteState)
    setIsProcessing(false);
    setIsGlobeLoader(false)
    console.log('use data came here false')
    setIsFirstFileUploaded(false);
    setIsUserInfoReceived(false);
  }
  return (
    <>

      {isThreeSecondLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100 z-50">
          <div className="loader w-16 h-16 border-4 border-t-4 border-gray-300 border-t-sky-500 rounded-full animate-spin"></div>
        </div>
      )}


      {isLoadedInIframe ?
        <>
          <div className="flex justify-center items-center h-screen px-4">
            {/* Container for centering content */}
            <div className="text-center max-w-md">
              {/* Label text */}
              <p className="text-lg text-gray-700 mb-6">
                Your quotation is currently in progress. What would you like to do next?
              </p>

              {/* Button Container */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button
                  onClick={() => {
                    const newTabUrl = `${window.location.origin}/create-instant-quotation/${quoteId}?token=${token}`;
                    window.top.location.href = newTabUrl;
                  }}
                  style={{
                    transition: "opacity 0.3s ease",
                  }}
                  className="px-6 py-3 bg-sky-500 hover:bg-sky-600 text-white text-sm sm:text-base font-medium rounded transition-colors w-full sm:w-auto text-center inline-block"
                >
                  Go to Quotation
                </button>


                <button
                  style={{
                    transition: "opacity 0.3s ease",
                  }}
                  className="px-6 py-3 border border-sky-500 text-sky-500 hover:bg-sky-100 text-sm sm:text-base font-medium rounded transition-colors w-full sm:w-auto"
                  onClick={() => {
                    window.top.location.href = process.env.REACT_APP_MAIN_WEBSITE_URL;
                  }}
                >
                  Start a New Quote
                </button>

              </div>
            </div>
          </div>



        </>
        :
        <>
          <div className='bg-white '>
            {(
              isProcessing || isGlobeLoader || isLoading
            ) ? <QuotationLoader
              selectedProcess={createQuoteState?.commanValues?.selectedProcess}
              processing={isProcessing}
              globeLoader={isGlobeLoader}
              isLoading={isLoading}
            // isLoading={true}
            /> : null}

            {showTermsOfUseModal ?
              <>
                <TermsOfUseModal
                  onProceed={handleOnProceedTOU}
                  onCancel={handleOnCancelTOU}
                  email={email}
                  open={showTermsOfUseModal}
                  setEmail={setEmail}
                  setIsUserLoggOut={setIsUserLoggOut}
                  isUserLoggOut={isUserLoggOut}
                />
              </> : <></>}


            {/* Code for UI */}
            <>
              <Header
                isUserLoggOut={isUserLoggOut} />
            </>




            {/* Create Quote D */}
            <div className={`create-instant-quotation`}>
              {
                !createQuoteState?.values?.length ?
                  <LeftSection
                    currentAcceptableProcess={currentAcceptableProcess}
                    setIsProcessing={setIsProcessing}
                    setIsGlobeLoader={setIsGlobeLoader}
                    files={files}
                    setFiles={setFiles}
                    OnDropFunction={OnDropFunction}
                    onSelectfile={onSelectfile}
                  /> : <></>
              }
              <div style={{ flex: 3 }}>
                {
                  createQuoteState?.values?.length ?
                    <TopSection
                      currentAcceptableProcess={currentAcceptableProcess}
                      setIsProcessing={setIsProcessing}
                      setIsGlobeLoader={setIsGlobeLoader}
                      files={files}
                      setFiles={setFiles}
                      OnDropFunction={OnDropFunction}
                      onSelectfile={onSelectfile}
                    /> : <></>
                }
                <CenterSection
                  handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                  setIsProcessing={setIsProcessing}
                  setIsGlobeLoader={setIsGlobeLoader}
                  files={files}
                  setFiles={setFiles}
                  masterThreeDTechnologyData={masterThreeDTechnologyData}
                  processing={isProcessing}
                  globeLoader={isGlobeLoader}
                  sample={isSamplePartUploaded}
                  setSample={setIsSamplePartUploaded}
                  twoDFiles={twoDFiles}
                  setTwoDFiles={setTwoDFiles}
                  setIsInchSelected={setIsInchSelected}
                  isInchSelected={isInchSelected}
                  onSubmit={onSubmit}
                  updateCommanValueOnChange={updateCommanValueOnChange}
                  OnDropFunction={OnDropFunction}
                  onSelectfile={onSelectfile}
                  updateValueOnChange={updateValueOnChange}
                  setIsInvalidFiles={setIsInvalidFiles}
                  selectedQuote={selectedQuote}
                  isQuoteUpdated={isQuoteUpdated}
                  handleNewVersionClick={handleNewVersionClick}
                  handleQuoteUpdate={handleQuoteUpdate}
                  isLoadedInIframe={isLoadedInIframe}
                  setIsThreeSecondLoading={setIsThreeSecondLoading}
                />
              </div>
              {createQuoteState?.values?.length ?
                <>
                  <RightSection
                    handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                    updateCommanValueOnChange={updateCommanValueOnChange}
                    onSubmit={onSubmit}
                    selectedQuote={selectedQuote}
                    selectedProcess={createQuoteState?.commanValues?.selectedProcess}
                    isQuoteChanged={createQuoteState?.commanValues?.isQuoteChanged}
                    handleQuoteUpdate={handleQuoteUpdate}
                  />
                </> : <></>}

            </div>

            <SignInModal
              show={isSignInModalOpen}
              onSignIn={handleAuthUser}
              onClose={() => setIsSignInModalOpen(false)}
              userEmail={email}

            />
            <ForwardToPurchaseModal
              isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
              setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
              quoteId={_id}
              selectedQuote={selectedQuote}
              setSelectedQuote={setSelectedQuote}
            />
            <ShareModalComponent
              isShareModalOpen={isShareModalOpen}
              setIsShareModalOpen={setIsShareModalOpen}
              selectedQuote={selectedQuote}
            />
            <TargetCostUserModal
              setIsTargetCostModalOpen={setIsTargetCostModalOpen}
              isTargetCostModalOpen={isTargetCostModalOpen}
              selectedQuote={selectedQuote}
              setSelectedQuote={setSelectedQuote}
            />
            <Chat
              RefId={selectedQuoteRef}
              open={isChatOpen}
              onDrawerClose={() => setIsChatOpen(false)}
            />
            <TeamCollaboration
              teamMemberModalShow={teamModalShow}
              setTeamMemberModalShow={setTeamModalShow}
              selectedQuoteForTeam={selectedQuote}
              updateQuoteData={setSelectedQuote}
              fetchDataForUser={() => null}
            />
            <DownloadQuotationModal
              selectedQuote={selectedQuote}
              show={isShowDownloadQuotationModal}
              setShow={setIsShowDownloadQuotationModal}
            />

            {
              !isChatOpen &&
              <FullScreenFileDrop
                OnDropFunction={OnDropFunction}
              />
            }

          </div>
        </>
      }
    </>

  )
}

export default CreateInstantQuotation;