import axios from "axios";
import qs from "qs";


let token = localStorage.getItem("Token");
import { axiosInstance } from "../AxiosInstance/AxiosInstance";

const config = require(`../../environment/development`).config;
let cancelToken;


export const getAllAddress = (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/getAllAddress`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getAllBulkPricing = (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/getAllBulkPricing`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (error) {
      // console.log(error);
      return reject(error);
    }
  });
};
export const getBulkPricing = (_id) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/getBulkPricing?id=${_id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const saveBulkPricing = async (token, payload) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let res = await axios
      .post(`${config.backEnd}/saveBulkPricing`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteBulkPricing = async ({ payload }, token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .delete(`${config.backEnd}/deleteBulkPricing`, {
        data: payload,
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const updateBulkPricing = async (token, payload) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    // console.log(payload)
    let res = await axios
      .put(`${config.backEnd}/updateBulkPricing`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};

export const handleUserNameUpdate = (payload, token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${config.backEnd}/change/user-name`,
        payload,
        { headers: header }
      );
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAddressById = async (_id, token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios
      .get(`${config.backEnd}/getAddress?id=${_id}`, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteAddress = async ({ payload }, token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    // console.log(payload)
    let response = await axios
      .delete(`${config.backEnd}/deleteAddress`, {
        data: payload,
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const saveAddress = async (token, payload) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let res = await axios
      .post(`${config.backEnd}/saveAddress`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};
export const updateAddress = async (token, payload) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let res = await axios
      .put(`${config.backEnd}/updateAddress`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};

export const getSaveQuotes = (tokn) => {
  tokn = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/getSaveQuote`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const saveQuoteById = (_id) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/saveQuoteById?_id=${_id}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getMaterial = (tokn) => {
  tokn = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/getMaterial`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getCheckOutData = (_id, tokn) => {
  tokn = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/getCheckOutData?_id=${_id}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const checkOut = ({ payload }, tokn) => {
  tokn = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${config.backEnd}/checkOut`, payload, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createPaymentIntent = (payload, token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/customer/payment-intent`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const saveText = ({ payload }) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${config.backEnd}/saveText`, payload, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getQuotes = async (tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/get/quotes`, { headers: header })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getOrders = async (tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/get/orders`, { headers: header })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateOrders = async (payload, tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/update/orders`, payload, { headers: header })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};



export const UpdateQuote = async (payload, tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let res = await axios
      .post(`${config.backEnd}/updateIqStepTwo`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};

export const updateQuoteAdmin = (payload, tokn) => {
  tokn = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/update-quote-admin`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createNewQuote = async (payload, tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/create/quote`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getQuoteDetails = async (payload, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/get/details/quote`, payload, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getOrdersAdmin = async (tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/admin/get/orders`, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchQuoteById = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-quote-byId/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchQuoteByQuoteId = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-quote-byQuoteId/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};


export const fetchQuoteByIdForQuoteVersion = async (data, tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-quote-by-id-quote-version/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};


export const getMaterials = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(
        `${config.backEnd}/comman/get-materials/?selectedProccess=${data.id}`,
        { headers: header }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const newCheckout = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/create/checkout`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getNewChkoutData = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let pageNum = data.pageNum || 1;
    let response = await axios
      .get(`${config.backEnd}/get/checkout?pageNum=${pageNum}`, {
        headers: header,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateOrderPoNmbr = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/order/po`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadPdf = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let axiosConfig = {
      method: "post",
      url: `${config.backEnd}/download/pdf`,
      headers: {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
    };

    let response = await axios(axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCompleteStatus = async (tokn, id) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/admin/update/completeStatus?id=${id}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const addDlvryCmnt = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };

    let response = await axios
      .post(`${config.backEnd}/admin/update/comment`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const sendQuotationToCustomerEmail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios.post(
      `${config.backEnd}/admin/send-to-email/quote`,
      data,
      { headers: header }
    );
    return response;
  } catch (error) {
    return { error: error, status: false };
  }
};

export const updateShippedDateMail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/mail/shipped-update`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateDeliveryMail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios.post(
      `${config.backEnd}/admin/mail/delivery-update`,
      data,
      { headers: header }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const quoteSaveMail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/mail/saved-quote`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const orderPlacedMail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/mail/order-placed`, data, { headers: header })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const orderClosedMail = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/mail/order-closed`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const countQuotesUser = async (tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/count/quotes`, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const countOrdersUser = async (tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/count/orders`, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateExpectedDlvryDate = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/expected-delivery`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateShippedDate = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/shipped-date`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanies = async (token,isCompanyForEmail) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getCompanies?email=${isCompanyForEmail}`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateCompany = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateCompany`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const createCompany = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/createCompany`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUsers = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getUsers`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAdminUsers = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getAdminUsers`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateUser = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateUser`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const createUser = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/createUser`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const saveCadAnalysis = async (analysis, token) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/saveFileAnalysis`, analysis, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Material Master Calls

// For layer thickness starts
export const getLayerThickess = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getLayerThickess`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addLayerThickness = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addLayerThickness`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateLayerThickness = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateLayerThickness`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
// For layer thickness ends

//  For Material starts
export const getMaterialMaster = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getMaterialMaster`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addMaterial = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addMaterial`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMaterial = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateMaterial`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
// For material ends

// For sub materials
export const getSubmaterial = async (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getSubmaterial`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addSubmaterial = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addSubmaterial`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSubmaterial = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateSubmaterial`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

// For submaterials ends

// For partmaking starts
export const getPartMaking = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getPartMaking`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addPartMaking = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addPartMaking`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePartMaking = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updatePartMaking`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

// For partmaking ends

// For surface finish starts
export const getSurfaceFinish = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getSurfaceFinish`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addSurfaceFinish = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addSurfaceFinish`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSurfaceFinish = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateSurfaceFinish`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

// For surface finish ends

// for surface treatment starts
export const getSurfaceTreatment = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getSurfaceTreatment`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addSurfaceTreatment = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addSurfaceTreatment`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSurfaceTreatment = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateSurfaceTreatment`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

// for surface treatment ends

// For tolerance starts
export const getTolerance = async (token) => {
  token = localStorage.getItem("Token");


  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/getTolerance`, {
        headers: header,
      });
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addTolerance = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addTolerance`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTolerance = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/updateTolerance`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateEmailSubscriptions = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .put(`${config.backEnd}/email-subscription`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getProfileDetails = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(tokn)}`,
        "Content-type": "application/json",
      };

      let response = await axios.get(`${config.backEnd}/profile`, {
        headers: header,
      });
      console.log(response);
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

// for tolerance ends

// MAterial Master calls end

// Get all quote version getAllQuoteVersion
export const fetchQuoteByRefId = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-quote-byRefId/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserAddressAdmin = async (token, userId) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getUserAddressAdmin/${userId}`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getallAddressAdmin = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getallAddressAdmin`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateQuote = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/update-quote`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchOrderById = async (data, tokn) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: "",
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-order-byId/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handlePartsDataUpdate = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/order/partsdata`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleQualityDocOrderUpdate = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/order/qualitydoc`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleQRInformationUpdate = async (data, token) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios
      .post(
        `${config.backEnd}/admin/update/order/qr-information-update`,
        data,
        { headers: header }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleConsignMentPictureUpload = async (data, tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/update/order/consignmentPhoto`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleDeliveryLinkUpdate = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };

    let response = await axios
      .post(`${config.backEnd}/admin/update/order/deliveryLink`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getQualityRelatedIssue = async (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getQualityRelatedIssue`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addQualityRelatedIssue = async (tokn, data) => {
  tokn = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addQualityRelatedIssue`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteQualityRelatedIssue = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .delete(`${config.backEnd}/admin/deleteQualityRelatedIssue`, {
        data: data,
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPurchaseOrderIssue = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/getPurchaseOrderRelatedIssue`,
        { headers: header }
      );
      return resolve(response.data.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addPurchaseOrderIssue = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/admin/addPurchaseOrderIssue`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const deletePurchaseOrderIssue = async (token, data) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    // console.log(data)
    let response = await axios
      .delete(`${config.backEnd}/admin/deletePurchaseOrderIssue`, {
        data: data,
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export const getTrackingDetails = (data) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        "DHL-API-Key": process.env.REACT_APP_DHL_API_KEY,
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
      let response = await axios.get(
        `https://api-eu.dhl.com/track/shipments?trackingNumber=${data}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const shareQuoteToEmails = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/shareQuoteToEmails`, data, { headers: header })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPurchaseOrderRelatedIssueUser = async (token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/getPurchaseOrderRelatedIssue`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getQualityRelatedIssueUser = async (token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/getQualityRelatedIssue`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const submitRaiseIssue = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios
      .post(`${config.backEnd}/submitRaiseIssue`, data, { headers: header })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getIssues = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/comman/getIssues`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getUserpaymentData = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/customer/payment-methods`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const removePaymentMethod = async (pm_id, token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.delete(
        `${config.backEnd}/customer/payment-method/${pm_id}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getSetupIntent = async (token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/customer/create/payment-method`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const confirmPaymentMethod = async (data, token) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/customer/payment-method/checkout`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleIssueClose = async (token, data) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/comman/handleIssueClose`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateQuoteShipMethod = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/comman/updateQuoteShipMethod`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllPayments = (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/comman/get-all-payments`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getBoundingBoxValuesByFileName = async (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/comman/get-bounding-box-by-filename?filename=${data}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchAnalysisByQuoteId = async (data, token) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-analysis-by-quoteid/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async (tokn) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(`${config.backEnd}/comman/get-all-users`, {
      headers: header,
    });
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const handleSubmitFeedBack = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${config.backEnd}/submit-feed-back`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};


export const updateOrderRating = async (tokn, data) => {
  tokn = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .put(`${config.backEnd}/order-rating`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleOrderCancel = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .put(`${config.backEnd}/admin/cancel-order`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const sendViewOrderOTPToEmail = async (data) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
      let response = await axios.post(
        `${config.backEnd}/comman/mail/view-order-otp`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchOrderAsTeamMember = async (token, data) => {
  token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(`${config.backEnd}/comman/fetch-order-as-team-member/${data}`, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleQuoteCancel = (token, data) => {
  token = localStorage.getItem("Token");

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.put(`${config.backEnd}/comman/cancel-quote`, data, { headers: header });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const moveQuoteToProgressStatus = (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }

      let response = await axios.put(`${config.backEnd}/comman/move-quote-to-progress`, data, { headers: header });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSignedUrlForFile = async (fileName) => {
  let token = localStorage.getItem("Token");

  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .get(
        `${config.backEnd}/comman/get-signed-url-for-file-name/${fileName}`,
        { headers: header, responseType: "blob" }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateOrderAdminComment = async (payload) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/update-order-comment`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateQuoteAdminComment = async (payload) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/update-quote-comment`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateOrderCharges = async (payload) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/update-order-charges`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createEightXAddress = async (data) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/create-eightx-address`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getEightXAddress = async (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/get-eightx-address`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateEightXAddress = async (data) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/update-eightx-address`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteEightXAddress = async (id) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.delete(
        `${config.backEnd}/admin/delete-eightx-address/${id}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const triggerAdminApprovalOTP = ({ payload }) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/trigger-approval-otp`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchMakePortalEmails = async (token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${config.backEnd}/admin/fetch-make-portal-emails`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getOrderForQuotationId = async (token, id) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${config.backEnd}/comman/get-order-for-quotation-id/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAllQuoteForRfqs = async (token) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${config.backEnd}/comman/get-all-quote-for-rfqs`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const fetchEmailContentByIdForMake = async (token, id) => {
  token = localStorage.getItem("Token");
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${config.backEnd}/admin/fetch-email-content-for-make/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const handleDownloadUsersData = async (token) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/download-user-data`,
        { headers: header },
        { responseType: "blob" }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const changeQuoteAdminOwner = (token, payload) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/change-quote-admin-owner`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const changeSalesOrderAdminOwner = (token, payload) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/change-sales-order-admin-owner`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleDownloadCocForOrder = async (orderId) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
      let response = await axios.get(
        `${config.backEnd}/comman/download-cert-of-conformance/${orderId}`,
        { headers: header },
        { responseType: "blob" }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateQuotationNotesAndTnc = (token, payload) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/admin/update-quotation-notes`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const verifyViewOrderOTP = (payload) => {
  let token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`,
      };

      let response = await axios.post(
        `${config.backEnd}/comman/verify-view-order-otp`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const downloadPdfForViewOrder = async (data) => {
  let token = localStorage.getItem("Token");
  try {
    let axiosConfig = {
      method: "post",
      url: `${config.backEnd}/comman/download/pdf`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
      data: qs.stringify(data),
    };

    let response = await axios(axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const setAddressAsDefault = async (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/set-default-address`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleChangeCEDD = async (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/change-cedd`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const downloadDeliveryDocket = async (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/download-delivery-docket`,
        data,
        { headers: header },
        { responseType: "blob" }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleMarkAsSend = async (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/handle-mark-as-send`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleCommonAddAddressForUser = (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/comman/handle-admin-add-address-for-user`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleCommonDeleteAddressForUser = (token, id) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.delete(
        `${config.backEnd}/comman/handle-admin-delete-address-for-user/${id}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleCommonEditAddressForUser = (token, data) => {
  token = localStorage.getItem("Token");
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/comman/handle-admin-edit-address-for-user`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

//Code To Fetch Material By Id
export const getMaterialDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = await axiosInstance.get(`/admin/getMaterialById/${id}`, { headers });
    return response.data;
  } catch (error) {
    return false;
  }
};
//Code To Fetch SubMaterial By Id
export const getSubMaterialById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get(`/admin/getSubmaterialBy/${id}`, { headers });
    return response.data;
  } catch (error) {
    return false;
  }
};
//Code to Fetch Surface Material By Id
export const getSurfaceFinishDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };


    const response = await axiosInstance.get(
      `/admin/getSurfaceFinishDetailsById/${id}`, { headers }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};
//Code To Fetch Tolerance Details By Id
export const getToleranceDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = await axiosInstance.get(
      `/admin/getToleranceDetailsById/${id}`, { headers }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};
//Code To Fetch SurfaceTreatment Details by id
export const getSurfaceTreatmentDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = axiosInstance.get(
      `/admin/getSurfaceTreatmentDetailsById/${id}`, { headers }
    );
    return (await response).data;
  } catch (error) {
    return fasle;
  }
};
//Code To Fetch PartsMaking Details By id
export const getPartsMarkingDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get(
      `/admin/getPartMakingDetailsById/${id}`, {
      headers
    }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};
export const getLayerThicknessDetailsById = async (id) => {
  let token = localStorage.getItem("Token");
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.get(
      `/admin/getLayearThicknessDetailsById/${id}`, {
      headers
    }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};



export const performAnalysisForFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${process.env.REACT_APP_QUAOAR_SERVER_URL}/api/v1/process`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    return response.data;

  } catch (error) {
    return false;
  }

}

