import axios from "axios";
import { config } from "../../environment/development";
const timeOut = 600000;

export const axiosInstance = axios.create({
  baseURL: config.backEnd,
  timeout: timeOut,
});
//Interceptor for Request ex Add Authorization Token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token");
    config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => Promise.reject(error)
);
