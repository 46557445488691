import React, { useState, useEffect, Fragment } from 'react'
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents'
import {
    createMaterial,
    getAll3dProcess,
    getAllTechnology,
    getThreeDMaterial,
    createProcess,
    upsert3dTechnologyData,
    upsetMaterial,
    createMachineForTechnology,
    getTechnologyByid,
    getMachineDataById,
    getMaterialByIdThreeD,
    get3dProcessById,
    getLeadTimeDataForPostProcessing,
    updateLeadTimeDataForPostProcessing,
    getMetalCCDataForMaterial,
    updateMetalCCForMaterial,
} from '../../utils/actions/adminActions';
import { toast } from "react-toastify"
import { LucidLoader, PlusIcon } from '../../components/Icons';



const AdminTechNology = () => {
    const [isTechnologyModalOpen, setIsTechModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selectedTechnology, setSelectedTechnology] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const token = localStorage.getItem('Token')

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getAllTechnology(token);
            if (!response || !response.success) {
                toast.error('Failed to load 3D technology.');
                return;
            }
            const { data } = response;
            setData(data);

        } catch (error) {
            console.log("Erorr", error)
            toast.error('Failed to load 3D technology.');
        } finally {
            setLoading(false);
            setIsTechModalOpen(false);
        }
    };

    return (
        !loading ?
            <div className='w-full'>
                <h3 className='text-center text-2xl  my-1 mt-2 font-bold'>Technology</h3>
                <div className='flex items-center gap-x-2 my-1  justify-end me-10 '>
                    <button
                        onClick={() => setIsTechModalOpen(true)}

                        className='bg-primaryColor px-2 py-2  text-white text-sm rounded'>
                        Add Technology
                    </button>


                </div>
                <div class="relative overflow-x-auto w-full !my-3">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500   ">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Machines
                                </th>
                                <th scope="col" class="px-6 py-3">

                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                data?.map(({ machines, name, _id }, idx) => {
                                    return <TechRow
                                        name={name}
                                        machines={machines}
                                        _id={_id}
                                        idx={idx}
                                        setSelectedTechnology={setSelectedTechnology}
                                        selectedTechnology={selectedTechnology}
                                        onSubmit={fetchData}
                                    />
                                })
                            }

                        </tbody>
                    </table>
                </div>
                {
                    selectedTechnology != null &&
                    <div className='mt-4'>
                        {console.log('selecteddTechnlogo', selectedTechnology)}
                        <h3 className='text-center text-2xl font-bold'>Machines </h3>
                        <h6 className='text-center text-lg  my-2 font-bold'>({data[selectedTechnology]?.name}) </h6>
                        <div class="relative overflow-x-auto w-full">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            dimensions
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            materials
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        data[selectedTechnology]?.machines?.map(({ dimensions, name, materials, _id }, idx) => {
                                            return <MachineRow
                                                idx={idx}
                                                _id={_id}
                                                name={name}
                                                dimensions={dimensions}
                                                materials={materials}
                                                selectedMachine={selectedMachine}
                                                setSelectedMachine={setSelectedMachine}
                                                onSubmit={fetchData}
                                            />
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    selectedTechnology != null && selectedMachine != null &&
                    <div className='mt-4'>
                        <h3 className='text-center text-2xl font-bold'>Materials </h3>
                        <h6 className='text-center text-lg  my-2 font-bold'>({data[selectedTechnology]?.name + " => " + data[selectedTechnology]?.machines[selectedMachine]?.name}) </h6>
                        <div class="relative overflow-x-auto w-full">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Name
                                        </th>
                                        {
                                            data[selectedTechnology].technologyType == "Metal" ?
                                                <>
                                                    <th scope="col" class="px-6 py-3">
                                                        Cost Per CC 1
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Cost Per CC 2
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Cost Per CC 3
                                                    </th>
                                                </>

                                                : <> <th scope="col" class="px-6 py-3">
                                                    Cost Per CC 1 $
                                                </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Cost Per CC 2 $
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Cost Per CC 3 $
                                                    </th>
                                                </>
                                        }

                                        <th scope="col" class="px-6 py-3">
                                            Density (g/cc)
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            Post Process
                                        </th>
                                        <th></th>

                                        <th scope="col" class="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        data[selectedTechnology]?.machines[selectedMachine]?.materials?.map(({ costPerCC1, minCost1, costPerCC2, minCost2, costPerCC3, minCost3, name, postProcess, _id, density }, idx) => {
                                            return <MaterialRow
                                                costPerCC1={costPerCC1}
                                                costPerCC2={costPerCC2}
                                                costPerCC3={costPerCC3}
                                                minCost1={minCost1}
                                                minCost2={minCost2}
                                                minCost3={minCost3}
                                                density={density}
                                                name={name}
                                                postProcess={postProcess}
                                                idx={idx}
                                                selectedMaterial={selectedMaterial}
                                                setSelectedMaterial={setSelectedMaterial}
                                                _id={_id}
                                                onSubmit={fetchData}
                                                isMetal={data[selectedTechnology]?.technologyType == "Metal" ? true : false}
                                            />
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    selectedTechnology != null && selectedMachine != null && selectedMaterial != null &&
                    <div className='my-5'>
                        <h3 className='text-center text-2xl  my-2 font-bold'>Post Process </h3>
                        <h6 className='text-center text-lg  my-2 font-bold'>({data[selectedTechnology]?.name + " => " + data[selectedTechnology]?.machines[selectedMachine]?.name + "=>" + data[selectedTechnology]?.machines[selectedMachine]?.materials[selectedMaterial]?.name}) </h6>
                        <div class="relative overflow-x-auto w-full">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            costPerCC1
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            costPerCC2
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            costPerCC3
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            color
                                        </th>
                                        <th>
                                            description
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            Manual Review Required
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Action
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Lead Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        data[selectedTechnology]?.machines[selectedMachine]?.materials[selectedMaterial]?.postProcess?.map(({ costPerCC1, costPerCC2, costPerCC3, color, fixedCost, description, name, _id, manualReviewRequired }, idx) => {
                                            return <PostProcessRow
                                                name={name}
                                                manualReviewRequired={manualReviewRequired}
                                                costPerCC1={costPerCC1}
                                                costPerCC2={costPerCC2}
                                                costPerCC3={costPerCC3}
                                                color={color}
                                                fixedCost={fixedCost}
                                                description={description}
                                                _id={_id}
                                                onSubmit={fetchData}
                                                idx={idx}
                                                leadTimeModalTitle={data[selectedTechnology]?.name + " => " + data[selectedTechnology]?.machines[selectedMachine]?.name + "=>" + data[selectedTechnology]?.machines[selectedMachine]?.materials[selectedMaterial]?.name + "=>" + name}
                                            />
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                }



                <Modal
                    isOpen={isTechnologyModalOpen}
                    children={<UpsertTechnologyComponent
                        onSubmit={fetchData}
                    />
                    }
                    title="Create Technology"
                    onClose={() => setIsTechModalOpen(false)}
                />
            </div> : <div className='flex items-center justify-center h-screen w-screen'><LucidLoader additionalClass={'text-primaryColor'} /></div>
    )
}

export default AdminDashboardComponents(AdminTechNology);


const Modal = ({ isOpen, onClose, title, children, footer }) => {
    if (!isOpen) return null;
    console.log('ttile', title)
    return (
        <div
            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
            onClick={onClose}
        >
            <div
                className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 relative"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 text-2xl"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <div className="mb-4 text-gray-700">{children}</div>
                {footer && <div className="mt-4">{footer}</div>}
            </div>
        </div>
    );
};


const CreatePostProcessComponent = ({ id, onSubmit, postProcessId }) => {
    const token = localStorage.getItem("Token") || null;
    const [postInfo, setPostInfo] = useState({
        name: "",
        costPerCC1: "",
        costPerCC2: "",
        costPerCC3: "",
        color: "",
        description: "",
        manualReviewRequired: false,
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        console.log(name, value)
        setPostInfo((prevInfo) => ({
            ...prevInfo,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    useEffect(() => {
        (async () => {
            if (postProcessId) {
                try {

                    const response = await get3dProcessById(token, postProcessId);
                    console.log(response);
                    if (!response || !response.success) {
                        toast.success(response?.message);
                    }
                    const { data } = response;
                    setPostInfo(data);

                } catch (error) {
                    toast.error('Failed to load 3D process.');
                }
            }
        })()

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await createProcess(token, { ...postInfo, id, postProcessId });
            if (!response || !response.success) {
                toast.error('Failed to create post processing.');
                return;
            }
            toast.success("Post Process created succesfully");


        } catch (error) {
            toast.error('Failed to create post processing.');
            onSubmit();

        } finally {
            setLoading(false);
            onSubmit();
        }
    }

    return (
        <div className="p-4 bg-white rounded shadow-md max-w-md mx-auto">
            <form
                onSubmit={handleSubmit}
                className="w-[90%] sm:w-[400px] max-w-sm mx-auto bg-white p-4 rounded-lg "
            >
                <div className="mb-3">
                    <label className="block text-sm text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={postInfo.name}
                        onChange={handleChange}
                        className="mt-1 p-1.5 border rounded w-full text-sm"
                        required
                    />
                </div>

                <div className="mb-3">
                    <label className="block text-sm text-gray-700">Color</label>
                    <input
                        type="text"
                        name="color"
                        value={postInfo.color}
                        onChange={handleChange}
                        className="mt-1 p-1.5 border rounded w-full text-sm"
                        required
                    />
                </div>

                <div className="grid grid-cols-3 gap-2 mb-3">
                    <div>
                        <label className="block text-sm text-gray-700">Cost per CC1</label>
                        <input
                            type="number"
                            name="costPerCC1"
                            value={postInfo.costPerCC1}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-1.5 border rounded w-full text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-700">Cost per CC2</label>
                        <input
                            type="number"
                            name="costPerCC2"
                            value={postInfo.costPerCC2}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-1.5 border rounded w-full text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-gray-700">Cost per CC3</label>
                        <input
                            type="number"
                            name="costPerCC3"
                            value={postInfo.costPerCC3}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-1.5 border rounded w-full text-sm"
                        />
                    </div>
                </div>

                <div className="mb-3">
                    <label className="block text-sm text-gray-700">Description</label>
                    <textarea
                        name="description"
                        value={postInfo.description}
                        onChange={handleChange}
                        className="mt-1 p-1.5 border rounded w-full text-sm"
                    />
                </div>

                <div className="mb-3 flex items-center">
                    <input
                        type="checkbox"
                        name="manualReviewRequired"
                        checked={postInfo.manualReviewRequired}
                        onChange={handleChange}
                        className="mr-2"
                        id="manual-review-check-post"
                    />
                    <label className="text-sm text-gray-700" htmlFor="manual-review-check-post">
                        Manual Review Required
                    </label>
                </div>

                <button
                    type="submit"
                    className="bg-primaryColor text-white py-1.5 px-3 rounded text-sm hover:bg-darkPrimaryColor"
                >
                    {!loading ? `${postProcessId ? "Update" : "Create"} ` : <LucidLoader />}
                </button>
            </form>

        </div>
    );
};

const CreateMaterialComponent = ({ id, onSubmit, materialId, isMetal }) => {
    const token = localStorage.getItem("Token") || null;
    const [materialInfo, setMaterialInfo] = useState({
        name: "",
        costPerCC1: "",
        minCost1: 0,
        costPerCC2: "",
        minCost2: 0,
        costPerCC3: "",
        minCost3: 0,
        density: 0,
    });
    const [loading, setLoading] = useState(false);

    const validateMaterial = () => {
        // Check for cost and min cost pairs
        for (let i = 1; i <= 3; i++) {
            const costKey = `costPerCC${i}`;
            const minCostKey = `minCost${i}`;

            if (materialInfo[costKey] || materialInfo[minCostKey]) {
                if (!materialInfo[costKey] || !materialInfo[minCostKey]) {
                    toast.error(`Cost Per CC ${i} and Min Cost ${i} are required together.`);
                    return false;
                }
            }
        }

        if (!materialInfo.costPerCC1 && !materialInfo.costPerCC2 && !materialInfo.costPerCC3) {
            toast.error("Atleast one cost is required");
            return false;
        }
        return true; // All validations passed
    };


    useEffect(() => {
        (async () => {
            if (materialId) {
                try {
                    const response = await getMaterialByIdThreeD(token, materialId);
                    if (!response || !response.success) {
                        toast.error(response?.message);
                        return;
                    }
                    const { data } = response;
                    setMaterialInfo(data);
                } catch (error) {

                }
            }

        })()

    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMaterialInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        console.log("Materials :", materialInfo);
        e.preventDefault();
        if (materialInfo.fixedCost == 0) {
            toast.error("Fixed Cost cannot ce 0");
            return;
        }
        if (!isMetal) {
            const isValidate = validateMaterial();
            if (!isValidate) return;
        }


        e.preventDefault();
        setLoading(true);
        try {
            const data = await createMaterial(token, { ...materialInfo, id, materialId });
            if (!data || !data.success) {
                toast.error(data?.message);
                return;
            }
            toast.success(data?.message);
            onSubmit();
        } catch (error) {
            toast.error(data?.message);
            onSubmit();
        } finally {
            setLoading(false);
        }
    };

    console.log('materialInfo', materialInfo)

    return (
        <div className="p-4 bg-white rounded shadow-md max-w-md mx-auto">

            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={materialInfo.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Cost and Min-Cost Fields in Grid */}
                {!isMetal ? <div className="grid grid-cols-3 gap-4 mb-4">
                    {/* Cost 1 */}
                    <div>
                        <label className="block text-gray-700">Cost per CC 1 $</label>
                        <input
                            type="number"
                            name="costPerCC1"
                            value={materialInfo.costPerCC1}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    {/* Cost 2 */}
                    <div>
                        <label className="block text-gray-700">Cost per CC 2 $</label>
                        <input
                            type="number"
                            name="costPerCC2"
                            value={materialInfo.costPerCC2}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    {/* Cost 3 */}
                    <div>
                        <label className="block text-gray-700">Cost per CC 3 $</label>
                        <input
                            type="number"
                            name="costPerCC3"
                            value={materialInfo.costPerCC3}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    {/* Min Cost 1 */}
                    <div>
                        <label className="block text-gray-700">Min Cost 1 $</label>
                        <input
                            type="number"
                            name="minCost1"
                            value={materialInfo.minCost1}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    {/* Min Cost 2 */}
                    <div>
                        <label className="block text-gray-700">Min Cost 2 $</label>
                        <input
                            type="number"
                            name="minCost2"
                            value={materialInfo.minCost2}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    {/* Min Cost 3 */}
                    <div>
                        <label className="block text-gray-700">Min Cost 3 $</label>
                        <input
                            type="number"
                            name="minCost3"
                            value={materialInfo.minCost3}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                    handleChange(e);
                                }
                            }
                            }
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                </div> : null}
                <div className='mb-2'>
                    <label className="block text-gray-700">Density (g/cc)</label>
                    <input
                        type="number"
                        name="density"
                        value={materialInfo.density}
                        onChange={(e) => {
                            handleChange(e);
                        }
                        }
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                <button
                    type="submit"
                    className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-darkPrimaryColor"
                >
                    {!loading ? `${materialId ? "Update" : "Create"} ` : <LucidLoader />}
                </button>
            </form>

        </div>
    );
};

const UpsertTechnologyComponent = ({ id, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('Token') || null;
    const [technologyInfo, setTechnologyInfo] = useState({
        name: "",
        technologyType: ''
    });

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    setLoading(true)
                    const response = await getTechnologyByid(token, id);
                    if (!response || !response.success) {
                        toast.error(response?.message);
                        return;
                    }
                    const name = response?.data?.name;
                    const technologyType = response?.data?.technologyType;
                    setTechnologyInfo((pre) => ({ name, technologyType }))
                };
            } catch (error) {
                toast.error(response?.message);
            } finally {
                setLoading(false)
            }

        })()
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTechnologyInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const isDataValid = Boolean(technologyInfo.name && technologyInfo.technologyType);

            if (!isDataValid) toast.error("Technology name and type is required.");
            setLoading(true)
            const response = await upsert3dTechnologyData(token, { ...technologyInfo, id });
            if (!response || !response.success) {
                toast.error("Failed to create 3D technology");
                return;
            }
            toast.success(response?.message || "Success");
            if (onSubmit) {
                onSubmit();
            }
        } catch (err) {
            toast.error(`Error while ${id ? "updating" : "creating"} technology`)
        } finally {
            setLoading(false)
        }

    };

    return (
        <div className="p-4 bg-white rounded shadow-md max-w-md mx-auto">
            {loading ? <LucidLoader /> : <>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={technologyInfo.name}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded w-full"
                            required
                        />

                        <label className="block text-gray-700 mt-2">Technology Type</label>
                        <select
                            name="technologyType"
                            value={technologyInfo.technologyType}
                            defaultValue={technologyInfo.technologyType || ""}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded w-full"
                            required
                        >
                            <option value="" disabled>Select Type</option>
                            <option value="Metal">Metal</option>
                            <option value="Plastic">Plastic</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-darkPrimaryColor"
                    >
                        {id ? "Update " : "Create "}
                    </button>
                </form>
            </>}

        </div>
    );
};

const CreateNewMachine = ({ id, onSubmit, machineId }) => {
    const token = localStorage.getItem('Token') || null;
    const [technologyInfo, setTechnologyInfo] = useState({
        name: "",
        dimensions: { xDim: "", yDim: "", zDim: "" },
    });

    useEffect(() => {
        (async () => {
            if (machineId) {
                try {
                    const response = await getMachineDataById(token, machineId);
                    if (!response || !response.success) {
                        toast.error("Failed to fetch machine data.");
                        return;
                    }
                    const { data: { name, dimensions } } = response;
                    setTechnologyInfo({ name, dimensions });


                } catch (error) {
                    toast.error("Failed to fetch machine data.");
                }
            }

        })()
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setTechnologyInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleDimensionChange = (e) => {
        const { name, value } = e.target;
        setTechnologyInfo((prevInfo) => ({
            ...prevInfo,
            dimensions: { ...prevInfo.dimensions, [name]: value },
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await createMachineForTechnology(token, { ...technologyInfo, id, machineId });
        console.log(response);
        if (!response || !response.success) {
            toast.error(response?.message);
            return;
        }
        toast.success(response?.message);
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <div className="p-4 bg-white rounded shadow-md max-w-md mx-auto">
            {/* <h2 className="text-2xl font-bold mb-4">
                Create Machine
            </h2> */}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={technologyInfo.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Dimensions</label>
                    <div className="flex space-x-2">
                        <input
                            type="number"
                            name="xDim"
                            placeholder="X"
                            value={technologyInfo.dimensions.xDim}
                            onChange={handleDimensionChange}
                            className="mt-1 p-2 border rounded w-1/3"
                        />
                        <input
                            type="number"
                            name="yDim"
                            placeholder="Y"
                            value={technologyInfo.dimensions.yDim}
                            onChange={handleDimensionChange}
                            className="mt-1 p-2 border rounded w-1/3"
                        />
                        <input
                            type="number"
                            name="zDim"
                            placeholder="Z"
                            value={technologyInfo.dimensions.zDim}
                            onChange={handleDimensionChange}
                            className="mt-1 p-2 border rounded w-1/3"
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-darkPrimaryColor"
                >
                    {!machineId ? "Create" : "Update"}
                </button>
            </form>
        </div>
    );
};


const CreateLeadTimeComponent = ({ onSubmit, postProcessId, leadTimeData, setLeadTimeData }) => {

    const token = localStorage.getItem("Token") || null;
    const [loading, setLoading] = useState(false);

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = leadTimeData?.map((row, i) =>
            i === index ? { ...row, [name]: value } : row
        );
        setLeadTimeData(updatedRows);
    };

    const addRow = () => {
        setLeadTimeData((prev) => ([...prev, { minCCValue: '', maxCCValue: '', days1: '', days2: '', days3: '' }]));
    };

    const removeRow = (index) => {
        const updatedRows = leadTimeData.filter((_, i) => i !== index);
        setLeadTimeData(updatedRows);
    };


    return (
        <div className="p-4 bg-white rounded shadow-md max-w-3xl mx-auto">

            <div className="mb-2">
                <h3 className="text-gray-700 font-semibold">Lead Time Details</h3>
                <p className='text-red-900 text-xs'>For manual review enter 0.</p>
                <table className="min-w-full mt-2 border rounded">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Min CC</th>
                            <th className="px-4 py-2 border">Max CC</th>
                            <th className="px-4 py-2 border">Lead time 1 (days)</th>
                            <th className="px-4 py-2 border">Lead time 2 (days)</th>
                            <th className="px-4 py-2 border">Lead time 3 (days)</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leadTimeData?.map((row, index) => (
                            <tr key={index}>
                                <td className="px-2 py-2 border">
                                    <input
                                        type="number"
                                        name="minCCValue"
                                        value={row.minCCValue}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-4 py-2 border">
                                    <input
                                        type="number"
                                        name="maxCCValue"
                                        value={row.maxCCValue}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-2 py-2 border">
                                    <input
                                        type="number"
                                        name="days1"
                                        value={row.days1}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-2 py-2 border">
                                    <input
                                        type="number"
                                        name="days2"
                                        value={row.days2}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-2 py-2 border">
                                    <input
                                        type="number"
                                        name="days3"
                                        value={row.days3}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    <button
                                        type="button"
                                        onClick={() => removeRow(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button
                    type="button"
                    onClick={addRow}
                    className="mt-2 bg-gray-300 text-gray-700 py-1 px-4 rounded hover:bg-gray-400"
                >
                    Add Row
                </button>
            </div>

            <button
                type="submit"
                className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-darkPrimaryColor"
                onClick={onSubmit}
            >
                {!loading ? `${postProcessId ? "Update" : "Create"} ` : <LucidLoader />}
            </button>

        </div>
    );
};


const CreateMetalCCComponent = ({ onSubmit, materialId, metalCCData, setMetalCCData, selectedCost }) => {

    if (!metalCCData) return null;





    const token = localStorage.getItem("Token") || null;
    const [loading, setLoading] = useState(false);

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        setMetalCCData((prev) => ({
            ...prev,
            [`cost${selectedCost}RowData`]: prev[`cost${selectedCost}RowData`]?.map((row, i) =>
                i === index ? { ...row, [name]: value } : row
            ),
        }));
    };


    const addRow = () => {
        setMetalCCData((prev) => ({
            ...prev,
            [`cost${selectedCost}RowData`]: [
                ...(prev[`cost${selectedCost}RowData`] || []),
                { minCCValue: '', maxCCValue: '', costPerCC: '', fixedCost: '' },
            ],
        }));
    };


    const removeRow = (index) => {
        setMetalCCData((prev) => ({
            ...prev,
            [`cost${selectedCost}RowData`]: prev[`cost${selectedCost}RowData`]?.filter((_, i) => i !== index),
        }));
    };



    return (
        <div className="p-4 bg-white rounded shadow-md max-w-5xl mx-auto">
            <div className="mb-2">
                <h3 className="text-gray-700 font-semibold">Metal CC Details</h3>
                <p className='text-red-900 text-xs'>For manual review enter 0.</p>
                <table className="min-w-full mt-2 border rounded">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Min CC</th>
                            <th className="px-4 py-2 border">Max CC</th>
                            <th className="px-4 py-2 border">Fixed Cost</th>
                            <th className="px-4 py-2 border">Cost per CC</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {metalCCData[`cost${selectedCost}RowData`]?.map((row, index) => (
                            <tr key={index}>
                                <td className="px-4 py-2 border">
                                    <input
                                        type="number"
                                        name="minCCValue"
                                        value={row.minCCValue}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-4 py-2 border">
                                    <input
                                        type="number"
                                        name="maxCCValue"
                                        value={row.maxCCValue}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-4 py-2 border">
                                    <input
                                        type="number"
                                        name="fixedCost"
                                        value={row.fixedCost}
                                        // onChange={(e) => handleRowChange(index, e)}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleRowChange(index, e)
                                            }
                                        }
                                        }

                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-4 py-2 border">
                                    <input
                                        type="number"
                                        name="costPerCC"
                                        value={row.costPerCC}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleRowChange(index, e)
                                            }
                                        }
                                        }
                                        className="p-1 border rounded w-full"
                                        required
                                    />
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    <button
                                        type="button"
                                        onClick={() => removeRow(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button
                    type="button"
                    onClick={addRow}
                    className="mt-2 bg-gray-300 text-gray-700 py-1 px-4 rounded hover:bg-gray-400"
                >
                    Add Row
                </button>
            </div>

            <button
                type="submit"
                className="bg-primaryColor text-white py-2 px-4 rounded hover:bg-darkPrimaryColor"
                onClick={onSubmit}
            >
                {!loading ? `${materialId ? "Update" : "Create"} ` : <LucidLoader />}
            </button>

        </div>
    );
};


const TechRow = ({
    name,
    machines,
    _id,
    idx,
    setSelectedTechnology,
    selectedTechnology,
    onSubmit
}) => {
    const [modal, setModal] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const isSelected = selectedTechnology === idx;

    return (
        <tr
            key={_id}
            className={`border-b ${isSelected ? "bg-blue-100 border-blue-400 shadow-lg" : "bg-teal-50 hover:bg-slate-100"} transition-all duration-200 ease-in-out cursor-pointer`}
            onClick={() => setSelectedTechnology((pre) => (pre === idx ? null : idx))}
        >
            <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${isSelected ? "text-blue-800 font-semibold" : "text-gray-700"}`}>
                {name}
            </th>
            <td className="px-6 py-4 flex items flex-wrap">
                {machines?.map(item => (
                    <span
                        key={item._id}
                        className="border border-gray-300 px-2 py-1 rounded-lg bg-gray-100 mr-2 text-sm"
                    >
                        {item.name}
                    </span>
                ))}
                <span onClick={() => setModal(true)} className="cursor-pointer">
                    <PlusIcon additionalClass="h-5 mx-2 text-gray-500 hover:text-gray-700" />
                </span>
                <Modal
                    isOpen={modal}
                    onClose={() => setModal(false)}
                    title={`Create Machine For ${name}`}
                    children={<CreateNewMachine id={_id} onSubmit={onSubmit} />}
                />
            </td>
            <td></td>
            <td className="px-6 py-4">
                <div>
                    <button onClick={() => setIsUpdateModalOpen(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Update
                    </button>
                </div>
            </td>
            <Modal
                isOpen={isUpdateModalOpen}
                onClose={() => setIsUpdateModalOpen(false)}
                title={`Update Technology`}
                children={
                    <UpsertTechnologyComponent
                        onSubmit={onSubmit}
                        id={_id}
                    />
                }
            />
        </tr>
    );
};


const MaterialRow = ({
    costPerCC1, minCost1, costPerCC2, minCost2, costPerCC3, minCost3,
    density,
    name,
    _id,
    idx,
    postProcess,
    selectedMaterial,
    setSelectedMaterial,
    onSubmit,
    isMetal,
}) => {
    const [modal, setModal] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [showMetalCCModal, setShowMetalCCModal] = useState(false)
    const [metalCCData, setMetalCCData] = useState(null);
    const [selectedCost, setSelectedCost] = useState(1);
    const token = localStorage.getItem("Token") || null;

    const isSelected = selectedMaterial === idx;

    useEffect(() => {
        if (_id && showMetalCCModal) fetchMetalCCData();
    }, [_id, showMetalCCModal]);

    const fetchMetalCCData = async () => {
        try {
            const fetchMetalCCData = await getMetalCCDataForMaterial(token, _id);

            console.log("Metal CC Data :", fetchMetalCCData);
            if (fetchMetalCCData && fetchMetalCCData.success) {

                const data = fetchMetalCCData.data;
                if (data) {
                    setMetalCCData(data);
                }
                else {
                    setMetalCCData({
                        cost1RowData: [],
                        cost2RowData: [],
                        cost3RowData: [],
                    });
                }

            }
        } catch (err) {
            console.log("Error fetching metal cc for material.", err)
            toast.error("Error fetching metal cc for material.")
        }
    }

    function validateMetalCCData() {
        if (!metalCCData) {
            toast.error("No Metal CC data found to validate.");
            return false;
        }

        const costKeys = Object.keys(metalCCData).filter(key => key.startsWith('cost'));

        if (costKeys.length === 0) {
            toast.error("No valid cost data keys found in Metal CC data.");
            return false;
        }

        for (const costKey of costKeys) {
            const costData = metalCCData[costKey];

            // Skip validation for empty or undefined cost data
            if (!Array.isArray(costData) || costData.length === 0) {
                continue;
            }

            for (let i = 0; i < costData.length; i++) {
                const row = costData[i];
                const minCCValue = parseFloat(row.minCCValue);
                const maxCCValue = parseFloat(row.maxCCValue);
                const costPerCC = parseFloat(row.costPerCC);
                const fixedCost = parseFloat(row.fixedCost);

                // Check if the converted values are valid numbers
                if (isNaN(minCCValue) || isNaN(maxCCValue) || isNaN(costPerCC) || isNaN(fixedCost)) {
                    toast.error(
                        `${costKey} data is invalid. Ensure all minCCValue, maxCCValue, costPerCC, and fixedCost are valid numbers.`
                    );
                    return false;
                }

                // Update the costData array with parsed integers for consistent data
                row.minCCValue = minCCValue;
                row.maxCCValue = maxCCValue;
                row.costPerCC = costPerCC;
                row.fixedCost = fixedCost;
            }

            // Validate the ranges for the current costKey
            const sortedData = [...costData].sort((a, b) => a.minCCValue - b.minCCValue);

            if (sortedData[0].minCCValue !== 0) {
                toast.error(`${costKey} data is invalid. The first minCCValue must start from 0.`);
                return false;
            }

            for (let i = 0; i < sortedData.length - 1; i++) {
                const currentMax = sortedData[i].maxCCValue;
                const nextMin = sortedData[i + 1].minCCValue;

                if (currentMax >= nextMin) {
                    if (currentMax === nextMin) {
                        toast.error(
                            `${costKey} data is invalid. There is a gap between the ranges ending at ${currentMax} and starting at ${nextMin}. Ensure all ranges are strictly non-inclusive.`
                        );
                    } else {
                        toast.error(
                            `${costKey} data is invalid. The range ${sortedData[i].minCCValue}-${currentMax} overlaps with ${nextMin}-${sortedData[i + 1].maxCCValue}. Ensure ranges are strictly non-overlapping.`
                        );
                    }
                    return false;
                }
            }
        }

        return true;
    }



    const onMetalCCSave = async (setLoading) => {
        try {
            let isMetalCCDataValid = validateMetalCCData();
            console.log('isMetalCCDataValid', isMetalCCDataValid)
            if (isMetalCCDataValid) {
                const response = await updateMetalCCForMaterial(token, { metalCCData, _id })
                if (!response || !response.success) {

                    throw new Error();

                }
                toast.success("Data Updated Succesfully");
            }
        } catch (err) {

        }
    }

    return (
        <tr
            key={_id}
            className={`border-b ${isSelected ? "bg-green-100 border-blue-400 shadow-lg" : "bg-amber-50 hover:bg-slate-50"} transition-all duration-200 ease-in-out cursor-pointer`}
            onClick={() => setSelectedMaterial((pre) => (pre === idx ? null : idx))}
        >
            <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${isSelected ? "text-blue-800 font-semibold" : "text-gray-700"}`}>
                {name}
            </th>
            {isMetal ?
                <>
                    <td className="px-6 py-4">
                        <button onClick={() => {
                            setSelectedCost(1);
                            setShowMetalCCModal(true)

                        }} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                            Configure
                        </button>
                    </td>
                    <td className="px-6 py-4">
                        <button onClick={() => {
                            setSelectedCost(2);
                            setShowMetalCCModal(true)

                        }} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                            Configure
                        </button>
                    </td>
                    <td className="px-6 py-4">
                        <button onClick={() => {
                            setSelectedCost(3);
                            setShowMetalCCModal(true)

                        }} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                            Configure
                        </button>
                    </td>
                </>
                :
                <>
                    <td className='px-6 py-4'>
                        {costPerCC1}
                    </td>
                    <td className='px-6 py-4'>
                        {costPerCC2}
                    </td>
                    <td className='px-6 py-4'>
                        {costPerCC3}
                    </td>

                </>

            }
            <td className='px-6 py-4'>
                {density}
            </td>
            {/* <td className="px-6 py-4">
                {isMetal ? <>
                    <button onClick={() => setShowMetalCCModal(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Configure
                    </button>
                </> :
                    costPerCC
                }
            </td> */}
            <td className="px-6 py-4 flex items-center flex-wrap">
                {postProcess?.map(item => (
                    <span
                        key={item._id}
                        className="border border-gray-300 px-2 py-1 rounded-lg bg-gray-100 mr-2 text-sm"
                    >
                        {item.name}
                    </span>
                ))}
                <span onClick={() => setModal(true)} className="cursor-pointer">
                    <PlusIcon additionalClass="h-5 mx-2 text-gray-500 hover:text-gray-700" />
                </span>
                <Modal
                    isOpen={modal}
                    onClose={() => setModal(false)}
                    title={`Create PostProcess For ${name}`}
                    children={<CreatePostProcessComponent id={_id} onSubmit={onSubmit} />}
                />
            </td>
            <td></td>
            <td className="px-6 py-4">
                <div>
                    <button onClick={() => setIsUpdateModalOpen(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Update
                    </button>
                </div>
            </td>
            <Modal
                title={'Update Material'}
                onClose={() => setIsUpdateModalOpen(false)}
                isOpen={isUpdateModalOpen}
                children={
                    <CreateMaterialComponent
                        materialId={_id}
                        onSubmit={onSubmit}
                        isMetal={isMetal}
                    />
                }
            />

            <Modal
                title={`Update ${name} CC`}
                onClose={() => setShowMetalCCModal(false)}
                isOpen={showMetalCCModal}
                children={
                    <CreateMetalCCComponent
                        materialId={_id}
                        onSubmit={onMetalCCSave}
                        metalCCData={metalCCData}
                        setMetalCCData={setMetalCCData}
                        selectedCost={selectedCost}
                    />
                }
            />
        </tr>
    );
};


const MachineRow = ({
    name,
    materials,
    dimensions: { xDim, yDim, zDim },
    _id,
    selectedMachine,
    setSelectedMachine,
    idx,
    onSubmit
}) => {
    const [modal, setModal] = useState(false);
    const [isUpdateModalOpen, setIdUpdateModalOpen] = useState(false);
    const isSelected = selectedMachine === idx;

    return (
        <tr
            key={_id}
            className={`border-b ${isSelected ? "bg-orange-100 border-blue-900 shadow-lg" : "bg-blue-100 hover:bg-slate-100"} transition-all duration-200 ease-in-out cursor-pointer`}
            onClick={() => setSelectedMachine((prev) => (prev === idx ? null : idx))}
        >
            <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${isSelected ? "text-blue-800 font-semibold" : "text-gray-700"}`}>
                {name}
            </th>
            <th className="px-6 py-4 font-medium whitespace-nowrap">
                <span>{xDim}</span> x <span>{yDim}</span> x <span>{zDim}</span>
            </th>
            <td className="px-6 py-4 flex items-center flex-wrap">
                {materials.map(({ name, _id }) => (
                    <span
                        key={_id}
                        className="border border-gray-300 px-2 py-1 rounded-lg bg-gray-100 mr-2 text-sm"
                    >
                        {name}
                    </span>
                ))}
                <span onClick={() => setModal(true)} className="cursor-pointer">
                    <PlusIcon additionalClass="h-5 mx-2 text-gray-500 hover:text-gray-700" />
                </span>
                <Modal
                    isOpen={modal}
                    onClose={() => setModal(false)}
                    title={`Create Material For ${name}`}
                    children={<CreateMaterialComponent id={_id} onSubmit={onSubmit} />}
                />
            </td>
            <td className="px-6 py-4">
                <div>
                    <button onClick={() => setIdUpdateModalOpen(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Update
                    </button>
                </div>
            </td>
            <Modal
                onClose={() => setIdUpdateModalOpen(false)}
                title={'Update Machines'}
                isOpen={isUpdateModalOpen}
                children={<CreateNewMachine
                    machineId={_id}
                    onSubmit={onSubmit}

                />
                }
            />
        </tr>
    );
};



const PostProcessRow = ({
    costPerCC1,
    costPerCC2,
    costPerCC3,
    color,
    fixedCost,
    description,
    _id,
    name,
    idx,
    selectedPostProcess,
    setSelectedPostProcess,
    onSubmit,
    leadTimeModalTitle,
    manualReviewRequired,
}) => {
    const isSelected = selectedPostProcess === idx;
    const [isUpdateModalOpen, setIdUpdateModalOpen] = useState(false);
    const [showLeadTimeData, setShowLeadTimeData] = useState(false);
    const [leadTimeData, setLeadTimeData] = useState([]);
    const token = localStorage.getItem("Token") || null;

    useEffect(() => {
        if (_id && showLeadTimeData) fetchLeadTimeData();
    }, [_id, showLeadTimeData]);


    function validateLeadTimeData() {
        if (!leadTimeData || leadTimeData.length === 0) {
            toast.error("No lead time data found to save.");
            return false;
        }

        for (let i = 0; i < leadTimeData.length; i++) {
            const minCCValue = parseFloat(leadTimeData[i].minCCValue, 10);
            const maxCCValue = parseFloat(leadTimeData[i].maxCCValue, 10);
            const days1 = parseFloat(leadTimeData[i].days1, 10);
            const days2 = parseFloat(leadTimeData[i].days2, 10);
            const days3 = parseFloat(leadTimeData[i].days3, 10);

            if (
                isNaN(minCCValue) ||
                isNaN(maxCCValue) ||
                isNaN(days1) ||
                isNaN(days2) ||
                isNaN(days3)
            ) {
                toast.error(
                    `Lead time data is invalid. Ensure all minCCValue, maxCCValue, days1, days2, and days3 are valid numbers (row ${i + 1}).`
                );
                return false;
            }

            // Normalize the values
            leadTimeData[i].minCCValue = minCCValue;
            leadTimeData[i].maxCCValue = maxCCValue;
            leadTimeData[i].days1 = days1;
            leadTimeData[i].days2 = days2;
            leadTimeData[i].days3 = days3;
        }

        const sortedData = [...leadTimeData].sort((a, b) => a.minCCValue - b.minCCValue);

        if (sortedData[0].minCCValue !== 0) {
            toast.error("Lead time data is invalid. The first minCCValue must start from 0.");
            return false;
        }

        for (let i = 0; i < sortedData.length - 1; i++) {
            const currentMax = sortedData[i].maxCCValue;
            const nextMin = sortedData[i + 1].minCCValue;

            // Ensure ranges are strictly non-inclusive and continuous
            if (currentMax >= nextMin) {
                if (currentMax === nextMin) {
                    toast.error(
                        `Lead time data is invalid. The ranges are touching between ${currentMax} and ${nextMin}. Ensure all ranges have a strict gap between them.`
                    );
                } else {
                    toast.error(
                        `Lead time data is invalid. The range ${sortedData[i].minCCValue}-${currentMax} overlaps with ${nextMin}-${sortedData[i + 1].maxCCValue}. Ensure ranges do not overlap.`
                    );
                }
                return false;
            }
        }

        return true;
    }


    const onLeadTimeSave = async () => {
        try {
            let isLeadTimeDataValid = validateLeadTimeData();
            // console.log('isLeadTimeValid', isLeadTimeDataValid)
            if (isLeadTimeDataValid) {

                console.log("Lead Time Data :", leadTimeData);
                const response = await updateLeadTimeDataForPostProcessing(token, { leadTimeData, _id });

                toast.success("LeadTime Updated Succesfully");
            }
        } catch (err) {
            toast.error("Unable To Update LeadTime");
        } finally {
            setShowLeadTimeData(false);

        }
    }

    const fetchLeadTimeData = async () => {
        try {
            const fetchedLeadTimeData = await getLeadTimeDataForPostProcessing(token, _id);
            if (fetchedLeadTimeData?.success) {
                setLeadTimeData(fetchedLeadTimeData?.data?.rowData || [])
            }
        } catch (err) {
            console.log("Error fetching lead time data for postprocessing.", err)
            toast.error("Error fetching lead time for post processing")
        }
    }

    return (
        <tr
            key={_id}
            className={`border-b ${isSelected ? "bg-blue-150 border-blue-400 shadow-lg" : "bg-blue-50 hover:bg-slate-100"} transition-all duration-200 ease-in-out cursor-pointer`}
        >
            <th scope="row" className={`px-6 py-4 font-medium whitespace-nowrap ${isSelected ? "text-blue-800 font-semibold" : "text-gray-700"}`}>
                {name}
            </th>
            <td className="px-6 py-4">
                {costPerCC1}
            </td>
            <td className="px-6 py-4">
                {costPerCC2}
            </td>
            <td className="px-6 py-4">
                {costPerCC3}
            </td>
            <td className="px-6 py-4">
                {color}
            </td>
            <td className="px-6 py-4">
                {description}
            </td>
            <td className="px-6 py-4">
                {manualReviewRequired ? "Yes" : "No"}
            </td>
            <td className="px-6 py-4">
                <div>
                    <button onClick={() => setIdUpdateModalOpen(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Update
                    </button>
                </div>
            </td>
            <td className="px-6 py-4">
                <div>
                    <button onClick={() => setShowLeadTimeData(true)} className={`px-3 py-1 rounded ${isSelected ? "bg-blue-500 text-white" : "bg-yellow-500 text-white"} hover:opacity-90 transition duration-150`}>
                        Configure
                    </button>
                </div>
            </td>

            <Modal
                title={`Lead time for ${leadTimeModalTitle}`}
                onClose={() => setShowLeadTimeData(false)}
                isOpen={showLeadTimeData}
                children={
                    <CreateLeadTimeComponent
                        postProcessId={_id}
                        onSubmit={onLeadTimeSave}
                        leadTimeData={leadTimeData}
                        setLeadTimeData={setLeadTimeData}
                    />
                }

            />

            <Modal
                title={`Update Post Process`}
                onClose={() => setIdUpdateModalOpen(false)}
                isOpen={isUpdateModalOpen}
                children={
                    <CreatePostProcessComponent
                        postProcessId={_id}
                        onSubmit={onSubmit}
                    />
                }

            />
        </tr>
    );
};
