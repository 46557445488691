import React, { useEffect, useState } from "react";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { async } from "regenerator-runtime";
import { toast } from "react-toastify";
import { deleteMaterial, getAllMaterialData, upsertMaterial } from "../../utils/actions/adminActions";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import { ArrowLeft, ArrowUpIcon } from "../../components/Icons";

const MaterialMasterComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allMaterialsData, setAllMaterialsData] = useState([]);
    const [filteredMaterialsData, setFilteredMaterialsData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isReload, setIsReload] = useState();
    const [formData, setFormData] = useState({
        cnc_material: "",
        cnc_grade: "",
        cnc_material_desc: "",
        cnc_material_density_gms_cm3: "",
        cnc_material_review: "false",
    });

    const [materialSortingType, setMaterSortingType] = useState("aced");
    const [gradeSortingType, setGradeSortingType] = useState("aced");

    const [searchText, setSearchText] = useState("");

    function resetData() {
        setFormData({
            cnc_material: "",
            cnc_grade: "",
            cnc_material_desc: "",
            cnc_material_density_gms_cm3: "",
            cnc_material_review: "false",
        });
    }

    useEffect(() => {

        (async () => {
            const resposne = await getAllMaterialData();
            if (!resposne || !resposne.success) {
                throw new Error();
            }
            const { data } = resposne;
            setAllMaterialsData(data);
        })();


    }, [isReload])

    useEffect(() => {
        (async () => {
            const data = [...allMaterialsData];
            // Sorting logic based on both materialSortingType and gradeSortingType
            data.sort((a, b) => {
                // Compare cnc_material
                const materialCompare = a.cnc_material.localeCompare(b.cnc_material);
                const adjustedMaterialCompare = materialSortingType === "aced" ? materialCompare : -materialCompare;

                if (adjustedMaterialCompare !== 0) {
                    return adjustedMaterialCompare;
                }

                // Compare cnc_grade
                const gradeCompare = a.cnc_grade.localeCompare(b.cnc_grade);
                return gradeCompare;
            });

            setFilteredMaterialsData(data);
        })();
    }, [isReload, materialSortingType, allMaterialsData]);


    const toggleMaterialSorting = () => {
        setMaterSortingType(prev => (prev === "aced" ? "decd" : "aced"));
    };

    console.log("Material Sorting Type: ", materialSortingType);




    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await upsertMaterial(formData);
            if (!response || !response.success) {
                throw new Error();
            };
            toast.success(`Material ${formData?._id ? "Updated" : "Created"} Succesfully`);
        } catch (error) {
            toast.error("Unable To Add Material At The moment");
        }
        finally {
            setIsModalOpen(false);
            resetData();
            setIsReload(pre => !pre);
        }


    };

    const handleDelete = async (item) => {
        const { _id } = item;
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const response = await deleteMaterial(_id);
            if (!response || !response.success) {
                throw new Error();
            };

            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Delete Item At The Moment");
        }
    }

    const handleUpdate = (item) => {
        setFormData(item);
        setIsModalOpen(true);
    }

    useEffect(() => {
        const filterData = allMaterialsData.filter(item =>
            item.cnc_material.toLowerCase().includes(searchText.toLowerCase()) ||
            item.cnc_grade.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredMaterialsData(filterData);

    }, [searchText]);


    return (
        <div className="w-screen relative my-2">
            <div className="flex items-center justify-between px-3">
                <BackButton
                    additionalClasses='mx-2 my-2'
                />
                <h2 className="font-bold my-4 text-center text-2xl">Materials</h2>
                <button
                    onClick={() => {
                        resetData();
                        setIsModalOpen(true);
                    }}
                    className="bg-primaryColor rounded  px-2 py-2 text-sm text-white"
                >
                    Create New
                </button>
            </div>

            <form class="max-w-lg mx-auto my-2">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search" id="default-search" class="block w-full py-3  ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border focus:border-primaryColor  " placeholder="Search " required />
                </div>
            </form>

            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2  border-gray-200 flex my-2.5"><span>Material</span> <span onClickCapture={toggleMaterialSorting}>
                            <ArrowUpIcon
                                additionalClasses={`${materialSortingType != 'aced' ? 'rotate-180' : ''} h-5`}
                            />
                        </span></th>
                        <th class="px-4 py-2 border border-gray-200"><span>Grade</span>

                            {/* <span onClick={toggleGradeSorting}>
                                <ArrowUpIcon
                                    additionalClasses={`${gradeSortingType == 'aced' ? 'rotate-180' : ''} h-5`}
                                />
                            </span> */}


                        </th>
                        <th class="px-4 py-2 border border-gray-200">Description </th>
                        <th class="px-4 py-2 border border-gray-200">Density (g/cm³) </th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200"> Parameters </th>
                        <th class="px-4 py-2 border border-gray-200">Cost Model $ </th>
                        <th class="px-4 py-2 border border-gray-200">Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMaterialsData.length
                        ? filteredMaterialsData.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_grade}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_desc}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_density_gms_cm3}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_review ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200 text-white">
                                    <button onClick={() => navigate('/admin/cnc/select-sub-materials', { state: item })} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Select
                                    </button>
                                </td>
                                <td className="px-4 py-2 border border-gray-200 text-white">
                                    <button onClick={() => navigate('/admin/cnc/material-options', { state: item })} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Configure
                                    </button>
                                </td>
                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded w-1/3">
                        <h3 className="text-lg font-bold mb-4">Create New Material</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Material Name</label>
                                <input
                                    type="text"
                                    name="cnc_material"
                                    value={formData.cnc_material}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Grade</label>
                                <input
                                    type="text"
                                    name="cnc_grade"
                                    value={formData.cnc_grade}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Description</label>
                                <textarea
                                    name="cnc_material_desc"
                                    value={formData.cnc_material_desc}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Density (g/cm³)</label>
                                <input
                                    type="number"
                                    name="cnc_material_density_gms_cm3"
                                    value={formData.cnc_material_density_gms_cm3}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Review Material</label>
                                <select
                                    name="cnc_material_review"
                                    value={formData.cnc_material_review}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="bg-gray-300 px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-primaryColor text-white px-4 py-2 rounded"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboardComponents(MaterialMasterComponent);
