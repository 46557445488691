import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

let baseRouteCommonInvoice = '/api/v2/common/invoice'

export const handleDownloadCocForInvoice = async (invoiceId) => {
    try {
        const response = await axiosInstance.get(
            `${baseRouteCommonInvoice}/download-cert-of-conformance-invoice/${invoiceId}`,
            { responseType: 'blob' } // Set the response type to blob
        );
        return response;
    } catch (error) {
        throw new Error('Failed to download Certificate of Conformance for the invoice');
    }
};



export const downloadDeliveryDocketForInvoice = async (token, data) => {
    try {
      const headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axiosInstance.post(
        `${baseRouteCommonInvoice}/download-delivery-docket-invoice`,
        data,
        {
          headers,
          responseType: 'blob', 
        }
      );
  
      return response;
    } catch (error) {
      throw error; 
    }
  };
  