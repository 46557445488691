import { useEffect, useState,useContext } from "react";
import 'react-quill/dist/quill.snow.css';
import _ from 'lodash';
import jwtDecode from "jwt-decode";
import { Drawer, Image, Tabs, } from "antd";
import moment from "moment";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Linkify from 'react-linkify';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageSeparator,
    MessageInput,
    InputToolbox,
    AttachmentButton,
    SendButton,
    Loader,
} from "@chatscope/chat-ui-kit-react";
import { db, storage, } from "../utils/firebase";
import fileSaver from "file-saver";
import {ChatRefContext}  from '../context/chatRef.context';
import { getSignedUrlForFile } from "../utils/actions/allactions";
import deleteImage from '../images/reddelete.svg';
import { extractURLAndEncodedFilename } from "../utils/helper";
import jwt_decode from "jwt-decode";
import { Offcanvas } from "react-bootstrap";
import RichTextEditor from "./RichtextEditor";
import ReactQuill from "react-quill";

function Chat({ open, onDrawerClose, RefId }) {
    console.log(RefId)
    let {selectedChatRefId,setSelectedChatRefId,chatType} = useContext(ChatRefContext);
    const [chatStyle,setChatStyle] = useState(null)
    const oldRefId = RefId;
    if(RefId) {
        RefId = RefId.split(' ')[0];
        if(RefId.startsWith('S')){
            RefId = RefId.slice(1)
        }
    }

    // console.log("selectedahcat ref",selectedChatRefId)
    if(selectedChatRefId) {
        let newSelectedChatRefId = selectedChatRefId.split(' ')[0];
        if(selectedChatRefId.startsWith('S')){
            newSelectedChatRefId = newSelectedChatRefId.slice(1)
            setSelectedChatRefId(newSelectedChatRefId)
        }
    }
    let loggedInUserFirstName = localStorage.getItem('firstName');
    let loggedInUserLastName = localStorage.getItem('lastName');

    // console.log('------yes----------',RefId)
    const [showAttachment, setShowAttachment,] = useState(false);
    const [progress, setProgress,] = useState(0);
    const [addingAttachment, setAddAttachment, ] = useState(false);
    const [attachments, setAttachments,] = useState([]);
    let token = localStorage.getItem("Token");
    let decodedJwt={};
    if(token){
        decodedJwt = jwt_decode(token)
    }
    
    // let { RefId } = useParams();
    // const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [keys, setKeys] = useState([]);
    const [isOpenedAnytime, setIsOpenedAnytime] = useState(false);
    

    const onClose = () => {
        // setOpen(false);
        if(RefId)
        db.ref('/users/'+ RefId+'/' + decodedJwt._id).update({
            isOnline: false,
            RefId,
            email: decodedJwt.email,
            firstName: loggedInUserFirstName || decodedJwt?.firstName,
            lastName: loggedInUserLastName || decodedJwt?.lastName,
            lastOnline: new Date().toString()
        });
        onDrawerClose();
        setSelectedChatRefId(null);
    };


    const handlePaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text/plain');
        setMessage(message+pastedText);
        
        
        // Validate the pasted text to allow only plain text
        if (!isPlainText(pastedText)) {
          event.preventDefault();
          // Optionally, you can show a warning or error message to the user
          console.log('Only plain text is allowed');
        }
    };

    const isPlainText = (text) => {
      // You can implement additional validation here
      // For example, check for any non-text characters or patterns
      return /^[\p{L}\p{N}\p{P}\p{Z}]+$/u.test(text);
    };

    const download = async (name, url) => {
    const data=  extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };


    const onSend = async (attachmentUrl = null,attachmentName = null) => {
        try{
            // console.log('message');
            if(RefId) {
                const snapshot = await db.ref('/count/'+ RefId).get();
                let count = 0;
                if (snapshot.exists()) {
                    count = snapshot.val();
                } else {
                    count = 0;
                }
                const msgId = new Date().getTime()
                const messageObj = {
                    text:  !attachmentUrl ? message : null,
                    type: attachmentUrl ? "attachment" : "text",
                    attachmentName: attachmentName,
                    createdon: new Date().toString(),
                    userid: decodedJwt._id,
                    email: decodedJwt.email,
                    firstName: loggedInUserFirstName|| decodedJwt?.firstName,
                    lastName: loggedInUserLastName || decodedJwt?.lastName,
                    url: attachmentUrl
                }
                if(messageObj.text === '') return;
                db.ref('/messages/'+ RefId + '/' + msgId).set(messageObj)
                // .then(() => setMessages([...messages,messageObj]))
                .catch(err => console.log(err));
                db.ref('/count/'+ RefId).set(count+1);
                
                // console.log('----------3---------')
                db.ref('/users/'+ RefId + '/'+ decodedJwt._id).set({
                    msgId: msgId,
                    count: count+1,
                    isOnline: true,
                    RefId,
                    email: decodedJwt.email,
                    firstName: loggedInUserFirstName || decodedJwt?.firstName,
                    lastName: loggedInUserLastName || decodedJwt?.lastName,
                    lastOnline: new Date().toString()
                });
                db.ref("/timings/"+RefId).update({
                    lastOnline: new Date().toString(),
                    isEveryoneOffline: false,
                });
                if(!attachmentUrl){
                    setMessage("")
                }  
            }
        }
        catch(err){
            console.log("err",err)
        }
        
    }

    const onChange = (value) => {
        setMessage(value)
    }

    const handleKeySort = (arrayToSort,) =>{
        let data = [...arrayToSort].sort((a, b) => {
            return moment(a).isAfter(b) ? -1 : 1
        });
        return data;
    }

    useEffect(() => {

        // console.log('--------a---------',open,isOpenedAnytime)
        // let openedNow = isOpenedAnytime;
        // if(open && !isOpenedAnytime) {
        //     console.log('--------b---------',open)
        //     setIsOpenedAnytime(true)
        //     openedNow = true;
        // }
        if(open && RefId) {
        // console.log('--------c---------',RefId)
            // console.log('----------1---------')
            db.ref('/users/' + RefId + '/'+ decodedJwt._id).get().then(async snapshot => {
                db.ref('/users/' + RefId + '/'+ decodedJwt._id).onDisconnect().update({
                    isOnline: false,
                    RefId,
                    lastOnline: new Date().toString()
                });

                let value = {
                    count: 0,
                    isOnline: true,
                    RefId,
                    email: decodedJwt.email,
                    firstName: loggedInUserFirstName||decodedJwt?.firstName,
                    lastName: loggedInUserLastName || decodedJwt?.lastName,
                    lastOnline: new Date().toString()
                };

                if (snapshot.exists()) {
                    value = snapshot.val();
                }

                const snapshotVal = await db.ref('/count/'+ RefId).get();

                if(snapshotVal.exists()) {
                    const count = snapshotVal.val();
                    value.count = count;
                }

                // console.log('----------2---------')
                db.ref('/users/'+ RefId + '/' + decodedJwt._id).set({
                    ...value,
                    isOnline: true,
                    RefId,
                    email: decodedJwt.email,
                    firstName: loggedInUserFirstName || decodedJwt?.firstName,
                    lastName: loggedInUserLastName || decodedJwt?.lastName,
                    lastOnline: new Date().toString()
                })
                db.ref("/timings/"+RefId).update({
                    lastOnline: new Date().toString(),
                    isEveryoneOffline: false,
                });
            });

            if(chatType=='customer'){
                setChatStyle(null)
            }else if(chatType=='supplier'){
                setChatStyle({
                    backgroundColor:'#f3ceb6',
                    color:'#6e3815'
                })
            }
        }
    }, [open,RefId])

    useEffect(() => {
        if(RefId) {
        db.ref('/messages/'+ RefId).on('value', (snapshot) => {
            const data = snapshot.val();
            let messages = [];
            if(data){
                const updatedData = {};
                Object.keys(data).forEach((key) => {
                const innerObj = data[key];
                innerObj.key = key;
                updatedData[key] = innerObj;
                });
                messages = Object?.values(updatedData);  
            }
            
            
            const property = 'createdon';
            const array = messages.map(mess => {
                const date = new Date(mess.createdon);
                return {
                    ...mess,
                   'createdon': moment(mess.createdon).format('MMM DD, YYYY'),
                    'time': date.getHours()+':'+(date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes())
                }
            });
            const arr = array.reduce(function (groups, item) {
                var name = item[property]
                var group = groups[name] || (groups[name] = []);
                group.push(item);
                return groups;
            }, {});

            const keys = Object.keys(arr);
            const sortedKey = handleKeySort(keys);
            // keys.sort();
            setKeys(keys);
            if(RefId==selectedChatRefId){
                setMessages(arr);
            }
        });
        if(chatType=='customer'){
            setChatStyle(null)
        }else if(chatType=='supplier'){
            setChatStyle({
                backgroundColor:'#f3ceb6',
                color:'#6e3815'
            })
        }
    }
    }, [RefId,selectedChatRefId])

    useEffect(() => {
        if(RefId) {
        db.ref('/messages/'+ RefId).on('value', (snapshot) => {
            const data = snapshot.val();
            let messages = [];
            if(data){
                const updatedData = {};
                Object.keys(data).forEach((key) => {
                const innerObj = data[key];
                innerObj.key = key;
                updatedData[key] = innerObj;
                });
                messages = Object?.values(updatedData);
            }

            const property = 'createdon';
            const array = messages.map(mess => {
                const date = new Date(mess.createdon);
                return {
                    ...mess,
                    'createdon': moment(mess.createdon).format('DD-MM-YYYY'), 
                    'time': date.getHours()+':'+(date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes())
                }
            });
            const arr = array.reduce(function (groups, item) {
                var name = item[property]
                var group = groups[name] || (groups[name] = []);
                group.push(item);
                return groups;
            }, {});

            const keys = Object.keys(arr);
            const sortedKey = handleKeySort(keys);
            // keys.sort();
            setKeys(keys);
            setMessages(arr);
        });
        if(chatType=='customer'){
            setChatStyle(null)
        }else if(chatType=='supplier'){
            setChatStyle({
                backgroundColor:'#f3ceb6',
                color:'#6e3815',
            })
        }
    }
    }, [])

    const uploadFiles = (file, e) => {
        if (!file) return;
        try{
        setAddAttachment(true);
        let fileNameForDownload = `${Date.now()}_${file.name}`;
        const uploadTask = storage.ref(`/files/${fileNameForDownload}`).put(file);
        
        uploadTask.on("state_changed", (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(prog);
        },
            (err) => console.log(err),
            () => {  
                    // storage.ref("files").child(file.name).getDownloadURL().then(url => {
                        onSend(fileNameForDownload,file.name)  
                        setAddAttachment(false);  
                            // e.target.value = ""         
                    // }).catch(e => console.log(e))
                }
            )
        }catch(err){
            console.log('something went wrong',err)
        }
    }

    const handleFileDownload = async(fileNameForDownload,fileName) =>{
        try{
            const encodedURL = encodeURIComponent(fileNameForDownload);
            const response = await getSignedUrlForFile(encodedURL);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }catch(err){
            console.log('err',err)
        }
    }

    const attachFunc = () => {
        let upload = false;
        const fileUploader = document.getElementById('file-uploader');
        fileUploader.click();
        fileUploader.addEventListener('change', (event) => {   
            if(upload === true) return;
            const files = event.target.files[0];
            uploadFiles(files);
            upload = true;
            fileUploader.value=null;
        });

        // let upload = false;
        // if(upload === true) return;
        // const files = e.target.files[0];
        // uploadFiles(files, e);
        // upload = true;

    }

    const tabsChange = (key) => {
        if(key === '2') {
            setShowAttachment(true);
            let arr = [];
            keys.map(key => messages[key].filter(message => {
                if (message.type === "attachment") {
                    arr = [...arr, message];
                }
            }));
            setAttachments(arr);

        } else {
            setShowAttachment(false);
        }
    };

    const name = (message) => {
        // console.log('message.email',message?.email, message)
        if (message?.firstName && message?.lastName && message?.email.includes('8xparts')) {
            return message.firstName + ' ' + message.lastName + ' (8xParts)';
        } else if (message?.firstName && message?.lastName) {
            return message.firstName + ' ' + message.lastName; 
        } else {
            return message.firstName;
        }
    };

    const handleMessageDelete =async (message)=>{
        try{
            let adminReponse = confirm("Are you sure you want to delete the message ?");
            if(!adminReponse){
                return;
            }

             await db.ref('/messages/'+ RefId + '/' + message.key).remove();

            const countSnapshot = await db.ref("/count/" + RefId).once("value");
            const count = await countSnapshot.val() || { val: 0 };
            await db.ref('/count/'+ RefId).set(count-1);

            let userSnapShot = db.ref('/users/'+ RefId + '/'+ decodedJwt._id).get();
            userData = userSnapShot.value();
            await db.ref('/users/'+ RefId + '/'+ decodedJwt._id).set({
                ...userData,
                count: count-1,
            });

        }catch(err){
            console.log("Error",err)
        }
    }

    const items = [
        {
            key: '1',
            label: `Chat`,
            children: (keys.map((key,i) => {
                return (
                    <div key={i}>
                        <MessageSeparator content={key} />
                        {messages[key]?.map((message,j) => {
                    return (
                        <div key={j}>
                            <Message
                                model={{
                                    // message: message.text || message.attachmentName,
                                    sentTime: message.createdon,
                                    sender: message.email,
                                    position: "single",
                                    type: 'html',
                                    direction: message.userid === decodedJwt._id ? "outgoing" : "incoming"
                                }}
                            >
                                <Message.CustomContent>
                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target="_blank" rel="noopener" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>}>
                                    <Message.HtmlContent html={message?.text?.replaceAll('&lt;', '<')?.replaceAll('&gt;', '>') || message.attachmentName} />
                                    </Linkify>
                                </Message.CustomContent>
                                {
                                    message.type === 'attachment' && (
                                        <Message.CustomContent>
                                            <div>
                                                {
                                                    (message?.url?.toLowerCase()?.includes("png") || message?.url?.toLowerCase()?.includes("jpg") || message?.url?.toLowerCase()?.includes("jpeg") || message?.url?.toLowerCase()?.includes("webp")) ?
                                                    <Image src={`https://devmakeapi.8xparts.com/comman/get-signed-url-for-file-name/`+message.url} width="200px" /> :
                                                    <div
                                                        style ={{
                                                            background: "#ededed",
                                                            padding: "8px",
                                                            borderRadius: 8,
                                                            background: "white",
                                                            textAlign: 'center',
                                                            minWidth: 160,
                                                            marginBottom: 8
                                                        }}
                                                    >
                                                        <p 
                                                            className="text"
                                                            style={{fontWeight: 700}}
                                                        >
                                                            {message.attachmentName}
                                                        </p>
                                                    </div>
                                                }
                                                <p onClick={() => handleFileDownload(message.url,message.attachmentName)}  style={{cursor:'pointer',color:'#2995DB'}}>Download</p>
                                            </div>
                                        </Message.CustomContent>
                                    )
                                }
                            </Message>
                            {
                                message.userid === decodedJwt._id ? <div className='outgoing'  style={{margin:'-5px 0px 15px 0px'}}>{name(message)}, {message.time}{"  "} {message.email.split("@")[1]=='8xparts.com'?<img src={deleteImage} height="12px" onClick={()=>handleMessageDelete(message)} style={{height:'12px',margin:'-3px 3px 1px 5px',cursor:"pointer"}}/>:''}</div> : <div className='incomming' style={{margin:'-5px 0px 15px 0px'}}>{name(message)}, {message.time}</div>
                            }

                        </div>
                )
            }
            )}
                    </div>
                );
            }
            )),
        },
        {
            key: '2',
            label: `Attachments`,
            children: (attachments.length ? attachments.map((item, index) => {
                return (
                    <Message
                        model={{
                            message: item.attachmentName,
                            sentTime: item.createdon,
                            sender: item.email,
                            position: "single",
                            type: 'text',
                            direction: item.userid === decodedJwt._id ? "outgoing" : "incoming"
                        }}
                    >
                        <Message.CustomContent>
                            <div>
                            {
                                (item?.url?.toLowerCase()?.includes("png") || item?.url?.toLowerCase()?.includes("jpg") || item?.url?.toLowerCase()?.includes("jpeg") || item?.url?.toLowerCase()?.includes("webp")) ?
                                <Image src={`https://devmakeapi.8xparts.com/comman/get-signed-url-for-file-name/`+item.url} width="200px" /> :
                                <div
                                    style ={{
                                        background: "#ededed",
                                        padding: "8px",
                                        borderRadius: 8,
                                        background: "white",
                                        textAlign: 'center',
                                        minWidth: 160,
                                        marginBottom: 8
                                    }}
                                >
                                    <p 
                                        className="text"
                                        style={{fontWeight: 700}}
                                    >
                                        {item.attachmentName}
                                    </p>
                                </div>
                            }
                                <p onClick={() => handleFileDownload(item.url,item.attachmentName)}  style={{cursor:'pointer',color:'#2995DB'}}>Download</p>
                            </div>
                        </Message.CustomContent>
                    </Message>
                )
            }) : null),
        },
    ];

    // const onKeyDown = (e) => {
    //     const code = (e.keyCode ? e.keyCode : e.which);
    //     if (code == 13 && !e.shiftKey) {
    //         e.preventDefault();
    //     }
    // }

    const [pressCount, setPressCount] = useState(0);

    const  onKeyDown = e => {
        if (e.key === "Enter") {
            setPressCount((prevCount) => prevCount + 1);
            // If Enter is pressed twice, trigger the send message logic
            if (pressCount + 1 === 2) {
              onSend() // Trigger your send message logic here
              setPressCount(0); // Reset press count after sending message
            }
        } else {
            setPressCount(0); // Reset press count if a different key is pressed
        }
    }

    return (
        <>
        <div>
            <Offcanvas
                placement="end"
                show={open}
                onHide={onClose}
                style={{width: "35%"}}
                className="chat-drawer"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="sub-header">
                    RefId: {oldRefId}
                    </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="chat-div">
                            <MainContainer>
                                <ChatContainer >
                                    <MessageList autoScrollToBottom={true} autoScrollToBottomOnMount={true}>
                                    {
                                        addingAttachment && 
                                        <div
                                            style={{
                                                position: "sticky",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "rgba(255, 255, 255, 0.8)", // Background color with transparency
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                zIndex: 10,
                                            }}
                                        >
                                            <Loader style={{transform: "translateX(-25%)"}}>Uploading Attachment ...</Loader> 
                                        </div>
                                    }
                                        <Tabs tabPosition="top" defaultActiveKey="1" items={items} onChange={tabsChange} />
                                    </MessageList>  
                                </ChatContainer>
                            </MainContainer>
                            <div className="input-div">
                                <input 
                                    type="file" 
                                    id='file-uploader' 
                                    style={{display: 'none'}}
                                    // onChange={(e) => {attachFunc(e)}} 
                                />
                                <AttachmentButton className="attachment-btn" onClick={attachFunc} />
                                <ReactQuill
                                    message={message}
                                    onChange={onChange} 
                                    value={message}
                                    // onKeyDown={(e) => onKeyDown(e)}
                                />
                                <div className="btn-div flex gap-2 mt-2">
                                    <button 
                                        className="border-btn"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button 
                                        className="primary-btn w-100 text-white"
                                        onClick= {() => {
                                            onSend()
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
            </Offcanvas>
        </div>
        </>
    );
}

export default Chat;



   