import React from 'react'
import ProcessTabs from '../ProcessTabs/ProcessTabs'
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import uploadImg from "../../../../images/upload.svg";
import uploadGif from '../../../../assets/upload.gif';
import Dropzone from "react-dropzone";
import * as uploadLottie from '../../../../assets/upload-lottie.json';
import Lottie from 'lottie-react';
import animationData from "../../../../assets/JsonFiles/uploadJson.json";

const LeftSection = ({
    currentAcceptableProcess,
    setIsProcessing,
    setIsGlobeLoader,
    files,
    setFiles,
    bounding,
    setBounding,
    OnDropFunction,
    onSelectfile
}) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

    return (
        <div className="sm:col-span-1 p-4 border-r border-gray-300 shadow-sm h-full left-section">
            {currentAcceptableProcess?.length ?
                <>
                    <h1 class="process-title">Manufacturing Process</h1>
                    <div className='process-tabs-container'>
                        {currentAcceptableProcess.map(item => (
                            <ProcessTabs
                                name={item}
                            />
                        ))}
                    </div>
                </> : ""
            }

            <>

                {createQuoteState?.values && createQuoteState?.values.length > 0 && (
                    <>
                        {/* <div class="border-t border-gray-300 mt-4 mb-2"></div> */}
                        {/* <h1 className='m-2'>Add more files:</h1> */}
                        <div className="upload-req">
                            <Dropzone
                                onDrop={(e) => OnDropFunction(e)}
                                minSize={1024}
                                maxSize={10 * 1024 * 1024} //10mb
                                disabled={createQuoteState?.commanValues?.selectedProcess === 0 ? true : false}
                                multiple={true}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps({ className: " upload-box dropzone " })}
                                    >
                                        <span className="up-icon h-20 aspect-square">
                                            {/* <img src={uploadGif} alt="upload" /> */}
                                            <Lottie
                                                animationData={animationData}
                                                loop={true}
                                            />
                                        </span>
                                        <span
                                            className={
                                                createQuoteState?.commanValues?.selectedProcess === 0 ? "error" : "inp-txt"
                                            }
                                            style={{ fontSize: "16px" }}
                                        >
                                            {createQuoteState?.commanValues?.selectedProcess === 0
                                                ? "Please select a Process to continue..."
                                                : "Drop more files to upload"}
                                        </span>
                                        <div className="file-button">
                                            <input {...getInputProps()} />

                                            <label
                                                className="file-input__label"
                                                htmlFor="file-input"
                                            // onClick={() => checkIfProcessSet()}
                                            >
                                                <span>Select a file</span>
                                            </label>
                                        </div>
                                        {createQuoteState?.commanValues?.selectedProcess === 1 ||
                                            createQuoteState?.commanValues?.selectedProcess === 2 ? (
                                            <p className='info-text'>
                                                Instant Quotation for - .stp, .step files. Other file
                                                format are also accepted (RFQ)
                                            </p>
                                        ) : null}
                                    </div>
                                )}
                            </Dropzone>

                        </div>
                    </>
                )}
            </>

        </div>
    )
}

export default LeftSection