import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useNavigate } from "react-router-dom";
import { config } from "../../environment/development";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import {
  createUpdateOrganizationSetup,
  deleteOrganzationLogo,
} from "../../api/customer/quotation/adminQuotationApi";
import { Loading } from "../../components/Loading";
import { fetchOrganzationDetails } from "../../api/customer/quotation/commonQuotationApi";

const licenseKey = "";
const labelStyle = {
  fontWeight: "bold",
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  marginLeft: "10px",
};

const OrganizationSetup = () => {
  const [organizationData, setOrganizationData] = useState({
    organizationName: "",
    organizationLogo: "",
    organizationAddress: {
      addressLineOne: "",
      addressLineTwo: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
    },
    organizationBankDetails: {
      bankName: "",
      bankAddress: "",
      accountName: "",
      accountNumber: "",
      branchCode: ""
    },
    organizationPhone: "",
    organizationEmail: "",
    organizationCurrency: "",
    organizationCurrencySymbol: "",
    quotationNotes: "",
    quotationTnc: "",
    invoiceTnC: "",
    purchaseOrderTnc: "",
  });

  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const token = localStorage.getItem("Token");
  useDocumentTitle("Organization Setup");
  const quotationNotesRef = useRef();
  const quotationTncRef = useRef();
  const invoiceTnCRef = useRef();
  const purchaseOrderTncRef = useRef();
  const editors = useRef({
    editor1: null,
    editor2: null,
    editor3: null,
    editor4: null
  });
  const [ckEditorData, setCkEditorData] = useState({
    quotationNotes: '',
    quotationTnc: '',
    invoiceTnC: '',
    purchaseOrderTnc: '',
  });



  useEffect(() => {
    try {
      (async () => {
        setShowLoader(true)
        const organizationResponse = await fetchOrganzationDetails(token);
        let organizationData = organizationResponse?.data;

        setOrganizationData(organizationResponse?.data);

        setCkEditorData({
          quotationNotes: organizationData?.quotationNotes || "",
          quotationTnc: organizationData?.quotationTnc || "",
          invoiceTnC: organizationData?.invoiceTnC || "",
          purchaseOrderTnc: organizationData?.purchaseOrderTnc || "",
        });
        setLoading(false);
        setShowLoader(false)
      })()
    } catch (err) {
      setShowLoader(false)
      toast.error("Failed to load organization data. Please try again later.")
    }
  }, [])


  useEffect(() => {
    const initializeEditors = async () => {
      if (!loading && window.ClassicEditor) {
        try {
          const [editor1, editor2, editor3, editor4] = await Promise.all([
            window.ClassicEditor.create(quotationNotesRef.current, { licenseKey }),
            window.ClassicEditor.create(quotationTncRef.current, { licenseKey }),
            window.ClassicEditor.create(invoiceTnCRef.current, { licenseKey }),
            window.ClassicEditor.create(purchaseOrderTncRef.current, { licenseKey })
          ]);

          editors.current = { editor1, editor2, editor3, editor4 };

          // Set initial data
          editor1.setData(organizationData?.quotationNotes || '');
          editor2.setData(organizationData?.quotationTnc || '');
          editor3.setData(organizationData?.invoiceTnC || '');
          editor4.setData(organizationData?.purchaseOrderTnc || '');

          // Setup change listeners
          const setupChangeListener = (editor, field) => {
            editor.model.document.on('change:data', () => {
              setCkEditorData(prev => ({ ...prev, [field]: editor.getData() }));
            });
          };

          setupChangeListener(editor1, 'quotationNotes');
          setupChangeListener(editor2, 'quotationTnc');
          setupChangeListener(editor3, 'invoiceTnC');
          setupChangeListener(editor4, 'purchaseOrderTnc');
        } catch (error) {
          console.error('Error initializing editors:', error);
        }
      }
    };

    initializeEditors();

    // Cleanup function
    return () => {
      Object.values(editors.current).forEach(editor => editor?.destroy());
    };
  }, [loading]);


  const handleBankDetailsChange = (field, value) => {
    let localBankDetails = organizationData.organizationBankDetails || {};
    localBankDetails[field] = value;
    setOrganizationData({ ...organizationData, organizationBankDetails: localBankDetails });
  };


  const handleAddressChange = (field, value) => {
    let localAddressDetails = organizationData?.organizationAddress || {};
    localAddressDetails[field] = value;
    setOrganizationData({ ...organizationData, organizationAddress: localAddressDetails });
  };


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageUpload = async (e) => {
    try {
      setShowLoader(true);
      let formData = new FormData();
      formData.append("organizationLogo", e.target.files[0]);
      const response = await createUpdateOrganizationSetup(formData)
      setOrganizationData(response?.data);
      setShowLoader(false)
    } catch (err) {
      setShowLoader(false)
      console.error('Error uploading image:', err);
      toast.error('Error uploading image');
    }

  };

  const handleDeleteImage = async () => {
    let deleteResponse = await deleteOrganzationLogo();
    setOrganizationData(deleteResponse?.data)
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (!organizationData?.organizationName) {
      toast.error("Organization name is required");
      return;
    }
    try {
      setShowLoader(true);
      const updatedData = {
        ...organizationData,
        ...ckEditorData,
      };
      let formData = new FormData();
      formData.append("organizationData", JSON.stringify(updatedData));
      const response = await createUpdateOrganizationSetup(formData)

      if (response?.success) {
        toast.success("Organization data updated successfully")
      }
      setShowLoader(false)
    } catch (err) {
      setShowLoader(false)
      console.error('Error updating organization data:', err);
      toast.error("Failed to update organization data. Please try again later.")
    }

  };

  const handleGeneratePdf = async (e) => {
    e.preventDefault();
    setPdfLoading(true)
    try {
      const response = await axios.get(config.backend + "/api/quotation/generate-pdf", {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(response)
      if (response.statusText == "OK") {
        toast.success("PDF generated successfully!");
        setPdfLoading(false)
      }
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "quotation.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  console.log('showLoader', showLoader)

  return (
    <div className="right-container">
      <div className="center-heading">
        <div className="admin-user-container" style={{ border: "1px solid #bdc1c6", borderRadius: "3px", padding: "10px 0px", background: "#fff" }}>
          <div className="admin-user-header">Update organization details</div>


          <form onSubmit={handleSubmit}>
            <div className="admin-user-body">
              <div className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {organizationData?.organizationLogo ? (
                    <img
                      src={organizationData?.organizationLogo}
                      alt="organization Logo"
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#f7f7f7",
                        border: "1px dashed #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        fontSize: "14px",
                        color: "#aaa",
                      }}
                    >
                      No Image
                    </div>
                  )}
                  <span style={{ fontSize: "12px", color: "#666", textAlign: "center", width: "244px" }}>
                    For optimal results, please upload an image between 1 MB and 2 MB in size.
                  </span>
                </div>

                {/* Buttons Section */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {organizationData?.organizationLogo ? (
                    <button
                      type="button"
                      onClick={() => handleDeleteImage()}
                      className="blue-btn-ui !bg-red-500"
                      style={{
                        padding: "8px 12px",
                        fontSize: "14px",
                      }}
                    >
                      Delete
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => document.getElementById("organizationLogo").click()}
                      className="blue-btn-ui"
                      style={{
                        padding: "8px 12px",
                        fontSize: "14px",
                      }}
                    >
                      Add Image
                    </button>
                  )}
                </div>

                <input
                  type="file"
                  id="organizationLogo"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
              <div className="admin-user-name">
                <input
                  type="text"
                  id="organizationName"
                  name="organizationName"
                  value={organizationData?.organizationName}
                  onChange={handleFormChange}
                  placeholder="Enter organization name"
                />
              </div>


              <label style={labelStyle} className="fw-bold">Address: </label>

              <div className="admin-user-name">
                <input
                  type="text"
                  id="addressLineOne"
                  name="addressLineOne"
                  value={organizationData?.organizationAddress?.addressLineOne}
                  onChange={(e) => {
                    handleAddressChange("addressLineOne", e.target.value);
                  }}
                  placeholder="Enter address line 1"
                />
                <input
                  type="text"
                  id="addressLineTwo"
                  name="addressLineTwo"
                  value={organizationData?.organizationAddress?.addressLineTwo}
                  onChange={(e) => {
                    handleAddressChange("addressLineTwo", e.target.value);
                  }}
                  placeholder="Enter address line 2"
                />
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={organizationData?.organizationAddress?.zipCode}
                  onChange={(e) => {
                    handleAddressChange("zipCode", e.target.value);
                  }}
                  placeholder="Enter zip code"
                />

              </div>

              <div className="admin-user-name">
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={organizationData?.organizationAddress?.city}
                  onChange={(e) => {
                    handleAddressChange("city", e.target.value);
                  }}
                  placeholder="Enter city"
                />
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={organizationData?.organizationAddress?.state}
                  onChange={(e) => {
                    handleAddressChange("state", e.target.value);
                  }}
                  placeholder="Enter state"
                />
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={organizationData?.organizationAddress?.country}
                  onChange={(e) => {
                    handleAddressChange("country", e.target.value);
                  }}
                  placeholder="Enter country"
                />
              </div>




              <label style={labelStyle} className="fw-bold">Bank Details: </label>

              <div className="admin-user-name">
                <input
                  type="text"
                  id="bankName"
                  name="bankName"
                  value={organizationData?.organizationBankDetails?.bankName}
                  onChange={(e) => {
                    handleBankDetailsChange("bankName", e.target.value);
                  }}
                  placeholder="Enter bank name"
                />


                <input
                  type="text"
                  id="accountName"
                  name="accountName"
                  value={organizationData?.organizationBankDetails?.accountName}
                  onChange={(e) => {
                    handleBankDetailsChange("accountName", e.target.value);
                  }}
                  placeholder="Enter account name"
                />

                <input
                  type="text"
                  id="accountNumber"
                  name="accountNumber"
                  value={organizationData?.organizationBankDetails?.accountNumber}
                  onChange={(e) => {
                    handleBankDetailsChange("accountNumber", e.target.value);
                  }}
                  placeholder="Enter account number"
                />
              </div>

              <div className="admin-user-name">
                <input
                  type="text"
                  id="branchCode"
                  name="branchCode"
                  value={organizationData?.organizationBankDetails?.branchCode}
                  onChange={(e) => {
                    handleBankDetailsChange("branchCode", e.target.value);
                  }}
                  placeholder="Enter branch code"
                />
                <input
                  type="text"
                  id="bankAddress"
                  name="bankAddress"
                  value={organizationData?.organizationBankDetails?.bankAddress}
                  onChange={(e) => {
                    handleBankDetailsChange("bankAddress", e.target.value);
                  }}
                  placeholder="Enter bank address"
                />
              </div>





              <label style={labelStyle} className="fw-bold">Currency Details: </label>

              <div className="admin-user-name">

                <span className="ml-2">
                  <select
                    onChange={handleFormChange}
                    id="organizationCurrency"
                    name="organizationCurrency"
                    value={organizationData?.organizationCurrency}
                    style={{ width: '300px' }}
                  >
                    <option value="">Select Currency</option>
                    <option value="usd">USD</option>
                    <option value="euro">EURO </option>
                  </select>
                </span>
                <input
                  type="text"
                  id="organizationCurrencySymbol"
                  name="organizationCurrencySymbol"
                  value={organizationData?.organizationCurrencySymbol}
                  maxLength="1"
                  onChange={handleFormChange}

                  placeholder="Currency Symbol"
                />
              </div>
              <div className="mb-3">
                <label style={labelStyle} className="fw-bold">Quotation Notes: </label>
                <div ref={quotationNotesRef} id="quotatioNotes"></div>
              </div>

              <div className="mb-3">
                <label style={labelStyle} className="fw-bold">Quotation Terms and Conditions: </label>
                <div ref={quotationTncRef} ></div>
              </div>

              <div className="mb-3">
                <label style={labelStyle} className="fw-bold">Invoice Terms and Conditions: </label>
                <div ref={invoiceTnCRef} ></div>
              </div>

              <div className="mb-3">
                <label style={labelStyle} className="fw-bold">Purchase Order Terms and Conditions: </label>
                <div ref={purchaseOrderTncRef} ></div>
              </div>
            </div>

            <button type="submit" className="blue-btn-ui">Save Changes</button>
          </form>


          {showLoader ? <Loading /> : ""}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(OrganizationSetup);
