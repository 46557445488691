import axios from "axios";


const supplierBaseUrl = process.env.REACT_APP_SUPPLIER_BACKEND;


export const uploadFileToRFQ = (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let header = {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "application/json",
        };
  
        let response = await axios.post(
          `${supplierBaseUrl}/admin/upload-file-to-rfq`,
          data,
          { headers: header }
        );
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    });
  };