import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addMaterial } from '../../../utils/actions/allactions';
import { validateAdmin, getToken, numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";

function AddMaterialMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle('Add Material')
  const [error, setError] = useState("");
  const [initialValue, setInitialValue] = useState({
    name: '',
    is3DPrinting: '',
    isMachining: '',
    isSheetMetal: '',
    isInjectionMolding: '',
    threeDMaterialCost: '',
    threeD_Material_Density: '',
    threeDExcessVolume: '',
    threeDPrintingHourly: '',
    threeDPrintingRate: '',
    threeDRecoatingRate: '',
    isActive: ''
  });



  const validateMaterialVal = () => {
    if (!initialValue.name ||
      (initialValue.is3DPrinting == null || initialValue.is3DPrinting === "") ||
      (initialValue.isMachining == null || initialValue.isMachining === "") ||
      (initialValue.isSheetMetal == null || initialValue.isSheetMetal === "") ||
      (initialValue.isInjectionMolding == null || initialValue.isInjectionMolding === "") ||
      (initialValue.isActive == null || initialValue.isActive === "")) {
      return false;
    }
    return true;
  }


  useEffect(() => {
    validateAdmin(token, navigate);
  }, []);

  const handleAddMaterial = async () => {
    setError('');
    const isMaterialDataValid = validateMaterialVal();

    if (!isMaterialDataValid) {
      setError("Enter valid values for all the field")
    } else {
      const addedMaterial = await addMaterial(token, initialValue);
      if (addedMaterial?.data?.data?.code == 11000) {
        toast.error("Material already exists")
      } else {
        navigate('/admin/material');
        toast.success("Material added successfully")
      }
    }
  }


  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading">  Add Material </h1>

        <div className="edit-company-container">
          <div>
            <label htmlFor="name" > Name </label>: &nbsp;
            <input
              name="name"
              type="text"
              placeholder="Enter  name"
              onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
            />
          </div>

          <div className="admin-status-div">
            <label htmlFor="is3dPrinting" >Is 3d Printing?</label>: &nbsp;
            <input
              name="is3dPrinting"
              type="radio"
              value="true"
              onChange={(e) => { setInitialValue({ ...initialValue, is3DPrinting: e.target.value === "true" ? true : "" }) }}
            />Yes

            <input
              name="is3dPrinting"
              type="radio"
              value="false"
              onChange={(e) => { setInitialValue({ ...initialValue, is3DPrinting: e.target.value === "false" ? false : "" }) }}
            />No
          </div>

          <div className="admin-status-div">
            <label htmlFor="isMachining" >Is  Machining?</label>: &nbsp;
            <input
              name="isMachining"
              type="radio"
              value="true"
              onChange={(e) => { setInitialValue({ ...initialValue, isMachining: e.target.value === "true" ? true : "" }) }}
            />Yes

            <input
              name="isMachining"
              type="radio"
              value="false"
              onChange={(e) => { setInitialValue({ ...initialValue, isMachining: e.target.value === "false" ? false : "" }) }}
            />No
          </div>

          <div className="admin-status-div">
            <label htmlFor="isSheetMetal" >Is  SheetMetal?</label>: &nbsp;
            <input
              name="isSheetMetal"
              type="radio"
              value="true"
              onChange={(e) => { setInitialValue({ ...initialValue, isSheetMetal: e.target.value === "true" ? true : null }) }}
            />Yes

            <input
              name="isSheetMetal"
              type="radio"
              value="false"
              onChange={(e) => { setInitialValue({ ...initialValue, isSheetMetal: e.target.value === "false" ? false : null }) }}
            />No
          </div>

          <div className="admin-status-div">
            <label htmlFor="isInjectionMolding" >Is InjectionMolding?</label>: &nbsp;
            <input
              name="isInjectionMolding"
              type="radio"
              value="true"
              onChange={(e) => { setInitialValue({ ...initialValue, isInjectionMolding: e.target.value === "true" ? true : null }) }}
            />Yes

            <input
              name="isInjectionMolding"
              type="radio"
              value="false"
              onChange={(e) => { setInitialValue({ ...initialValue, isInjectionMolding: e.target.value === "false" ? false : null }) }}
            />No
          </div>

          <div>
            <label htmlFor="threeDMaterialCost" > 3D Material Cost  </label>: &nbsp;
            <input
              name="threeDMaterialCost"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Material Cost"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeDMaterialCost: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="threeD_Material_Density" > 3D Material Density  </label>: &nbsp;
            <input
              name="threeD_Material_Density"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Material Density"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeD_Material_Density: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="threeDExcessVolume" > 3D Excess Volume  </label>: &nbsp;
            <input
              name="threeDExcessVolume"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Excess Volume"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeDExcessVolume: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="threeDPrintingHourly" > 3D  Printing Hourly  </label>: &nbsp;
            <input
              name="threeDPrintingHourly"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Printing Hourly "
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeDPrintingHourly: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="threeDPrintingRate" > 3D  Printing Rate  </label>: &nbsp;
            <input
              name="threeDPrintingRate"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Printing Rate "
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeDPrintingRate: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="threeDRecoatingRate" > 3D  Recoating Rate  </label>: &nbsp;
            <input
              name="threeDRecoatingRate"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter 3D Recoating Rate "
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, threeDRecoatingRate: e.target.value }) }}
            />
          </div>

          <div className="admin-status-div">
            <label htmlFor="isActive" >Status</label>: &nbsp;
            <input
              name="isActive"
              type="radio"
              value="true"
              onChange={(e) => { setInitialValue({ ...initialValue, isActive: e.target.value === "true" ? true : null }) }}
            />Yes

            <input
              name="isActive"
              type="radio"
              value="false"
              onChange={(e) => { setInitialValue({ ...initialValue, isActive: e.target.value === "false" ? false : null }) }}
            />No
          </div>





          <div className="center-heading">
            <button className="button-search" onClick={handleAddMaterial}>  Create </button>
          </div>
          {
            error ?
              <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
              : null
          }
        </div>
      </div>
    </div>
  )
}
export default AddMaterialMaster;



