import jwt_decode from "jwt-decode";
import { getPresignedUrlForFile } from "../api/helperapi/helperApi";
import { toast } from "react-toastify";

export const validateAdmin = (token, navigate) => {
  if (!token) {
    navigate("/");
  } else {
    let decode = jwt_decode(token);
    if (!decode) {
      navigate("/");
      toast.error("Invalid Token found!");
    } else if (decode) {
      if (decode.userType === "admin") {
      } else if (decode.userType === "user") {
        navigate("/saved-quote");
      } else {
        console.log('camhere 1')
        navigate("/");
      }
    }
  }
};

export const getToken = () => {
  return localStorage.getItem("Token");
};

export const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const currencyFormatter = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount);
};


export const extractURLAndEncodedFilename = (url) => {
  const normalizedURL = url.replace(/[\\/]/g, '/');

  // Split the normalized URL by the last '/'
  const parts = normalizedURL.split('/');
  const filename = parts[parts.length - 1];

  // URL encode the extracted filename
  const encodedFilename = encodeURIComponent(filename.trim());

  // Extract the URL without the filename
  const urlWithoutFilename = normalizedURL.substring(0, normalizedURL.lastIndexOf('/') + 1);

  return {
    url: urlWithoutFilename,
    encodedFilename: encodedFilename
  };
}


//if logic is changes here also change logic in  calculatePriceValBasedOnData  below
export const calculatePriceBasedOnData = (order) => {
  try {
    let localTotal = order?.quoteId?.partsData.reduce((accumulator, item) => {
      return (
        accumulator +
        Number(item.Qty) *
        Number(
          order.quoteSelectedShipMethod === 1
            ? item?.price1
            : order.quoteSelectedShipMethod === 2
              ? item?.price2
              : item?.price3
        )
      );
    }, 0);
    let additionalCost = Number(
      order?.additionalCost ? order?.additionalCost : 0
    );
    let shippingCharge = Number(
      order?.shippingCharge ? order?.shippingCharge : 0
    );
    let adjustmentValue = Number(order?.adjustmentValue ? order?.adjustmentValue : 0);
    localTotal = Number(localTotal) + additionalCost + shippingCharge;
    let tax = order?.tax ? order?.tax : 0;
    return currencyFormatter(Number(localTotal + ((localTotal * tax) / 100) + adjustmentValue).toFixed(2));
  } catch (err) {
    console.log("err", err)
  }

}

export const calculatePriceValBasedOnData = (order) => {
  try {
    let localTotal = order?.quoteId?.partsData.reduce((accumulator, item) => {
      return (
        accumulator +
        Number(item.Qty) *
        Number(
          order.quoteSelectedShipMethod === 1
            ? item?.price1
            : order.quoteSelectedShipMethod === 2
              ? item?.price2
              : item?.price3
        )
      );
    }, 0);
    let additionalCost = Number(
      order?.additionalCost ? order?.additionalCost : 0
    );
    let shippingCharge = Number(
      order?.shippingCharge ? order?.shippingCharge : 0
    );
    let adjustmentValue = Number(order?.adjustmentValue ? order?.adjustmentValue : 0);
    localTotal = Number(localTotal) + additionalCost + shippingCharge;
    let tax = order?.tax ? order?.tax : 0;
    return Number(localTotal + ((localTotal * tax) / 100) + adjustmentValue).toFixed(2);
  } catch (err) {
    console.log("err", err)
  }

}
export const calculatePriceValBasedOnDataWithoutTax = (order) => {
  try {
    let localTotal = order?.quoteId?.partsData.reduce((accumulator, item) => {
      return (
        accumulator +
        Number(item.Qty) *
        Number(
          order.quoteSelectedShipMethod === 1
            ? item?.price1
            : order.quoteSelectedShipMethod === 2
              ? item?.price2
              : item?.price3
        )
      );
    }, 0);
    let additionalCost = Number(
      order?.additionalCost ? order?.additionalCost : 0
    );
    let shippingCharge = Number(
      order?.shippingCharge ? order?.shippingCharge : 0
    );
    let adjustmentValue = Number(order?.adjustmentValue ? order?.adjustmentValue : 0);
    localTotal = Number(localTotal) + additionalCost + shippingCharge;
    return Number(localTotal + adjustmentValue).toFixed(2);
  } catch (err) {
    console.log("err", err)
  }
}



export function capitalizedStatus(text) {
  // Split the text by hyphen and capitalize the first letter of each word
  const words = text.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with spaces
  const result = words.join(' ');

  return result;
}


export function capitalizeString(inputString) {
  // Split the input string into words based on spaces or dashes
  const words = inputString.split(/[ -]/);

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back together with spaces
  const capitalizedString = capitalizedWords.join(' ');

  return capitalizedString;
}

export const getFileExtension = (filename) => {
  return filename?.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}


export const isValidFileName = (name) => {
  const type = getFileExtension(name)?.toUpperCase();
  if (type === "STP" || type === "STEP" || type === "IGES" || type === "OBJ" || type === "STL") return true;
  return false;
}

export const isValidExtension = (extension) => {
  console.log("Extension check ", extension)
  extension = extension?.trim()?.toUpperCase();
  if (extension === "STP" || extension === "STEP" || extension === "IGES" || extension === "OBJ" || extension === "STL") return true;
  return false;
}

export const handleLogout = () => {
  localStorage.removeItem("Token");
  localStorage.removeItem("_id");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("isLoggedIn");
  navigate("/");
  return;
};



export const validateUser = (token, navigate) => {
  try {
    if (!token) {
      return false;
    } else {
      let decode = jwt_decode(token);
      if (decode.exp * 1000 < Date.now()) {
        return false;
      }
      if (decode.exp * 1000 - Date.now() < 1800) {
        return false;
      }
      if (!decode) {
        return false;
      } else if (decode) {
        if (decode.userType !== "user") {
          return false;
        }
      }
    }
    return true;
  } catch (err) {
    return false
  }
};


export function formatNumberWithCommas(number) {
  if (typeof number !== "number") {
    number = Number(number);
  }

  // Fix to 2 decimal places, then split integer and decimal parts
  const [integerPart, decimalPart] = number.toFixed(2).split(".");

  // Add commas to the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the formatted integer part and decimal part
  return `${formattedInteger}.${decimalPart}`;
}

// Example usage
console.log(formatNumberWithCommas(1234567.89)); // Output: "1,234,567.89"
console.log(formatNumberWithCommas(1000));       // Output: "1,000.00"
console.log(formatNumberWithCommas(12345.6));    // Output: "12,345.60"


export const roundToTwoDigits = (number) => {
  if (isNaN(number) || typeof number !== 'number') {
    return 0;
  }
  return Math.round(number * 100) / 100;
};


export const isShowPriceAccordingToProcess = (selectedQuote) => { //params quote 

  // console.log("Response :",selectedQuote);
  if (!selectedQuote) return false;

  const selectedProcess = selectedQuote?.selectedProcess;
  if (!selectedProcess) return false;
  if (selectedProcess <= 2) {
    if (selectedQuote?.price1 || selectedQuote?.price2 || selectedQuote?.price3) return true;
    return false;
  }
  else if (selectedProcess > 2) {

    const isAnyPriceValid = (selectedQuote?.price1 || selectedQuote?.price2 || selectedQuote?.price3) && (selectedQuote?.isEmailSent || selectedQuote?.isSent);
    return isAnyPriceValid;
  }
  return false;

}

export const isShowPriceAccordingToProcessAtIndex = (selectedQuote,index) => { 

  console.log('inded check')
  if (!selectedQuote) return false;

  const selectedProcess = selectedQuote?.selectedProcess;
  const partItem = selectedQuote?.partsData[index];
  if (!selectedProcess) return false;
  if (selectedProcess <= 2) {
    if (partItem?.price1 || partItem?.price2 || partItem?.price3) return true;
    return false;
  }
  else if (selectedProcess > 2) {
    const isAnyPriceValid = (partItem?.price1 || partItem?.price2 || partItem?.price3) && (selectedQuote?.isEmailSent || selectedQuote?.isSent);
    return isAnyPriceValid;
  }
  return false;
}


const downloadFileFromS3 = async (s3FileObject) => {
  try {
    if (!s3FileObject.bucket || !s3FileObject.key) {
      toast.error("File details are missing. Please verify the file information or contact support for assistance.");
      return;
    }

    const signedUrl = await getPresignedUrlForFile(s3FileObject.bucket, s3FileObject.key);

    console.log('singedURl', signedUrl);
    if (!signedUrl) {
      toast.error("Unable to retrieve the file. Please try again or contact support for further assistance.");
      return;
    }

    const link = document.createElement("a");
    link.href = signedUrl;
    link.download = s3FileObject.key.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.error("Error downloading file:", err);
    toast.error("An unexpected error occurred while downloading the file. Please try again or contact support.");
  }
};


export const getPriorityShippingMethod = (quote) => {
  if (quote?.price3) return 3;
  if (quote?.price2) return 2;
  return 1;
}

export default downloadFileFromS3;