import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import { useCreateQuoteContext } from "../../context/create.quote.context";

import {
  AssemblyStepFileIcon,
  IndividualPartFiles,
} from "../../components/Icons";

const OptionOneSelection = () => {
  let token = localStorage.getItem("Token");
  let userName = localStorage.getItem("firstName");
  const loggedInUser = localStorage.getItem("Token");
  let {createQuoteState}  = useCreateQuoteContext();
  let projectName = createQuoteState?.commanValues?.projectName;

  const navigate = useNavigate();

  useEffect(() => {
    let decode;
    if (token) {
      decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      }
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      {loggedInUser ? (
        <div>
          <Header activeTab="/" />
          <div>
            {/* <div className="my-2 bg-white px-5 md:px-24 py-4 rounded mx-2 ">
            <h3 className="md:text-xl font-bold ">
              Hey {userName || ""} Welcome !
            </h3>
            <p className="text-slate-500 text-[12px] md:text-[15px] ">
              The power of digital manufacturing at your fingertips
            </p>
          </div> */}
          </div>
          <div className="p-5 bg-white  mx-2 !py-10">
            <p className=" text-2xl md:text-2xl lg:text-4xl font-medium text-center ">
              {/* Select one of the following options: */}
              Select File Type
            </p>
            <div className="flex flex-col md:flex-row gap-y-5 my-12 justify-center gap-x-20 items-center">
              <Link
                to="/get-instant-quote"
                state={{ option: 1, projectName: projectName }}
                className="md:w-96 md:h-56 px-5  py-3 flex flex-col justify-center gap-y-5 items-center rounded font-bold text-sm md:text-xl text-white bg-primaryColor  hover:scale-110 transition-all hover:shadow-xl"
              >
                <IndividualPartFiles
                  additionalClasses={" h-12  w-24 md:h-20 md:w-32 "}
                />
                <span className=" my-2 sm:text-2xl">Individual Part files</span>
              </Link>
              <Link
                to="/get-instant-quote"
                state={{ option: 2, projectName: projectName }}
                className="md:w-96 md:h-56 px-5 py-3 flex flex-col items-center justify-center gap-y-5 rounded font-bold  text-sm md:text-xl text-white bg-primaryColor hover:scale-110 transition-all hover:shadow-xl"
              >
                <AssemblyStepFileIcon additionalClass=" h-12  w-24 md:!h-20 md:w-32" />
                <span className="my-2 sm:!text-2xl">Assembly Step files</span>
              </Link>
            </div>
          </div>

        </div>
      ) : (
        <p>Please Login First</p>
      )}
    </div>
  );
};

export default OptionOneSelection;
