import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { numberInputOnWheelPreventChange } from "../../../../utils/helper";
import { DeleteIcon } from "../../../../components/Icons";
import AutoResizingTextarea from "../../../../components/AutoResizeTextAreaComponent/AutoResizeTextAreaComponent";
import DynamicWidthInput from "../../../../components/AutoResizeTextAreaComponent/DynamicWithInput";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AsyncIterator } from "regenerator-runtime";
import axios from "axios";
import ThreeDViewerComponent from "../../../../components/ViewerComponent/ThreeDViewerComponent";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../../../context/create.quote.context";

const ManualProcessLinesUI = (props) => {
  let { quoteId } = useParams();
  let index = props.index;
  let val = props.values[index];
  const [itemDescription, setItemDescription] = useState("");
  const [itemNotes, setItemNotes] = useState(val.Notes);
  const [itemQuantity, setItemQuantity] = useState(val.Qty);
  const [linePartName, setLinePartName] = useState(val.linePartName);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();


  const handleChangeRouteForRepeatOrder = () => {
    // let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
    if (userConfirmation) {
      navigate(`/create-instant-quotation/${quoteId}?isRepeatOrderWithUpdate=true`)
    }
  }


  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [])

  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [props.values])


  const onDescriptionChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemDescription(event.target.value);
    val.description = event.target.value;

    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }

  const handlePartNameChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setLinePartName(event.target.value)
    val.linePartName = event.target.value;


    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }



  const onQuantityChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemQuantity(event.target.value);
    val.Qty = event.target.value;
    
    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }

  const onQuantityInc = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemQuantity(itemQuantity++);
    val.Qty = itemQuantity++;
  }

  const onQuantityChangeWithIcon = (value) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    if (value <= 0) {
      return;
    }
    setItemQuantity(value);
    val.Qty = value;
  }




  console.log("Val :", val);


  return (
    <Fragment>
      {
        (val.file || val.selectedFile) ? (
          <tr key={index}>
            <ThreeDViewerComponent
              item={val}
              selectedProcess={props?.selectedQuote?.selectedProcess}
            />
            <td >
              <input
                type="text"
                readOnly
                value={
                  ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!")?.length > 25
                    ? ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!").substring(0, 25) + "..."
                    : ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!")
                }
                className=" border-gray-300 rounded py-2 me-2  w-full focus:outline-none focus:ring-2 text-primaryColor underline"
                title={
                  (val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!"
                }
              />
            </td>
            <td >
              <Form.Control
                as="textarea"
                name="descripton"
                className="description"
                placeholder="Enter description here"
                value={itemDescription}
                onChange={onDescriptionChange}
                rows={1}
                maxLength={50}
              />
              {/* <AutoResizingTextarea
                value={itemDescription}
                onChange={onDescriptionChange}
                name="descripton"
                maxLength={50}
              /> */}
            </td>
            <td>
              <Form.Control
                name="quantity"
                type="number"
                className="quantity"
                min={1} // Sets the minimum value to 1
                max={99} // Sets the maximum value to 99
                value={val.Qty}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 1 && value <= 99) {
                    onQuantityChange(e); // Only allow valid values between 1 and 99
                  }
                }}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} // Prevent invalid characters
                onWheel={(e) => e.target.blur()} // Prevent scrolling to change the value
                style={{ width: "64px" }}
              />


              {/* <DynamicWidthInput
                name={'quantity'}
                className='quantity'
                onChange={(e) => {
                  if (!e.target.value || Number(e.target.value) < 1) {
                    return;
                  }
                  onQuantityChange(e)
                }}
                value={val.Qty}
                minWidth={80}
              /> */}
            </td>

            <td className="text-center">
              <button
                onClick={() => props.onDelete(index)}
              >
                <DeleteIcon />
              </button>
            </td>
          </tr>)
          : (
            <tr key={index}>
              <td>
                {props?.singleValue?.thumbnail ? <img
                  className="h-14"
                  src={props?.singleValue?.thumbnail}
                /> : <div className="no-img-div"></div>}
              </td>
              <td>
                <Form.Control
                  name='linePartName'
                  className="part-name"
                  type="text"
                  value={linePartName}
                  placeholder="Enter part name here"
                  onChange={handlePartNameChange}
                />
              </td>
              <td >
                {/* <AutoResizingTextarea
                  value={itemDescription}
                  onChange={onDescriptionChange}
                  name="descripton"
                  maxLength={50}
                /> */}
                <Form.Control
                as="textarea"
                name="descripton"
                className="description"
                placeholder="Enter description here"
                value={itemDescription}
                onChange={onDescriptionChange}
                rows={1}
                maxLength={50}
              />
              </td>

              <td className="flex items-center">
                <Form.Control
                  name="quantity"
                  type="number"
                  className="quantity"
                  min={1} // Sets the minimum value to 1
                  max={99} // Sets the maximum value to 99
                  value={val.Qty}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 1 && value <= 99) {
                      onQuantityChange(e); // Only allow valid values between 1 and 99
                    }
                  }}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} // Prevent invalid characters
                  onWheel={(e) => e.target.blur()} // Prevent scrolling to change the value
                  style={{ width: "64px" }}
                />

                {/* <DynamicWidthInput
                  name={'quantity'}
                  className='quantity mt-3'
                  onChange={(e) => {
                    if (!e.target.value || Number(e.target.value) < 1) {
                      return;
                    }
                    onQuantityChange(e)
                  }}
                  value={val.Qty}
                  minWidth={80}
                /> */}
              </td>

              <td className="text-center">
                <button
                  onClick={() => props.onDelete(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          )}

    </Fragment>
  )
};
export default ManualProcessLinesUI;