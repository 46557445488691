import React, { useContext, useEffect, useRef, useState } from "react";
import {
  updateQuoteAdmin,
  downloadPdf,
  sendQuotationToCustomerEmail,
  fetchQuoteByRefId,
  getallAddressAdmin,
  fetchQuoteByIdForQuoteVersion,
  newCheckout,
  updateQuoteShipMethod,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
  updateQuoteAdminComment,
  updateQuotationNotesAndTnc,
  handleMarkAsSend,
  handleCommonAddAddressForUser,
  handleCommonDeleteAddressForUser,
  handleCommonEditAddressForUser,
} from "../../utils/actions/allactions";
import {
  numberInputOnWheelPreventChange,
  currencyFormatter,
  // downloadFileFromS3,
} from "../../utils/helper";
import downloadFileFromS3 from "../../utils/helper";
import { fetchRFQForQuotation } from "../../utils/actions/supplierActions";
import {
  createRFQ,
  cancelRFQForQuotation,
} from "../../utils/actions/supplierActions";
import { useParams, useNavigate, Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import _ from "lodash";
import downloadImg from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import { maxFileUploadSize, processType, quoteVersionDescriptions } from "../../utils/constant";
import { Modal, Button, Row, Col, Form, FormControl } from "react-bootstrap";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import moment from "moment";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import { activateTargetCostForUser, getAllCncCertifications, getAllthreeDCertifications, getSalesOrdersForAllQuotation } from "../../utils/actions/adminActions";
import { getAllCertifications, handleProjectNameChange } from "../../utils/actions/commanActions";
import { Loading } from "../../components/Loading";
import { DownloadIcon, EditIcon, EyeIcon, LucidLoader } from "../../components/Icons";
import { CrossIcon } from "../../components/GlobalProjectNameComponent";
import { fetchOrganzationDetails } from "../../api/customer/quotation/commonQuotationApi";
import ReactQuill from 'react-quill';
import CertificationModal from "../../components/CertificationModal/CertificationModal";
import { downloadQuotationPdf } from "../../api/customer/quotation/commonQuotationApi";
import AdminCertificationComponent from "../../components/AdminCertificationsComponent/AdminCertificationComponent";


const AdminQuote = () => {
  let { _id } = useParams();
  useDocumentTitle("Quote Details");
  let navigate = useNavigate();
  let token = localStorage.getItem("Token");
  const [leadTime, setLeadTime] = useState([]);
  const [shippingDays, setShippingDays] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState();
  const [allVersionForRefId, setAllVersionForRefId] = useState([]);
  const [selectedShipMethod, setSelectedShipMethod] = useState("");
  let [showModal, setShowModal] = useState(false);
  const [showFollowerModal, setShowFollowerModal] = useState(false);
  const handleClose = () => {
    setVersionDescriptionValue("");
    setShowModal(false);
  };

  const [certificationsBasedOnSelectedProcess, selectedCertificationsBasedOnSelected] = useState(false);

  const [open, setOpen] = useState(false);
  const { setSelectedChatRefId, selectedChatRefId, setChatType } =
    useContext(ChatRefContext);
  const [poDocument, setPoDocument] = useState(null);
  const [poNumber, setPoNumber] = useState("");
  const [showSoModal, setShowSoModal] = useState(false);
  const [linesTotal, setLinesTotal] = useState([]);
  const [versionDescriptionValue, setVersionDescriptionValue] = useState("");
  const [rFQForQuotation, setRFQForQuotation] = useState(null);
  const [salesOrder, setSalesOrder] = useState({});
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isChanged, setIsChanged] = useState({
    quotationNotes: false,
    partInfo: false,
    leadtime: false,
    shippingCharge: false,
    shippingDays: false,
    certifications: false,
  });
  const [isQuotationDownloading, setQuotationDownloading] = useState(false);
  const [isCaptchaModalForAskTargetCost, setIsCaptchModalForAskTargetCost] = useState(false);

  const [showDQModal, setShowDQModal] = useState(false);
  const [functionalityForDQ, setFunctionalityForDQ] = useState("");
  const [quotationNotes, setQuotationNotes] = useState("");
  const [allOrdersForProject, setAllOrdersForProject] = useState([]);
  const [quotationTnc, setQuotationTnc] = useState("");

  // New state for address selected for quotation download
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCostUpdatedbyAdminWhenTargetCostIsAvailable, setIsCostUpdatedByAdminWhenTargetCostIsAvailable] = useState(false);
  const [isCertificationsModalOpen, setIsCertificationModalOpen] = useState(false);
  const [editableProjectName, setEditableProjectName] = useState('');
  const [showSendtoSupplierModal, setShowSendtoSupplierModal] = useState(false);


  useEffect(() => {
    (async () => {
      if (selectedQuote) {
        const organizationResponse = await fetchOrganzationDetails();
        let organizationData = organizationResponse?.data;
        if (!selectedQuote?.quotationTnc) {
          setQuotationTnc(organizationData?.quotationTnc);
        } else {
          setQuotationTnc(selectedQuote?.quotationTnc);
        }

        if (!selectedQuote?.quotationNotes) {
          setQuotationNotes(organizationData?.quotationNotes);
        } else {
          setQuotationNotes(selectedQuote?.quotationNotes);
        }
      }
    })()

  }, [selectedQuote]);
  useEffect(() => {
    (async () => {
      try {

        if (selectedQuote?.selectedProcess == 1) {
          //cnc cert
          const response = await getAllCncCertifications();
          const { data } = response;
          selectedCertificationsBasedOnSelected(data);


        }
        else if (selectedQuote?.selectedProcess == 2) {
          //3d cert
          const response = await getAllthreeDCertifications();
          const { data } = response;
          selectedCertificationsBasedOnSelected(data);




        }
        else {
          //manual Process
          const response = await getAllCertifications();
          const { data } = response;
          selectedCertificationsBasedOnSelected(data);




        }



      } catch (error) {
        toast.error("Failed To Fetch Certifications");
      }

    })();

  }, [selectedQuote?.selectedProcess]);
  const handleTargetCostAsk = async () => {
    if (!selectedQuote) {
      toast.error("Quotation Not Found");
      return;
    }
    try {

      const response = await activateTargetCostForUser(token, selectedQuote?._id);

      if (!response || !response?.success) {
        throw new Error();
      };
      toast.success(`Email sent to user for Target cost`);
      fetchAllQoutesForRefId();

    } catch (error) {
      toast.error("Unable To Ask Target Cost At The Moment");
    }

  }

  const handleEditClick = () => {
    let project = { ...selectedQuote };
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );

    setSelectedQuote({
      ...selectedQuote,
      previousProjectName: selectedQuote.projectName,
      editableProjectName: editableText,
    });

    setIsEditingProjectName(true);
  };

  const handleEditableChange = (e) => {
    const { value } = e.target;
    setSelectedQuote({ ...selectedQuote, editableProjectName: value });
  };

  const handleUpdateClick = async (index) => {
    if (editableProjectName.trim() === "") {
      alert("Project name cannot be empty");
      return;
    }
    setSelectedQuote((pre) => {
      return { ...pre, projectName: editableProjectName }
    });

    let data = {
      RefId: localSelectedQuote.RefId,
      projectName: localSelectedQuote.projectName,
    };

    const response = await handleProjectNameChange(token, data);
    if (response.status) {
      toast.success(response.message);
      setIsEditingProjectName(false);
    } else {
      toast.error(response.message);
    }
  };

  const handleCancelClick = () => {
    setIsEditingProjectName(false);
    setSelectedQuote({ ...selectedQuote, editableProjectName: "" });
  };

  const handleCloseCaptchaModal = () => setShowCaptchaModal(false);

  const handledAddAddress = async (addressData) => {
    let data = {
      userId: selectedQuote.userId._id,
      addressData,
    };
    const response = await handleCommonAddAddressForUser(token, data);
    if (response.status) {
      let localAllUserAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      localAllUserAddress.push(response.data);
      setAddressValues({ ...addressValues, address: localAllUserAddress });
      setShowDQModal(true);
    };
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    const response = await handleCommonEditAddressForUser(token, address);
    if (response.status) {
      allAddresses[index] = response.data;
      let addressValueData = {};
      if (addressValues.shippingAddress == response.data._id) {
        addressValueData = {
          addressline1: response.data.addressLineOne,
          addressline2: response.data.addressLineTwo,
          city: response.data.city,
          country: response.data.country,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          state: response.data.state,
          zipCode: response.data.postalCode,
        };
      }
      setAddressValues({
        ...addressValues,
        ...addressValueData,
        address: allAddresses,
      });
      setShowDQModal(true);
    }
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    if (i > -1) {
      const addressToDelete = addressValues.address[i];
      let localAllAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      const addressDeleteResponse = await handleCommonDeleteAddressForUser(
        token,
        addressToDelete._id
      );
      if (addressDeleteResponse.status) {
        localAllAddress.splice(i, 1);
        setAddressValues({ ...addressValues, address: localAllAddress });
      }
    }
  };

  const handleUpdateQuotationNotesAndTnc = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
        quotationNotes,
        quotationTnc,
      };

      const response = await updateQuotationNotesAndTnc(token, data);
      if (response.status) {
        toast.success(response.message);
        setSelectedQuote({
          ...selectedQuote,
          quotationNotes: response?.data?.quotationNotes,
          quotationTnc: response?.data?.quotationTnc,
        });
        setIsChanged({ ...isChanged, quotationNotes: false });
      }
    } catch (err) {
      console.log(
        "Error in admin quote 137 on: " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleTeamModalShow = async () => {
    setShowFollowerModal(true);
  };

  const handlePoModalClose = () => {
    setShowSoModal(false);
  };

  const handlePoDocumentDelete = () => {
    setPoDocument(null);
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(selectedQuote.RefId);
    setChatType("customer");
  };

  const onClose = () => {
    setOpen(false);
    setChatType(null);
    setSelectedChatRefId(null);
  };

  useEffect(() => {
    fetchAllQoutesForRefId();
    fetchSupplierData();
  }, []);

  useEffect(() => {
    let line1Total = 0,
      line2Total = 0,
      line3Total = 0;

    selectedQuote?.partsData?.forEach((item) => {
      line1Total += Number(item.Qty) * Number(item.price1);
      line2Total += Number(item.Qty) * Number(item.price2);
      line3Total += Number(item.Qty) * Number(item.price3);
    });

    setLinesTotal([line1Total, line2Total, line3Total]);
  }, [selectedQuote]);

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fetchSupplierData = async () => {
    try {
      const fetchRFQResponse = await fetchRFQForQuotation(_id, token);
      if (fetchRFQResponse.data.status) {
        setRFQForQuotation(fetchRFQResponse?.data.data);
      }
    } catch (err) {
      console.log(
        "err in admin quote 283 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  console.log("isLoaderActive", isLoaderActive);

  const fetchAllQoutesForRefId = async () => {
    try {
      setIsLoading(true);
      const selectedQuoteDetails = await fetchQuoteByIdForQuoteVersion(
        _id,
        token
      );
      const allVersions = await fetchQuoteByRefId(
        selectedQuoteDetails.data.data.RefId,
        token
      );
      setAllVersionForRefId(allVersions.data.data);
      setSelectedQuote(selectedQuoteDetails.data.data);

      let data = [];
      allVersions.data.data.forEach((item) => data.push(item._id));
      const response = await getSalesOrdersForAllQuotation(token, data);
      if (response.status) {
        setAllOrdersForProject(response.data);
        let filteredOrder = response.data.filter(
          (item) => item.quoteId._id == selectedQuoteDetails.data.data._id
        );
        if (filteredOrder.length) {
          setSalesOrder(filteredOrder[0]);
        }
      }

      let localLeadTime = [
        selectedQuoteDetails.data.data.leadTime1,
        selectedQuoteDetails.data.data.leadTime2,
        selectedQuoteDetails.data.data.leadTime3,
      ];
      setLeadTime(localLeadTime);
      console.log("Setting Project Name :", selectedQuoteDetails?.data?.data?.projectName);
      setEditableProjectName(selectedQuoteDetails?.data?.data?.projectName);


      let localShippingLeadTime = [
        selectedQuoteDetails.data.data.shippingDays1,
        selectedQuoteDetails.data.data.shippingDays2,
        selectedQuoteDetails.data.data.shippingDays3,
      ];
      setShippingDays(localShippingLeadTime);

      let fetchedUserAddress = await getallAddressAdmin(token);

      let localUserAddresses = [];
      fetchedUserAddress.map((address) => {
        if (
          address?.userId?._id === selectedQuoteDetails?.data?.data?.userId?._id
        ) {
          localUserAddresses.push(address);
        }
      });
      if (localUserAddresses.length > 0) {
        setAddressValues({
          ...addressValues,
          address: [...localUserAddresses],
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const validateData = () => {
    if (
      selectedQuote?.selectedProcess === 1 ||
      selectedQuote?.selectedProcess === 2
    ) {
      if (
        (selectedQuote?.price3 === undefined ||
          selectedQuote?.price3 === "" ||
          selectedQuote?.price3 === 0) &&
        (selectedQuote?.price1 === undefined ||
          selectedQuote?.price1 === "" ||
          selectedQuote?.price1 === 0) &&
        (selectedQuote?.price2 === undefined ||
          selectedQuote?.price2 === "" ||
          selectedQuote?.price3 === 0)
      ) {
        return false;
      }

      if (
        (selectedQuote.price1 > 0 &&
          (leadTime[0] === 0 || !leadTime[0] || leadTime[0] === "")) ||
        (selectedQuote.price2 > 0 &&
          (leadTime[1] === 0 || !leadTime[1] || leadTime[1] === "")) ||
        (selectedQuote.price3 > 0 &&
          (leadTime[2] === 0 || !leadTime[2] || leadTime[2] === ""))
      ) {
        return false;
      }

      if (
        (selectedQuote.price1 > 0 &&
          (linesTotal[0] === 0 || !linesTotal[0] || linesTotal[0] === "")) ||
        (selectedQuote.price2 > 0 &&
          (linesTotal[1] === 0 || !linesTotal[1] || linesTotal[1] === "")) ||
        (selectedQuote.price3 > 0 &&
          (linesTotal[2] === 0 || !linesTotal[2] || linesTotal[2] === ""))
      ) {
        return false;
      }
    } else {
      if (
        (selectedQuote?.price3 === undefined ||
          selectedQuote?.price3 === "" ||
          selectedQuote?.price3 === 0) &&
        (selectedQuote?.price1 === undefined ||
          selectedQuote?.price1 === "" ||
          selectedQuote?.price1 === 0) &&
        (selectedQuote?.price2 === undefined ||
          selectedQuote?.price2 === "" ||
          selectedQuote?.price2 === 0)
      ) {
        return false;
      }

      if (
        (selectedQuote.price1 > 0 &&
          (leadTime[0] === 0 || !leadTime[0] || leadTime[0] === "")) ||
        (selectedQuote.price2 > 0 &&
          (leadTime[1] === 0 || !leadTime[1] || leadTime[1] === "")) ||
        (selectedQuote.price3 > 0 &&
          (leadTime[2] === 0 || !leadTime[2] || leadTime[2] === ""))
      ) {
        return false;
      }

      if (
        (selectedQuote.price1 > 0 &&
          (linesTotal[0] === 0 || !linesTotal[0] || linesTotal[0] === "")) ||
        (selectedQuote.price2 > 0 &&
          (linesTotal[1] === 0 || !linesTotal[1] || linesTotal[1] === "")) ||
        (selectedQuote.price3 > 0 &&
          (linesTotal[2] === 0 || !linesTotal[2] || linesTotal[2] === ""))
      ) {
        return false;
      }
    }

    if (selectedQuote.price1) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price1 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    if (selectedQuote.price2) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price2 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    if (selectedQuote.price3) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price3 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }


    return true;
  };

  const handleSendEmail = async () => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.leadtime ||
        isChanged.shippingCharge ||
        isChanged.shippingDays
      ) {
        toast.error(
          "There are some unsaved changes. Please update order before sending the quotation to customer email."
        );
        return;
      }
      let selectedShipMethodsToSendEmail = [];
      if (selectedQuote.price1) {
        selectedShipMethodsToSendEmail.push(1);
      }
      if (selectedQuote.price2) {
        selectedShipMethodsToSendEmail.push(2);
      }

      if (selectedQuote.price3) {
        selectedShipMethodsToSendEmail.push(3);
      }

      if (selectedShipMethodsToSendEmail.length == 0) {
        toast.error("No Price is updated,Please update atleast one price.");
        setIsLoaderActive(false);
        return;
      }

      let adminResponse = false;
      if (selectedQuote.isEmailSent) {
        adminResponse = confirm(
          "Quotation email is already sent to customer for this quote. Are you sure you want to send the email again?"
        );
      } else {
        adminResponse = confirm("Are you sure you want to send the quotation details to user email?");
      }
      if (!adminResponse) return;
      setIsLoaderActive(true);
      setShowDQModal(false);
      const shippingAddressId = addressValues.shippingAddress;

      let data = {
        fname: selectedQuote?.userId.firstName,
        email: selectedQuote?.userId.email,
        quoteRefId: selectedQuote?.RefId,
        quoteId: selectedQuote._id,
        price1: selectedQuote?.price1 || 0,
        price3: selectedQuote.price3 || 0,
        price2: selectedQuote?.price2 || 0,
        hidePrice: selectedQuote?.hidePrice,
        partsLength: selectedQuote?.partsData.length,
        process: selectedQuote?.selectedProcess,
        partData: selectedQuote?.partsData,
        shippingAddressId,
      };
      let response = await sendQuotationToCustomerEmail(token, data);
      console.log("response", response);
      if (response.status == false) {
        setIsLoaderActive(false);
        toast.error("Something went wrong while sending email to customer.");
        return;
      } else {
        toast.success("Quotation sent to email successfully.");
        setIsLoaderActive(false);
        let localAllVersionForRefId = [...allVersionForRefId];
        localAllVersionForRefId.forEach((item) => {
          if (item._id == selectedQuote._id) {
            item.isEmailSent = true;
          }
        });
        setAllVersionForRefId(localAllVersionForRefId);
        setSelectedQuote({ ...selectedQuote, isEmailSent: true });
      }
    } catch (err) {
      setIsLoaderActive(false);
      toast.error(err.message || "Something went wrong");
    }
  };

  const handleQuoteSend = async () => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.shippingCharge ||
        isChanged.leadtime ||
        isChanged.shippingDays
      ) {
        toast.error(
          "Please update the quotation before downloading the quote."
        );
        return;
      }

      let shipMethodWithPrice = [];
      if (selectedQuote.price1) {
        shipMethodWithPrice.push(1);
      }
      if (selectedQuote.price2) {
        shipMethodWithPrice.push(2);
      }

      if (selectedQuote.price3) {
        shipMethodWithPrice.push(3);
      }

      if (shipMethodWithPrice.length == 0) {
        toast.error("No Price is updated,Please update atleast one price.");
        return;
      }

      let data = {
        quoteId: selectedQuote._id,
      };

      let response = await handleMarkAsSend(token, data);
      let localAllVersionForRefId = [...allVersionForRefId];
      localAllVersionForRefId.forEach((item) => {
        if (item._id == response?.data?._id) {
          item.isSent = response?.data?.isSent;
        }
      });
      setAllVersionForRefId(localAllVersionForRefId);
      setSelectedQuote({ ...selectedQuote, isSent: response?.data?.isSent });
      toast.success("Quotation is now mark as sent");
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleUpdateQuote = async (updateOption) => {
    try {
      if (!versionDescriptionValue) {
        toast.error("Please select the value for  version description");
        return;
      }

      // setShowModal(false);
      let isDataValid = validateData();
      if (!isDataValid) {
        toast.error("Please enter valid value for LeadTime and Item Price");
        return;
      }

      let adminUserResponse = confirm("Are you sure you want to continue?");
      if (adminUserResponse) {
        let data = {
          partsData: selectedQuote?.partsData,
          _id: selectedQuote?._id,
          process: selectedQuote?.selectedProcess,
          price3: selectedQuote?.price3 || 0,
          leadTime: leadTime,
          price1: selectedQuote?.price1 || 0,
          price2: selectedQuote?.price2 || 0,
          updateOption: updateOption,
          shippingCharge1: selectedQuote.shippingCharge1,
          shippingCharge2: selectedQuote.shippingCharge2,
          shippingCharge3: selectedQuote.shippingCharge3,
          shippingDays1: selectedQuote.shippingDays1,
          shippingDays2: selectedQuote.shippingDays2,
          shippingDays3: selectedQuote.shippingDays3,
          adminComment: selectedQuote?.adminComment,
          versionDescription: versionDescriptionValue,
          isTargetCostUpdatedByAdmin: isCostUpdatedbyAdminWhenTargetCostIsAvailable,
          certifications: selectedQuote?.certifications,
          orderCertificationsCost: selectedQuote?.orderCertificationsCost,
        };

        let response = await updateQuoteAdmin(data, token);
        let updatedQuote = response?.data;

        if (response?.status == false) {
          throw {
            message: "Something went wrong while updating price!",
          };
        } else {
          toast.success("Price update successfully");
          setShowModal(false);
          setSelectedQuote(updatedQuote);
          if (updateOption == "new") {
            let localAllQuoteVersion = [...allVersionForRefId, updatedQuote];
            localAllQuoteVersion.sort((a, b) => {
              return b.version - a.version;
            });
            setAllVersionForRefId(localAllQuoteVersion);
          } else {
            let localAllQuoteVersion = [...allVersionForRefId];
            localAllQuoteVersion.forEach((item, index) => {
              if (item?._id === updatedQuote?._id) {
                localAllQuoteVersion[index] = updatedQuote;
              }
            });

            localAllQuoteVersion.sort((a, b) => {
              return b.version - a.version;
            });

            console.log("allQuotaitonVerson", localAllQuoteVersion);

            setAllVersionForRefId([...localAllQuoteVersion]);
          }
          navigate(`/admin/admin-quote/${updatedQuote?._id}`);
          fetchSupplierDataForQuoteId(updatedQuote?._id);
          setIsChanged({
            ...isChanged,
            partInfo: false,
            leadtime: false,
            shippingCharge: false,
            shippingDays: false,
            certifications: false,
          });
        }
      }
    } catch (err) {
      console.log("err", err);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong during fetch getSaveQuoteData";
      return false;
    }
  };

  const handleLeadTimeChange = (event, index) => {
    setIsChanged({ ...isChanged, leadtime: true });
    let localLeadTimeCopy = [...leadTime];
    let leadTimeAtIndex = { ...leadTime[index] };
    leadTimeAtIndex = event.target.value;
    localLeadTimeCopy[index] = leadTimeAtIndex;
    setLeadTime(localLeadTimeCopy);
    setSelectedQuote({
      ...selectedQuote,
      leadTime1: localLeadTimeCopy[0],
      leadTime2: localLeadTimeCopy[1],
      leadTime3: localLeadTimeCopy[2],
    });
  };

  const handleShippingLeadTimeChange = (event, index) => {
    setIsChanged({ ...isChanged, shippingDays: true });
    let localShippingLeadTimeCopy = [...shippingDays];
    let shippingLeadTimeAtIndex = { ...shippingDays[index] };
    shippingLeadTimeAtIndex = event.target.value;
    localShippingLeadTimeCopy[index] = shippingLeadTimeAtIndex;
    setShippingDays(localShippingLeadTimeCopy);
    setSelectedQuote({
      ...selectedQuote,
      shippingDays1: localShippingLeadTimeCopy[0],
      shippingDays2: localShippingLeadTimeCopy[1],
      shippingDays3: localShippingLeadTimeCopy[2],
    });
  };


  const handleQMShowClick = (functionality) => {
    setFunctionalityForDQ(functionality);
    if (functionality == "download-quote") {
      if (selectedShipMethod === "") {
        toast.error("Select a cost for which you want to download the quote.");
        return;
      }
    }
    setShowDQModal(true);
  };

  let handleDownload = async () => {
    try {
      if (!addressValues.shippingAddress) {
        toast.error(
          "Please select a valid shipping address to download quotation."
        );
        return;
      }
      let data = {
        quoteId: selectedQuote?._id,
        selectedShipMethod,
        shippingAddress: addressValues.shippingAddress,
        RefId: selectedQuote?.RefId,
        // version: selectedQuote?.Version,
        price: selectedShipMethod == 1 ? selectedQuote?.price1 + selectedQuote?.shippingCharge1 + selectedQuote?.orderCertificationsCost
          : selectedShipMethod == 2 ? selectedQuote?.price2 + selectedQuote?.shippingCharge2 + selectedQuote?.orderCertificationsCost : selectedQuote?.price3 + selectedQuote?.shippingCharge3 + selectedQuote?.orderCertificationsCost
      };

      let lastDataOnPdf = selectedShipMethod == 1 ? "Rapid" : (
        selectedShipMethod == 2 ? "Standard" : "Economy"
      );
      setShowDQModal(false);
      setQuotationDownloading(true);

      let response = await downloadQuotationPdf(data);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `8xParts_Quote_${selectedQuote?.RefId}_V${selectedQuote.version}_${lastDataOnPdf}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);


    } catch (err) {
      console.log("Error downloading Quotation", err)
    } finally {
      setQuotationDownloading(false);
    }
  };

  const handleBack = () => {
    let adminResponse = confirm(
      "Information will be lost, are you sure you want to go back ?"
    );
    if (adminResponse) {
      navigate(-1);
    }
  };

  useEffect(() => {
    handleChangeQuoteVersion(_id);
  }, [_id]);

  const handleChangeQuoteVersion = (selectedQuoteId) => {
    allVersionForRefId.forEach((quote) => {
      if (quote._id == selectedQuoteId) {
        setSelectedQuote(quote);
        let localLeadTime = [quote.leadTime1, quote.leadTime2, quote.leadTime3];
        setLeadTime(localLeadTime);
        fetchSupplierDataForQuoteId(quote._id);
        let line1Total = 0,
          line2Total = 0,
          line3Total = 0;
        quote?.partsData?.forEach((item) => {
          line1Total += Number(item.Qty) * Number(item.price1);
          line2Total += Number(item.Qty) * Number(item.price2);
          line3Total += Number(item.Qty) * Number(item.price3);
        });
        setLinesTotal([line1Total, line2Total, line3Total]);
        setQuotationNotes(quote?.quotationNotes || quotationNotes);
        setQuotationTnc(quote?.quotationTnc || quotationTnc);

        let filteredOrders = allOrdersForProject.filter(
          (item) => item.quoteId._id == quote._id
        );
        if (filteredOrders.length) {
          setSalesOrder(filteredOrders[0]);
        } else {
          setSalesOrder(null);
        }
      }
    });
  };

  const fetchSupplierDataForQuoteId = async (quoteId) => {
    try {
      const fetchRFQResponse = await fetchRFQForQuotation(quoteId, token);
      if (fetchRFQResponse.data.status) {
        setRFQForQuotation(fetchRFQResponse.data.data);
      } else {
        setRFQForQuotation(null);
      }
    } catch (err) {
      console.log(
        "err in admin quote 682 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleQuotePriceSelection = (e) => {
    setSelectedShipMethod(Number(e.target.value));
    setSelectedQuote({ ...selectedQuote, selectedShipMethod: e.target.value });
  };

  const handleEditQuote = () => {
    alert("")
    navigate(`/create-instant-quotation/${selectedQuote?._id}`);
  };

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false,
        });

        let localAllVersionForRefId = [...allVersionForRefId];
        localAllVersionForRefId.forEach((item) => {
          if (item._id == selectedQuote._id) {
            item.isCancelled = false;
          }
        });
        setAllVersionForRefId(localAllVersionForRefId);

        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };

  const handleCancelQuoteClick = async () => {
    try {
      if (selectedQuote.isCancelled) {
        toast.error("Quotation is already cancelled");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("Sales order for this quotation is already created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to cancel this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          const adminResponse = confirm(
            "Do you want to cancel the RFQ associated with the quotation?"
          );
          if (adminResponse) {
            let quotationIdsForRFQ = [selectedQuote._id];
            await cancelRFQForQuotation(token, {
              quoteIds: quotationIdsForRFQ,
            });
          }
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true,
          });

          let localAllVersionForRefId = [...allVersionForRefId];
          localAllVersionForRefId.forEach((item) => {
            if (item._id == selectedQuote._id) {
              item.isCancelled = true;
            }
          });
          setAllVersionForRefId(localAllVersionForRefId);

          toast.success(response.message);
        } else {
          toast.error("Something went wrong.Please try again later!");
        }
      } else {
        return;
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong.Please try again later");
    }
  };

  const handleProceedToPO = async () => {
    if (!poNumber || !poDocument) {
      toast.error("Po Number and Po document are required to create an order.");
      return;
    }

    if (selectedShipMethod === "") {
      toast.error("Select the cost for which you want to place an order.");
      return;
    }

    if (
      selectedQuote?.selectedProcess <= 2 &&
      ((selectedShipMethod == 1 && selectedQuote?.price1 == 0) ||
        (selectedShipMethod == 2 && selectedQuote?.price2 == 0) ||
        (selectedShipMethod == 3 && selectedQuote?.price3 == 0))
    ) {
      toast.error("Invalid selection of price.");
      return;
    }

    if (
      selectedQuote?.selectedProcess >= 3 &&
      ((selectedShipMethod == 1 && selectedQuote?.price1 == 0) ||
        (selectedShipMethod == 2 && selectedQuote?.price2 == 0) ||
        (selectedShipMethod == 3 && selectedQuote?.price3 == 0))
    ) {
      toast.error("Invalid selection of price.");
      return;
    }

    if (
      addressValues?.address?.length === 0 ||
      !addressValues?.address?.length
    ) {
      toast.error(
        "User does not have any address added in the system. Please add atleast one address in order to place an order for the user"
      );
      return;
    }
    let userResponse = confirm(
      "Are you sure you want to proceed with the selected version?"
    );
    if (userResponse) {
      try {
        let data = {
          quoteId: selectedQuote?._id,
          selectedShipMethod: selectedShipMethod,
        };
        await updateQuoteShipMethod(token, data);

        let formData = new FormData();
        formData.append("firstName", selectedQuote?.userId.firstName);
        formData.append("lastName", selectedQuote?.userId.lastName);
        formData.append("email", selectedQuote?.userId.email);
        formData.append("mobileNumber", selectedQuote?.userId.phoneNumber);
        formData.append(
          "companyName",
          selectedQuote?.userId.companySiteId.companyName
        );
        formData.append(
          "vatNumber",
          selectedQuote?.userId.companySiteId.vatNumber
        );
        formData.append("addressline1", "");
        formData.append("addressline2", "");
        formData.append("zipCode", "");
        formData.append("city", "");
        formData.append("state", "");
        formData.append("country", "");
        formData.append("shippingAddress", addressValues?.address[0]._id);
        formData.append("tax", selectedQuote?.userId.companySiteId.taxes);
        formData.append(
          "subTotal",
          selectedShipMethod === 1
            ? selectedQuote?.price1
            : selectedShipMethod === 2
              ? selectedQuote?.price2
              : selectedQuote?.price3
        );
        formData.append("quoteSelectedShipMethod", selectedShipMethod);
        formData.append("quoteId", selectedQuote?._id);
        formData.append("file", poDocument);
        formData.append("purchaseNumber", poNumber);
        formData.append("orderNumberLater", true);

        newCheckout(formData, token)
          .then((res) => {
            if (res.response?.data?.data?.message) {
              toast.error(res.response.data.data.message);
            }
            if (res.data.status == false) {
              toast.error("Something went wrong while placing the order");
            }
            navigate("/admin/orders/" + res.data.data._id, {
              state: { order: res.data.data },
            });
          })
          .catch((err) => {
            toast.error(err);
          });
      } catch (err) {
        console.log("err", err);
        return err;
      }
    }
  };


  const handleShippingChargeChange = (e, index) => {
    setIsChanged({ ...isChanged, shippingCharge: true });
    let shippingChargeValue = Number(e.target.value);

    let totalPrice1 = 0;
    let totalPrice2 = 0;
    let totalPrice3 = 0;
    selectedQuote?.partsData.map((item, i) => {
      totalPrice1 += Number(item.price1) * Number(item.Qty);
      totalPrice2 += Number(item.price2) * Number(item.Qty);
      totalPrice3 += Number(item.price3) * Number(item.Qty);
      return;
    });

    if (index == 1) {
      setSelectedQuote({
        ...selectedQuote,
        shippingCharge1: shippingChargeValue,
        price1: totalPrice1,
      });
    } else if (index == 2) {
      setSelectedQuote({
        ...selectedQuote,
        shippingCharge2: shippingChargeValue,
        price2: totalPrice2,
      });
    } else {
      setSelectedQuote({
        ...selectedQuote,
        shippingCharge3: shippingChargeValue,
        price3: totalPrice3,
      });
    }
  };

  const handleUpdateAdminComment = async () => {
    try {
      let data = {
        RefId: selectedQuote.RefId,
        adminComment: selectedQuote.adminComment,
      };
      const response = await updateQuoteAdminComment(data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(
        "err in admin quote 921 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleProceedToSOModal = () => {
    if (
      isChanged.partInfo ||
      isChanged.shippingCharge ||
      isChanged.leadtime ||
      isChanged.shippingDays
    ) {
      toast.error("Please update the quotation before downloading the quote.");
      return;
    }

    if (selectedShipMethod === "") {
      toast.error("Select the cost for which you want to place an order.");
      return;
    }
    setShowSoModal(true);
  };

  const handleCreateRFQ = async (type) => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.additionalCost ||
        isChanged.shippingCharge ||
        isChanged.leadtime ||
        isChanged.shippingDays
      ) {
        toast.error(
          "Please update the quotation before downloading the quote."
        );
        return;
      }

      let partsData = [];
      const parts = selectedQuote.projectName.split("_");
      let projectName = parts.slice(1).join("_");
      projectName = prompt(
        "Enter the project name for selected supplier.",
        projectName
      );
      if (projectName?.length > 0) {
        selectedQuote.partsData.forEach((item) => {
          let partDataAtIndex = {
            cncMaterial: item.cncMaterial?._id,
            cncSurfaceFinish: item.cncSurfaceFinish?._id,
            cncHeatTreatment: item.cncHeatTreatment?._id,
            cncTolerance: item.cncTolerance?._id,
            cncParkMarking: item.cncParkMarking?._id,
            cncReviewMyDesign: item?.cncReviewMyDesign?._id,
            cncInternalCorner: item?.cncInternalCorner?._id,
            threeDTechnology: item.threeDTechnology?._id,
            threeDMachine: item.threeDMachine?._id,
            threeDMaterial: item.threeDMaterial?._id,
            threeDPostProcessing: item.threeDPostProcessing?._id,
            materialType: item.MaterialType?._id,
            subMaterial: item.SubMaterial?._id,
            surfaceFinish: item.surfaceFinish?._id,
            surfaceTreatment: item.surfaceTreatment?._id,
            tolerance: item.tolerance?._id,
            partMarking: item.partMarking?._id,
            layer: item.layer?._id,
            qty: item.Qty,
            partNotes: item.Notes,
            description: item.description,
            stagingPartsDataId: item?.stagingPartsDataId?._id,
            noOfThread: item.noOfThread,
            selectedFile: item.selectedFile,
            twoDFile: item.twoDFile,
            gltfPlainFileUrl: item.gltfPlainFileUrl,
            thumbnail: item?.thumbnail,
          };
          partsData.push(partDataAtIndex);
        });
        var projectNames;
        if (type == "offer") {
          projectNames = `O_${selectedQuote.RefId}-V-${selectedQuote.version}-${projectName}`;
        } else {
          projectNames = `${selectedQuote.RefId}-V-${selectedQuote.version}-${projectName}`;
        }
        let data = {
          type: type,
          quotationId: selectedQuote._id,
          quotationRefId: `${selectedQuote.RefId}-V-${selectedQuote.version}`,
          selectedProcess: selectedQuote.selectedProcess,
          isQrTrackingRequested: selectedQuote.isQrTrackingRequested,
          isDimensionalReportRequested:
            selectedQuote.isDimensionalReportRequested,
          isConformanceCertificateRequested:
            selectedQuote.isConformanceCertificateRequested,
          partsData,
          rfqStatus: "active",
          projectName: projectNames,
        };

        let rFQResponse = await createRFQ(data, token);
        if (rFQResponse.data.status) {
          toast.success(rFQResponse.data.message);
          if (type == "offer") {
            navigate(`/admin/offers-details/${rFQResponse.data.data._id}`);
          } else {
            navigate(`/admin/rfq-details/${rFQResponse.data.data._id}`);
          }

        } else {
          toast.error(rFQResponse.data.message);
          return;
        }
      } else {
        toast.error("Enter valid Project Name");
        return;
      }
    } catch (err) { }
  };

  const handleGoToRFQClick = () => {
    navigate(`/admin/rfq-details/${rFQForQuotation._id}`);
  };

  const handleUpdateButtonClick = () => {
    if (selectedQuote.isCancelled) {
      toast.error("Quote is already cancelled.");
      return;
    }



    let isShippingCostValid = true;

    if (selectedQuote.price1 && !(selectedQuote.shippingCharge1 > 0)) {
      isShippingCostValid = false;
    }
    if (selectedQuote.price2 && !(selectedQuote.shippingCharge2 > 0)) {
      isShippingCostValid = false;
    }
    if (
      (selectedQuote.price3 && !(selectedQuote.shippingCharge3 > 0))
    ) {
      isShippingCostValid = false;
    }

    if (!isShippingCostValid) {
      let adminResponse = confirm(
        "Shipping charge for one of the cost is zero. Are you sure you want to proceed?"
      );
      if (!adminResponse) {
        return;
      }
    }

    setShowModal(true);
  };

  const handleVersionDescriptionSelect = (e) => {
    setVersionDescriptionValue(e.target.value);
  };

  const handleItemInformationChange = (index, field, value) => {
    try {
      let localPartsData = [...selectedQuote?.partsData];
      setIsChanged({ ...isChanged, partInfo: true });
      localPartsData[index][field] = value;

      let totalPrice1 = 0;
      let totalPrice2 = 0;
      let totalPrice3 = 0;

      if (
        field == "price1" ||
        field == "price2" ||
        field == "price3" ||
        field == "Qty"
      ) {
        if ((field == 'price1' || field == 'price2' || field == 'price3') && selectedQuote?.userTargetCost) {
          setIsCostUpdatedByAdminWhenTargetCostIsAvailable(true);
        };
        localPartsData.forEach((item, i) => {
          totalPrice1 += Number(item.price1) * Number(item.Qty);
          totalPrice2 += Number(item.price2) * Number(item.Qty);
          totalPrice3 += Number(item.price3) * Number(item.Qty);
        });
      }

      setSelectedQuote({
        ...selectedQuote,
        partsData: localPartsData,
        price1: totalPrice1,
        price2: totalPrice2,
        price3: totalPrice3,
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const renderProjectName = () => {
    try {
      const firstUnderscoreIndex = selectedQuote?.projectName?.indexOf("_");
      const serialNumber = selectedQuote?.projectName.substring(
        0,
        firstUnderscoreIndex
      );
      const editableText = selectedQuote?.projectName.substring(
        firstUnderscoreIndex + 1
      );

      if (isEditingProjectName) {
        return (
          <>
            <div className="flex items-center justify-center  text-sm ">
              <span className="w-full">{selectedQuote?.RefId}_</span>
              <Form.Control
                type="text"
                value={editableProjectName}
                onChange={(e) => setEditableProjectName(e.target.value)}
                style={{
                  margin: " 0px 4px",
                  height: "25px",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
              />
              <span onClick={() => handleUpdateClick()}>
                <EditIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
              </span>

              <span onClick={() => handleCancelClick()}>
                <CrossIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
              </span>
            </div>
          </>
        );
      }

      return (
        <div className="flex items-center">
          <Link to={``}>
            <span>
              {selectedQuote?.projectName?.length >= 25
                ? selectedQuote?.projectName?.slice(0, 10) + "..."
                : selectedQuote?.projectName}
            </span>
          </Link>

          <span onClick={() => handleEditClick()}>
            <EditIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
          </span>
        </div>
      );
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleSelectCertification = (cert) => {
    const { _id, certificationType, price } = cert;

    //check is certPresent Or Not 
    const orderCertificationsCost = selectedQuote?.orderCertificationsCost;
    let costToAdjust = (() => {
      if (certificationType == 'per order') {
        return price;
      }
      else if (certificationType == 'per line item') {
        return selectedQuote?.partsData?.length * price;
      }
      return 0;
    })();
    const isCertificationAlReadyExists = selectedQuote?.certifications?.find((item) => item?._id == _id);
    let updatedCertifications;
    let updatedOrderCertificationsCost;

    if (isCertificationAlReadyExists) {
      updatedCertifications = selectedQuote?.certifications.filter((item) => item?._id != _id);
      updatedOrderCertificationsCost = orderCertificationsCost - costToAdjust;
    }
    else {
      updatedCertifications = [...selectedQuote?.certifications, cert];
      updatedOrderCertificationsCost = orderCertificationsCost + costToAdjust;
    }

    setSelectedQuote((pre) => {
      return {
        ...pre,
        certifications: updatedCertifications,
        orderCertificationsCost: updatedOrderCertificationsCost,
      }
    });
    setIsChanged((pre) => {
      return { ...pre, certifications: true };
    });

  };


  return (
    <div className="admin-container">
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        ""
      )}

      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendEmail}
        buttonText="Send Email"
      />
      <CaptchaModal
        showModal={isCaptchaModalForAskTargetCost}
        handleClose={() => setIsCaptchModalForAskTargetCost(false)}
        handleSendEmail={handleTargetCostAsk}
        buttonText="Ask Target Cost"
      />
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          recentAddress={addressValues?.address}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          id={editId}
          values={addressValues}
          handledAddAddress={handledAddAddress}
          handleAddressOnEdit={handleAddressOnEdit}
        />
      )}

      <TeamCollaboration
        teamMemberModalShow={showFollowerModal}
        selectedQuoteForTeam={selectedQuote}
        setTeamMemberModalShow={setShowFollowerModal}
        fetchDataForUser={fetchAllQoutesForRefId}
        updateQuoteData={setSelectedQuote}
      />

      <AdminCertificationComponent
        show={isCertificationsModalOpen}
        handleSelect={handleSelectCertification}
        onClose={() => setIsCertificationModalOpen(false)}
        certifications={certificationsBasedOnSelectedProcess}
        selectedQuote={selectedQuote}


      />


      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
            className="font-bold"
          >
            Do you want to create a new version or update the exist quote?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "left" }}>
            <h6 className="my-1 font-medium">Select the version description: </h6>
            <Form.Select
              defaultValue={versionDescriptionValue || "default"}
              onChange={handleVersionDescriptionSelect}
            >
              <option disabled value="default">
                Select an option
              </option>
              {quoteVersionDescriptions?.map((item, i) => {
                return (
                  <option value={item.id} key={i}>
                    {item.value + `  ( ${item.id} )`}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div style={{ textAlign: "center" }}>
            <h6 style={{ textAlign: "left", margin: "30px 0 -10px 0" }}>
              Select Update Option:{" "}
            </h6>

            <Button
              className="req-quote-btn"
              onClick={() => handleUpdateQuote("new")}
            >
              New Version
            </Button>

            {!selectedQuote?.myorders ? (
              <>
                <Button
                  style={{ marginLeft: "20px" }}
                  className="req-quote-btn"
                  onClick={() => handleUpdateQuote("update")}
                >
                  Update Existing
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          {selectedQuote?.myorders ? (
            <>
              <p style={{ color: "red", marginTop: "10px" }}>
                Cannot update the existing quotation since an order has already
                been created for that quotation.
              </p>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSoModal}
        onHide={handlePoModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Enter PO Number and Upload PO Document for Sales Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className="mb-6">
                <label>Purchase Order Number</label>
                <Form.Control
                  type="text"
                  placeholder="Enter PO Number"
                  value={poNumber ? poNumber : ""}
                  onChange={(e) => {
                    setPoNumber(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-6">
                <label> PO Document</label>
                {poDocument ? (
                  <div className="downloadables">
                    {poDocument?.name ? (
                      <>
                        <a
                          className="downloadables"
                          href={URL.createObjectURL(poDocument)}
                          download={poDocument?.name}
                        >
                          <span>{poDocument.name} </span>
                          <img src={downloadImg} style={{ height: "15px" }} />
                        </a>{" "}
                        &nbsp; &nbsp;
                        <img
                          src={deleteImageEnabled}
                          style={{ cursor: "pointer", height: "18px" }}
                          alt="delete"
                          height="18px"
                          onClick={handlePoDocumentDelete}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="ul-files">
                      <div className="file-button">
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          onChange={(e) => {
                            let filesForCheck = Array.from(e.target.files);
                            let isAllFilesValid = true;
                            filesForCheck.forEach((file) => {
                              if (file.size > maxFileUploadSize) {
                                isAllFilesValid = false;
                              }
                            });

                            if (!isAllFilesValid) {
                              toast.error(
                                "File size should be less than 10 MB"
                              );
                              return;
                            }
                            setPoDocument(e.target.files[0]);
                          }}
                        />
                        <label htmlFor="file-input" className=" white-btn">
                          {" "}
                          <span>Browse</span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleProceedToPO}>
            Proceed To Sales Order
          </Button>
          <Button className="req-quote-btn" onClick={handlePoModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order2"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDQModal}
        onHide={() => setShowDQModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-sm"
            style={{ fontSize: "15px" }}
          >
            {functionalityForDQ == "download-quote"
              ? "Download quotation"
              : "Send To Email"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-bar mt-4">
            <h6 className="font-bold  my-2">
              Select shipping address for quotation
            </h6>
            <div className="billing-form">
              <div className="address">
                {addressValues?.address?.map((el, id) => (
                  <div className="singleAddress" key={id}>
                    <div style={{ display: "flex" }}>
                      <input
                        className="btn-radio"
                        type="radio"
                        value={el._id}
                        checked={addressValues.shippingAddress == el._id}
                        name="rd"
                        style={{ marginRight: "5px" }}
                        onChange={(e) => {
                          setError({
                            ...error,
                            shippingAddress: "",
                          });
                          setAddressValues({
                            ...addressValues,
                            shippingAddress: el._id,
                            addressline1: el.addressLineOne,
                            addressline2: el.addressLineTwo,
                            city: el.city,
                            country: el.country,
                            state: el.state,
                            zipCode: el.postalCode,
                            firstName: el.firstName,
                            lastName: el.lastName,
                          });
                        }}
                      />
                      <div className="address">
                        <div className="name">
                          {el?.firstName} {el?.lastName}
                        </div>
                        <div className="add">
                          {el?.addressLineOne} {el?.addressLineTwo} {el?.city}{" "}
                          {el?.state} {el.postalCode} {el.country}
                        </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="edit" onClick={() => editHandler(id)}>
                        Edit
                      </div>
                      |
                      <div className="delete" onClick={() => deleteData(id)}>
                        Delete
                      </div>
                    </div>
                  </div>
                ))}

                <div className="allAddress">
                  <div className="link"></div>
                  {addressValues?.address?.length >= 5 ? (
                    <div
                      className="addNew"
                      style={{ cursor: "auto", opacity: "0.5" }}
                    >
                      + Add New Address
                    </div>
                  ) : (
                    <div className="addNew" onClick={addNewHandler}>
                      + Add New Address
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {functionalityForDQ == "download-quote" ? (
            <Button onClick={handleDownload} className="req-quote-btn">
              Download Quotation
            </Button>
          ) : functionalityForDQ == "send-to-email" ? (
            <Button
              onClick={() => {
                setShowDQModal(false);
                setShowCaptchaModal(true);
              }}
              className="req-quote-btn"
            >
              Send To Email
            </Button>
          ) : (
            ""
          )}

          <Button
            onClick={() => setShowDQModal(false)}
            className="req-quote-btn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <Chat RefId={selectedQuote?.RefId} open={open} onDrawerClose={onClose} />
      <Sidebar />
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold  mt-3">Quote Details</h1>
        </div>

        <div className="right-container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: "-20px",
            }}
          >
            <div className="qoute-version-block">
              <label className="qoute-version-label">Project Name :</label>
              <p
                style={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                  width: "400px",
                }}
              >
                {renderProjectName()}
              </p>
            </div>

            <div className="qoute-version-block">
              <label className="qoute-version-label">Selected Process :</label>
              <p
                style={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                  width: "300px",
                }}
              >
                {selectedQuote
                  ? processType.map((val, i) => {
                    if (val.id == selectedQuote?.selectedProcess) {
                      return val.name;
                    }
                  })
                  : null}
              </p>
            </div>


          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              margin: "10px 0px",
              border: "1px solid gray",
            }}
          >
            <table
              className="qoute-version-table-admin"
              style={{ border: "1px solid black", width: "100%" }}
            >
              <thead>
                <tr className="heading-row">
                  <th>Select Version</th>
                  <th>Version Description</th>
                  <th>Date</th>
                  <th>Cost 1</th>
                  <th>LeadTime 1</th>
                  <th>Cost 2</th>
                  <th>LeadTime 2</th>
                  <th>Cost 3</th>
                  <th>LeadTime 3</th>
                  <th>User Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {allVersionForRefId?.map((quoteItem, index) => {
                  const versionDescriptionText = quoteItem.versionDescription
                    ? `${quoteVersionDescriptions.find(
                      (item) => item.id === quoteItem.versionDescription
                    )?.value || "Not found"
                    }  ( ${quoteItem.versionDescription} )`
                    : "Not found";

                  const userName = (
                    <>
                      {(quoteItem?.userId?.firstName || "") +
                        " " +
                        (quoteItem?.userId?.lastName || "")}
                      <br />
                      {quoteItem?.userId
                        ? `(${quoteItem.userId.email?.split("@")[0]})`
                        : ""}
                    </>
                  );

                  let quotationStatus = "";
                  if (quoteItem.myorders && !quoteItem.isCancelled) {
                    quotationStatus = "Completed";
                  } else if (
                    (moment(Date.now()).diff(quoteItem.createdAt) /
                      (1000 * 3600 * 24) >
                      45 &&
                      !quoteItem.myorders) ||
                    quoteItem.isCancelled
                  ) {
                    quotationStatus = "Cancelled";
                  } else if (
                    !quoteItem.myorders &&
                    moment(Date.now()).diff(quoteItem.createdAt) /
                    (1000 * 3600 * 24) <=
                    45 &&
                    !quoteItem.isCancelled
                  ) {
                    quotationStatus = "In Progress";
                    if (
                      ((quoteItem.price1 == 0 &&
                        quoteItem.price2 == 0 &&
                        quoteItem.price3 == 0
                      ) ||
                        (quoteItem.selectedProcess != 2 &&
                          !quoteItem.isUpdatedByAdmin)) &&
                      !quoteItem.isCancelled &&
                      !quoteItem.isSent
                    ) {
                      quotationStatus = "In Review";
                    } else if (quoteItem.isSent) {
                      quotationStatus = "Sent";
                    }
                  }

                  return (
                    <tr
                      key={index}
                      className={
                        quoteItem._id === selectedQuote._id
                          ? "highlight-row"
                          : ""
                      }
                      onClick={() =>
                        navigate(`/admin/admin-quote/${quoteItem._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>Version {quoteItem.version}</td>
                      <td>{versionDescriptionText}</td>
                      <td>{moment(quoteItem.createdAt).format('YYYY-MM-DD')}</td>
                      <td>{currencyFormatter(quoteItem.price1)}</td>
                      <td>{quoteItem.leadTime1}</td>
                      <td>{currencyFormatter(quoteItem.price2)}</td>
                      <td>{quoteItem.leadTime2}</td>
                      <td>{currencyFormatter(quoteItem.price3)}</td>
                      <td>{quoteItem.leadTime3}</td>
                      <td>{userName}</td>
                      <td>{quotationStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {selectedQuote ? (
            <div className="quotes-table">
              <table className="table">
                {selectedQuote?.selectedProcess >= 3 ? (
                  <>
                    <thead>
                      <tr>
                        <th scope="col">File Name</th>
                        <th scope="col">Part Description</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Rapid</th>
                        <th scope="col">Standard</th>
                        <th scope="col">Economy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuote?.partsData?.length > 0 &&
                        selectedQuote?.partsData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                }}
                              >
                                <div
                                  className="prd-thumb"
                                  style={{ color: "dodgerblue" }}
                                >
                                  <p
                                    style={{ color: "dodgerblue" }}

                                  >
                                    {

                                      item?.selectedFile?.originalname ?
                                        <>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="mx-2"
                                            onClick={() => downloadFileFromS3(item?.selectedFile)}
                                          >
                                            {item?.selectedFile?.originalname}
                                            <DownloadIcon />
                                          </span>
                                        </> : item.linePartName ?
                                          <>
                                            <span
                                              style={{ cursor: "pointer" }}
                                              className="mx-2"
                                            >
                                              {item?.linePartName}
                                            </span>
                                          </> : "No file uploaded"
                                    }
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <FormControl
                                  type="text"
                                  value={item?.description}
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter part description"
                                />
                              </td>

                              <td>
                                <FormControl
                                  type="number"
                                  value={item?.Qty}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Qty",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Qty"
                                  onWheel={numberInputOnWheelPreventChange}
                                  style={{ minWidth: "50px" }}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price1 ? item?.price1 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price1",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price2 ? item?.price2 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price2",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>

                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item.price3 ? item.price3 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price3",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}

                      <tr className="leadTimeRow">
                        <td></td>
                        <td id="leadTimeTitle">Lines Total</td>
                        <td></td>
                        <td>
                          {currencyFormatter(
                            linesTotal[0] ? linesTotal[0].toFixed(2) : 0
                          )}
                        </td>
                        <td>
                          {currencyFormatter(
                            linesTotal[1] ? linesTotal[1].toFixed(2) : 0
                          )}
                        </td>
                        <td>
                          {currencyFormatter(
                            linesTotal[2] ? linesTotal[2].toFixed(2) : 0
                          )}
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td>
                          <div onClick={() => {
                            setIsCertificationModalOpen(true);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                            className='flex w-full items-center justify-center border !py-3'>
                            <EyeIcon />
                            <p className="whitespace-nowrap text-[12px]">View Certifications</p>
                          </div>
                        </td>
                        <td id="leadTimeTitle">Certifications </td>
                        <td></td>
                        <td>
                          <FormControl
                            disabled={true}
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.orderCertificationsCost
                                ? selectedQuote?.orderCertificationsCost
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            disabled={true}
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.orderCertificationsCost
                                ? selectedQuote?.orderCertificationsCost
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            disabled={true}
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.orderCertificationsCost
                                ? selectedQuote?.orderCertificationsCost
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td id="leadTimeTitle">Lead Time</td>
                        <td></td>
                        <td>
                          <FormControl
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            style={{ width: "100%" }}
                            value={leadTime[0] ? leadTime[0] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 0)}
                          />
                          <p style={{ marginLeft: "10px" }}>
                            {leadTime[0]
                              ? `(${Math.floor(leadTime[0] / 7)}-${Math.floor(leadTime[0] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={leadTime[1] ? leadTime[1] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 1)}
                          />
                          <p style={{ marginLeft: "10px" }}>
                            {leadTime[1]
                              ? `(${Math.floor(leadTime[1] / 7)}-${Math.floor(leadTime[1] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={leadTime[2] ? leadTime[2] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 2)}
                          />
                          <p>
                            {leadTime[2]
                              ? leadTime[2] +
                              " Days" +
                              `(${Math.floor(leadTime[2] / 7)}-${Math.floor(leadTime[2] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td id="leadTimeTitle">Shipping & Duties</td>
                        <td></td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge1
                                ? selectedQuote?.shippingCharge1
                                : ""
                            }
                            placeholder="Cost"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 1)}
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge2
                                ? selectedQuote?.shippingCharge2
                                : ""
                            }
                            placeholder="Cost"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 2)}
                            min={0}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge3
                                ? selectedQuote?.shippingCharge3
                                : ""
                            }
                            placeholder="Cost"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 3)}
                            min={0}
                          />
                        </td>
                      </tr>
                      <tr className="leadTimeRow">
                        <td></td>
                        <td id="leadTimeTitle">Shipping Days</td>
                        <td></td>

                        <td>
                          <FormControl
                            // type="number"
                            style={{ width: "100%" }}
                            // onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingDays1
                                ? selectedQuote?.shippingDays1
                                : ""
                            }
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingLeadTimeChange(e, 0)}
                          />
                        </td>
                        <td>
                          <FormControl
                            style={{ width: "100%" }}
                            value={
                              selectedQuote?.shippingDays2
                                ? selectedQuote?.shippingDays2
                                : ""
                            }
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingLeadTimeChange(e, 1)}
                          />
                        </td>
                        <td>
                          <FormControl
                            style={{ width: "100%" }}
                            value={
                              selectedQuote?.shippingDays3
                                ? selectedQuote?.shippingDays3
                                : ""
                            }
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingLeadTimeChange(e, 2)}

                          />
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td id="leadTimeTitle">Total</td>
                        <td></td>
                        <td>
                          <h6>
                            {currencyFormatter(
                              selectedQuote?.price1
                                ? (
                                  selectedQuote?.price1 +
                                  selectedQuote?.shippingCharge1 + selectedQuote?.orderCertificationsCost
                                )?.toFixed(2)
                                : 0
                            )}
                          </h6>
                        </td>

                        <td>
                          <h6>
                            {currencyFormatter(
                              selectedQuote?.price2
                                ? (
                                  selectedQuote?.price2 +
                                  selectedQuote?.shippingCharge2 + selectedQuote?.orderCertificationsCost
                                ).toFixed(2)
                                : 0
                            )}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {currencyFormatter((
                              selectedQuote?.price3 +
                              selectedQuote?.shippingCharge3 + selectedQuote?.orderCertificationsCost
                            ).toFixed(2)

                            )}
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th scope="col"> 3D File </th>
                        <th scope="col"> 2D File </th>
                        <th scope="col">Part Description</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Rapid</th>
                        <th scope="col">Standard</th>
                        <th scope="col">Economy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuote?.partsData.length > 0 &&
                        selectedQuote?.partsData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  minWidth: "150px",
                                  cursor: "pointer",
                                }}

                              >
                                <div>
                                  <p
                                    className=" flex  items-center  gap-x-2 "
                                    onClick={() => downloadFileFromS3(item?.selectedFile)}
                                  >
                                    <span className="!text-primaryColor">
                                      {item?.selectedFile?.originalname
                                        || "Uploaded Sample File"}
                                    </span>
                                    <DownloadIcon
                                      additionalClass={"text-primaryColor"}
                                    />
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  minWidth: "150px",
                                }}
                              >
                                <div style={{ color: "dodgerblue" }}>
                                  <p className=" flex  items-center  gap-x-2 ">
                                    {item.twoDFile ? (
                                      <>
                                        <span
                                          className="inline-block text-primaryColor "
                                          onClick={() => downloadFileFromS3(item?.twoDFile)}
                                        >
                                          {
                                            item.twoDFile.originalname
                                            || "Uploaded Sample File"}

                                          <DownloadIcon
                                            additionalClass={
                                              "text-primaryColor inline-block"
                                            }
                                          />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span style={{ color: "#757575" }}>
                                          No 2D File Uploaded
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <div
                                  className="desc-part"
                                  style={{ width: "100%" }}
                                >
                                  <FormControl
                                    type="text"
                                    value={item?.description}
                                    onChange={(e) =>
                                      handleItemInformationChange(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter part description"
                                  />
                                  {selectedQuote.selectedProcess == 1 ?
                                    <>
                                      {item?.cncMaterial ? (
                                        <li> <span>Material :</span><span>{item?.cncMaterial?.cnc_material}</span></li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.cncMaterial ? (
                                        <li> <span>Grade :</span><span>{item?.cncMaterial?.cnc_grade}</span></li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.cncSurfaceFinish ? (
                                        <li><span>Finish :</span><span>{item?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.cncHeatTreatment ? (
                                        <li><span>Treatment :</span><span>{item?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.cncTolerance ? (
                                        <li><span>Tolerance :</span><span>{item?.cncTolerance.cnc_tolerance}</span></li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.cncParkMarking ? (
                                        <li><span>Marking :</span><span>{item?.cncParkMarking.cnc_part_marking ? "Yes" : "No"}</span></li>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                    :
                                    <>
                                      <p>
                                        <span>
                                          Technology :
                                        </span>
                                        <span>
                                          {item.threeDTechnology &&
                                            item.threeDTechnology?.name
                                          }
                                        </span>
                                      </p>
                                      <p>
                                        <span>
                                          Material :
                                        </span>
                                        <span>
                                          {item.threeDMaterial &&
                                            item.threeDMaterial?.name
                                          }
                                        </span>
                                      </p>
                                      <p>
                                        <span>Post Process :</span>
                                        <span>
                                          {item.threeDPostProcessing &&
                                            item.threeDPostProcessing?.name
                                          }
                                        </span>
                                      </p>

                                    </>}

                                </div>
                              </td>

                              <td>
                                <FormControl
                                  type="number"
                                  value={item?.Qty}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Qty",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Qty"
                                  onWheel={numberInputOnWheelPreventChange}
                                  style={{ minWidth: "50px" }}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price1 ? item?.price1 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price1",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price2 ? item?.price2 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price2",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>

                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item.price3 ? item.price3 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price3",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {selectedQuote?.selectedProcess === 1 ||
                        selectedQuote?.selectedProcess === 2 ? (
                        <>
                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Lines Total</td>
                            <td></td>
                            <td>
                              {currencyFormatter(
                                linesTotal[0] ? linesTotal[0].toFixed(2) : 0
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                linesTotal[1] ? linesTotal[1].toFixed(2) : 0
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                linesTotal[2] ? linesTotal[2].toFixed(2) : 0
                              )}
                            </td>
                          </tr>


                          <tr className="leadTimeRow">
                            <td></td>
                            <td>
                              <div onClick={() => {
                                setIsCertificationModalOpen(true);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }} className='flex items-center justify-center border py-3'>
                                <EyeIcon />
                                <p className="whitespace-nowrap">View Certifications</p>
                              </div>
                            </td>
                            <td id="leadTimeTitle">Certifications </td>
                            <td></td>
                            <td>
                              <FormControl
                                disabled={true}
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.orderCertificationsCost
                                    ? selectedQuote?.orderCertificationsCost
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                disabled={true}
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.orderCertificationsCost
                                    ? selectedQuote?.orderCertificationsCost
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                disabled={true}
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.orderCertificationsCost
                                    ? selectedQuote?.orderCertificationsCost
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Lead Time</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                onWheel={numberInputOnWheelPreventChange}
                                style={{ width: "100%" }}
                                value={leadTime[0] ? leadTime[0] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 0)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[0]
                                  ? `(${Math.floor(leadTime[0] / 7)}-${Math.floor(leadTime[0] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={leadTime[1] ? leadTime[1] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 1)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[1]
                                  ? `(${Math.floor(leadTime[1] / 7)}-${Math.floor(leadTime[1] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={leadTime[2] ? leadTime[2] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 2)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[2]
                                  ? `(${Math.floor(leadTime[2] / 7)}-${Math.floor(leadTime[2] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Shipping & Duties</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge1
                                    ? selectedQuote?.shippingCharge1
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 1)
                                }
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge2
                                    ? selectedQuote?.shippingCharge2
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 2)
                                }
                                min={0}
                              />
                            </td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge3
                                    ? selectedQuote?.shippingCharge3
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 3)
                                }
                                min={0}
                              />
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Shipping LeadTime</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                value={
                                  selectedQuote?.shippingDays1
                                    ? selectedQuote?.shippingDays1
                                    : ""
                                }
                                placeholder="Shipping days"
                                min={0}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleShippingLeadTimeChange(e, 0)}
                              />
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                value={
                                  selectedQuote?.shippingDays2
                                    ? selectedQuote?.shippingDays2
                                    : ""
                                }
                                placeholder="Shipping days"
                                min={0}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleShippingLeadTimeChange(e, 1)}
                              />
                            </td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                value={
                                  selectedQuote?.shippingDays3
                                    ? selectedQuote?.shippingDays3
                                    : ""
                                }
                                placeholder="Shipping days"
                                min={0}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleShippingLeadTimeChange(e, 2)}
                              />
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Total</td>
                            <td></td>
                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.price1
                                    ? (
                                      selectedQuote?.price1 +
                                      selectedQuote?.shippingCharge1 + selectedQuote?.orderCertificationsCost
                                    )?.toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.price2
                                    ? (
                                      selectedQuote?.price2 +
                                      selectedQuote?.shippingCharge2 + selectedQuote?.orderCertificationsCost
                                    ).toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>
                                {currencyFormatter((
                                  selectedQuote?.price3 +
                                  selectedQuote?.shippingCharge3 + selectedQuote?.orderCertificationsCost
                                ).toFixed(2)

                                )}
                              </h6>
                            </td>
                          </tr>
                          {selectedQuote?.userTargetCost ? <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Requested Target Cost</td>
                            <td></td>
                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.userTargetCost
                                    ? (
                                      selectedQuote?.userTargetCost 
                                    ).toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr> : null}
                        </>
                      ) : null}
                    </tbody>
                  </>
                )}
              </table>
              <div style={{ textAlign: "left", display: "flex", width: "100%" }}>
                <div style={{ width: "100%", flex: 1, marginRight: "10px" }}>
                  <h6 className="font-medium my-2">
                    Quotation Notes :&nbsp;&nbsp;
                  </h6>
                  <ReactQuill
                    value={quotationNotes}
                    onChange={(e) => {
                      setIsChanged({ ...isChanged, quotationNotes: true })
                      setQuotationNotes(e)
                    }}
                    placeholder="Enter notes here"
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      maxHeight: '300px',
                      overflow: 'auto'
                    }}
                  />
                </div>
                <div style={{ width: "100%", flex: 1 }}>
                  <h6 className="font-medium my-2">
                    Quotation Tnc :&nbsp;&nbsp;
                  </h6>
                  <ReactQuill
                    value={quotationTnc}
                    onChange={(e) => {
                      setIsChanged({ ...isChanged, quotationNotes: true })
                      setQuotationTnc(e)
                    }}
                    placeholder="Enter notes here"
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      maxHeight: '300px',
                      overflow: 'auto'
                    }}
                  />
                </div>
              </div>


              <div>
                <div className="quote-buttons admin-quote-buttons">
                  <button
                    className="update-button"
                    style={{ width: "180px" }}
                    onClick={handleUpdateQuotationNotesAndTnc}
                  >
                    Update Quote Note & TnC
                  </button>
                </div>
              </div>
              <div style={{ textAlign: "left" }}>
                <h6 className="font-medium my-2">
                  Admin Comments :&nbsp;&nbsp;
                </h6>
                <FormControl
                  as="textarea"
                  rows="3"
                  style={{ width: "100%" }}
                  placeholder="Enter comment here"
                  value={selectedQuote?.adminComment}
                  onChange={(e) =>
                    setSelectedQuote({
                      ...selectedQuote,
                      adminComment: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <div className="row quote-buttons admin-quote-buttons">
                  <button
                    className="update-button"
                    onClick={handleUpdateAdminComment}
                  >
                    Update Comment
                  </button>
                  {!selectedQuote?.isCancelled ? (
                    <>
                      <button
                        className="update-button"
                        style={{ leftMargin: "20px" }}
                        onClick={handleUpdateButtonClick}
                      >
                        Update
                      </button>
                      <button
                        className="update-button"
                        onClick={handleEditQuote}
                      >
                        Edit Quote{" "}
                      </button>

                      <select
                        name="download-quote-cost-select"
                        defaultValue="default"
                        onChange={(e) => handleQuotePriceSelection(e)}
                        // className="download-quote-cost-select"
                        placeholder="Select price to download quote"
                        className="w-fit h-[30px] mt-[10px]  text-black bg-slate-200 border   rounded-sm"
                      >
                        <option disabled value="default">
                          Select Cost
                        </option>
                        <option className="hover:bg-primaryColor" value="1">
                          Rapid
                        </option>
                        <option className="hover:bg-primaryColor" value="2">
                          Standard
                        </option>
                        <option className="hover:bg-primaryColor" value="3">
                          Economy
                        </option>
                      </select>

                      <button
                        className="update-button flex items-center justify-center "
                        onClick={() => {
                          if (
                            isChanged.partInfo ||
                            isChanged.shippingCharge ||
                            isChanged.leadtime ||
                            isChanged.shippingDays
                            || isChanged.certifications
                          ) {
                            toast.error(
                              "Please update the quotation before downloading the quote."
                            );
                            return;
                          }

                          handleQMShowClick("download-quote");
                        }}
                      >
                        {!isQuotationDownloading ? (
                          <span>Download Quote</span>
                        ) : (
                          <LucidLoader additionalClass={"animate-spin"} />
                        )}
                      </button>
                      <button
                        className="update-button flex items-center justify-center"
                        // onClick={handleSendEmail}
                        onClick={() => {
                          if (
                            isChanged.partInfo ||
                            isChanged.shippingCharge ||
                            isChanged.leadtime ||
                            isChanged.shippingDays || isChanged.certifications
                          ) {
                            toast.error(
                              "Please update the quotation before downloading the quote."
                            );
                            return;
                          }
                          handleQMShowClick("send-to-email");
                        }}
                      >
                        {!isLoaderActive ? (
                          "Send To Email"
                        ) : (
                          <LucidLoader additionalClass={"animate-spin"} />
                        )}
                      </button>

                      {!selectedQuote.isSent ? (
                        <>
                          <button
                            className="update-button"
                            onClick={handleQuoteSend}
                          >
                            Mark as Sent
                          </button>
                        </>
                      ) : (
                        <></>
                      )}

                      {salesOrder?._id ? (
                        <>
                          <button
                            className="update-button"
                            onClick={() =>
                              navigate(`/admin/orders/${salesOrder?._id}`)
                            }
                          >
                            Go to SO
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="update-button"
                            onClick={handleProceedToSOModal}
                          >
                            Proceed to SO
                          </button>
                        </>
                      )}
                      {
                        ((selectedQuote?.price1 || selectedQuote?.price2 || selectedQuote?.price3)
                          &&
                          (!selectedQuote?.isTargetCostActivated)) ?
                          <button
                            onClick={() => setIsCaptchModalForAskTargetCost(true)}
                            className="update-button whitespace-nowrap"
                          >
                            Ask Target Cost
                          </button> : null
                      }

                      <button className="update-button">
                        <div onClick={showDrawer}>
                          <ChatButton
                            RefId={selectedQuote?.RefId}
                            showDrawer={showDrawer}
                            color={"white"}
                          />
                        </div>
                      </button>

                      {/* {rFQForQuotation == null ? (
                        <button className="update-button" onClick={handleCreateRFQ}>
                          Send to Suppliers{" "}
                        </button>
                      ) : (
                        <button
                          className="update-button"
                          onClick={handleGoToRFQClick}
                        >
                          Go To RFQ{" "}
                        </button>
                      )} */}
                      {rFQForQuotation == null ? (
                        <button className="update-button" onClick={() => setShowSendtoSupplierModal(true)}>
                          Send to Suppliers
                        </button>
                      ) : (
                        <button className="update-button" onClick={handleGoToRFQClick}>
                          Go To RFQ
                        </button>
                      )}

                      <Modal
                        style={{
                          width: "500px",
                          margin: "auto",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                        size="sm"
                        dialogClassName="qoute-version-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showSendtoSupplierModal}
                        onHide={() => setShowSendtoSupplierModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "15px" }}>
                            Choose Action
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="action-buttons d-flex justify-content-evenly">
                            <Button className="req-quote-btn" onClick={() => handleCreateRFQ("offer")}>
                              Create Offer
                            </Button>
                            <Button className="req-quote-btn" onClick={() => handleCreateRFQ("rfq")}>
                              Create RFQ
                            </Button>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                      </Modal>

                      <button
                        className="update-button"
                        onClick={handleTeamModalShow}
                      >
                        Team
                      </button>
                      <button
                        className="update-button"
                        onClick={handleCancelQuoteClick}
                      >
                        Cancel Quote{" "}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="update-button"
                        onClick={handleMoveToProgress}
                      >
                        Move to Progress
                      </button>
                    </>
                  )}

                  <button className="update-button" onClick={handleBack}>
                    Back{" "}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <h5>Loading</h5>
          )}
        </div>
      </span>
    </div>
  );
};
export default AdminQuote;
