import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";





export const createUpdateOrganizationSetup = async (organizationData) => {
    try {
        const response = await axiosInstance.post('/api/v2/admin/masters/organization', organizationData);
        return response.data;

    } catch (error) {
        throw new Error('Failed to create or update organization setup', error);
    }
}


export const deleteOrganzationLogo = async () => {
    try {
        const response = await axiosInstance.delete('/api/v2/admin/masters/organization-logo');
        return response.data;

    } catch (error) {
        throw new Error('Failed to create or update organization setup');
    }
}