import moment from "moment";
import PriceBox from "../../components/PriceBox";
import saveico from "../../images/save-ico.svg";
import closeImg from "../../images/close.svg";
import shareico from "../../images/share.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import DownloadImage from "../../images/download.svg";
import tickwhite from "../../images/tick-white.svg";
import {
  downloadPdf,
  shareQuoteToEmails,
  updateQuoteShipMethod,
  getOrderForQuotationId,
  handleCommonAddAddressForUser,
  handleCommonDeleteAddressForUser,
  handleCommonEditAddressForUser,
  getAllAddress,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import { ChatRefContext } from "../../context/chatRef.context";
import { currencyFormatter, isShowPriceAccordingToProcess } from "../../utils/helper";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import {
  ArchiveIcon,
  ArchiveMoveIcon,
  ArrowIcon,
  ArrowRight,
  DocIcon,
  DownloadIcon,
  EditIcon,
  ForwardToPurchaseIcon,
  LucidLoader,
  Proceed,
  ShareIcon,
  UsersIcon,
} from "../Icons";
import { createNewQuotationVersionAndOrder } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../ForwardToPurchaserModal";
import TargetCostUserModal from "../TargetCostUserModal/TargetCostUserModal";
import { downloadQuotationPdf } from "../../api/customer/quotation/commonQuotationApi";
import { checkGenericUser } from "../../utils/actions/userActions";
import DownloadQuotationModal from "../DownloadQuotationComponent/DownloadQuotationModal";

function QuoteVersionPriceBox({
  selectedQuote,
  handleOnClickPriceBox,
  selectedPrice,
  selectedShipMethod,
  setSelectedShipMethod,
  setSelectedQuote,
}) {
  const { selectedProcess } = selectedQuote;
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');
  let token = localStorage.getItem("Token");
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  let loggedInUserEmail = localStorage.getItem("email");
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([])
  );
  const [inputEmail, setInputEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubTotalZero, setIsSubTotalZero] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [isOrderAlreadyCreated, setIsOrderAlreadyCreated] = useState(false);
  const [showDQModal, setShowDQModal] = useState(false);
  let email = localStorage.getItem("email");
  const [isOpen, setisOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isQuotationDownloading, setIsQuotationDownloading] = useState(false);
  const [selectedShippingMethodForDownloadQuotation, setSelectShippingMethodForDownloadQuotation] = useState(1);
  const [isGenericUser, setIsGenericUser] = useState(true);
  const funcName = searchParams.get("funcName");

  useEffect(() => {
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [])
  console.log("Selected quote :", selectedQuote);
  useEffect(() => {

    (async () => {
      try {
        const id = localStorage.getItem("_id");
        const response = await checkGenericUser(id);
        if (response.success) {
          const { isGeneric } = response;
          setIsGenericUser(isGeneric);
        }
      } catch (error) {


      }
    })()

  }, []);


  // New state for address selected for quotation download
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isShareQuoteLoading, setIsShareQuoteLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });

  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong.Please try again later")
    }
  };

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };




  const handledAddAddress = async (addressData) => {
    let data = {
      userId: selectedQuote.userId._id,
      addressData,
    };
    try {
      const response = await handleCommonAddAddressForUser(token, data);
      if (response.status) {
        let localAllUserAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        localAllUserAddress.push(response.data);
        setAddressValues({ ...addressValues, address: localAllUserAddress });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error("Unable To Add Address");
    }
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    try {
      const response = await handleCommonEditAddressForUser(token, address);
      if (response.status) {
        allAddresses[index] = response.data;
        let addressValueData = {};
        if (addressValues.shippingAddress == response.data._id) {
          addressValueData = {
            addressline1: response.data.addressLineOne,
            addressline2: response.data.addressLineTwo,
            city: response.data.city,
            country: response.data.country,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            state: response.data.state,
            zipCode: response.data.postalCode,
          };
        }
        setAddressValues({
          ...addressValues,
          ...addressValueData,
          address: allAddresses,
        });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    try {
      if (i > -1) {
        const addressToDelete = addressValues.address[i];
        let localAllAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        const addressDeleteResponse = await handleCommonDeleteAddressForUser(
          token,
          addressToDelete._id
        );
        if (addressDeleteResponse.status) {
          localAllAddress.splice(i, 1);
          setAddressValues({ ...addressValues, address: localAllAddress });
        }
      }
    } catch (error) {
      toast.error("Delete Data");
    }
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(selectedQuote.RefId);
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };

  let startDate = moment();
  let after10 = startDate.add(10, "days");
  after10 = after10.format("Do MMM");

  let startDate2 = moment();
  let after15 = startDate2.add(15, "days");
  after15 = after15.format("Do MMM");

  let startDate3 = moment();
  let after20 = startDate3.add(20, "days");
  after20 = after20.format("Do MMM");

  const handleQuotationShare = async () => {
    try {
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        let data = {
          quoteId: selectedQuote._id,
          selectedShipMethod: selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        setIsShareQuoteLoading(true);
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([loggedInUserEmail]));
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    } finally {
      setIsShareQuoteLoading(false);
    }
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleShareQuoteModalOpen = () => {
    if (!isSubTotalZero) {
      if (!selectedQuote._id) {
        const userResponse = confirm(
          "To use the share quote functionality you need to save the quote. Please confirm if you want to save the quote with current details?"
        );
        if (userResponse) {
          let response = props.onSubmit(
            null,
            { saveQuote: true },
            { functionalityName: "Share" }
          );
        }
        return;
      } else {
        setShareQuoteModalShow(true);
      }
    } else {
      toast.error(
        "This RFQ cannot be shared. Please use chat functionality to contact the admin for updates on the prices. "
      );
    }
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };

  const handleProceedToCheckout = async () => {
    if (isOrderAlreadyCreated) {

      const { RefId, version } = selectedQuote;
      const response = await createNewQuotationVersionAndOrder(token, { RefId, version });
      console.log(response);

      if (!response || !response.success) {
        toast.error("Unable To Checkout At The moment");
        return;
      }
      const { newId } = response;
      navigate("/get-instant-quote/checkout/" + newId);

    }
    let data = {
      quoteId: selectedQuote._id,
      selectedShipMethod: selectedShipMethod,
    };
    try {
      let response = await updateQuoteShipMethod(token, data);
      if (response.status == 200) {
        setSelectedShipMethod(selectedShipMethod);
        navigate("/get-instant-quote/checkout/" + selectedQuote._id);
      } else {
        toast.error("Something went wrong while updating quote information");
      }
    } catch (error) {
      toast.error("Unable To Proceed To Checkout");
    }

    //
  };

  let handleDownload = async () => {
    if (!addressValues.shippingAddress) {
      toast.error(
        "Please select a valid shipping address to download quotation."
      );
      return;
    }
    let data = {
      quoteId: selectedQuote?._id,
      selectedShipMethod: selectedShippingMethodForDownloadQuotation,
      shippingAddress: addressValues.shippingAddress,
      RefId: selectedQuote?.RefId,
      // version: selectedQuote?.Version,
      price: selectedShippingMethodForDownloadQuotation == 1 ? selectedQuote?.price1 : selectedShippingMethodForDownloadQuotation == 2 ? selectedQuote?.price2 : selectedQuote?.price3
    };

    setShowDQModal(false);
    setIsQuotationDownloading(true);
    try {

      let response = await downloadQuotationPdf(data);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedQuote?.RefId}_V${selectedQuote.version}_${data.price}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      toast.error("We are currently unable to download the quotation. Please feel free to use the chat for further assistance.");
    } finally {
      setIsQuotationDownloading(false);
    }
  };

  const checkIsSubTotalZero = () => {
    setIsSubTotalZero(false);
    if (selectedQuote.selectedProcess >= 3) {
      if (
        selectedQuote.price1 <= 0 &&
        selectedQuote.price2 <= 0 &&
        selectedQuote.sheetAndInjectionManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    } else {
      if (
        selectedQuote.price1 <= 0 &&
        selectedQuote.price2 <= 0 &&
        selectedQuote.stManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    }
  };

  const fetchOrderData = async () => {




    try {
      let orderResponse = await getOrderForQuotationId(
        token,
        selectedQuote?._id
      );
      if (orderResponse.status) {
        setIsOrderAlreadyCreated(true);
      } else {
        setIsOrderAlreadyCreated(false);
      }
      const fetchedAddresses = await getAllAddress(token);
      let localUserAddresses = [];
      fetchedAddresses?.data?.map((address) => {
        if (address?.userId === selectedQuote?.userId?._id) {
          localUserAddresses.push(address);
        }
      });

      setAddressValues({ ...addressValues, address: [...localUserAddresses] });
    } catch (err) {
      console.log(
        "err in quote version pb on 290" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);


  useEffect(() => {
    checkIsSubTotalZero();
    fetchOrderData();
  }, [selectedQuote]);

  const handleTeamModal = async (e, selectedQuote) => {
    e.preventDefault();

    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "teamCollaboration",
      //   userId: userId,
      //   quoteRefId: selectedQuote?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const handleQMShowClick = () => {
    if (selectedShipMethod === "") {
      toast.error("Select a cost for which you want to download the quote.");
      return;
    }
    setShowDQModal(true);
  };

  return (
    <>
      <div className="qoute-version-price-div">
        {showAddressModal && (
          <AddressModal
            addressModal={showAddressModal}
            setShowAddressModal={setShowAddressModal}
            recentAddress={addressValues?.address}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            id={editId}
            values={addressValues}
            handledAddAddress={handledAddAddress}
            handleAddressOnEdit={handleAddressOnEdit}
          />
        )}
        <Chat RefId={selectedQuote.RefId} open={open} onDrawerClose={onClose} />
        <TeamCollaboration
          teamMemberModalShow={teamMemberModalShow}
          selectedQuoteForTeam={selectedQuoteForTeam}
          setTeamMemberModalShow={setTeamMemberModalShow}
          fetchDataForUser={() => null}
          updateQuoteData={setSelectedQuote}
        />
        <ForwardToPurchaseModal
          isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
          setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
          quoteId={selectedQuote?._id}
          selectedQuote={selectedQuote}
          setSelectedQuote={setSelectedQuote}

        />
        <TargetCostUserModal
          selectedQuote={selectedQuote}
          isTargetCostModalOpen={isTargetCostModalOpen}
          setIsTargetCostModalOpen={setIsTargetCostModalOpen}
          setSelectedQuote={setSelectedQuote}
        />
        <DownloadQuotationModal
          show={showDQModal}
          setShow={setShowDQModal}
          selectedQuote={selectedQuote}


        />
        <div className="qoute-version-optimised-price price-box">
          {(
            !isShowPriceAccordingToProcess(selectedQuote)
          ) ?
            <p style={{ color: 'red' }}>  Prices are not available for this version of the saved quotation. To contact our operations team, please use the chat functionality.</p>
            :
            <></>
          }

          {isShowPriceAccordingToProcess(selectedQuote) ? (
            <>
              {selectedQuote.selectedProcess >= 3 ? (
                <div className="price-tabs">
                  {selectedQuote.price1 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 1
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost,
                          1
                        )
                      }
                    >
                      <PriceBox
                        finalPrice={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        price={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime1 + selectedQuote?.shippingDays1} Business days`}
                        days={after10}
                        value={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.price2 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 2
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost,
                          2
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime2 + selectedQuote?.shippingDays2} Business days`}
                        days={after15}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedQuote.price3 > 0 && (
                    <div
                      className={
                        selectedQuote &&
                          selectedShipMethod == 3
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost,
                          3
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost
                        }
                        price={(
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime3 + selectedQuote?.shippingDays3} Business days`}
                        days={"N/A"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="price-tabs">
                  {selectedQuote.price1 > 0 ?
                    <div className={selectedQuote && selectedShipMethod == 1 ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost), 1)}>
                      <PriceBox
                        finalPrice={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        price={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime1 + selectedQuote?.shippingDays1} Business days`}
                        days={after10}
                        value={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </div>
                    : <></>}

                  {selectedQuote.price2 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 2
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          (selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost),
                          2
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime2 + selectedQuote?.shippingDays2} Business days`}
                        days={after15}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.price3 > 0 &&
                    <div className={selectedQuote && selectedShipMethod == 3 && selectedQuote.price3 ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost), 3)}>
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost)}
                        price={(selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime3 + selectedQuote?.shippingDays3} Business days`}
                      />
                    </div>
                  }

                </div>
              )}


              {isShowPriceAccordingToProcess(selectedQuote) ? <div className="button-bx">
                <button
                  className="flex cursor-pointer rounded-sm w-full justify-center items-center gap-x-3 bg-primaryColor text-white  py-2 "
                  onClick={handleProceedToCheckout}
                >
                  <span>Proceed to checkout</span>
                  <Proceed />
                </button>
              </div> : null}
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          className="price-box qoute-version-optimised-price"
          style={{ width: "50%" }}
        >
          {!isSubTotalZero ? <div>
            <div className={`flex   gap-x-2`}>
              {!isGenericUser ? <button onClick={() => setIsForwardToPurchaserModalOpen(true)} className="bg-primaryColor flex-1 h-9 flex gap-x-2 items-center justify-center text-sm text-white w-full rounded-sm ">
                <span>Forward To Purchaser</span>
                <ForwardToPurchaseIcon />

              </button> : null}
              {selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <div onClick={() => setIsTargetCostModalOpen(true)} className="bg-primaryColor flex-1 h-9 flex items-center justify-center text-white gap-x-2 ">
                <span>Submit Target Cost</span>
                <DocIcon additionalClasses={'h-5'} />
              </div> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <div onClick={() => setIsTargetCostModalOpen(true)} className="bg-slate-500 flex-1 h-9 flex items-center justify-center text-white gap-x-2 ">
                <span>Target Cost Submitted</span>
                <DocIcon additionalClasses={'h-5'} />
              </div> : null}
            </div>
          </div> : null}
          <div className="btm-buttons relative ">
            <Row className="flex">
              <Col className="h-9 flex-1">
                <ChatButton
                  RefId={selectedQuote.RefId}
                  showDrawer={showDrawer}
                  additionalClass={'!rounded-sm'}
                />
              </Col>
              <Col onClick={() => setisOpen((pre) => !pre)} className="bg-slate-200 flex flex-1 items-center justify-center gap-x-2 cursor-pointer">
                <span>More Options</span>
                <ArrowIcon additionalClasses={`h-4 ml-2 ${isOpen && "rotate-90 transition-all"}`} />
              </Col>
              {isOpen ? <div className="absolute z-20 left-[300px]  2xl:left-[450px] top-8 mt-2 w-60 bg-slate-100 text-black border border-white rounded shadow-lg ml-5 ">
                {!isGenericUser ? <div>
                  <div
                    className=" w-full flex justify-between  items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                    onClick={(e) => handleTeamModal(e, selectedQuote)}
                  >
                    <span className="text-sm">Team</span>
                    <UsersIcon />
                  </div>
                </div> : null}
                {selectedQuote._id &&
                  (((selectedQuote.isUpdatedByAdmin && selectedQuote.isSent) || selectedQuote.selectedProcess == 2) ||
                    selectedQuote.isRepeatOrderQuotation) ? (
                  <Col md={12}>
                    <Link
                      to={`/create-instant-quotation/${selectedQuote?._id}`}
                      className="flex  text-black py-2 justify-between px-4  gap-x-5  hover:bg-slate-200"
                    >
                      <span className="text-sm">Edit Quotation</span>
                      <EditIcon />
                    </Link>
                  </Col>
                ) : null}
                {isShowPriceAccordingToProcess(selectedQuote) ? <Col >
                  <Link
                    className="flex  text-black hover:bg-slate-200 px-4 py-2 justify-between gap-x-5 rounded-sm"
                    onClick={handleQMShowClick}
                  >
                    <span className="text-sm whitespace-nowrap">Download Quotation   </span>
                    {!isQuotationDownloading ? <DownloadIcon
                    /> : <LucidLoader />}
                  </Link>
                </Col> : null}
                {
                  !isSubTotalZero ?
                    <Col>
                      <Link
                        className="flex hover:bg-slate-200 text-black px-4 py-2 justify-between gap-x-5 rounded-sm text-sm"
                        onClick={handleShareQuoteModalOpen}
                      >
                        <span className="text-sm">{selectedQuote._id ? "Share" : null}</span>
                        <ShareIcon />
                      </Link>
                    </Col> : null
                }
                {selectedQuote.isCancelled && !isSubTotalZero ?
                  <button
                    className="hover:bg-slate-200 h-10 sq-btn px-4 flex justify-between gap-x-2 items-center w-full text-sm"
                    onClick={handleMoveToProgress}
                  >
                    <span>Move To Progress</span>
                    <ArchiveMoveIcon additionalClasses={'h-5'} />
                  </button>
                  :
                  !isSubTotalZero &&
                  <button
                    className="h-10 hover:bg-slate-200 px-4 sq-btn flex items-center gap-x-2 justify-between  w-full text-sm"
                    onClick={handleArchiveQuotation}
                  >
                    <span> Archive</span>
                    <ArchiveIcon additionalClasses={'h-5'} />
                  </button>
                }
              </div> : null}


            </Row>
          </div>
        </div>

        <Modal
          size="sm"
          dialogClassName="qoute-version-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={shareQuoteModalShow}
          onHide={handleShareQuoteClose}
          className="border border-green-500 !flex justify-center items-center"
        >
          <Modal.Header closeButton >
            <Modal.Title
              id="contained-modal-title-vcenter "
              style={{ fontSize: "15px" }}

            >
              Share Quotation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="share-quote-div">
              <h6 className="my-2 ms-2  font-medium text-xs md:text-[15px]">
                Emails entered below will receive an email with Quotation
                details in their mailbox.
              </h6>
              <div className=" ms-2 flex md:flex-row flex-col md:justify-start md:items-center items-start gap-x-2">
                <input
                  placeholder="Enter the email  to share the quotation details  "
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  className="border border-slate-500 px-2 md:!w-96 sm:!w-56 py-2 w-36"
                />
                <button onClick={handleAddButtonToReceiverList} className="text-xs md:text-sm md:my-0 my-2  bg-primaryColor px-2 py-2 rounded text-white  ">
                  + Add Email
                </button>
              </div>
              <div className="share-content-div">
                <h6 className="ms-2">Receiver's List</h6>
                <div className="email-list-share-div">
                  {emailToShareQuote.size > 0
                    ? Array.from(emailToShareQuote).map((item, index) => {
                      return (
                        <span className="email-item" key={index}>
                          {item}{" "}
                          <img
                            src={closeImg}
                            className="close-img"
                            onClick={(e) => handleDeleteShareEmail(item)}
                          />
                        </span>
                      );
                    })
                    : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="share-quote-div-footer">
              {!isShareQuoteLoading ? <div className="share-button" onClick={handleQuotationShare}>
                Share
              </div> :
                <div className="share-button">
                  <LucidLoader />
                </div>
              }
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default QuoteVersionPriceBox;
