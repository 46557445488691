import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./common.scss";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChatRefProvider } from "./context/chatRef.context";
import { UserContextProvider } from "./context/user.context";
import { AdminContextProvider } from "./context/admin.context";
import { CreateQuoteContextProvider } from "./context/create.quote.context";
import "react-quill/dist/quill.snow.css"; 

// CSS file for phone number input file
import "react-phone-number-input/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CreateQuoteContextProvider>
        <AdminContextProvider>
            <UserContextProvider>
                <ChatRefProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ChatRefProvider>
            </UserContextProvider>
        </AdminContextProvider>
    </CreateQuoteContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
