import { useState, useEffect, useContext, useReducer, useRef } from "react";
import {
  getAdminUsers,
} from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import cancelledImage from "../../images/close_red.svg";
import settingImage from "../../images/setting-green.svg";
import sendImage from "../../images/send.svg";
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import { currencyFormatter, validateAdmin, capitalizeString } from "../../utils/helper";
import { Form } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { Loading } from "../../components/Loading";
import { fetchFilteredBills, fetchBillForBillNumberSearch, changeBillAdminOwner, getAllSuppliers } from "../../utils/actions/supplierActions";
import allValuesImage from "../../images/all-entity.svg";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";



const SupplierBillsMaster = () => {
  const navigate = useNavigate();
  useDocumentTitle("Bills ");
  const { state, dispatch } = useAdminContext();
  const { selectedChatRefId, setSelectedChatRefId, setChatType } = useContext(ChatRefContext);
  let token = localStorage.getItem("Token");
  const [adminUsers, setAdminUsers] = useState([]);
  const [directBillResult, setDirectBillResult] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [loading, setLoading] = useState(false);

  const companyDropDownRef = useRef(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] = useState(false);



  const initialBillState = {
    createdFrom: "",
    createdTo: "",
    adminOwnerId: "",
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    billList: [],
    billCount: {},
    supplierBillNumber: "",
    companyName: '',
    poNumber: '',
  };

  const BILL_ACTIONS = {
    UPDATE_CREATED_FROM: "update-created-from",
    UPDATE_CREATED_TO: "update-created-to",
    UPDATE_ADMIN_OWNER_ID: "update-owner-id",
    UPDATE_SORT_BY: "update-sort-by",
    UPDATE_SORT_ORDER: "update-sort-order",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_PAGE: "update-total-page",
    UPDATE_BILL_LIST: "update-invoices",
    UPDATE_BILL_COUNT: "update-invoice-count",
    UPDATE_BILL_FILTER: 'update-invoice-filter',
    UPDATE_SUPPLIER_BILL_NUMBER: "update-supplier-bill-number",
    UPDATE_COMPANY_NAME: "update-company-name",
    UPDATE_PO_NUMBER: "update-po-number",
  };

  const billReducer = (state, action) => {
    switch (action.type) {
      case BILL_ACTIONS.UPDATE_CREATED_FROM: return { ...state, createdFrom: action.payload };
      case BILL_ACTIONS.UPDATE_CREATED_TO: return { ...state, createdTo: action.payload };
      case BILL_ACTIONS.UPDATE_ADMIN_OWNER_ID: return { ...state, adminOwnerId: action.payload };
      case BILL_ACTIONS.UPDATE_SORT_BY: return { ...state, sortBy: action.payload };
      case BILL_ACTIONS.UPDATE_SORT_ORDER: return { ...state, sortOrder: action.payload };
      case BILL_ACTIONS.UPDATE_PAGE_NUM: return { ...state, pageNum: action.payload };
      case BILL_ACTIONS.UPDATE_TOTAL_PAGE: return { ...state, totalPages: action.payload };
      case BILL_ACTIONS.UPDATE_BILL_LIST: return { ...state, billList: action.payload };
      case BILL_ACTIONS.UPDATE_BILL_COUNT: return { ...state, billCount: action.payload };
      case BILL_ACTIONS.UPDATE_BILL_FILTER: return { ...state, ...action.payload }
      case BILL_ACTIONS.UPDATE_SUPPLIER_BILL_NUMBER: return { ...state, supplierBillNumber: action.payload };
      case BILL_ACTIONS.UPDATE_COMPANY_NAME: return { ...state, companyName: action.payload };
      case BILL_ACTIONS.UPDATE_PO_NUMBER: return { ...state, poNumber: action.payload };
      default: return state;
    }
  };

  const [billState, billDispatch] = useReducer(
    billReducer,
    initialBillState
  );

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token).then(response => setAdminUsers(response)).catch(toast.error);
        getAllSuppliers(token).then(supplierResponse => supplierResponse.status ? setAllCompanies(supplierResponse.data) : null).catch(toast.error);
      }
      fetchCommonData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let data = {
          createdFrom: billState.createdFrom,
          createdTo: billState.createdTo,
          adminOwnerId: billState.adminOwnerId,
          sortBy: billState.sortBy,
          sortOrder: billState.sortOrder,
          pageNum: billState.pageNum,
          billFilter: state.billActiveFilter,
          supplierName: selectedCompanies.size ? [...selectedCompanies].join(',') : "",
          supplierBillNumber: billState.supplierBillNumber,
          poNumber: billState.poNumber,
        }

        // const response= await fetchFilteredInvoices(token,data);
        const response = await fetchFilteredBills(token, data);

        billDispatch({ type: BILL_ACTIONS.UPDATE_BILL_LIST, payload: response?.data?.bills })
        let billBucketCount = response?.data?.billCategoryCount;
        billDispatch({ type: BILL_ACTIONS.UPDATE_BILL_COUNT, payload: billBucketCount });
        let totalPages = Math.ceil(Number(state.billActiveFilter == 'pending for approval' ? billBucketCount.pendingingForApprovalCount : state.billActiveFilter == 'sent' ? billBucketCount.sentCount : state.billActiveFilter == 'approved' ? billBucketCount.approvedCount : state.billActiveFilter == 'paid' ? billBucketCount.paidCount : billBucketCount.allCount) / 10);
        billDispatch({ type: BILL_ACTIONS.UPDATE_TOTAL_PAGE, payload: totalPages || 0 })
        setLoading(false);
      } catch (err) {
        if (err.message != "canceled") {
          toast.error(err.message)
          setLoading(false);
        }
        console.log("err", err)
      }

    }

    fetchData();
  }, [
    billState.createdFrom,
    billState.createdTo,
    billState.adminOwnerId,
    billState.sortBy,
    billState.sortOrder,
    billState.pageNum,
    state.billActiveFilter,
    selectedCompanies.size,
    billState.supplierBillNumber,
    billState.poNumber,
  ]);


  useEffect(() => {
    function handleClickOutside(event) {
      if (companyDropDownRef.current && !companyDropDownRef.current.contains(event.target)) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  }

  const searchCompany = (e) => {
    billDispatch({
      type: BILL_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    })
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        (item.companyName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true)
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      billDispatch({
        type: BILL_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      })
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER,
        payload: 'all-bill',
      });
      setSelectedCompanies(localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };



  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    setChatType("supplier");
  };

  const onClose = () => {
    setSelectedChatRefId(null);
    setChatType(null);
  };

  const handleAdminOwnerChange = async (e, billId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(`Are you sure you want to assign this bill to ${adminUserSelected.firstName + " " + adminUserSelected.lastName}?`);
      if (!adminResponse) {
        return;
      }

      let data = {
        billId: billId,
        adminId: e.target.value,
      };
      let quoteResponse = await changeBillAdminOwner(token, data);
      if (quoteResponse.status) {
        let localbillList = [...billState.billList];
        localbillList.forEach(item => {
          if (item._id == billId) {
            item.adminOwnerId = adminUserSelected._id;
          }
        });
        billDispatch({ type: BILL_ACTIONS.UPDATE_BILL_LIST, payload: localbillList })
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 86 Invoice on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value?.selected + 1 || 1;
    billDispatch({ type: BILL_ACTIONS.UPDATE_PAGE_NUM, payload: pageNum })
  };


  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER,
      payload: field,
    });

    billDispatch({
      type: BILL_ACTIONS.UPDATE_BILL_FILTER, payload: {
        createdFrom: "",
        createdTo: "",
        adminOwnerId: "",
        sortBy: "",
        sortOrder: 1,
        pageNum: 1,
        totalPages: 0,
        supplierBillNumber: ''
      }
    })
  };


  let handleSort = (field) => {
    billDispatch({
      type: BILL_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    })

    let sortStatus = billState.sortBy == field ? billState.sortOrder == 1 ? -1 : 1 : 1;
    billDispatch({
      type: BILL_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    })
  };

  const handleBillSearch = async (e) => {
    try {
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectBillResult([]);
        return;
      }

      let response = await fetchBillForBillNumberSearch(token, e.target.value);
      if (response.status) {
        setDirectBillResult(response.data);
      } else {
        setDirectBillResult([])
        toast.error("Something went wrong while fetching the data.")
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleRefresh = () => {
    billDispatch({
      type: BILL_ACTIONS.UPDATE_BILL_FILTER, payload: {
        createdFrom: "",
        createdTo: "",
        // userName: "",
        adminOwnerId: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
        supplierBillNumber: '',
        companyName: '',
        poNumber: '',
      }
    })

    dispatch({
      type: ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER,
      payload: 'all-bill',
    });
    setSelectedCompanies(new Set());
  }

  return (
    <div className="admin-container">
      <div style={{ height: 'auto' }}>
        {loading ?
          <Loading />
          : ""}
      </div>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="font-bold text-2xl my-2">Supplier Bills</h1>
          <div className="right-container ">
            <div className="bucketFilterDiv">

              <div className={`bucketFilterOptions ${state.billActiveFilter == 'all-bill' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('all-bill')}>
                <span className="textBox">
                  <p>All Bills</p>
                  <h5>{billState?.billCount?.allCount || 0}</h5>
                </span>
                <img className='filterIcon' src={allValuesImage} />
              </div>


              <div
                className={`bucketFilterOptions ${state.billActiveFilter == "pending for approval" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("pending for approval")}
              >
                <span className="textBox">
                  <p>Pending For Approval</p>
                  <h5>{billState?.billCount?.pendingingForApprovalCount}</h5>
                </span>
                <img className="filterIcon" src={waitingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.billActiveFilter == "approved" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("approved")}
              >
                <span className="textBox">
                  <p>Approved</p>
                  <h5>{billState?.billCount?.approvedCount}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>



              <div
                className={`bucketFilterOptions ${state.billActiveFilter == "paid" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("paid")}
              >
                <span className="textBox">
                  <p>Paid</p>
                  <h5>{billState?.billCount?.paidCount}</h5>
                </span>
                <img className="filterIcon" src={tickGreenImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.billActiveFilter == "rejected" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("rejected")}
              >
                <span className="textBox">
                  <p>Rejected</p>
                  <h5>{billState?.billCount?.rejectedCount}</h5>
                </span>
                <img className="filterIcon" src={cancelledImage} />
              </div>

            </div>

            <>
              <Chat RefId={selectedChatRefId} open={selectedChatRefId?.length > 0} onDrawerClose={onClose} />


              <div className="search-bar">
                <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>

                  <div className="search-block">
                    <label className="sr-label">Admin Owner</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={billState?.adminOwnerId || "default"}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        billDispatch({
                          type: BILL_ACTIONS.UPDATE_ADMIN_OWNER_ID,
                          payload: e.target.value,
                        })
                      }
                      }
                    >
                      <option value="default" disabled>
                        {" "}
                        Select owner
                      </option>
                      {adminUsers?.map((val, i) => {
                        return (
                          <option key={i} value={val._id}>
                            {val.firstName + " " + val.lastName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Supplier Bill Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={billState.supplierBillNumber}
                      placeholder="Enter Bill Number"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER,
                          payload: 'all-bill',
                        });

                        billDispatch({
                          type: BILL_ACTIONS.UPDATE_SUPPLIER_BILL_NUMBER,
                          payload: e.target.value,
                        })
                      }}
                    />
                  </div>



                  <div className="search-block">
                    <label className="sr-label">Ref ID</label>

                    <input
                      type="text"
                      className="form-control"
                      value={directFilterVal}
                      placeholder="Search Ref ID"
                      onChange={handleBillSearch}
                    />
                    {(directFilterVal.trim().length > 0 && directBillResult.length > 0) ? (
                      <div className="direct-search-drop-down-div ">
                        <table className="search-table">
                          <thead>
                            <tr>
                              <th>Ref ID</th>
                              <th>Total Amount</th>
                              <th>Status</th>
                              <th>Bill Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(directFilterVal.trim().length > 0 && directBillResult.length > 0) &&
                              directBillResult?.map((val, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className="direct-search-drop-down"
                                    onClick={() =>
                                      window.open(
                                        `/admin/bill-details/${val?._id}`, "_blank"
                                      )
                                    }
                                  >
                                    <td style={{ textAlign: "left" }}>
                                      {val?.billNumber}
                                    </td>
                                    <td>
                                      {currencyFormatter(val.billTotalAmount)}
                                    </td>
                                    <td>
                                      {val.status}
                                    </td>
                                    <td>
                                      {moment(val.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>


                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>


                  <div className="search-block">
                    <label className="sr-label">Supplier Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={billState.companyName}
                          placeholder={`${selectedCompanies.size} suppliers selected`}
                          onChange={(e) => searchCompany(e)}
                          onFocus={handleCompanyFocus}
                        />

                        {isCompanyDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: 'left'
                            }}
                            ref={companyDropDownRef}
                          >
                            {filteredCompanies.length > 0 ?
                              filteredCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil-com"}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'fil-com'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(item._id)}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'fil-com'} style={{ marginLeft: '5px', fontSize: '11px' }}>
                                      {item.companyName}
                                    </label>
                                  </div>
                                )
                              }) :
                              allCompanies?.length > 0 && allCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + 'all-com'}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'all-com'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(item._id)}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'all'} style={{ marginLeft: '5px', fontSize: '11px' }}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          <></>
                        )}

                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">PO#</label>
                    <input
                      type="text"
                      className="form-control"
                      value={billState.poNumber}
                      placeholder="PO#"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER,
                          payload: 'all-bill',
                        });

                        billDispatch({
                          type: BILL_ACTIONS.UPDATE_PO_NUMBER,
                          payload: e.target.value,
                        })
                      }}
                    />
                  </div>



                  <div className="search-block">
                    <div>
                      <label className="sr-label">Bill Created From</label>
                      <input
                        type="date"
                        value={billState.createdFrom}
                        className="form-control"
                        onChange={(e) => {
                          billDispatch({
                            type: BILL_ACTIONS.UPDATE_CREATED_FROM,
                            payload: e.target.value,
                          })
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="search-block">
                    <div>
                      <label className="sr-label">Bill Created To</label>
                      <input
                        type="date"
                        value={billState.createdTo}
                        className="form-control"
                        onChange={(e) => {
                          billDispatch({
                            type: BILL_ACTIONS.UPDATE_CREATED_TO,
                            payload: e.target.value,
                          })
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="search-btn" style={{ marginTop: '10px' }}>
                    <button onClick={handleRefresh} className="button-search">Reset all filters</button>
                  </div>

                </div>
              </div>

              <div className="quotes-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("billNumber")}
                      >
                        Bill Ref {billState.sortBy == 'supplierBillNumber' ? billState.sortOrder == 1 ? "↓" : "↑" : "↓"}{" "}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("supplierBillNumber")}
                      >
                        Supplier Bill No {billState.sortBy == 'supplierBillNumber' ? billState.sortOrder == 1 ? "↓" : "↑" : "↓"}{" "}
                      </th>
                      <th scope="col" style={{ cursor: "pointer" }}>
                        {" "}
                        Admin Owner{" "}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("createdAt")}
                      >
                        Bill Created at {billState.sortBy == 'createdAt' ? billState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("purchaseOrderNumber")}
                      >
                        PO #{billState.sortBy == 'purchaseOrderNumber' ? billState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("billTotalAmount")}
                      >
                        Amount {billState.sortBy == 'billTotalAmount' ? billState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th>Due Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {billState.billList?.length > 0 &&
                      billState.billList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                width: "150px",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >
                              <div className="flex items-center gap-x-1">
                                <Link to={`/admin/bill-details/${item?._id}`}
                                  className="text-primaryColor"

                                >
                                  {item.billNumber}
                                </Link>
                                <HoverInfo content={item.adminComment} />
                              </div>


                              {state.billActiveFilter == 'all-bill' && item?.status
                                ? <span className="!text-sm">< br />{`(${capitalizeString(item?.status)})`}</span>
                                : ""}
                            </td>
                            <td>
                              {item?.supplierBillNumber}
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                value={item?.adminOwnerId || "default"}
                                style={{ width: "100%", marginTop: "-10px" }}
                                onChange={(e) =>
                                  handleAdminOwnerChange(e, item._id)
                                }
                              >
                                <option value="default" disabled>
                                  {" "}
                                  Select owner
                                </option>
                                {adminUsers?.map((val, i) => {
                                  return (
                                    <option key={i} value={val._id}>
                                      {val.firstName + " " + val.lastName}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </td>
                            <td>
                              {moment(item.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td>
                              {item?.purchaseOrderId?.purchaseOrderNumber}
                            </td>
                            <td> {currencyFormatter(item.billTotalAmount)}</td>
                            <td>
                              {item?.dueDate ? moment(item.dueDate).format("MMM DD, YYYY") : "NA"}                            </td>

                            <td>
                              <div>
                                <button
                                  type="button"
                                  className="up-button w-[100px]"
                                // style={{
                                //   marginTop: "3px 0px",
                                //   background: "white",
                                //   color: "#2B96DC",
                                //   width: "100px",
                                //   border: "1px solid #a8e5ff",
                                // }}
                                >
                                  <ChatButton
                                    RefId={item?.purchaseOrderId?.pORefId}
                                    showDrawer={() => showDrawer(item?.purchaseOrderId?.pORefId)}
                                  />
                                </button>
                              </div>
                            </td>

                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="floatRight">
                <Pagination
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={billState?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handleActivePage}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </>

          </div>
        </div>
      </span>
    </div>
  );
};

export default AdminDashboardComponents(SupplierBillsMaster, {
  width: "100%",
  overflowX: "auto",
  backgroundColor: "#f4e6dd",
  minHeight: "100vh",
});
