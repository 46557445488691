import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteBulkPricing,
  getAllBulkPricing,
  getBulkPricing,
  saveBulkPricing,
  updateBulkPricing,
} from "../utils/actions/allactions";
import Sidebar from "./Sidebar";
import { numberInputOnWheelPreventChange} from "../utils/helper";
import moment from "moment";
import useDocumentTitle from '../utils/useDocumentTitle';

const BulkPricing = () => {
  const [formData, setFormData] = useState({});
  const [pricingData, setPricingData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  useDocumentTitle('Bulk Pricing')

  const token = localStorage.getItem("Token");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllBulkPricing(token);
        setPricingData(response.data);
      } catch (err) {
        console.log("Error in bulk pricing 26"+moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a'),err);
      }
    }
    fetchData();
  }, []);

  const onChangeHandler = (e, name) => {
    setFormData((val) => ({
      ...val,
      [name]: e.target.value,
    }));
  };
  const saveHandle = async (e) => {
    e.preventDefault();
    let val = false;
    pricingData.map((el)=>{ 
      if(el.qty == formData.qty) 
        val = true; 
      })
    if(!val){
      const response = await saveBulkPricing(token, formData);

      if (response?.data?.status === false) {
        toast.error("Something went wrong");
      } else if (response?.data?.status === true) {
        toast.success("Pricing Added Successfully");
        setPricingData((val) => [...val, response.data.data]);
        setFormData(() => ({
          qty: "",
          percentage: "",
        }));
      }
    } else {
      toast.warning('Already Added for this Qty')
    }
   
  };

  const deleteData = async (_id) => {
    try {
      const payload = {
        _id,
      };
      const response = await deleteBulkPricing({ payload }, token);
      const newData = pricingData.filter((pricing, index) => {
        return pricing._id !== _id;
      });
      setPricingData(newData);
      if (response.data.status == false) {
        toast.error("something went wrong");
      } else if (response.data.status == true) {
        toast.success("Pricing Deleted Successfully");
      }
    } catch (error) {
      console.log("Error in bulk pricig 82"+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),error);
    }
  };

  const editHandler = async (_id) => {
    setIsEdit(true);
    const response = await getBulkPricing(_id);
    setFormData(() => ({
      qty: response.data.qty,
      percentage: response.data.percentage,
      _id,
    }));
  };

  const updateData = async (e) => {
    e.preventDefault();
    let val = false;
    pricingData.map((el)=>{ 
      if(el.qty == formData.qty) 
        val = true; 
      })

        const response = await updateBulkPricing(token, formData);
        if (response.data.status == false) {
          toast.error("something went wrong");
        } else if (response.data.status == true) {
          const response = await getAllBulkPricing(token);
          setPricingData(response.data);
          toast.success("Pricing Updated Successfully");
        }
        setIsEdit(false);
        setFormData(() => ({
          qty: "",
          percentage: "",
        })); 
  };

  return (
    <div className="admin-container">
      <Sidebar />
      <span style={{width:'100%' }}>
      <div className="right-container">
        <div className="center-heading">
          <h1>Bulk Pricing </h1>
          <div className="bulk-input">
            <div className="col-md-2">
              <div className="bulk-input-block">
                <label className="sr-label">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData?.qty}
                  onWheel={numberInputOnWheelPreventChange}
                  onChange={(e) => onChangeHandler(e, "qty")}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="bulk-input-block">
                <label className="sr-label">Discount Percentage</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData?.percentage}
                  onWheel={numberInputOnWheelPreventChange}
                  onChange={(e) => onChangeHandler(e, "percentage")}
                />
              </div>
            </div>
            {isEdit ? (
              <button className="bulk-button" onClick={updateData}>
                Update
              </button>
            ) : (
              <button
                className="bulk-button"
                // onClick={saveHandler}
                onClick={saveHandle}
              >
                Add
              </button>
            )}
          </div>

          <div className="quotes-table">
            <div style={{ padding:"40px", width:"80%", marginLeft:"23%"}}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Quantity</th>
                    <th scope="col">%age</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pricingData?.map((el, i) => (
                    <tr key={i}>
                      <td>{el.qty}</td>
                      <td>{el.percentage}</td>
                      <td>
                        {" "}
                        <button
                          className="button-edit"
                          onClick={() => editHandler(el._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="button-delete"
                          onClick={() => deleteData(el._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </span>
    </div>
  );
};

export default BulkPricing;
