import React from "react";
import PhotoViewer from "../../../components/PhotoViewer/PhotoViewer";
import img1 from "../ProjectsImages/3DPrinted/img (1).jpg";
import img2 from "../ProjectsImages/3DPrinted/img (2).jpg";
import img3 from "../ProjectsImages/3DPrinted/img (3).jpg";
import img4 from "../ProjectsImages/3DPrinted/img (4).jpg";
import img5 from "../ProjectsImages/3DPrinted/img (5).jpg";
import img6 from "../ProjectsImages/3DPrinted/img (6).jpg";
import img7 from "../ProjectsImages/3DPrinted/img (7).jpg";
import img8 from "../ProjectsImages/3DPrinted/img (8).jpg";
import img9 from "../ProjectsImages/3DPrinted/img (9).jpg";
import img10 from "../ProjectsImages/3DPrinted/img (10).jpg";
import img11 from "../ProjectsImages/3DPrinted/img (11).jpg";
import img12 from "../ProjectsImages/3DPrinted/img (12).jpg";
import img13 from "../ProjectsImages/3DPrinted/img (13).jpg";
import img14 from "../ProjectsImages/3DPrinted/img (14).jpg";
import img15 from "../ProjectsImages/3DPrinted/img (15).jpg";
import img16 from "../ProjectsImages/3DPrinted/img (16).jpg";
import img17 from "../ProjectsImages/3DPrinted/img (17).jpg";
import img18 from "../ProjectsImages/3DPrinted/img (18).jpg";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";

const ThreeDProjects = () => {
  const imageArr = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
  ];


  const materialNames = [
    "Steel",
    "Bead blasted Aluminum",
    "Bead blasted Aluminum",
    "Black ABS",
    "Nylon",
    "White ABS",
    "Nylon",
    "Steel",
    "Steel",
    "Steel",
    "Steel",
    "Black ABS",
    "Rubber 65A Black",
    "White ABS",
    "White ABS",
    "Rubber 80A",
    "Transparent Polycarbonate",
    "Transparent Polycarbonate",
  ]



  return (
    <>
      <div className=" flex  bg-primaryColor justify-between items-center px-10 ">
        <Link to="">
          <img className="h-24" src={logo} />
        </Link>{" "}
        <Link to="/login" className=" px-3 py-1 bg-white rounded">
          Back
        </Link>
      </div>
      <div className="csr-section">
        <h4 className="text-xl font-bold my-2" style={{ textAlign: "center" }}>3D Printing Projects</h4>
        <span>
          <PhotoViewer images={imageArr} altText={`image`} materialNames={materialNames} />
        </span>
      </div>

    </>
  );
};

export default ThreeDProjects;
