import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

let baseRouteUserMaster = '/api/v2/user/masters';



export const getUserDetailsById = async (userId) => {
    try {
        const response = await axiosInstance.get(`${baseRouteUserMaster}/get-user-details-by-id/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching user details: ${error.message}`);
    }
}
