import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({
    label = "Go Back",
    additionalClasses = "",
    onClick = null
}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    };

    return (
        <button
            onClick={handleClick}
            className={`px-4 py-2 bg-gray-300 hover:bg-gray-300 text-black rounded ${additionalClasses}`}
        >
            {label}
        </button>
    );
};

export default BackButton;
