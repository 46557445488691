import { axiosInstance } from "../../utils/AxiosInstance/AxiosInstance";


export const getPresignedUrlForFile = async (bucketName, bucketKey) => {
    try {
        const response = await axiosInstance.get('/api/v2/s3/get-presigned-url', {
            params: {
                bucketName,
                objectKey: bucketKey,
            },
        });

        return response.data.signedUrl;
    } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        throw new Error("Failed to fetch pre-signed URL");
    }
};

export const getAllMaterialsList = async () => {
    try {
        const response = await axiosInstance.get('/api/v2/common/masters/materials-list');
        return response.data;
    } catch (error) {
        console.error("Error fetching materials:", error);
        throw new Error("Failed to fetch materials");
    }
}

const asyncHandler = (fn) => {

}

