import React, { useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from '../../../../context/create.quote.context';
import uploadImg from "../../../../images/upload.svg";
import { getFileExtension } from '../../../../utils/helper';
// import { getThumbnail } from '../../../../utils/actions/allactions';
import { triggerFunc, triggerFuncLink } from '../../../../utils/trigger';
import { setSameForAllField, setValues, updateSelectedProcess } from '../../../../context/create.quote.actions';
import { toast } from 'react-toastify';
import AutomatedProcessLinesUI from '../AutomatedProcessLinesUI/AutomatedProcessLinesUI';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import ManualProcessLinesUI from '../ManualProcessLinesUI/ManualProcessLinesUI';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import Switch from "react-input-switch";
import { ArrowRightLeft, EditIcon, PlusIcon } from '../../../../components/Icons';
import EditProjectName from '../../../../components/EditProjectName/EditProjectName';
import uploadGif from '../../../../assets/upload.gif';
import * as uploadLottie from '../../../../assets/upload-lottie.json';
import Lottie from 'react-lottie';
import { handleProjectNameChange } from '../../../../utils/actions/commanActions';
import animationData from "../../../../assets/JsonFiles/uploadJson.json";
import { samplePartsData } from '../../../../utils/constant';

const CenterSection = ({
  setIsInvalidFiles,
  setIsProcessing,
  setIsGlobeLoader,
  files,
  setFiles,
  masterThreeDTechnologyData,
  boxDimension,
  processing,
  globeLoader,
  sample,
  setSample,
  setTwoDFiles,
  twoDFiles,
  setIsInchSelected,
  isInchSelected,
  onSubmit,
  updateCommanValueOnChange,
  OnDropFunction,
  onSelectfile,
  updateValueOnChange,
  updateQuote,
  selectedQuote,
  isQuoteUpdated,
  handleNewVersionClick,
  handleQuoteUpdate,
  isLoadedInIframe,
  setIsThreeSecondLoading
}) => {
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const [isProjectNameEditModalOpen, setisProjectNameModalOpen] = useState(false);
  const [uploadSampleModal, setUploadSampleModal] = useState(false);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  let lineItemTemplate = {
    selectedFile: "",
    Notes: "",
    Qty: 1,
    description: "",
    MaterialType: "",
    SubMaterial: "",
    file: "",
    layer: "",
    partMaking: "",
    surfaceFinish: "",
    surfaceTreatment: "",
    tolerance: "",
    price: 0,
    price1: 0,
    price2: 0,
    partName: "",
    linePartName: "",
  };
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("location", location)


  const handleDelete = (index) => {
    console.log("Index :", index);
    try {
      let afterFltr = createQuoteState?.values.filter((val, i) => i !== index)
      if (afterFltr.length === 0) {
        toast.error("At least one line item is required.");
        return;
      }
      setValues([...afterFltr], createQuoteDispatch, createQuoteState)
      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      updateCommanValueOnChange('isProcessChanged', true);


      // try {
      //   let local2DFiles = { ...twoDFiles };
      //   delete local2DFiles[index];
      //   for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {

      //     local2DFiles[i - 1] = local2DFiles[i];
      //     delete local2DFiles[i];
      //   }
      //   setTwoDFiles({ ...local2DFiles });
      // } catch (err) {
      //   console.log('err', err);
      // }
    }
    catch (err) {
      console.log("err", err)
    }
  }


  async function createFile(url, type) {
    if (typeof window === "undefined") return; // make sure we are in the browser
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type || "",
      path: "SamplePart.stp",
    };
    let fileName = url?.slice(url.lastIndexOf("/") + 1);
    let filewa = new File([data], fileName, {
      type: type || "",
      path: "SamplePart.stp",
    });
    return new File([data], fileName, metadata);
  }


  const checkIfProcessSet = async (item) => {

    return new Promise(async (resolve) => {
      try {
        const response = await createFile(
          item.stepFileUrl,
          ""
        );

        onSelectfile([response]);
        setFiles([
          files,
          response,
        ]);
        return resolve(true);
      } catch (err) {
        console.log(err);
        return resolve(false);
      }
    });
  };


  const onClickSampleUpload = async (item) => {
    try {
      updateSelectedProcess(item.selectedProcess, createQuoteDispatch, createQuoteState);
      const response = await checkIfProcessSet(item);
      setSample(true);
      if (!response) return;

      // here we add logic to upload sample file
    } catch (err) {
      console.log("error ", err);
      return err;
    }finally{
      setIsThreeSecondLoading(false)

    }
  };


  const handleSameForAllField = (newValue) => {
    setSameForAllField(newValue, createQuoteDispatch, createQuoteState)
  }

  const handleAddItem = () => {
    let localValues = [...createQuoteState?.values];
    localValues.push(lineItemTemplate);
    setValues(localValues, createQuoteDispatch, createQuoteState);

    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  };

  const handleProjectNameSave = async (updatedProjectName) => {
    const data = { projectName: updatedProjectName, quoteId: selectedQuote?._id };
    const token = localStorage.getItem("Token");
    await handleProjectNameChange(token, data);
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
      payload: updatedProjectName,
    })
    setisProjectNameModalOpen(false);
  }



  const handleVersionChange = (selectedItem) => {
    console.log("Selected Item:", selectedItem);
    if (selectedItem?._id) {
      navigate('/create-instant-quotation/' + selectedItem?._id)
    } else {
      toast.error("The selected version is invalid. Please refresh the page and try again.");
    }
  };
  const handleProjectNameCancel = (updatedProjectName) => {


    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
      payload: updatedProjectName,
    })
    setisProjectNameModalOpen(false);
  }





  return (
    <div className="file-details-section">
      {createQuoteState?.values?.length ?
        <>
          <div className='flex space-between project-name-section'>

            <div className="flex items-center w-full">
              <h1 className='flex sub-header project-name'>
                {
                  <span>{createQuoteState?.commanValues?.projectName}</span>
                }

                <span onClick={() => setisProjectNameModalOpen(true)} className='ml-2 cursor-pointer'>
                  <EditIcon
                    additionalClass={'!h-5 icon'}
                  /></span> </h1>
            </div>
            <div className="flex items-center space-x-2" style={{ marginRight: 32 }}>
              <span
                className={`transition-colors ${isInchSelected ? "info-text text-gray-500" : "info-text font-semibold text-black"
                  }`}
              >
                mm
              </span>
              <Switch
                styles={{
                  track: {
                    backgroundColor: '#2995DB'
                  },
                  trackChecked: {
                    backgroundColor: '#2995DB'
                  },
                  button: {
                    backgroundColor: '#FFF'
                  },
                  buttonChecked: {
                    backgroundColor: '#FFF'
                  }
                }}
                className="custom-switch mm-switch"
                on="true"
                value={isInchSelected?.toString()}
                onChange={() => setIsInchSelected(!isInchSelected)}
                off="false"
                aria-label="Toggle between mm and inch"
              />
              <span
                className={`transition-colors ${isInchSelected ? "info-text font-semibold text-black" : "info-text text-gray-500"
                  }`}
              >
                inch
              </span>
            </div>
            <div className='flex items-center space-x-2'>
              <p className="text">Version</p>
              <div className="version-selection-div">
                <Form.Select
                  className="w-[72px] version-select"
                  onChange={(e) => {
                    console.log('values in onChange', e.target.value);
                    const selectedVersion = Number(e.target.value);
                    const selectedItem = selectedQuote?.allVersionForRefId?.find(
                      item => item.version === selectedVersion
                    );
                    handleVersionChange(selectedItem);
                  }}
                  defaultValue={createQuoteState?.commanValues?.version}
                >
                  {selectedQuote?.allVersionForRefId?.map(item => (
                    <option key={item.version} value={item.version} selected={createQuoteState?.commanValues?.version == item.version}>
                      {item.version}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <button
                // className='py-1 px-2 bg-primaryColor text-white rounded flex items-center justify-between' 
                className='grey-btn new-version-btn'
                onClick={handleNewVersionClick}
              >
                New
                <PlusIcon additionalClass={'icon'} />
              </button>
            </div>
          </div>

          {createQuoteState?.commanValues?.selectedProcess <= 2 ? <>
            <div className="flex items-center space-x-8">
              {/* MM-Inch Toggle */}
              {/* <div className="flex items-center space-x-2">
                <span
                  className={`transition-colors ${isInchSelected ? "info-text text-gray-500" : "info-text font-semibold text-black"
                    }`}
                >
                  mm
                </span>
                <Switch
                  className="custom-switch mm-switch"
                  on="true"
                  value={isInchSelected?.toString()}
                  onChange={() => setIsInchSelected(!isInchSelected)}
                  off="false"
                  aria-label="Toggle between mm and inch"
                />
                <span
                  className={`transition-colors ${isInchSelected ? "info-text font-semibold text-black" : "info-text text-gray-500"
                    }`}
                >
                  inch
                </span>
              </div> */}

              {/* Apply to All Parts Toggle */}
              {/* <div className="flex items-center space-x-2">
                <Switch
                  className="custom-switch apply-to-all-switch"
                  on="true"
                  value={createQuoteState?.commanValues.sameForAllField?.toString()}
                  onChange={() =>
                    handleSameForAllField(!createQuoteState?.commanValues.sameForAllField)
                  }
                  off="false"
                  aria-label="Toggle apply to all parts"
                />
                <span
                  className={`transition-colors ${createQuoteState?.commanValues.sameForAllField
                    ? "info-text font-semibold text-black"
                    : "info-text text-gray-500"
                    }`}
                >
                  Apply selected specifications to all parts
                </span>
              </div> */}
            </div>

          </> : <></>}
          {(createQuoteState?.commanValues?.selectedProcess == 1 || createQuoteState?.commanValues?.selectedProcess == 2) ?
            <>
              <div className="product-thumb item-cards-list">
                {createQuoteState?.values &&
                  createQuoteState?.values?.length > 0 &&
                  createQuoteState?.values?.map((singleValue, index) => {
                    return (
                      <AutomatedProcessLinesUI
                        selectedQuote={selectedQuote}
                        setIsInvalidFiles={setIsInvalidFiles}
                        masterThreeDTechnologyData={masterThreeDTechnologyData}
                        key={`machingPrinting-${index}`}
                        singleValue={singleValue}
                        index={index}
                        onDelete={handleDelete}
                        files={files}
                        setFiles={setFiles}
                        boxDimension={boxDimension}
                        processing={processing}
                        globeLoader={globeLoader}
                        keyValue={index + 1}
                        sample={sample}
                        values={createQuoteState?.values}
                        setValues={setValues}
                        isInvalidFiles={createQuoteState?.commanValues?.isInvalidFiles}
                        selectedProcess={createQuoteState?.commanValues.selectedProcess}
                        updateValueOnChange={updateValueOnChange}
                        commanValues={createQuoteState?.commanValues}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                        sameForAllField={createQuoteState?.commanValues.sameForAllField}
                        setSameForAllField={handleSameForAllField}
                        twoDFiles={twoDFiles}
                        setTwoDFiles={setTwoDFiles}
                        setIsInchSelected={setIsInchSelected}
                        isInchSelected={isInchSelected}
                        isQuoteUpdated={isQuoteUpdated}
                        handleQuoteUpdate={handleQuoteUpdate}
                      />
                    );
                  })}
              </div>
            </> :
            <>
              {(location.pathname != "/create-instant-quotation-iframe") &&
                <>
                  <div>
                    <div className="pro-dm">
                      <h5 className='my-3'>
                        Enter description with parts specification
                      </h5>
                      <div className="parts-table">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>

                              <th className="cursor-pointer text-xs">Snapshot</th>
                              <th className="cursor-pointer text-xs">Part Name</th>
                              <th className="cursor-pointer text-xs">Description</th>
                              <th className="cursor-pointer text-xs">Quantity</th>
                              <th className="cursor-pointer text-xs"></th>
                            </tr>
                            {createQuoteState?.values &&
                              createQuoteState?.values.length > 0 &&
                              createQuoteState?.values.map((singleValue, index) => {
                                return (
                                  <ManualProcessLinesUI
                                    selectedQuote={selectedQuote}
                                    singleValue={singleValue}
                                    onSubmit={onSubmit}
                                    keyValue={index + 1}
                                    key={`sheetInjectionInput-${index}`}
                                    files={files}
                                    onDelete={handleDelete}
                                    index={index}
                                    values={createQuoteState?.values}
                                    selectedProcess={createQuoteState?.commanValues?.selectedProcess}
                                    updateValueOnChange={updateValueOnChange}
                                    commanValues={createQuoteState?.commanValues}
                                    updateCommanValueOnChange={updateCommanValueOnChange}
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="btm-buttons">
                    <Row>
                      <Col md={2}>
                        <div className="price-box">
                          <button
                            onClick={handleAddItem}
                            className='primary-btn'
                          >
                            Add Item
                          </button>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span style={{ fontSize: "12px", color: "red" }}>
                          *If you want you add an item without file, you can
                          click on the "Add Item" button above
                        </span>
                      </Col>
                    </Row>
                  </div>
                </>}

            </>
          }




        </>
        :
        <>
          <div
            className={`upload-req ${(location.pathname != "/create-instant-quotation-iframe") ? "mt-4" : ""}`}
          >
            {createQuoteState?.values && createQuoteState?.values.length === 0 && (
              <Dropzone
                onDrop={(e) => OnDropFunction(e)}

                disabled={createQuoteState?.commanValues?.selectedProcess === 0 ? true : false}
                multiple={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({ className: " upload-box dropzone" })}
                  >
                    <span className="up-icon !h-20 aspect-square">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: animationData,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                          }
                        }}
                      />
                    </span>
                    <span
                      className={
                        createQuoteState?.commanValues?.selectedProcess === 0 ? "error" : "inp-txt"
                      }
                    >
                      {createQuoteState?.commanValues?.selectedProcess === 0
                        ? "Please select a Process to continue..."
                        : "Drop files here to upload"}
                    </span>
                    <div className="file-button">
                      <input {...getInputProps()} />

                      <label
                        className="file-input__label"
                        htmlFor="file-input"
                      >
                        <span>Select a file</span>
                      </label>
                    </div>
                    {createQuoteState?.commanValues?.selectedProcess === 1 ||
                      createQuoteState?.commanValues?.selectedProcess === 2 ? (
                      <p className='info-text'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) : createQuoteState?.commanValues?.selectedProcess === 3 ? (
                      <p className='info-text'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) : createQuoteState?.commanValues?.selectedProcess === 4 ? (
                      <p className='info-text'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) :
                      createQuoteState?.commanValues?.selectedProcess === 5 ? (
                        <p className='info-text'>
                          Instant Quotation for - .stp, .step files. Other file
                          format are also accepted (RFQ)
                        </p>
                      ) :
                        createQuoteState?.commanValues?.selectedProcess === 6 ? (
                          <p className='info-text'>
                            Instant Quotation for - .stp, .step files. Other file
                            format are also accepted (RFQ)
                          </p>
                        ) :
                          null}
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        </>}


      {createQuoteState?.values &&
        createQuoteState?.values.length === 0 &&
        (createQuoteState?.commanValues?.selectedProcess >= 3 ? (
          ""
        ) : (
          <>
            <div className='upload-req w-full p-0'>
              <div className="sample-box flex items-center">
                <div className="text">
                  Not yet ready to upload your files at the moment? Use our
                  sample part file to try out our instant quotation tool
                </div>
                <button
                  disabled={false}
                  onClick={() => setUploadSampleModal(true)}
                  className="upload-btn p-2 "
                >
                  Upload Sample Part
                </button>
              </div>
            </div>

            {isLoadedInIframe ?

              <>
                <Modal
                  centered
                  className="upload-sample-modal"
                  show={uploadSampleModal}
                  onHide={() => setUploadSampleModal(false)}
                  style={{ width: '100%', margin: '0' }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-sm font-semibold -mb-10 -mt-10">Upload Sample Part</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="space-y-1 -mb-10 -mt-2">
                      {samplePartsData.map((item, i) => (
                        <div
                          key={i}
                          className="flex items-center gap-2 p-2 border rounded-md shadow-sm bg-white "
                        >
                          {/* Image */}
                          <img
                            src={item.thumbnail}
                            alt="upload"
                            className="w-16 h-14 rounded object-cover"
                          />

                          {/* Details Section */}
                          <div className="flex-grow">
                            <p className="text-xs font-medium text-gray-800">{item.title}</p>
                            <p className="text-xxs text-gray-600 break-words whitespace-normal">
                              {item.description}
                            </p>
                          </div>

                          {/* Upload Button */}
                          <button
                            onClick={() => {
                              onClickSampleUpload(item);
                              setUploadSampleModal(false);
                              setIsThreeSecondLoading(true)
                            }}
                            className="px-2 py-1 text-xs font-medium text-white bg-sky-500 rounded hover:bg-sky-600"
                          >
                            Upload
                          </button>
                        </div>
                      ))}
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>

              </>
              :
              <>
                <Modal
                  centered
                  className='upload-sample-modal modal'
                  show={uploadSampleModal}
                  onHide={() => setUploadSampleModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className='sub-header'>Upload Sample Part</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='sample-cards-div'>
                      {
                        samplePartsData.map((item, i) => (
                          <div className="sample-card">
                            <div className="sample-card-content">
                              <img src={item.thumbnail} alt="upload" />
                              <p className="text title-text">{item.title}</p>
                              <p className="text description-text">{item.description}</p>
                            </div>
                            <div className="sample-card-action">
                              <button
                                onClick={() => {
                                  onClickSampleUpload(item);
                                  setUploadSampleModal(false);
                                  setIsThreeSecondLoading(true)
                                }}
                                className="primary-btn"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        ),)
                      }
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </>
            }



          </>
        ))}
      <EditProjectName
        key={createQuoteState?.commanValues?.projectName}
        show={isProjectNameEditModalOpen}
        currentName={createQuoteState?.commanValues?.projectName}
        onSave={handleProjectNameSave}
        onCancel={handleProjectNameCancel}
      />


    </div>
  )
}

export default CenterSection