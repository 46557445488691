import logo from "../../assets/static/logo/logo.png";
import orders from "../../images/orders.svg";
import saved from "../../images/saved.svg";
import profile from "../../images/profile.svg";
import jwtDecode from "jwt-decode";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { firebaseSignOutOnLogOut } from "../../utils/firebase";
import { useUserContext, USER_ACTIONS } from "../../context/user.context";
import {
  fetchOrdersForParameters,
  fetchQuotesForParameters,
} from "../../utils/actions/userActions";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../context/create.quote.context";
import SignInModal from "../LoggedInModal/SignInModal";

const Header = ({ isUserLoggOut, }) => {
  let token = localStorage.getItem("Token");
  const dropDownRef = useRef();
  const handBurgurDropDownRef = useRef();
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const [isAdminUser, setIsAdminUser] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const loggedInUser = localStorage.getItem("Token");
  const isValidUser = localStorage.getItem("isLoggedIn");
  console.log("Is Not Valid User :",isValidUser);

  console.log("Is Valid User :", isValidUser)
  let user_id = localStorage.getItem("_id");
  if (loggedInUser) {
    var userfirstName = localStorage.getItem("firstName");
    var userlastName = localStorage.getItem("lastName");
  }
  const { state, dispatch } = useUserContext();



  const routerLocation = useLocation();
  const { quoteId } = useParams();
  const isMyOrdersPage = routerLocation.pathname.search("/my-orders") != -1;
  const isSavedQuatationPage = routerLocation.pathname.search("/saved-quote") != -1;
  const navigate = useNavigate();
  const [toogle, setToggle] = useState({
    isProfile: false,
    isHandBurgur: false,
  });
  const handleLogout = () => {

    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("pathAfterLogin");
    firebaseSignOutOnLogOut();
    console.log("cam here 11453534543")
    setToggle({
      isProfile: false,
      isHandBurgur: false,
    })
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
      payload: 2,
    })
    navigate("/");
    window.location.reload();

  };


  useEffect(() => {
    if (isUserLoggOut) {
      setRefresh((pre) => !pre);
    }

  }, [isUserLoggOut]);

  const [isSignInModalShow, setIsSignModalShow] = useState(false);


  const handleClickOutside = (e) => {
    if (dropDownRef.current && handBurgurDropDownRef.current && !handBurgurDropDownRef.current.contains(e.target) && !dropDownRef.current.contains(e.target)) {
      setToggle({
        isHandBurgur: false,
        isProfile: false,
      })

    }
  }
  useEffect(() => {
    if (!token) return;
    let decode = jwtDecode(token);
    if (!decode) {
      console.log("cam here 1154645645654")
      navigate("/");
      toast.error("Invalid Token found!");
    } else if (decode) {
      if (decode.userType == "user") {
      } else if (decode.userType == "admin") {
        setIsAdminUser(true);
      } else {
        console.log("cam here 113454353534")
        navigate("/");
      }
    }
    fetchData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  async function fetchData() {
    if (!user_id) return;
    if (isAdminUser) return;
    try {
      let dataForOrder = {
        pageNum: 1,
        activeTab: "",
        filterProjectName: "",
        orderActiveFilter: state.orderFilterActive,
        userId: user_id,
        createdFromFilter: "",
        createdToFilter: "",
        partNameFilter: "",
      };

      const orderResponse = await fetchOrdersForParameters(
        token,
        dataForOrder,
        false
      );
      if (orderResponse.status) {
        let data = {
          orderCount:
            orderResponse.data.inProgressCount +
            orderResponse.data.completedCount,
          inProgressOrderCount: orderResponse.data.inProgressCount,
          completedOrderCount: orderResponse.data.completedCount,
        };

        dispatch({ type: USER_ACTIONS.UPDATE_ORDER_COUNT, payload: data });
      }
    } catch (err) {
      console.log("error fetching order count", err);
    }

    try {
      let dataForQuote = {
        pageNum: 1,
        activeTab: "",
        filterProjectName: "",
        quoteFilterActive: state.quoteFilterActive,
        userId: user_id,
        createdFromFilter: "",
        createdToFilter: "",
        partNameFilter: "",
      };
      const response = await fetchQuotesForParameters(
        token,
        dataForQuote,
        false
      );
      if (response.status) {
        let data = {
          quoteCount: response.data.inProgressCount + response.data.readyToOrderCount + response.data.completedCount,
          inProgressQuoteCount: response.data.inProgressCount,
          completedQuoteCount: response.data.completedCount,
          readyToOrderQuoteCount: response.data.readyToOrderCount,
        };
        dispatch({ type: USER_ACTIONS.UPDATE_QUOTE_COUNT, payload: data });
      }
    } catch (err) {
      console.log("error fetch quote count", err);
    }
  }
  return (
    <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
      <SignInModal
        show={isSignInModalShow}
        onSignIn={() => navigate("/saved-quote")}
        showNotEmailOption={true}
        onClose={() => setIsSignModalShow(false)}
      />
      <nav className=" shadow-sm navbar h-16 relative  bg-newPrimaryColor flex justify-between md:px-6 px-3 items-center">
        <div >
          <div className="flex pe-2 ">
            <div className="logo-pt absolute bottom-1.5  flex items-center justify-center ">
              <a
                href={process.env.REACT_APP_MAIN_WEBSITE_URL}
                rel="noopener noreferrer"
              >
                <img
                  className="h-10 relative ms-4 scale-125 -translate-y-2"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            {isValidUser && <div className="md:px-2 cursor-pointer lg:flex lg:ms-24 text-white  hidden">
              <div
                onClick={() => {
                  !isAdminUser
                    ? navigate("/my-orders")
                    : alert("Functionality not available for admin");
                }}
                className="flex justify-between items-center gap-2  md:text-sm md:px-2"
              >
                <img className="md:h-4 h-3 " src={orders} />
                <p className="lg:text-sm md:text-[13px]">
                  My Orders {state.orderCount}
                </p>
              </div>
              <div
                onClick={() => {
                  !isAdminUser
                    ? navigate("/saved-quote")
                    : alert("Functionality not available for admin");
                }}
                className="flex cursor-pointer justify-between items-center gap-2 text-sm px-2"
              >
                <img className="h-4 " src={saved} />
                <p>Saved Quotes {state.inProgressQuoteCount + state.readyToOrderQuoteCount + state.completedQuoteCount}</p>
              </div>
            </div>}
          </div>
        </div>
        <div>
          <div ref={dropDownRef} className="lg:flex hidden relative  items-center justify-between">
            {isAdminUser && (
              <Link
                to={"/admin"}
                className="flex items-center  gap-x-2 mx-2 py-[7px] text-white px-3 border  rounded text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-shield-minus h-4"
                >
                  <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                  <path d="M9 12h6" />
                </svg>
                <p>Go Back To Admin Panel</p>
              </Link>
            )}
            {(
              isMyOrdersPage ||
              isSavedQuatationPage

            ) ? <button
              onClick={() => {
                createQuoteDispatch({
                  type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
                  payload: 1,
                });
                navigate("/");

              }}
              className="text-sm  border border-white cursor-pointer  mx-2   shadown-sm   w-max  flex items-center  rounded  px-1 md:py-2 md:px-2 lg:py-[7px] lg:px-3 text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-square-pen h-4 mx-1"
              >
                <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
              </svg>
              <p className="lg:text-sm pe-2">Get Instant Quote</p>
            </button> : null}
            {isValidUser && <a
              href={process.env.REACT_APP_SUPPORT_CALL_LINK}
              target="_blank"
              className="text-sm cursor-pointer mx-1 flex items-center bg-complementaryPrimaryColor  text-white shadown-sm  py-2 px-3 rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-calendar-plus-2 h-4 mx-1"
              >
                <path d="M8 2v4" />
                <path d="M16 2v4" />
                <rect width="18" height="18" x="3" y="4" rx="2" />
                <path d="M3 10h18" />
                <path d="M10 16h4" />
                <path d="M12 14v4" />
              </svg>
              Meet with our engineers
            </a>}
            {isValidUser && <div
              onClick={() =>
                setToggle((pre) => {
                  return { ...pre, isProfile: !pre.isProfile };
                })
              }
              className={`ms-4 cursor-pointer ${routerLocation.pathname.includes(`/create-instant-quotation/${quoteId}`) && "hidden"}`}
            >
              <img className="h-7" src={profile} />
            </div>}
            {toogle.isProfile && (
              <ul className=" z-10 border shadow-sm text-sm gap-2 font-medium ps-2  tracking-wider hover:bg-slate-100    py-2 absolute     right-0 top-10 bg-white rounded-md ">
                <li className="text-[12px] font-bold  px-2">
                  HI , {`${userfirstName} ${userlastName}`.toUpperCase()}
                </li>
                <li
                  onClick={() =>
                    !isAdminUser
                      ? navigate("/profile")
                      : alert("Functionality not available for admin")
                  }
                  className="flex w-40 cursor-pointer hover:bg-slate-100 py-1 my-2 justify-start px-1 items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-user h-4"
                  >
                    <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                  <span className="text-sm ms-2 ">Profile</span>
                </li>
                <li className="flex w-40 cursor-pointer hover:bg-slate-100 py-1 justify-start px-1 my-2 items-center" onClick={handleLogout}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-log-out h-4"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1="21" x2="9" y1="12" y2="12" />
                  </svg>
                  <span className="text-sm ms-2">Logout</span>
                </li>
              </ul>
            )}
          </div>
          {!isValidUser ? <div>
            <button onClick={() => setIsSignModalShow(true)} className="px-3 py-2 rounded text-sm bg-primaryColor text-white me-5">Login</button>
          </div> : null}
          {isValidUser && <div ref={handBurgurDropDownRef} className="lg:hidden relative  text-white">
            <div >
              {!toogle.isHandBurgur ? (
                <svg
                  onClick={() => {
                    setToggle((pre) => ({
                      ...pre,
                      isHandBurgur: true,
                    }));
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-menu cursor-pointer"
                >
                  <line x1="4" x2="20" y1="12" y2="12" />
                  <line x1="4" x2="20" y1="6" y2="6" />
                  <line x1="4" x2="20" y1="18" y2="18" />
                </svg>
              ) : (
                <svg
                  onClick={() => {
                    setToggle((pre) => ({
                      ...pre,
                      isHandBurgur: false,
                    }));
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-x cursor-pointer"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              )}
              {toogle.isHandBurgur && (
                <div className="absolute rounded border border-gray-200 py-2 z-10 w-40 px-2 bg-white  text-black  top-7 right-0.5">
                  <h3 className="text-[12px] font-normal px-2">
                    HI , {`${userfirstName} ${userlastName}`.toUpperCase()}
                  </h3>
                  <ul className="">
                    <li
                      onClick={() => {
                        !isAdminUser
                          ? navigate("/my-orders")
                          : alert("Functionality not available for admin");
                      }}
                      className="flex hover:bg-slate-100 py-1 cursor-pointer  rounded-sm my-2 justify-start px-1 items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-arrow-up-right h-4"
                      >
                        <path d="M7 7h10v10" />
                        <path d="M7 17 17 7" />
                      </svg>
                      <span className="text-sm ms-2">
                        My Orders ({state.orderCount})
                      </span>
                    </li>
                    <li
                      onClick={() => {
                        !isAdminUser
                          ? navigate("/saved-quote")
                          : alert("Functionality not available for admin");
                      }}
                      className="flex hover:bg-slate-100 py-1 my-2 justify-start px-1 items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-save h-4"
                      >
                        <path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" />
                        <path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" />
                        <path d="M7 3v4a1 1 0 0 0 1 1h7" />
                      </svg>
                      <span className="text-sm ms-2 whitespace-nowrap">
                        Saved Quotes ({state.quoteCount})
                      </span>
                    </li>
                    <li
                      onClick={() => navigate("/project-name")}
                      className="flex hover:bg-slate-100 py-1 my-2 justify-start px-1 items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-square-pen h-4"
                      >
                        <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                      </svg>
                      <span className="text-sm ms-2">Get Instant Quote</span>
                    </li>
                    <a
                      href={process.env.REACT_APP_SUPPORT_CALL_LINK}
                      target="_blank"
                      className="flex hover:bg-slate-100 py-1 my-2 justify-start px-1 items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-calendar-arrow-down h-4"
                      >
                        <path d="m14 18 4 4 4-4" />
                        <path d="M16 2v4" />
                        <path d="M18 14v8" />
                        <path d="M21 11.354V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7.343" />
                        <path d="M3 10h18" />
                        <path d="M8 2v4" />
                      </svg>
                      <span className="text-sm ms-2">Meet with our engineers</span>
                    </a>
                    <li
                      onClick={() =>
                        !isAdminUser
                          ? navigate("/profile")
                          : alert("Functionality not available for admin")
                      }
                      className="flex hover:bg-slate-100 py-1 my-2 justify-start px-1 items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-user h-4"
                      >
                        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                      <span className="text-sm ms-2 ">Profile</span>
                    </li>
                    <li
                      onClick={handleLogout}
                      className="flex hover:bg-slate-100 py-1 justify-start px-1 my-2 items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-log-out h-4"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1="21" x2="9" y1="12" y2="12" />
                      </svg>
                      <span className="text-sm ms-2">Logout</span>
                    </li>
                    {isAdminUser && (
                      <Link
                        to={"/admin"}
                        className="flex hover:bg-slate-100 py-1 cursor-pointer  rounded-sm my-2 justify-start px-1 items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-shield-minus h-4"
                        >
                          <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                          <path d="M9 12h6" />
                        </svg>
                        <p className="text-sm ms-2">Go Back To ...</p>
                      </Link>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>}
        </div>
        {(isMyOrdersPage || isSavedQuatationPage) && (
          <div
            className={`arrow-pointer hidden lg:block ${isMyOrdersPage && 'left-[185px]'} ${isSavedQuatationPage && 'left-[300px]'} absolute h-3 w-10 bottom-0   `}
          >
            <TriangleArrowComponent />
          </div>
        )}
      </nav>
    </div>
  );
};
export default Header;

export const TriangleArrowComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="lucide lucide-triangle h-4 text-white bg"
  >
    <path d="M13.73 4a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />
  </svg>
);
