import axios from "axios";
const config = require(`../../environment/development`).config;
let cancelToken;
let cancelTokenForOrderFetch;


export const changeShippingAddressForSO = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/change-shipping-address-for-so`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchFilteredQuotes = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelToken = axios.CancelToken.source();

      const response = await axios.get(`${config.backEnd}/admin/fetch-filtered-quotes`, {
        cancelToken: cancelToken.token,
        headers,
        params: data,
      });
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message);
      }
      return reject(error);
    }
  });
};

export const fetchQuotesForRefIdSearch = (token, refId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${config.backEnd}/admin/get-quotes-for-ref-id-search/${refId}`,
        {
          headers,
          cancelToken: cancelToken.token
        },
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const getSalesOrdersForAllQuotation = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(`${config.backEnd}/admin/get-sales-order-for-all-quote`, {
        headers,
        params: data,
      });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}



export const fetchDashboardDataForMake = (token, filterData) => {
  return new Promise(async (resolve, reject) => {
    cancelTokenForOrderFetch && cancelTokenForOrderFetch.cancel();
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelTokenForOrderFetch = axios.CancelToken.source();
      let response = await axios.get(`${config.backEnd}/admin/get-dashboard-data-make`, {
        headers: header,
        params: filterData,
        cancelToken: cancelTokenForOrderFetch.token,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getIssuesForSalesOrder = (token, orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/admin/get-issue-for-sales-order/${orderId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const createInvoice = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/create-invoice`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteDraftInvoice = (token, invoiceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.delete(
        `${config.backEnd}/admin/delete-draft-invoice/${invoiceId}`,
        { headers }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const sendInvoiceToCustomer = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/send-invoice-to-customer/`, data,
        { headers }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDetailsForInvoiceDetailsPage = (token, invoiceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${config.backEnd}/admin/data-for-invoice-details/${invoiceId}`,
        { headers }
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const changeInvoiceAdminOwner = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }

      let response = await axios.post(`${config.backEnd}/admin/change-invoice-admin-owner`, payload, { headers: header });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const fetchInvoiceForInvoiceNumberSearch = (token, invoiceNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${config.backEnd}/admin/get-invoice-for-invoice-number-search/${invoiceNumber}`,
        {
          headers,
          cancelToken: cancelToken.token
        },
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const fetchFilteredInvoices = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelToken = axios.CancelToken.source();

      const response = await axios.get(`${config.backEnd}/admin/fetch-filtered-invoices`, {
        cancelToken: cancelToken.token,
        headers,
        params: data,
      });
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message);
      }
      return reject(error);
    }
  });
};



export const updateInvoiceQualityDoc = async (data, tokn) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-Type': 'application/json'
    }
    let response = await axios.post(`${config.backEnd}/admin/update/invoice/qualitydoc`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response
  } catch (error) {
    return error
  }
}


export const invoiceConsignMentPictureUpload = async (data, tokn) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-Type': 'application/json'
    }
    let response = await axios.post(`${config.backEnd}/admin/update/invoice/consignmentPhoto`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response
  } catch (error) {
    return error
  }
}


export const invoiceDeliveryLinkUpdate = async (tokn, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }

    let response = await axios.post(`${config.backEnd}/admin/update/invoice/deliveryLink`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response;
  } catch (error) {
    return error
  }
}


export const invoiceShippingDateUpdate = async (tokn, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.put(`${config.backEnd}/admin/invoice/shipped-date`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response;
  } catch (error) {
    return error
  }
}


export const invoiceDeliveryDateUpdate = async (tokn, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.put(`${config.backEnd}/admin/invoice/delivery-date`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response;
  } catch (error) {
    return error
  }
}


export const fetchFilteredOrders = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelTokenForOrderFetch != typeof undefined) {
        // Previous api calls are cancelled
        cancelTokenForOrderFetch.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelTokenForOrderFetch = axios.CancelToken.source();

      const response = await axios.get(`${config.backEnd}/admin/fetch-filtered-orders`, {
        cancelToken: cancelTokenForOrderFetch.token,
        headers,
        params: data,
      });
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message);
      }
      return reject(error);
    }
  });
};


export const markInvoiceAsPaid = async (tokn, data) => {
  try {
    console.log("tone", tokn)
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.put(`${config.backEnd}/admin/invoice-mark-paid`, data, { headers: header }).then(res => {
      return res.data
    }).catch(err => {
      return err
    })

    return response;
  } catch (error) {

    return error
  }
}

export const approveInvoice = async (tokn, data) => {
  try {
    console.log("tone", tokn)
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.put(`${config.backEnd}/admin/approve-invoice`, { id: data }, { headers: header }).then(res => {
      return res.data
    }).catch(err => {
      return err
    })

    return response;
  } catch (error) {

    return error
  }
}




export const sendInvoiceShippingEmail = async (tokn, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.post(`${config.backEnd}/admin/mail/invoice-shipping-update`, data, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const sendInvoiceDeliveryEmail = async (tokn, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(tokn)}`,
      'Content-type': 'application/json'
    }
    let response = await axios.post(`${config.backEnd}/admin/mail/invoice-delivery-update`, data, { headers: header })
    return response.data;
  } catch (error) {
    return error
  }
}


export const fetchOrdersForRefIdSearch = (token, refId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${config.backEnd}/admin/get-orders-for-ref-id-search/${refId}`,
        {
          headers,
          cancelToken: cancelToken.token
        },
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchAttachSalesOrders = (token, salesOrderIdArr) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/fetch-attach-sales-orders`,
        { salesOrderIdArr },
        { headers },
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const fetchInvoiceForSalesRefSearch = (token, salesRef) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();


      let response = await axios.get(
        `${config.backEnd}/admin/get-invoice-for-sales-ref-search/${salesRef}`,
        {
          headers,
          cancelToken: cancelToken.token
        },
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchInvoiceForPONumberSearch = (token, poNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();


      let response = await axios.get(
        `${config.backEnd}/admin/get-invoice-for-po-number-search/${poNumber}`,
        {
          headers,
          cancelToken: cancelToken.token
        },
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchQuotationDataForRFQ = (token, quotationIdArr) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${config.backEnd}/admin/fetch-quotations-for-rfqs`,
        { quotationIdArr },
        { headers },
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const FileUploadInQuote = async (token, formData) => {
  console.log(token, formData);
  try {
    const response = await axios.post(`${config.backEnd}/admin/upload-files-quotes`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(token)}`,
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}

export const FileDeleteQuote = async (token, data) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/delete-files-quote`, {
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`,
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const updatePartsData = async (token, data) => {
  try {
    const response = await axios.put(`${config.backEnd}/admin/update-parts-data`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`,
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }

}

export const createProcess = async (token, data) => {
  try {

    const response = await axios.post(`${config.backEnd}/admin/create-3d-material-postprocess`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    const message = error.message || "Something Went Wrong";
    return {
      success: false,
      message,
    };
  }
}
export const getAll3dProcess = async (token) => {
  try {

    const response = await axios.get(`${config.backEnd}/admin/get-all-3d-process`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const delete3dProcess = async (token, id) => {
  try {

    const response = await axios.delete(`${config.backEnd}/admin/delete-3d-process/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }

};

export const createMaterial = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin//create-3d-material`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}

export const getThreeDMaterial = async (token) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-3d-material`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const upsert3dTechnologyData = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/upsert-3dtechnology`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const getAllTechnology = async (token) => {
  try {
    console.log('token', token)
    const response = await axios.get(`${config.backEnd}/admin/get-3dtechnology`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getMaterialByIdThreeD = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-3d-material-by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return fasle;
  }
};


export const createMachineForTechnology = async (token, data) => {
  try {

    const response = await axios.post(`${config.backEnd}/admin/add-3d-machines-for-tech`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const getTechnologyByid = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-3d-technology-by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getMachineDataById = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-3d-machine-by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const get3dProcessById = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-3d-process-data/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}


export const getLeadTimeDataForPostProcessing = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-leadtime-data-for-post-processing/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}

export const updateLeadTimeDataForPostProcessing = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/update-leadtime-data-for-post-processing`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });

  } catch (error) {
    return false;
  }
}

export const getMetalCCDataForMaterial = async (token, id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-metal-cc-data-for-material/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}

export const updateMetalCCForMaterial = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/update-metal-cc-for-material`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const activateTargetCostForUser = async (token, id) => {
  try {
    const response = await axios.put(`${config.backEnd}/admin/activate-target-cost-for-user/${id}`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token)}`
      },
    });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const createCertification = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/comman/upsert-certificate`, data);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const deleteCertifications = async (token, id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/comman/delete-certificate/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const getSeq = async () => {
  try {

    const response = await axios.get(`${config.backEnd}/get-seq-number`);
    return response.data;

  } catch (error) {
    return false;
  }
}

export const deleteHeatTreatmentById = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/comman/cnc/ht/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};


export const upsertSurfaceFinish = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/st/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getAllSurfaceFinish = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-all-sf`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteSurfaceFinishById = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/st/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const upsertPartMarking = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/pm/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deletePartMarking = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/pm/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllPartMarking = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/pm/get-all-pm`);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const upsertTolerance = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/tol/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const deleteTolerance = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/tol/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllTolerance = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/tol/get-all-tole`);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const upsertInternalCorner = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/ic/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteInternalCorner = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/ic/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getAllInternalCorner = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin//cnc/ic/get-all-ic`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const upsertReviewDesing = async (data) => {
  try {

    const response = await axios.post(`${config.backEnd}/admin/cnc/rd/upsert`, data);
    return response.data;

  } catch (error) {
    return fasle;
  }
};

export const deleteReviewDesign = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/rd/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getAllReviewDesign = async () => {
  try {
    const resposne = await axios.get(`${config.backEnd}/admin/cnc/rd/get-all-rd`);
    return resposne.data;
  } catch (error) {
    return false;
  }
};

export const deleteMaterial = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/mt/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const upsertMaterial = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/mt/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllMaterialData = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/mt/get-all-mt`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getMaterialDataById = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-material-by-id/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const getMaterialOptionsById = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-material-options/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}



export const getMaterialDataWithPopulate = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-material-options-no-pouplate/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}


//machine
export const upsertMachine = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/mc/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllMachineDataWithPopulation = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-all-machine-with-pouplation`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllCncMachines = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/mc/get-all`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteMachine = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/mc/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getMachineOptions = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/mc/get-options/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getCncMachineDataById = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/mc/get-machine-data-by-id/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getMrrByMachineAndMaterialId = async (machineId, materialId) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/mrr/get`, { machineId, materialId });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const upsetMrrValues = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/mrr/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

//certifications

export const upsertCncCertification = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/cnc/upsert-certificate`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getAllCncCertifications = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/cnc/get-all-certificates`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteCncCertifications = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/cnc/delete-certificate/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}
//shipping rates

export const getShippingRates = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/sp/get-all`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const upsertShippingRates = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/sp/upsert`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};

//threeD Certification

export const upsertthreeDCertification = async (data) => {
  try {
    const response = await axios.post(`${config.backEnd}/admin/threeD/upsert-certificate`, data);
    return response.data;
  } catch (error) {
    return false;
  }
};


export const getAllthreeDCertifications = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/threeD/get-all-certificates`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deletethreeDCertifications = async (id) => {
  try {
    const response = await axios.delete(`${config.backEnd}/admin/threeD/delete-certificate/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getAllCompanyWithouFilter = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/admin/get-all-company`);
    return response.data.data;
    
  } catch (error) {
    return false;
  }
}