import React, { useEffect, useState } from 'react'
import { getFileExtension, isValidFileName, roundToTwoDigits } from '../../utils/helper';
import { Button, Modal } from 'react-bootstrap';
import Switch from "react-input-switch";
import { load3DViewer } from '../../utils/trigger';
import { getPresignedUrlForFile } from '../../api/helperapi/helperApi';
import { useParams } from 'react-router-dom';
import { CheckIconDFM, CircleAlertDFM, LucidLoader, SuccessIcon, WarningIcon } from '../Icons';
import { toast } from 'react-toastify';
import { miliMeterToInchFactor } from '../../utils/constant';

const ThreeDViewerComponent = ({ item, selectedProcess, isValidThreeDFile }) => {
    const [isViewerShow, setIsViewerShow] = useState(false);
    const [isViewerLoading, setIsViewerLoading] = useState(false);
    const [isInchSelected, setIsInchSelected] = useState(false);

    const [errorShowArray, setErrorShowArray] = useState([]);

    const handleViewerClick = async (file) => {
        try {
            setIsViewerLoading(true);
            const type = getFileExtension(file?.originalname);
            const response = await getPresignedUrlForFile(file.bucket, file.key);
            const fileResponse = await fetch(response);
            if (!fileResponse.ok) {
                throw new Error(`Failed to fetch file: ${fileResponse.statusText}`);
            }
            if
                (
                type == 'stp' ||
                type == 'step' ||
                type == 'iges' ||
                type == 'stl' ||
                type == 'STP' ||
                type == 'STEP' ||
                type == 'IGES' ||
                type == 'STL'
            ) {
                setIsViewerShow(true);
                setTimeout(() => {
                    load3DViewer(fileResponse?.url, type);
                }, 0);
                setIsViewerLoading(false);
            } else {
                return false;
            }

        } catch (error) {
            console.log("Error", error)
            toast.error("Failed to fetch quotation");
        }



    }

    useEffect(() => {

        if (item) {
            const tempErrorArray = [];
            if (item?.masterPartsDataThreeDId && selectedProcess == 2) {
                const {
                    threeD_machine_dimension_check_bypass,
                    threeD_check_unrecognised_features_bypass,
                    threeD_check_dfm_thicknessAnalysis_minThickness,
                    threeD_check_bores,

                } = item?.masterPartsDataThreeDId;
                if (!threeD_machine_dimension_check_bypass) {
                    tempErrorArray.push('Dimensions are out of machine platform size, review required');
                }
                // if (!threeD_check_unrecognised_features_bypass) {
                //     tempErrorArray.push('Unrecognised feature detected');
                // }
                // if (threeD_check_dfm_thicknessAnalysis_minThickness) {
                //   tempErrorArray.push('Minimum thickness < 1mm detected,  review required');
                // }
                if (threeD_check_bores) {
                    tempErrorArray.push('Min bore / hole dia < 2mm detected, review required.');
                }
            }

            if (item?.stagingPartsDataId && item?.masterPartsDataCNCId && selectedProcess == 1) {


                const { type } = item?.stagingPartsDataId;


                const {
                    cnc_check_X_dimension_check,
                    cnc_check_Y_dimension_check,
                    cnc_check_Z_dimension_check,
                    cnc_check_unrecognised_features_absent
                } = item?.masterPartsDataCNCId;
                console.log("Type :", type);


                console.log("cnc_check_unrecognised_features_absent :", cnc_check_unrecognised_features_absent);
                console.log("tempErrorArray :", tempErrorArray);
                if (type == 'CNC_MILLING') {
                    if (!cnc_check_X_dimension_check || !cnc_check_Y_dimension_check || !cnc_check_Z_dimension_check) {
                        tempErrorArray.push("The part exceeds standard machine's bed size - Review required");
                    }
                }
                else if (type == 'CNC_LATHE' || type == 'CNC_LATHE_MILLING') {
                    if (!cnc_check_X_dimension_check || !cnc_check_Y_dimension_check || !cnc_check_Z_dimension_check) {
                        tempErrorArray.push("The part exceeds standard machine's bed size - Review required");
                    }
                }
                if (!cnc_check_unrecognised_features_absent) {
                    tempErrorArray.push('3D surfaces detected. A 5-axis machining strategy may be necessary - Review required');
                }

                if (item?.featureAxisBoresDataForPart?.some(axisBores => axisBores?.axisbores_ratio > 3)
                    || item?.featureHolesDataForPart?.some(hole => hole?.bores_ratio > 3)
                ) {
                    tempErrorArray.push('Bore (hole) length/diameter ratio > 3 detected.');
                }

                if (item?.featureHolesDataForPart?.some(hole => hole?.counterbores_ratio > 3)) {
                    tempErrorArray.push('Counterbore length/diameter ratio > 3 detected.');
                }

                if (item?.featureShaftDataForPart?.some(shaft => shaft?.shaft_LD_ratio > 6)) {
                    tempErrorArray.push('Shaft L/D ratio > 6 detected.');
                }

                if (item?.stagingPartsDataId?.dfm_LD_ratio_turned_part > 6) {
                    tempErrorArray.push('Part exceeds safe L/D ratio.');
                }

                if (item?.featureHolesDataForPart?.some(hole => hole?.bores_diameter < 2)) {
                    tempErrorArray.push('Min hole of 2mm detected.');
                }

                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2001)) {
                    tempErrorArray.push('Unknown malfunction.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2002)) {
                    tempErrorArray.push('CAD file cannot be loaded.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2003)) {
                    tempErrorArray.push('There are no solid bodies to process.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2004)) {
                    tempErrorArray.push('A part being recognition does not contain any solids.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2101)) {
                    tempErrorArray.push('A part cannot be processed for unknown reason.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2102)) {
                    tempErrorArray.push('Thickness DFM check failed.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2103)) {
                    tempErrorArray.push('Clearance DFM check failed.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2104)) {
                    tempErrorArray.push('Accessibility check for faces/edges failed.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2201)) {
                    tempErrorArray.push('Sharp corners detected (unless specified in the request, sharp corners are manufactured with 5/64" (2 mm) min radii).');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2202)) {
                    tempErrorArray.push('Access to a face is obstructed by other features in the model, which may result in machining challenges. Engineering review is required to evaluate and determine the appropriate machining accessibility.');
                }

                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2203)) {
                    tempErrorArray.push('Edge access is blocked by neighboring features. Engineering review is required to assess CNC machining feasibility.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2204)) {
                    tempErrorArray.push('Wall thickness design issue detected.');
                }
                if (item?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2205)) {
                    tempErrorArray.push('Wall clearance design issue detected.');
                }

            }

            if (item?.stagingPartsDataId?.dfm_isAssembly_bypass == false) {
                tempErrorArray.push('An assembly file has been detected');
            }


            setErrorShowArray(tempErrorArray);
        }

    }, [item, selectedProcess]);


    if (isViewerShow) {
        return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50 p-4 sm:p-6 overflow-hidden" style={{ zIndex: 101 }}>
            <div className="relative w-full max-w-[1800px] h-full max-h-[95vh] bg-white p-4 sm:p-6 rounded-lg shadow-lg flex flex-col overflow-hidden">
                <div className="flex-grow flex flex-wrap gap-4 overflow-hidden">
                    <div id="cad-analyzer" className="cad-viewer flex-1 min-w-[300px]  border overflow-auto !h-[100%]"></div>
                    <div className="border overflow-auto w-full sm:w-[450px] max-h-[85vh] p-4 rounded-lg flex flex-col">
                        <h3 className="text-center font-bold mb-4">File Analysis</h3>
                        <ul className="part-details-list space-y-3 flex-grow overflow-y-auto">
                            <li className="flex text-sm justify-between items-center">
                                <span
                                    className="truncate max-w-xs inline-block hover:tooltip"
                                    title={item?.selectedFile?.originalname || "File name missing."}
                                >
                                    {(
                                        (item?.selectedFile?.originalname || "File name missing.")
                                            .slice(0, 25) +
                                        ((item?.selectedFile?.originalname || "File name missing.").length > 25 ? "..." : "")
                                    )}
                                </span>
                                <span className="flex items-center space-x-2">
                                    <span className={`transition-colors ${isInchSelected ? "text-gray-500" : "font-semibold text-black"}`}> (mm) </span>
                                    <Switch
                                        styles={{
                                            track: { backgroundColor: isInchSelected ? "#2B96DC" : "white" },
                                            trackChecked: { backgroundColor: "#2B96DC" },
                                            button: { backgroundColor: !isInchSelected ? "#2B96DC" : "white" },
                                        }}
                                        className={`custom-switch w-8 h-5 border rounded-full transition-all ${!isInchSelected && "border-primaryColor"}`}
                                        value={isInchSelected?.toString()}
                                        onChange={() => setIsInchSelected(!isInchSelected)}
                                        aria-label="Toggle between mm and inch"
                                    />
                                    <span className={`transition-colors ${isInchSelected ? "font-semibold text-black" : "text-gray-500"}`}> (inch) </span>
                                </span>
                            </li>
                            <li className="flex text-sm justify-between mt-2">
                                {item?.stagingPartsDataId && (
                                    <div>
                                        <span>
                                            {(!isInchSelected ? item?.stagingPartsDataId?.bboxDx_mm : item?.stagingPartsDataId?.bboxDx_in)} X
                                            {(!isInchSelected ? item?.stagingPartsDataId?.bboxDy_mm : item?.stagingPartsDataId?.bboxDy_in)} X
                                            {(!isInchSelected ? item?.stagingPartsDataId?.bboxDz_mm : item?.stagingPartsDataId?.bboxDz_in)}
                                            {isInchSelected ? " (inch)" : " (mm)"}
                                        </span>
                                    </div>
                                )}
                            </li>
                            <h1 className="font-bold text-center mt-4">DFM Analysis</h1>
                            {errorShowArray.length > 0 ? (
                                errorShowArray.map((item, index) => (
                                    <li key={index} className="py-2 border px-2 flex gap-x-2 items-center text-orange-500">
                                        <WarningIcon className="h-4 me-2" />
                                        <span className="text-sm text-slate-500">{item}</span>
                                    </li>
                                ))
                            ) : (
                                <li className="py-2 border px-2 flex gap-x-2 items-center text-green-500">
                                    <SuccessIcon className="h-4 me-2" />
                                    <span className="text-sm text-slate-500">No DFM warnings.</span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <button className="bg-gray-500 text-white px-6 py-2 rounded-lg" onClick={() => setIsViewerShow(false)}>Back</button>
                </div>
            </div>
        </div>)
    }


    return (
        <td className='items-center justify-center flex'>
            {(() => {
                const ext = isValidFileName(item?.selectedFile?.originalname);
                if (ext) {
                    return <div
                        onClick={() => {
                            handleViewerClick(
                                item?.selectedFile
                            )
                        }
                        }
                        className="flex flex-col !cursor-pointer "
                    >
                        {!isViewerLoading ?
                            item?.thumbnail ?
                                selectedProcess <= 2 ?
                                    <>
                                        <div className="flex flex-col items-center justify-center gap-2">
                                            <img
                                                className="thumbnail "
                                                src={item?.thumbnail}
                                            />
                                            {selectedProcess == 1 ?
                                                <>
                                                    <button className={` border-btn font-bold  ${errorShowArray.length == 0 ? "text-green-500" : "text-red-500"}  text-center   !text-[11px] flex gap-x-1 place-items-center`}>
                                                        <span>Analyze</span>
                                                        <span className="flex items-center">
                                                            <span>DFM</span>
                                                            {
                                                                errorShowArray.length == 0 ?
                                                                    <span>
                                                                        <CheckIconDFM
                                                                            additionalClasses={'!h-3 '} />
                                                                    </span> : <span>
                                                                        <CircleAlertDFM
                                                                            additionalClasses={'!h-3 '}
                                                                        />
                                                                    </span>
                                                            }
                                                        </span>
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button className={` border-btn font-bold  text-black  text-center justify-center  !text-[11px] flex gap-x-1 place-items-center`}>
                                                        <span>Viewer</span>
                                                    </button>
                                                </>
                                            }

                                        </div>
                                    </> :
                                    <div className=" w-24 flex flex-col items-center">
                                        <img
                                            className="h-14 "
                                            src={item?.thumbnail}
                                        />
                                        <p className={`font-bold  text-black  text-center justify-center  !text-[11px] flex gap-x-1 place-items-center`}>
                                            <span>Viewer</span>
                                        </p>
                                    </div>
                                : isValidThreeDFile ?
                                    <>
                                        <div className=" w-24 flex flex-col items-center">
                                            <img
                                                className="h-14 "
                                                src={item?.thumbnail}
                                            />
                                            <p className={`font-bold  text-black  text-center justify-center  !text-[11px] flex gap-x-1 place-items-center `}>
                                                <span>Viewer</span>
                                            </p>
                                        </div>
                                    </> :
                                    <>
                                    </> :
                            <div >
                                <LucidLoader additionalClass={'text-primaryColor'} />
                            </div>
                        }
                    </div>
                }
                return <div></div>;
            })()}
        </td >
    )
}

export default ThreeDViewerComponent