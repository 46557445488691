import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";

const EditProjectName = ({ show, currentName, onSave, onCancel }) => {

    console.log("Current ProjectName :", currentName);
    const [refIdInProjectName, setRefIdInProjectName] = useState(currentName?.split("_")[0] || "");
    const [projectName, setProjectName] = useState(currentName?.split("_").slice(1).join("_") || "");


    if (!show) return null;

    const handleSave = () => {
        if (projectName.trim()) {
            onSave(`${refIdInProjectName}_${projectName}`);
        }
    };

    const handleCancel = () => {
        onCancel(currentName);
    };

    const handleClose = () => {
        
    }

    return (
        <>
        {/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-md w-[400px] h-64 justify-between">
                <h3 className="text-lg font-semibold text-gray-700 mb-2 text-left">Edit Project Name</h3>
                <div className="flex items-center">
                    <input
                        type="text"
                        value={refIdInProjectName}
                        placeholder="Enter new project name"
                        disabled
                        className="w-2/5 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
                    />
                    <span>_</span>
                    <input
                        type="text"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        placeholder="Enter new project name"
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
                    />
                </div>
                <div className="flex justify-end gap-2 mt-4 w-full">
                    <button
                        onClick={handleCancel}
                        className="px-4 py-2 text-sm text-gray-500 bg-gray-200 rounded-md hover:bg-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div> */}
        <Modal 
            show={show} 
            onHide={handleCancel} 
            centered
            className="edit-project-name-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="sub-header">Edit Project Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    maxLength={50}
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder="Enter new project name"
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="border-btn"
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    className="primary-btn"
                    onClick={handleSave}
                    disabled={!projectName.trim()}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default EditProjectName;
