import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

// To be create and used later in admin quotation page.
export const fetchAllQuotationVersionByQuotationId = async (quotationId) => {
    try {
        const response = await axiosInstance.get(`/api/v2/common/all-quotation-version/${quotationId}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to create or update organization setup');
    }
}

export const downloadQuotationPdf = async (data) => {
    try {
        const response = await axiosInstance.post(`/api/v2/common/quotation/download-quotation-pdf`, data, {
            responseType: 'blob',
        });

        return response;
    } catch (error) {
        console.error('Error downloading PDF:', error);
        throw new Error('Failed to download the quotation PDF. Please try again later.');
    }
};



export const fetchOrganzationDetails = async () => {
    try {
        const response = await axiosInstance.get('/api/v2/common/masters/organization');
        return response.data;

    } catch (error) {
        console.log('Error fetching organization details:', error);
        throw new Error('Failed to fetch organization details');
    }
}