import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Form, Modal, Button, ListGroup } from "react-bootstrap";
import { deleteTwoDFile, fetchApi, uploadTwoDFile } from "../../../../utils/actions/commanActions";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import downloadFileFromS3, {
  currencyFormatter,
  numberInputOnWheelPreventChange,
  roundToTwoDigits,
} from "../../../../utils/helper";
import { DeleteIcon, DownloadIcon, LucidLoader } from "../../../../components/Icons";
import { getAllMachineDataWithPopulation } from "../../../../utils/actions/adminActions";
import { miliMeterToInchFactor } from "../../../../utils/constant";
import { useCreateQuoteContext } from "../../../../context/create.quote.context";
import "./AutomatedProcessLinesUI.css";
import Switch from "react-input-switch";
import { Select } from "antd";
import ThreeDViewerComponent from "../../../../components/ViewerComponent/ThreeDViewerComponent";

const AutomatedProcessLinesUI = (props) => {

  console.log("Props :", props);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const { setSameForAllField, commanValues, handleQuoteUpdate } = props;
  const { masterThreeDTechnologyData } = props;

  let { quoteId } = useParams();
  const token = localStorage.getItem("Token");
  const [threeDTechnologyData, setThreeDTechnologyData] = useState(masterThreeDTechnologyData || []);

  console.log("masterThreeDTechnologyData :", masterThreeDTechnologyData);
  console.log("threedTechData :", threeDTechnologyData);

  useEffect(() => {
    if (Array.isArray(masterThreeDTechnologyData)) {
      masterThreeDTechnologyData.sort((a, b) => a.name.localeCompare(b.name));
      setThreeDTechnologyData(masterThreeDTechnologyData)
    }
  }, [masterThreeDTechnologyData, props?.selectedProcess])



  const [materialData, setMaterialData] = useState([]);
  const [surfaceFinishData, setSurfaceFinishData] = useState([]);
  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(true);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const [allTechData, setAllTechData] = useState(false);
  const [allMaterialData, setAllMaterialData] = useState(false);
  const [allSurfaceFinishData, setAllSurfaceFinishData] = useState(false);


  //cnc
  const [selectedMachineData, setSelectedMachineData] = useState(null);
  const [materialGradeData, setMaterialGradeData] = useState(null);
  const [selectedMaterialName, setSelectedMaterialName] = useState("");




  const [selectedGradeName, setSelectedGradeName] = useState("");

  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [heatTreatment, setHeatTreatMent] = useState([]);
  const [allToleranceData, setAllToleranceData] = useState([]);
  const [internalCorner, setInternalCorner] = useState([]);
  const [reviewDesign, setReviewDesign] = useState([]);
  const [partMarking, setPartMarking] = useState([]);


  const [errorShowArray, setErrorShowArray] = useState([]);

  const [isEditingActivatedCnc, setisEditingActivatedCnc] = useState(false);
  const [isEditingActivatedThreeD, setIsEditingActivatedThreeD] = useState(false);

  const [isTwoDFileUploading, setIsTwoDFileUploading] = useState(false);

  createQuoteState


  useEffect(() => {

    if (props.singleValue) {
      const tempErrorArray = [];
      if (props.singleValue?.masterPartsDataThreeDId && props?.selectedProcess == 2) {
        const {
          threeD_machine_dimension_check_bypass,
          threeD_check_unrecognised_features_bypass,
          threeD_check_dfm_thicknessAnalysis_minThickness,
          threeD_check_bores,

        } = props.singleValue?.masterPartsDataThreeDId;
        if (!threeD_machine_dimension_check_bypass) {
          tempErrorArray.push('Dimensions are out of machine platform size, review required');
        }
        // if (!threeD_check_unrecognised_features_bypass) {
        //   tempErrorArray.push('Unrecognised feature detected');
        // }
        // if (threeD_check_dfm_thicknessAnalysis_minThickness) {
        //   tempErrorArray.push('Minimum thickness < 1mm detected,  review required');
        // }
        if (threeD_check_bores) {
          tempErrorArray.push('Min bore / hole dia < 2mm detected, review required.');
        }
      }

      if (props?.singleValue?.stagingPartsDataId && props.singleValue?.masterPartsDataCNCId && props?.selectedProcess == 1) {


        const { type } = props?.singleValue?.stagingPartsDataId;


        const {
          cnc_check_X_dimension_check,
          cnc_check_Y_dimension_check,
          cnc_check_Z_dimension_check,
          cnc_check_unrecognised_features_absent
        } = props.singleValue?.masterPartsDataCNCId;
        console.log("Type :", type);


        console.log("cnc_check_unrecognised_features_absent :", cnc_check_unrecognised_features_absent);
        console.log("tempErrorArray :", tempErrorArray);
        if (type == 'CNC_MILLING') {
          if (!cnc_check_X_dimension_check || !cnc_check_Y_dimension_check || !cnc_check_Z_dimension_check) {
            tempErrorArray.push("The part exceeds standard machine's bed size - Review required");
          }
        }
        else if (type == 'CNC_LATHE' || type == 'CNC_LATHE_MILLING') {
          if (!cnc_check_X_dimension_check || !cnc_check_Y_dimension_check || !cnc_check_Z_dimension_check) {
            tempErrorArray.push("The part exceeds standard machine's bed size - Review required");
          }
        }
        if (!cnc_check_unrecognised_features_absent) {
          tempErrorArray.push('3D surfaces detected. A 5-axis machining strategy may be necessary - Review required');
        }

        if (props?.singleValue?.featureAxisBoresDataForPart?.some(axisBores => axisBores?.axisbores_ratio > 3)
          || props?.singleValue?.featureHolesDataForPart?.some(hole => hole?.bores_ratio > 3)
        ) {
          tempErrorArray.push('Bore (hole) length/diameter ratio > 3 detected.');
        }

        if (props?.singleValue?.featureHolesDataForPart?.some(hole => hole?.counterbores_ratio > 3)) {
          tempErrorArray.push('Counterbore length/diameter ratio > 3 detected.');
        }

        if (props?.singleValue?.featureShaftDataForPart?.some(shaft => shaft?.shaft_LD_ratio > 6)) {
          tempErrorArray.push('Shaft L/D ratio > 6 detected.');
        }

        if (props?.singleValue?.stagingPartsDataId?.dfm_LD_ratio_turned_part > 6) {
          tempErrorArray.push('Part exceeds safe L/D ratio.');
        }

        if (props?.singleValue?.featureHolesDataForPart?.some(hole => hole?.bores_diameter < 2)) {
          tempErrorArray.push('Min hole of 2mm detected.');
        }

        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2001)) {
          tempErrorArray.push('Unknown malfunction.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2002)) {
          tempErrorArray.push('CAD file cannot be loaded.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2003)) {
          tempErrorArray.push('There are no solid bodies to process.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2004)) {
          tempErrorArray.push('A part being recognition does not contain any solids.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2101)) {
          tempErrorArray.push('A part cannot be processed for unknown reason.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2102)) {
          tempErrorArray.push('Thickness DFM check failed.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2103)) {
          tempErrorArray.push('Clearance DFM check failed.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2104)) {
          tempErrorArray.push('Accessibility check for faces/edges failed.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2201)) {
          tempErrorArray.push('Sharp corners detected (unless specified in the request, sharp corners are manufactured with 5/64" (2 mm) min radii).');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2202)) {
          tempErrorArray.push('Access to a face is obstructed by other features in the model, which may result in machining challenges. Engineering review is required to evaluate and determine the appropriate machining accessibility.');
        }

        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2203)) {
          tempErrorArray.push('Edge access is blocked by neighboring features. Engineering review is required to assess CNC machining feasibility.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2204)) {
          tempErrorArray.push('Wall thickness design issue detected.');
        }
        if (props?.singleValue?.semanticCodeWarningDataForPart?.some(warning => warning?.code == 2205)) {
          tempErrorArray.push('Wall clearance design issue detected.');
        }

      }

      if (props?.singleValue?.stagingPartsDataId?.dfm_isAssembly_bypass == false) {
        tempErrorArray.push('An assembly file has been detected');
      }


      setErrorShowArray(tempErrorArray);
    }

  }, [props.singleValue, props?.selectedProcess]);

  console.log("SingelValue", props?.singleValue)



  const keyFirst = props.selectedProcess == 1 ? 'masterPartsDataCNCId' : 'masterPartsDataThreeDId';
  const keySecond = props.selectedProcess == 1 ? 'cnc_part_show_cost' : 'threeD_part_show_cost';

  // console.log("Selected Machine :", selectedMachineData);


  //error useEffect
  useEffect(() => {
    (async () => {
      try {
        const response = await getAllMachineDataWithPopulation();
        if (!response || !response.success) {
          throw new Error();
        };
        const { data } = response;
        let selectedMachine;
        if (props?.singleValue?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_MILLING'?.toLowerCase()) {
          selectedMachine = data?.find((item) => item?.cnc_machine?.toLowerCase() == 'CNC_MILLING'?.toLowerCase())
        }
        else if (props?.singleValue?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE'?.toLowerCase()) {
          selectedMachine = data?.find((item) => item?.cnc_machine == 'CNC_MILLING')
        }
        else {
          selectedMachine = data?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
        }
        console.log("Selected Machine For Cnc :", selectedMachine);
        setSelectedMachineData(selectedMachine);

      } catch (error) {
        console.log('Error at the following', error)
        toast.error("Failed to fetch CNC machines data. Please refresh and try again.");
      }
    })()


  }, [props?.singleValue?.stagingPartsDataId?.type])

  //cnc use Effect Start
  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 2) return;
    if (selectedMachineData) {
      const materialGradeObject = {};
      const materials = selectedMachineData?.cnc_materials;
      if (Array.isArray(materials)) {
        materials.forEach((item) => {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;

          if (!materialGradeObject[materialName]) {
            materialGradeObject[materialName] = [];
          }
          if (materialGrade && !materialGradeObject[materialName].includes(materialGrade)) {
            materialGradeObject[materialName].push(materialGrade);
          }
        });
        const sortedMaterialGradeObject = Object.keys(materialGradeObject)
          .sort() // Sort the keys alphabetically
          .reduce((sortedObj, key) => {
            // Sort the values of each key alphabetically
            sortedObj[key] = materialGradeObject[key].sort((a, b) => a.localeCompare(b));
            return sortedObj;
          }, {});
        console.log("Sorted Material :", sortedMaterialGradeObject);
        setMaterialGradeData(sortedMaterialGradeObject);

        //selected first key
        const firstKey = 'Aluminum';
        setSelectedMaterialName(firstKey);
      }
    }
  }, [selectedMachineData, props?.selectedProcess]);


  //cnc use Effect Start
  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 2) return;
    if (selectedMaterialName) {
      const grade = materialGradeData[selectedMaterialName][0];

      console.log("All Materials Grade :", materialGradeData[selectedMaterialName]);
      if (grade) {
        setSelectedGradeName(grade);
      }
    }

  }, [selectedMaterialName, props?.selectedProcess]);


  console.log("Thumbnail :", props?.values[props.index]?.thumbnail);

  useEffect(() => {

    if (selectedMachineData) {
      const materials = selectedMachineData?.cnc_materials;

      if (materials && Array.isArray(materials)) {
        for (const item of materials) {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;

          if (materialName == selectedMaterialName && selectedGradeName == materialGrade) {
            console.log("Material :", item);
            if (item?._id) {
              handleOptionChange(1, item?._id);
            }
            break;
          }
        }
      }
    }


  }, [selectedGradeName, props.selectedProcess]);



  useEffect(() => {
    if (props?.singleValue?.cncMaterial && selectedMachineData) {
      const materials = selectedMachineData?.cnc_materials;
      if (materials && Array.isArray(materials)) {
        const selectedMaterial = materials.find((item) => item?._id == props?.singleValue?.cncMaterial);
        if (selectedMaterial) {
          console.log("Selected Material :", selectedMaterial);
          const allSurfaceFinishData = selectedMaterial?.cnc_surface_finish;
          const allHeatTreatMentData = selectedMaterial?.cnc_heat_treatment;
          const allToleranceData = selectedMaterial?.cnc_tolerance;
          const allInternalCornerData = selectedMaterial?.cnc_internal_corners;
          const allReviewDesignData = selectedMaterial?.cnc_review_my_design;
          const allPartMarkingData = selectedMaterial?.cnc_part_marking;

          //setting data 
          allSurfaceFinishData && setSurfaceFinish(allSurfaceFinishData);
          allSurfaceFinishData.sort((a, b) => {
            return a.cnc_surface_finish.localeCompare(b.cnc_surface_finish);
          });
          const firstSfItem = allSurfaceFinishData.find((item) => item?.cnc_surface_finish?.toLocaleLowerCase() == 'As machined'.toLocaleLowerCase()) || allSurfaceFinishData[0];
          handleOptionChange(3, firstSfItem?._id);


          allHeatTreatMentData && setHeatTreatMent(allHeatTreatMentData);
          // cnc_heat_treatment
          allHeatTreatMentData.sort((a, b) => {
            return a.cnc_heat_treatment.localeCompare(b.cnc_heat_treatment);
          });
          const firstHeatTreatMentItem = allHeatTreatMentData.find((item) => item?.cnc_heat_treatment?.toLocaleLowerCase() == 'Not Required'.toLocaleLowerCase()) || allHeatTreatMentData[0];
          handleOptionChange(4, firstHeatTreatMentItem?._id);



          allToleranceData && setAllToleranceData(allToleranceData);
          // cnc_tolerance
          allToleranceData.sort((a, b) => {
            return a.cnc_tolerance.localeCompare(b.cnc_tolerance);
          });


          const firstToleranceItem = allToleranceData[0];

          handleOptionChange(5, firstToleranceItem?._id);



          allInternalCornerData && setInternalCorner(allInternalCornerData);
          // cnc_internal_corner
          // allInternalCornerData.sort((a, b) => {
          //   return a.cnc_internal_corner.localeCompare(b.cnc_internal_corner);
          // });

          const firstInternalCornerItem = allInternalCornerData[0];

          handleOptionChange(7, firstInternalCornerItem?._id);

          allReviewDesignData && setReviewDesign(allReviewDesignData);


          const firstReviewDesignData = allReviewDesignData.find(item => item.cnc_design_review == false);

          handleOptionChange(6, firstReviewDesignData?._id);

          allPartMarkingData && setPartMarking(allPartMarkingData);


          const firstPartMarkingData = allPartMarkingData.find(item => item.cnc_part_marking == false);

          handleOptionChange(15, firstPartMarkingData?._id);

        }
      }
    }

  }, [props?.singleValue?.cncMaterial, selectedMachineData, props?.selectedProcess]);

  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 2 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.cncMaterial && isFirstTimeLoaded && selectedMachineData && quoteId) {
      setIsFirstTimeLoaded(false);
      const materials = selectedMachineData?.cnc_materials;
      if (materials && Array.isArray(materials)) {
        for (const item of materials) {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;
          if (item?._id == props?.singleValue?.cncMaterial) {
            setSelectedMaterialName(materialName);
          }
        }
      }
    }
  }, [props?.singleValue?.cncMaterial, isFirstTimeLoaded, selectedMachineData, quoteId, props?.selectedProcess]);

  //threeD useEffect Start
  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1) return;
    if (threeDTechnologyData && Array.isArray(threeDTechnologyData) && threeDTechnologyData.length) {
      const allMaterialSet = {}
      const surfaceFinishData = {}

      threeDTechnologyData.forEach((tech) => {
        const { machines } = tech;
        if (machines && Array.isArray(machines)) {
          machines.forEach((machine) => {
            const { materials } = machine;
            if (materials && Array.isArray(materials)) {
              materials.forEach((material) => {
                let { name, postProcess } = material;
                name.trim();
                if (!allMaterialSet[name]) {
                  allMaterialSet[name] = material;
                }
                if (postProcess && Array.isArray(postProcess)) {
                  postProcess.forEach((postProcess) => {
                    let { name } = postProcess;
                    name.trim();
                    if (!surfaceFinishData[name]) {
                      surfaceFinishData[name] = postProcess;
                    }
                  })
                }
              })
            }

          })


        }
      })
      setAllMaterialData(Object.values(allMaterialSet));
      setAllSurfaceFinishData(Object.values(surfaceFinishData));
    }

  }, [threeDTechnologyData, props?.selectedProcess]);


  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;

    if (!props?.singleValue?.threeDTechnology && !props?.singleValue?.threeDMachine && !props.singleValue?.threeDMaterial && isValidFileName(
      props?.files[props.index]?.originalname ||
      props?.files[props.index]?.name ||
      props?.files[props.index]?.selectedFile?.originalname ||
      props?.singleValue?.selectedFile?.filename ||
      props?.files[props.index]?.selectedFile?.path ||
      props?.singleValue?.selectedFile?.path
    )) {
      const x = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDx_mm)
      const y = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDy_mm)
      const z = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDz_mm)

      console.log('X Dim is :', x);
      console.log('Y Dim is :', y);
      console.log("Z Dim is :", z);


      let isFoundTech = false;

      if (x && y && z) {
        // Create a local copy and sort it to bring "mjf" to the first position
        const sortedTechnologyData = [...threeDTechnologyData].sort((a, b) => {
          return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
        });

        OuterLoop:
        for (const tech of sortedTechnologyData) {
          const { machines, _id } = tech;
          for (const machine of machines) {
            const { dimensions: { xDim, yDim, zDim } } = machine;
            const machinesDimensions = [xDim, yDim, zDim];
            machinesDimensions.sort((a, b) => a - b);
            const partsDimensions = [x, y, z].map(parseFloat);

            partsDimensions.sort((a, b) => a - b);
            console.log('machinesDimensions', machinesDimensions);
            console.log('partsDimensions', partsDimensions);

            if (
              machinesDimensions[0] >= partsDimensions[0]
              && machinesDimensions[1] >= partsDimensions[1]
              && machinesDimensions[2] >= partsDimensions[2]
            ) {
              props.updateValueOnChange(
                props.index,
                "threeDTechnology",
                _id,
                getFileExtension(props?.files[props?.index]?.name)
              );
              props.updateValueOnChange(
                props.index,
                "isOutOfSpec",
                false,
              );
              isFoundTech = true;
              break OuterLoop;
            }
          }
        }


        if (!isFoundTech) {
          const sortedTechnologyData = [...threeDTechnologyData].sort((a, b) => {
            return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
          });

          OuterLoop:
          for (const tech of sortedTechnologyData) {
            const { machines, _id } = tech;
            for (const machine of machines) {
              const { dimensions: { xDim, yDim, zDim } } = machine;
              const machinesDimensions = [xDim, yDim, zDim];
              machinesDimensions.sort((a, b) => a - b);
              const partsDimensions = [x, y, z].map(parseFloat);

              partsDimensions.sort((a, b) => a - b);
              console.log('machinesDimensions', machinesDimensions);
              console.log('partsDimensions', partsDimensions);

              if (
                machinesDimensions[0] >= partsDimensions[0]
                && machinesDimensions[1] >= partsDimensions[1]
                && machinesDimensions[2] >= partsDimensions[2]
              ) {
                props.updateValueOnChange(
                  props.index,
                  "threeDTechnology",
                  _id,
                  getFileExtension(props?.files[props?.index]?.name)
                );
                props.updateValueOnChange(
                  props.index,
                  "isOutOfSpec",
                  false,
                );
                isFoundTech = true;
                break OuterLoop;
              }
            }
          }
        }


      }

      console.log("isFoundTech", isFoundTech);

      if (!isFoundTech) {
        props.updateValueOnChange(
          props.index,
          "isOutOfSpec",
          true,
        );
      }
    }
  }, [threeDTechnologyData, props?.selectedProcess]);




  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.threeDTechnology &&
      isValidFileName(
        props?.files[props.index]?.originalname ||
        props?.files[props.index]?.name ||
        props?.files[props.index]?.file?.originalname ||
        props?.singleValue?.file?.filename ||
        props?.files[props.index]?.selectedFile?.path ||
        props?.singleValue?.file?.path
      )) {
      const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      if (!selectedTechnology) return; // check this with pratyush
      const { machines } = selectedTechnology;
      const materialSet = {};
      let selectedThreeDMachine = "";


      const x = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDx_mm)

      const y = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDy_mm)

      const z = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDz_mm)

      const volumeInCC = parseFloat(Number(props?.singleValue?.stagingPartsDataId?.totalVolume_mm3) / 1000);

      machines?.forEach((machine) => {
        const { materials } = machine;
        const { dimensions: { xDim, yDim, zDim }, _id: machineId } = machine;
        const machinesDimensions = [xDim, yDim, zDim];
        machinesDimensions.sort((a, b) => a - b);
        const partsDimensions = [x, y, z];
        partsDimensions.sort((a, b) => a - b);
        if (
          (machinesDimensions[0] >= partsDimensions[0]
            && machinesDimensions[1] >= partsDimensions[1]
            && machinesDimensions[2] >= partsDimensions[2]) || (props?.singleValue?.isOutOfSpec === true && machinesDimensions[0] == 0 || machinesDimensions[1] == 0 && machinesDimensions[2] == 0)

        ) {
          selectedThreeDMachine = machineId;
          materials?.forEach((material) => {
            const nameOfMaterial = material?.name;
            (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
          })

        }

      });
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMachine",
        selectedThreeDMachine,
      );
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      allMaterial.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialData(allMaterial);
      if (
        x && y && z && volumeInCC
      ) {
        let localValues = [...props.values];
        let updatedValues = localValues.map((item, idx) => {
          if (idx == props.index) {
            item.XDimensionValue = x;
            item.YDimensionValue = y;
            item.ZDimensionValue = z;
            item.partVolume = volumeInCC;
          }
          return item;
        });
        props.setValues(updatedValues, createQuoteDispatch, createQuoteState)
      }

    }
    else if (props?.singleValue?.threeDTechnology && threeDTechnologyData.length) {
      const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      // console.log("Selected Technology", selectedTechnology);
      const { machines } = selectedTechnology;
      const materialSet = {};
      machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          const nameOfMaterial = material?.name;
          (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
        })

      });
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      allMaterial.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialData(allMaterial);
    }

  }, [props?.singleValue?.threeDTechnology, props?.singleValue?.isOutOfSpec, threeDTechnologyData, props?.selectedProcess]);

  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.threeDMaterial) {
      // console.log('ThreeDMaterial :', props?.singleValue?.threeDMaterial);
      const materialId = props?.singleValue?.threeDMaterial;
      const techId = props?.singleValue?.threeDTechnology;
      let postProcessData = [];

      const selectedTech = threeDTechnologyData?.find(({ _id }) => _id == techId);
      selectedTech?.machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          if (material?._id == materialId) {
            const { postProcess } = material;
            postProcessData = postProcess;
          }
        })
      })
      if (postProcessData.length) {
        // console.log("Post Data", postProcessData);

        const allPostProcess = postProcessData;
        const firstPostProcessObject = allPostProcess.filter((item) => !item?.name?.toLowerCase().includes("custom")).reduce((minCostObject, item) => {


          return minCostObject?.costPerCC1 < item?.costPerCC1 ? minCostObject : item;
        }, allPostProcess[0]);
        const firstPostProcessData = firstPostProcessObject?._id;
        props.updateValueOnChange(
          props.index,
          "threeDPostProcessing",
          firstPostProcessData
        );
        allPostProcess.sort((a, b) => a.name.localeCompare(b.name));

        setSurfaceFinishData(allPostProcess);
      }
    }
  }, [props?.singleValue?.threeDMaterial, props?.singleValue?.isOutOfSpec, props?.selectedProcess, threeDTechnologyData]);





  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.isOutOfSpec) {
      const updatedTechData = masterThreeDTechnologyData?.map((tech) => {
        const { machines } = tech;

        const filteredMachines = machines.filter(({ dimensions: { xDim, yDim, zDim } }) => {
          return xDim == 0 && yDim == 0 && zDim == 0;
        });
        if (filteredMachines.length) {
          return { ...tech, machines: filteredMachines };
        };
        return null;

      }).filter((item) => item != null);

      if (updatedTechData?.length) {
        const firstTechId = updatedTechData[0]?._id;
        const selectedThreeDMachine = updatedTechData[0].machines[0]?._id;
        props.updateValueOnChange(
          props.index,
          "threeDTechnology",
          firstTechId,
        );
        props.updateValueOnChange(
          props.index,
          "threeDMachine",
          selectedThreeDMachine,
        );
      }


      console.log("Updated Tech Data In UseEffect Out of Spec Is True :", updatedTechData);
      updatedTechData.sort((a, b) => a.name.localeCompare(b.name));

      setThreeDTechnologyData(updatedTechData);
    }
    else if (!props.singleValue?.isOutOfSpec) {
      const updatedTechData = masterThreeDTechnologyData?.map((tech) => {
        const { machines } = tech;

        const filteredMachines = machines.filter(({ dimensions: { xDim, yDim, zDim } }) => {
          return xDim != 0 && yDim != 0 && zDim != 0;
        });
        if (filteredMachines.length) {
          return { ...tech, machines: filteredMachines };
        };
        return null;

      }).filter((item) => item != null);

      console.log("Updated Tech Data In UseEffect Out of Spec Is false :", updatedTechData);
      updatedTechData.sort((a, b) => a.name.localeCompare(b.name));

      setThreeDTechnologyData(updatedTechData);
    }
  }, [props?.singleValue?.isOutOfSpec, props?.selectedProcess]);





  let indexFor2DFiles = props.index;



  const handleQtyChange = (value) => {
    try {
      let percent = 0;
      props.updateValueOnChange(props.index, "Qty", parseInt(value), percent);
    } catch (err) {
      return err;
    }
  };


  const handle2DFileUpload = async (e) => {
    try {
      const file = e.target.files[0];

      if (!file) {
        console.log("No file uploaded");
        return;
      }

      if (!quoteId) {
        toast.error("Invalid quotation. Please refresh the page and try again.");
        return;
      }

      const fileSizeInMB = file.size / (1024 * 1024);

      if (file.size < 1024 || fileSizeInMB > 100) {
        toast.error("File size must be between 1 KB and 100 MB.");
        return;
      };

      console.log("Uploaded File Is:", file);
      setIsTwoDFileUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("quoteId", quoteId);
      formData.append("indexOfPartsData", indexFor2DFiles);
      const response = await uploadTwoDFile(formData);

      if (!response || !response.success) {
        toast.error("Failed to upload file. Please try again.");
        return;
      };
      toast.success("File uploaded successfully");

      const { data } = response;
      props.updateValueOnChange(indexFor2DFiles, 'twoDFile', data);




    } catch (err) {
      console.log("Error:", err);
    } finally {
      setIsTwoDFileUploading(false);
    }
  };


  const handleDelete2DFiles = async () => {
    try {
      const bodyData = {
        quoteId,
        indexOfPartsData: indexFor2DFiles,
      }
      const response = await deleteTwoDFile(bodyData);
      if (!response || !response.success) {
        throw new Error();
      }
      const { data } = response;
      toast.success("File Deleted successfully");
      props.updateValueOnChange(indexFor2DFiles, 'twoDFile', data);

    } catch (error) {
      toast.error("Failed to delete file. Please try again.");
    }
  };



  useEffect(() => {
    props.updateCommanValueOnChange(
      "sameForAllField",
      props?.sameForAllField,
      getFileExtension(props?.files[props?.index]?.name)
    );
  }, [props?.files]);



  let getFileExtension = (filename) => {
    return filename?.slice(((filename?.lastIndexOf(".") - 1) >>> 0) + 2);
  };


  const handleConfig = () => {
    // return;
    if (props.selectedProcess == 1) {
      setisEditingActivatedCnc(true);
    }
    else if (props.selectedProcess == 2) {
      setIsEditingActivatedThreeD(true);
    }
    props.updateCommanValueOnChange('isQuoteChanged', true);

  };

  useEffect(() => {
    if (quoteId && !createQuoteState.commanValues.isQuoteChanged) {
      setisEditingActivatedCnc(false);
      setIsEditingActivatedThreeD(false);
    }
  }, [createQuoteState.commanValues.isQuoteChanged])


  const fetchDataFromApi = (endPoint, payload = []) => {
    console.log("fetchDataFromApi", endPoint, payload);
    return new Promise(async (resolve, reject) => {
      try {
        console.log('cam ehr eddf')
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const handleOptionChange = (type, value) => {
    console.log("type :", type);
    console.log("Value :", value);
    try {
      switch (type) {

        case 1:
          props.updateValueOnChange(
            props.index,
            "cncMaterial",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;
        case 3:
          props.updateValueOnChange(
            props.index,
            "cncSurfaceFinish",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 4:
          props.updateValueOnChange(
            props.index,
            "cncHeatTreatment",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 5:
          props.updateValueOnChange(
            props.index,
            "cncTolerance",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 6:
          props.updateValueOnChange(
            props.index,
            "cncReviewMyDesign",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 7:
          props.updateValueOnChange(
            props.index,
            "cncInternalCorner",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 8:
          // props.updateCommanValueOnChange('sameForAllField', !props?.commanValues?.sameForAllField, getFileExtension(props?.files[props?.index]?.name)); commenting for same for all field
          props.updateCommanValueOnChange(
            "sameForAllField",
            !props?.sameForAllField,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 10:
          props.updateValueOnChange(
            props.index,
            "finalCost",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;

        case 11:
          props.updateValueOnChange(
            props.index,
            "threeDTechnology",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );

          break;
        case 12:
          props.updateValueOnChange(
            props.index,
            "threeDMachine",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;

        case 13:
          props.updateValueOnChange(
            props.index,
            "threeDMaterial",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;

        case 14:
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;
        case 15:
          props.updateValueOnChange(
            props.index,
            "cncParkMarking",
            value,
            getFileExtension(props?.files[props?.index]?.name)
          );
          break;
        case 16:
          {
            props.updateValueOnChange(
              props.index,
              "cncMaterialName",
              value);
          }

          break;
        default:
          break;
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    try {
      if (
        props.singleValue.algorithmPrice &&
        props.singleValue.partMarking &&
        props.singleValue.partMarking !== ""
      ) {
        handleOptionChange(10);
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.singleValue.partMarking, props.singleValue.noOfThread]);



  const isValidFileName = (name) => {
    const type = getFileExtension(name)?.toUpperCase();
    if (
      type === "STP" ||
      type === "STEP" ||
      // type === "IGES" ||
      // type === "OBJ" ||
      type === "STL"
    )
      return true;

    return false;
  };



  useEffect(() => {
    let localFiles = props.values.map((item) => {
      return item.file || item.selectedFile;
    });
    props.setFiles(localFiles);
  }, []);

  return (
    <div
      className="item-card"
      key={props.keyValue}
    >
      <div className="img-col" style={{ width: commanValues?.selectedProcess === 2 ? '20%' : '27%' }}>
        {(() => {
          const ext = isValidFileName(props?.files[props.index]?.originalname || props?.files[props.index]?.name);
          if (ext) {
            return <div className="flex flex-col !cursor-pointer ">
              {!isViewerLoading ?
                props?.values[props.index]?.thumbnail || ext ?
                  <>
                    <ThreeDViewerComponent
                      item={props.singleValue}
                      selectedProcess={commanValues?.selectedProcess}
                      isValidThreeDFile={ext}
                    />
                  </>
                  :
                  <>
                    <div className="no-preview-div" style={{ height: commanValues?.selectedProcess === 2 ? "190px" : "280px" }}>
                      <p className="text">Preview not available</p>
                    </div>
                  </> :
                <div className="flex items-center justify-center mx-2">
                  <LucidLoader additionalClass={'text-primaryColor loader'} />
                </div>}
            </div>
          }
          return null;
        })()}
      </div>
      <div className="text-col" style={{ width: commanValues?.selectedProcess === 2 ? '80%' : '73%' }}>
        <div className="flex text-xs  justify-between lg:flex-row flex-wrap flex-col items-start lg:items-start -mb-2 ">
          <div className="flex md:items-start md:flex-row flex-col justify-start gap-x-3 items-start w-2/5	">
            <div className="flex flex-col left-col mt-1">
              <h6 className="text font-bold">
                <span className=" overflow-hidden whitespace-nowrap inline-block text-ellipsis">
                  {(
                    (props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name || "SamplePart.stp")
                      .slice(0, 40) +
                    ((props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name || "SamplePart.stp").length > 40 ? '...' : '')
                  )}
                </span>
              </h6>
              {isValidFileName(props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name) &&
                props.singleValue?.stagingPartsDataId &&
                (props.singleValue.stagingPartsDataId.bboxDx_mm ||
                  props.singleValue.stagingPartsDataId.bboxDy_mm ||
                  props.singleValue.stagingPartsDataId.bboxDz_mm) ? (
                <>
                  <p className="info-text">
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDx_mm : props?.singleValue?.stagingPartsDataId?.bboxDx_in)} X
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDy_mm : props?.singleValue?.stagingPartsDataId?.bboxDy_in)} X
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDz_mm : props?.singleValue?.stagingPartsDataId?.bboxDz_in)}
                    {props.isInchSelected ? " (inch)" : " (mm)"}
                    {"   "}/{"   "}
                    {props?.singleValue?.stagingPartsDataId?.totalVolume_mm3 && (
                      <>
                        {
                          props.isInchSelected
                            ? roundToTwoDigits(props?.singleValue?.stagingPartsDataId?.totalVolume_mm3 / miliMeterToInchFactor)
                            : props?.singleValue?.stagingPartsDataId?.totalVolume_mm3
                        }{" "}
                        {props.isInchSelected ? " (in³)" : " (mm³)"}
                      </>
                    )}
                  </p>
                </>
              ) : (
                <>
                </>)}
              <div>
                {props?.singleValue?.twoDFile?.originalname ? (
                  <>
                    <div className="upload-2d-file-div">
                      <a
                        onClick={() => downloadFileFromS3(props?.singleValue?.twoDFile)}
                      >
                        <span className="text-sm !text-primaryColor">
                          {props?.singleValue?.twoDFile?.originalname?.length >
                            25
                            ? (props?.singleValue?.twoDFile?.originalname).substring(0, 25) + "..."
                            : props?.singleValue?.twoDFile?.originalname}
                        </span>{" "}
                        <DownloadIcon additionalClass={'h-4 text-primaryColor'} />
                      </a>
                      {/* &nbsp;&nbsp;&nbsp; */}
                      <div onClick={handleDelete2DFiles}>
                        <DeleteIcon
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isTwoDFileUploading ? <div className="file-button !cursor-pointer relative">
                      <input
                        name="file-input-2d"
                        id={`file-input-2d-${props.index}`}
                        className="file-input__input"
                        type="file"
                        onChange={handle2DFileUpload}
                      />
                      <label
                        className="icon-btn cursor-pointer"
                        htmlFor={`file-input-2d-${props.index}`}
                      >
                        {/* {alert(props.singleValue?.twoDRequired)} */}
                        <span >Upload 2D file </span>
                        {props.singleValue?.twoDFileRequired && <span className="text-red-500  absolute  -translate-x-[70px] mt-4">(required)</span>}
                      </label>

                    </div> :
                      <div className="icon-btn upload-btn-loading">
                        <LucidLoader additionalClass={'h-4'} />
                      </div>}
                  </>
                )}
              </div>
            </div>

          </div>
          <div className="flex items-center gap-x-4">
            <div className="flex items-center justify-center gap-x-2">
              <label className="info-text" style={{ fontSize: 13 }}>Quantity</label>
              <div class="relative flex items-center max-w-[8rem]">
                <input
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  size="sm"
                  max={3}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    handleQtyChange(e.target.value);
                  }}
                  value={props.singleValue.Qty}
                  // class="bg-gray-50 border-x-0 border-red-900 h-8 text-center text-gray-900 text-sm  border block w-20 quantity-input"
                  class="quantity-input"
                  disabled={(() => {
                    if (props.selectedProcess === 1) {
                      return !isEditingActivatedCnc;
                    }
                    if (props?.selectedProcess === 2) {
                      return !isEditingActivatedThreeD
                    }
                  })()}
                />
              </div>
            </div>
            {
              ((!props?.singleValue?.price1 && !props?.singleValue?.price2 && !props?.singleValue?.price3) || (props?.singleValue[keyFirst] && !(props?.singleValue[keyFirst][keySecond]))) ? <p className="text-red-500 !me-3 ">Review Required</p> : null
            }
            {<div>
              {
                (props?.commanValues?.selectedShipMethod === 1 && props?.singleValue?.price1 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div className="flex flex-col font-medium me-2">
                    <div>{currencyFormatter(props?.singleValue?.price1)}</div>
                    <div>{currencyFormatter(props?.singleValue?.price1 * props?.singleValue?.Qty)} <span className="text-slate-500">Each</span></div>
                  </div>
                ) : (props?.commanValues?.selectedShipMethod === 2 && props?.singleValue?.price2 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div>
                    <div className="flex flex-col font-medium me-2">
                      <div>{currencyFormatter(props?.singleValue?.price2)}</div>
                      <div>{currencyFormatter(props?.singleValue?.price2 * props?.singleValue?.Qty)} <span className="text-slate-500">Each</span></div>
                    </div>
                  </div>
                ) : (props?.commanValues?.selectedShipMethod === 3 && props?.singleValue?.price3 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div>
                    <div className="flex flex-col font-medium me-2">
                      <div>{currencyFormatter(props?.singleValue?.price3)}</div>
                      <div>{currencyFormatter(props?.singleValue?.price3 * props?.singleValue?.Qty)} <span className="text-slate-500">Each</span></div>
                    </div>
                  </div>
                ) : null
              }

            </div>}
          </div>
        </div>
        <div className="form-box">
          <div className="mb-1 flex items-center gap-x-2 justify-end">
            {/* Apply to All Parts Toggle */}
            <div className="flex items-center space-x-2" style={{ visibility: "hidden" }}>
              <Switch
                styles={{
                  track: {
                    backgroundColor: '#FFFFFF'
                  },
                  trackChecked: {
                    backgroundColor: '#2995DB'
                  },
                  button: {
                    backgroundColor: '#2995DB'
                  },
                  buttonChecked: {
                    backgroundColor: '#FFFFFF'
                  }
                }}
                className="custom-switch apply-to-all-switch"
                on="true"
                value={createQuoteState?.commanValues.sameForAllField?.toString()}
                onChange={() =>
                  setSameForAllField(!createQuoteState?.commanValues.sameForAllField)
                }
                off="false"
                aria-label="Toggle apply to all parts"
                disabled={!isEditingActivatedCnc}
              />
              <span
                className={`transition-colors ${createQuoteState?.commanValues.sameForAllField
                  ? "info-text font-semibold text-black"
                  : "info-text text-gray-500"
                  }`}
              >
                Apply selected specifications to all parts
              </span>
            </div>
            <div className="flex items-center gap-x-2" style={{ minWidth: 97 }}>
              {(!isEditingActivatedCnc && props.selectedProcess == 1) || (!isEditingActivatedThreeD && props.selectedProcess == 2) ?
                <p
                  onClick={handleConfig}
                  className="link-btn edit-btn"
                >
                  Edit Configuration
                </p> :
                <></>
              }
            </div>
            {
              (deleteConfirm) ?
                <div className="flex items-center gap-x-2 align-self-start" style={{ height: 24 }}>
                  <button
                    onClick={() => setDeleteConfirm(false)}
                  >
                    <span className="text">Cancel</span>
                  </button>
                  <button
                    onClick={() => {
                      if (deleteConfirm) {
                        props.onDelete(props.index);
                        setDeleteConfirm(false);
                      }
                      else {
                        setDeleteConfirm(true);
                      }
                    }}
                  >
                    <span className="text text-red-500">Are you sure?</span>
                  </button>
                </div> :
                <div className="flex items-center gap-x-2 align-self-start justify-end" style={{ height: 24 }}>
                  <button
                    onClick={() => {
                      if (deleteConfirm) {
                        props.onDelete(props.index);
                        setDeleteConfirm(false);
                      }
                      else {
                        setDeleteConfirm(true);
                      }
                    }}
                    className="link-btn delete-btn"
                  >
                    <DeleteIcon
                      additionalClasses={"text-red-500 mx-1 cursor-pointer"}
                    />
                  </button>
                </div>
            }
          </div>
          <Row className="form-box-row">
            <Col>
              <div className="form-left">
                <Form>
                  {props.selectedProcess === 1 ? (
                    <Row>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className={"frm-label"}>
                            Materials
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            onChange={(e) => {
                              const value = e;
                              handleOptionChange(16, value);
                              setSelectedMaterialName(value);
                            }}
                            className={`text-xs -mt-1 w-full ${props?.singleValue?.errors?.MaterialType &&
                              isValidFileName(props?.files[props.index].name)
                              ? "error-bg"
                              : ""
                              }`}
                            value={selectedMaterialName}
                            disabled={!isEditingActivatedCnc}
                            required={true}
                          >
                            {materialGradeData &&
                              Object.keys(materialGradeData)?.map((singleMaterial, m) => {
                                return (
                                  <Select.Option value={singleMaterial} key={`m-${m}`}>
                                    {singleMaterial}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Group>
                        {/* <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className={"frm-label"}>
                            Materials
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              const value = e.target.value;
                              handleOptionChange(16, value);
                              setSelectedMaterialName(value);
                            }}
                            className={`text-xs -mt-1 ${props?.singleValue?.errors?.MaterialType &&
                              isValidFileName(props?.files[props.index].name)
                              ? "error-bg"
                              : ""
                              }`}
                            value={selectedMaterialName}
                            disabled={!isEditingActivatedCnc}
                            required={true}
                          >
                            {materialGradeData &&
                              Object.keys(materialGradeData)?.map((singleMaterial, m) => {
                                return (
                                  <option value={singleMaterial} key={`m-${m}`}>
                                    {singleMaterial}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group> */}
                        {props?.singleValue?.errors?.cncMaterial &&
                          !props.isInvalidFiles && (
                            <span className="error">
                              Please select Material Type
                            </span>
                          )}
                      </Col>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className={"frm-label"}>
                            Grade
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            onChange={(e) => {
                              const value = e;
                              setSelectedGradeName(value);
                            }}
                            className={`text-xs -mt-1 w-full ${props?.singleValue?.errors?.SubMaterial &&
                              isValidFileName(props?.files[props.index].name)
                              ? "error-bg"
                              : ""
                              }`}
                            value={selectedGradeName}
                            disabled={!isEditingActivatedCnc}
                          >
                            {materialGradeData && Array.isArray(materialGradeData[selectedMaterialName]) && materialGradeData[selectedMaterialName].map((singleSubMaterial, index) => {
                              return (
                                <Select.Option
                                  value={singleSubMaterial}
                                  key={`f-${index}`}
                                >
                                  {singleSubMaterial}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Group>
                        {/* <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="frm-label">
                            Grade
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              const value = e.target.value;
                              setSelectedGradeName(value);
                            }}
                            className={`text-xs -mt-1 ${props?.singleValue?.errors?.SubMaterial &&
                              isValidFileName(props?.files[props.index].name)
                              ? "error-bg"
                              : ""
                              }`}
                            value={selectedGradeName}
                            disabled={!isEditingActivatedCnc}
                          >
                            <option value="">Select Values</option>
                            {materialGradeData && Array.isArray(materialGradeData[selectedMaterialName]) && materialGradeData[selectedMaterialName].map((singleSubMaterial, index) => {
                              return (
                                <option
                                  value={singleSubMaterial}
                                  key={`f-${index}`}
                                >
                                  {singleSubMaterial}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group> */}
                        {props?.singleValue?.errors?.SubMaterial &&
                          !props.isInvalidFiles && (
                            <span className="error">
                              Please select Sub Material Type
                            </span>
                          )}
                      </Col>

                      {props.selectedProcess === 1 && (
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Surface Finish
                            </Form.Label>
                            <Select
                              showSearch
                              optionFilterProp="value"
                              className="text-xs -mt-1 w-full"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(3, e);
                              }}
                              value={props.singleValue?.cncSurfaceFinish}
                              disabled={!isEditingActivatedCnc}
                            >
                              <option value="">Select Values</option>
                              {surfaceFinish?.length > 0 &&
                                surfaceFinish.map((Item, s) => {
                                  return (
                                    <Select.Option value={Item._id} key={`s-${s}`}>
                                      {Item.cnc_surface_finish}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Group>
                        </Col>
                      )}

                      {heatTreatment.length != 0 ? <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="frm-label">
                            Heat Treatment
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            className="text-xs -mt-1 w-full"
                            onChange={(e) => {
                              handleOptionChange(4, e);
                            }}
                            value={props.singleValue?.cncHeatTreatment}
                            disabled={!isEditingActivatedCnc}
                          >
                            {/* <option value="">Select Values</option> */}
                            {heatTreatment?.map((singleTreatment, index) => {
                              return (
                                <Select.Option
                                  value={singleTreatment._id}
                                  key={`f-${index}`}
                                >
                                  {singleTreatment.cnc_heat_treatment}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Group>
                      </Col> : null}

                      {props.selectedProcess === 1 && (
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">Tolerance</Form.Label>
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(5, e);
                              }}
                              value={props.singleValue?.cncTolerance}
                              className="text-xs -mt-1 w-full"
                              disabled={!isEditingActivatedCnc}
                            >
                              <option value="">Select Values</option>
                              {allToleranceData.length > 0 &&
                                allToleranceData.map((Item, t) => {
                                  return (
                                    <Select.Option value={Item._id} key={`f-${t}`}>
                                      {Item.cnc_tolerance}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Group>
                        </Col>
                      )}

                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Internal Corner
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(7, e);
                            }}
                            value={props.singleValue?.cncInternalCorner}
                            className="text-xs -mt-1 w-full"
                            disabled={!isEditingActivatedCnc}
                          >
                            <option value="">Select Values</option>
                            {internalCorner?.length > 0 &&
                              internalCorner.map((Item, index) => {
                                return (
                                  <Select.Option key={`p-${index}`} value={Item._id}>
                                    {Item.cnc_internal_corner}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Review My Design
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(6, e);
                            }}
                            value={props.singleValue?.cncReviewMyDesign}
                            className="text-xs -mt-1 w-full"
                            disabled={!isEditingActivatedCnc}
                          >
                            <option value="">Select Values</option>
                            {reviewDesign?.length > 0 &&
                              reviewDesign.map((Item, index) => {
                                return (
                                  <Select.Option key={`p-${index}`} value={Item._id}>
                                    {Item.cnc_review_my_design ? "Yes" : "No"}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Part Marking
                          </Form.Label>
                          <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(15, e);
                            }}
                            value={props.singleValue?.cncParkMarking}
                            className="text-xs -mt-1 w-full"
                            disabled={!isEditingActivatedCnc}
                          >
                            <option value="">Select Values</option>
                            {partMarking?.length > 0 &&
                              partMarking.map((Item, index) => {
                                return (
                                  <Select.Option key={`p-${index}`} value={Item._id}>
                                    {Item.cnc_part_marking ? "Yes" : "No"}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <div className="form-left">
                          <Form.Group
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">Description</Form.Label>
                            <Form.Control
                              type="text"
                              className="text-xs -mt-1"
                              maxLength={50}
                              // placeholder="Please input the part description here (Max 30 Chars)"
                              value={props.singleValue.description}
                              onChange={(e) => {
                                try {
                                  props.updateValueOnChange(
                                    props.index,
                                    "description",
                                    e.target.value
                                  );
                                } catch (err) {
                                  return err;
                                }
                              }}
                              readOnly={!isEditingActivatedCnc}
                            />
                          </Form.Group>
                        </div>
                      </Col>

                    </Row>
                  ) :
                    <>
                      <Row  >
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Technology/Process
                            </Form.Label>
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(11, e);
                              }}
                              value={props?.singleValue?.threeDTechnology}
                              className={` text-xxs -mt-1 w-full`}
                              disabled={!isEditingActivatedThreeD}
                            >
                              <option value="">Select Values</option>
                              {threeDTechnologyData?.length > 0 &&
                                threeDTechnologyData.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Group>
                        </Col>



                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            {/* working */}
                            <Form.Label className="frm-label">
                              Material
                            </Form.Label>
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(13, e);
                              }}
                              value={props.singleValue?.threeDMaterial}
                              className={`text-xs -mt-1 w-full`}
                              disabled={!isEditingActivatedThreeD}
                            >
                              <option value="">Select Values</option>
                              {materialData?.length > 0 &&
                                materialData.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Surface Finish
                            </Form.Label>
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(14, e);
                              }}
                              value={props.singleValue?.threeDPostProcessing}
                              className={`text-xs -mt-1 w-full`}
                              disabled={!isEditingActivatedThreeD}
                            >
                              <option value="">Select Values</option>
                              {surfaceFinishData?.length > 0 &&
                                surfaceFinishData?.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.name + " : "} <span>{Item?.color != 'NA' && Item.color}</span>
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>}

                </Form>
              </div>
            </Col>
          </Row>


        </div >
      </div>
    </div >
  );
};
export default AutomatedProcessLinesUI;
