import { CREATE_QUOTE_ACTION } from './create.quote.context';

export const setProjectName = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }
    console.log("Inside Comman Values 3:", { ...createQuoteState?.commanValues, projectName: newValue });

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: { ...createQuoteState?.commanValues, projectName: newValue }
    });
};


export const updateSelectedProcess = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }
    // console.log("Inside Comman Values 4:",{
    //     ...createQuoteState?.commanValues, selectedProcess: newValue
    // });

    const certificationsObj = localStorage.getItem(newValue) ? JSON.parse(localStorage.getItem(newValue)) : {};
    const { updatedCertifications = [], updatedOrderCertificationsCost = 0 } = certificationsObj;

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: {
            ...createQuoteState?.commanValues,
            selectedProcess: newValue,
            price1: 0,
            price2: 0,
            price3: 0,
            orderCertificationsCost: updatedOrderCertificationsCost,
            certifications: updatedCertifications,
            shippingCharge1: 0,
            shippingCharge2: 0,
            shippingCharge3: 0,
            isProcessChanged: true,
            leadTime1: 0,
            leadTime2: 0,
            leadTime3: 0,


        }
    });
};

export const setSameForAllField = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }
    console.log("Inside Comman Values 5:", { ...createQuoteState?.commanValues, sameForAllField: newValue });
    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: { ...createQuoteState?.commanValues, sameForAllField: newValue }
    });
};


export const setValues = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_VALUES,
        payload: newValue
    });
}


export const setCommanValues = (newValue, createQuoteDispatch, createQuoteState) => {

    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    console.log("Inside Comman Values 1:", newValue);
    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: newValue
    });
}

export const resetCreateQuoteState = (selectedProcess, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
        payload: selectedProcess
    });
}

export const setSelectedShippingMethod = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }
    else {
        console.log("New Value : " + newValue);
    }

    console.log("Inside Comman Values 2:", { ...createQuoteState?.commanValues, selectedShipMethod: newValue });

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: { ...createQuoteState?.commanValues, selectedShipMethod: newValue }
    });
};