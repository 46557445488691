import React from "react";
import moment from "moment";

const PricingOptions = ({
    price1,
    leadTime1,
    price2,
    leadTime2,
    price3,
    leadTime3,
    shippingCharge1,
    shippingDays1,
    shippingCharge2,
    shippingDays2,
    shippingCharge3,
    shippingDays3,
    selectedShippingMethod,
    setSelectedShippingMethod
}) => {

    return (
        <div className="right-section-options">
            <div className="delivery-options">
                {(price1 && leadTime1) ? <div
                    onClick={() => setSelectedShippingMethod(1)}
                    className={`border-btn delivery-option`}
                    style={selectedShippingMethod === 1 ? { border: "2px solid #2995DB" } : { border: "1px solid #E5E5E5" }}
                >
                    <div className="title-col">
                        <p className="text">Rapid</p>
                        <p className="info-text">{leadTime1} Business days</p>
                    </div>
                    <div className="price-col">
                        <p className="text"><b>{formatPrice(price1)}</b></p>
                    </div>
                </div> : null}
                {(price2 && leadTime2) ? <div
                    onClick={() => setSelectedShippingMethod(2)}
                    className={`border-btn delivery-option`}
                    style={selectedShippingMethod === 2 ? { border: "2px solid #2995DB" } : { border: "1px solid #E5E5E5" }}
                >
                    <div className="title-col">
                        <p className="text">Standard</p>
                        <p className="info-text">{leadTime2} Business days</p>
                    </div>
                    <div className="price-col">
                        <p className="text"><b>{formatPrice(price2)}</b></p>
                    </div>
                </div> : null}
                {(price3 && leadTime3) ? <div
                    onClick={() => setSelectedShippingMethod(3)}
                    className={`border-btn delivery-option`}
                    style={selectedShippingMethod === 3 ? { border: "2px solid #2995DB" } : { border: "1px solid #E5E5E5" }}
                >
                    <div className="title-col">
                        <p className="text">Economy</p>
                        <p className="info-text">{leadTime3} Business days</p>
                    </div>
                    <div className="price-col">
                        <p className="text"><b>{formatPrice(price3)}</b></p>
                    </div>
                </div> : null}
            </div>

            {
                (
                    (shippingCharge1 && shippingDays1) || (shippingCharge2 && shippingDays2) || (shippingCharge3 && shippingDays3)
                ) ?
                    <>
                        <div className="shipping-div">
                            <div className="title-col">
                                <p className="text">Shipping</p>
                                <p className="info-text">
                                    {
                                        selectedShippingMethod == 1 ? shippingDays1 : selectedShippingMethod == 2 ? shippingDays2 : selectedShippingMethod == 3 ? shippingDays3 : null
                                    } Business days
                                </p>
                            </div>
                            <div className="price-col">
                                <p className="text">
                                    <b>
                                        {
                                            selectedShippingMethod == 1 ? formatPrice(shippingCharge1) : selectedShippingMethod == 2 ? formatPrice(shippingCharge2) : selectedShippingMethod == 3 ? formatPrice(shippingCharge3) : null
                                        }
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div className="shipping-div total-div">
                            <div className="title-col">
                                <p className="text"><b>Total</b></p>
                            </div>
                            <div className="price-col">
                                <p className="text">
                                    <b>
                                        {
                                            selectedShippingMethod == 1 ? formatPrice(price1 + shippingCharge1) : selectedShippingMethod == 2 ? formatPrice(price2 + shippingCharge2) : selectedShippingMethod == 3 ? formatPrice(price3 + shippingCharge3) : null
                                        }
                                    </b>
                                </p>
                            </div>
                        </div>
                    </> : null}
                

            {/* Estimated Delivery */}
            {leadTime1 || leadTime2 || leadTime3 ?
                <>
                    <div className="grey-btn estimated-delivery">
                        <p className="text">Estimated delivery by <b>{WrapperFunctionForBusinessDays(leadTime1, leadTime2, leadTime3, shippingDays1, shippingDays2, shippingDays3, selectedShippingMethod)}</b></p>
                    </div>
                </>
                :
                <>
                </>}

        </div>
    );
};

export default PricingOptions;



const WrapperFunctionForBusinessDays = (
    leadTime1,
    leadTime2,
    leadTime3,
    shippingDays1, shippingDays2, shippingDays3,

    selectedShippingMethod) => {
    if (selectedShippingMethod == 1) return calculateBusinessDays(leadTime1, shippingDays1);
    if (selectedShippingMethod == 2) return calculateBusinessDays(leadTime2, shippingDays2);
    if (selectedShippingMethod == 3) return calculateBusinessDays(leadTime3, shippingDays3);
}




const calculateBusinessDays = (leadTime, shippingDays) => {
    const totalDays = leadTime + shippingDays;
    let currentDate = moment();
    let addedDays = 0;

    while (addedDays < totalDays) {
        currentDate.add(1, "days");
        if (currentDate.day() !== 6 && currentDate.day() !== 0) {
            addedDays++;
        }
    }

    return currentDate.format("DD MMM YYYY");
};


function formatPrice(price) {
    if (!price) return "NA";
    const fixedPrice = price.toFixed(2);

    return `$${fixedPrice}`;
}
