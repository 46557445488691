import React, { useState } from "react";
import loader from "../images/loader.gif";

export const Loading = () => {
  return (
    <>
      <div className="processing">
        <img src={loader} className="w-[50px] h-[50px]" />
      </div>
    </>
  );
};
