import React from "react";
import tickwhite from "../images/tick-white.svg";
import { currencyFormatter } from "../utils/helper";

const PriceBox = (props) => {

  const { className } = props;


  return (
    <div
      className={`pr-radio `}
      key={props.value}
      onClick={() => {
        props.setErrors({
          ...props.errors,
          price: null,
        });
      }}
    >
      <label className={`btn box-in ${className}`} htmlFor={`btnradio${props.id}`}>
        <span className="triangle-0">
          {" "}
          <img src={tickwhite} />
        </span>

        <span className="price-sp">{currencyFormatter(props.price ? (Number(props.price)).toFixed(2) : 0)}</span>
        <span className="vld-bx"> {props.business}</span>
        <span className="ship-bx">{props.Shipped}</span>
      </label>
    </div>
  );
};
export default PriceBox;
