import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header/header";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import {
  newCheckout,
  getUserpaymentData,
  confirmPaymentMethod,
  createPaymentIntent,
} from "../../utils/actions/allactions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { currencyFormatter } from "../../utils/helper";
import moment from "moment";
import { sendOrderCreationMailToAdmin } from "../../utils/actions/userActions";
import { DeleteIcon, DownloadIcon } from "../../components/Icons";
import { CrossIcon } from "../../components/GlobalProjectNameComponent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Payments = () => {
  let navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  let [part, setPart] = useState({});
  let [bill, setBill] = useState({});
  let [paymentIntent, setPaymentIntent] = useState("");
  let [loading, setLoading] = useState(false);
  let [addNewCard, setAddNewCard] = useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [placingInProcess, setPlacingInProcess] = useState(false);
  let token = localStorage.getItem("Token");
  let [ordr, setOrdr] = useState({
    ordrNbr: "",
    checkbx: false,
    file: "",
  });
  let [error, setError] = useState({
    ordrNbr: "",
    checkbx: "",
    poFile: "",
  });

  useEffect(() => {
    if (ordr.checkbx) {
      setOrdr((pre) => {
        return { ...pre, ordrNbr: '',file:"" }
      })
    }

  }, [ordr.checkbx]);

  console.log("Item :",ordr);

  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const RedirectComponent = () => {
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
      const timer = setTimeout(() => {
        navigate("/my-orders");
      }, countdown * 1000);

      return () => clearTimeout(timer);
    }, [countdown, navigate]);

    useEffect(() => {
      if (countdown > 0) {
        const countdownTimer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(countdownTimer);
      }
    }, [countdown]);

    return <div>Redirecting in {countdown} seconds...</div>;
  };

  const loggedInUser = localStorage.getItem("Token");
  if (loggedInUser) {
    var userfirstName = jwtDecode(localStorage.getItem("Token"))?.firstName;
    var userlastName = jwtDecode(localStorage.getItem("Token"))?.lastName;
  }
  let params = useParams();
  let location = useLocation();

  let _id = params._id;
  let user_id = localStorage.getItem("_id");

  const handlePaymentIntent = async (paymentMethodId) => {
    try {
      setPlacingInProcess(true);
      await confirmPaymentMethod(
        { paymentIntentId: paymentIntent.id, paymentMethodId },
        token
      );
      //admin order creation email here
      let data = {
        orderId: "S" + part.RefId + " V-" + part.version,
      };
      await sendOrderCreationMailToAdmin(token, data);
      setTimeout(() => {
        toast.success("Order placed successfully");
        navigate("/my-orders");
      }, 5000);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchPaymentMethod = async (bill) => {
    setPaymentLoading(true);
    const { data } = await getUserpaymentData(token);
    if (data?.paymentMethods?.length) setPaymentMethods(data.paymentMethods);
    let formData = {
      firstName: bill.firstName,
      lastName: bill.lastName,
      email: bill.email,
      mobileNumber: bill.mobileNumber,
      companyName: bill.companyName,
      shippingAddress: bill.shippingAddress,
      vatNumber: bill.vatNumber,
      addressline1: bill.addressline1,
      addressline2: bill.addressline2,
      zipCode: bill.zipCode,
      city: bill.city,
      state: bill.state,
      country: bill.country,
      tax: bill.tax,
      subTotal: bill.subTotal,
      quoteId: bill.quoteId,
      purchaseNumber: ordr.ordrNbr,
      orderNumberLater: ordr.checkbx,
    };
    // console.log("amount",Math.round((bill.subTotal +  (bill.tax ? bill.subTotal / bill.tax : 0)) * 100))
    const { data: paymentIntentData } = await createPaymentIntent(
      {
        amount: Math.round(
          (bill.subTotal + (bill.tax ? bill.subTotal / bill.tax : 0)) * 100
        ),
        orderDetail: formData,
      },
      token
    );
    setPaymentIntent(paymentIntentData.intent);
    setPaymentLoading(false);
  };

  const StripeWrapper = () => {
    const stripe = useStripe();
    const elements = useElements();

    const onFinish = async () => {
      if (elements == null) return;
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "onlineCardPayment",
        //   userId: userId,
        //   quoteRefId: part.RefId,
        // });
      }

      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/",
        },
        redirect: "if_required",
      });

      setShowPaymentModal(false);
      setAddNewCard(false);
      if (response.error) {
        if (response?.error?.code != "payment_intent_authentication_failure")
          return toast.error(`Error : ${response.error.message}`);
      }

      setTimeout(() => {
        navigate("/my-orders");
      }, 5000);
    };
    return (
      <div
        className="checkout-container"
        style={{ display: "flex", flexDirection: "column", gap: 24 }}
      >
        <div className="header">
          <div className="title" style={{ fontSize: 24, fontWeight: 600 }}>
            Complete Payment
          </div>
          <div className="description">
            Input your payment details to checkout.
          </div>
        </div>

        <div className="payment-method">
          <div className="payment-details">
            <PaymentElement />
          </div>
        </div>

        <Button
          type="primary"
          loading={btnLoading}
          size="large"
          onClick={onFinish}
        >
          {!placingInProcess
            ? `Pay ${currencyFormatter(
              Number(
                bill.subTotal + (bill.tax ? bill.subTotal / bill.tax : 0)
              ).toFixed(2)
            )}`
            : `Placing Order...`}
        </Button>
      </div>
    );
  };

  let handleSubmit = async () => {
    try {
      if (ordr.checkbx == false) {
        if (ordr.ordrNbr == "" || ordr?.file == "") {
          setError((val) => ({
            ...val,
            ordrNbr: "Please upload PO file and enter PO number to proceed !",
          }));
        } else {
          let userId = localStorage.getItem("_id");
          if (window.location.host === "make.8xparts.com") {
            // window?.dataLayer?.push({
            //   event: "createSalesOrder",
            //   type: "pay-later",
            //   userId: userId,
            //   quoteRefId: part?.RefId,
            // });
          }

          setLoading(true);
          let token = localStorage.getItem("Token");
          let formData = new FormData();
          formData.append("firstName", bill.firstName);
          formData.append("lastName", bill.lastName);
          formData.append("email", bill.email);
          formData.append("mobileNumber", bill.mobileNumber);
          formData.append("companyName", bill.companyName);
          formData.append("shippingAddress", bill.shippingAddress);
          formData.append("vatNumber", bill.vatNumber);
          formData.append("addressline1", bill.addressline1);
          formData.append("addressline2", bill.addressline2);
          formData.append("zipCode", bill.zipCode);
          formData.append("city", bill.city);
          formData.append("state", bill.state);
          formData.append("country", bill.country);
          formData.append("tax", bill.tax);
          formData.append("subTotal", bill.subTotal);
          formData.append("quoteId", bill.quoteId);
          formData.append("file", ordr.file);
          formData.append("purchaseNumber", ordr.ordrNbr);
          formData.append("orderNumberLater", ordr.checkbx);
          formData.append("partsData", JSON.stringify(part.partsData));

          newCheckout(formData, token)
            .then((res) => {
              if (res.data.status == false) {
                setLoading(false);
                navigate("/get-instant-quote/checkout/" + _id, {
                  state: {
                    billing: location.state.billing,
                    part: location.state.part,
                  },
                });
                toast.error("Failed to place order! Please try again later.");
              } else {
                //admin order creation email here
                let data = {
                  orderId: res.data.data.RefId,
                };
                sendOrderCreationMailToAdmin(token, data)
                  .then((resData) => {
                    setLoading(false);
                    setShowRedirectModal(true);
                  })
                  .catch((err) => {
                    setLoading(false);
                    setShowRedirectModal(true);
                    console.log("email not sent error", err);
                  });
              }
            })
            .catch((err) => {
              console.log(
                "error in payments 278 on" +
                moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
                err
              );
              setLoading(false);
              toast.error("Something went wrong");
            });
        }
      } else {
        setError((val) => ({
          ...val,
          ordrNbr: "",
        }));
        setLoading(true);
        let token = localStorage.getItem("Token");

        let formData = new FormData();
        formData.append("firstName", bill.firstName);
        formData.append("lastName", bill.lastName);
        formData.append("email", bill.email);
        formData.append("mobileNumber", bill.mobileNumber);
        formData.append("companyName", bill.companyName);
        formData.append("shippingAddress", bill.shippingAddress);
        formData.append("vatNumber", bill.vatNumber);
        formData.append("addressline1", bill.addressline1);
        formData.append("addressline2", bill.addressline2);
        formData.append("zipCode", bill.zipCode);
        formData.append("city", bill.city);
        formData.append("state", bill.state);
        formData.append("country", bill.country);
        formData.append("tax", bill.tax);
        formData.append("subTotal", bill.subTotal);
        formData.append("quoteId", bill.quoteId);
        formData.append("file", ordr.file);
        formData.append("purchaseNumber", ordr.ordrNbr);
        formData.append("orderNumberLater", ordr.checkbx);

        newCheckout(formData, token)
          .then((res) => {
            setLoading(false);
            if (res?.response?.data?.data?.message) {
              toast.error(res.response.data.data.message);
            } else if (res?.data?.status == true) {
              //admin order creation email here
              let data = {
                orderId: res.data.data.RefId,
              };
              sendOrderCreationMailToAdmin(token, data);
              setShowRedirectModal(true);
            } else {
              navigate("/get-instant-quote/checkout/" + _id, {
                state: {
                  billing: location.state.billing,
                  part: location.state.part,
                },
              });
              toast.error("Failed to place order! Please try again later.");
            }
          })
          .catch((err) => {
            setLoading(false);
            return err;
          });
      }
    } catch (error) {
      console.log(
        "error in payments 349 on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        error
      );
      return error;
    }
  };

  useEffect(() => {
    async function fetchData() {
      let token = localStorage.getItem("Token");
      if (token) {
        let decode = jwtDecode(token);
        if (!decode) {
          navigate("/login");
        } else if (decode.userType == "admin") {
          navigate("/admin");
        } else if (decode.userType == "user") {
          if (location.state && location.state !== null) {
            setPart(location.state.part);
            setBill(location.state.billing);
            // paymentIntent?.client_secret && !showPaymentModal && setShowPaymentModal(true)
          } else {
            navigate("/saved-quote");
            toast.error("Data not found!");
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }
    fetchData();
  }, []);

  // const handlePurcheseOrderDocumentDownload = ()=>{
  //   if(ordr.file)
  //   {
  //     const a = document.createElement("a");
  //     a.href = URL.createObjectURL(ordr.file);
  //     a.download = ordr.file.name;
  //   }
  // }
  // const handlePurcheseOrderDocumentCancel = ()=>
  // {

  // }

  return (
    <div className="content-wrapper">
      <Header />
      <Modal
        size="lg"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showRedirectModal}
        onHide={() => {
          navigate(`/my-orders`);
          setShowRedirectModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Order created successfully.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <h6>
                A sales order confirmation will be sent once we receive a copy
                of the purchase order
              </h6>
              <RedirectComponent />
            </div>
            <br />
          </div>
          <div style={{ color: "#8b8b8b" }}></div>
        </Modal.Body>
      </Modal>

      <div className="intro-bar">
        <div className="int-left">
          <h3>Start Production - Select Payment Method</h3>
          <p>
            Quotation Reference ID: #
            {part && part.RefId ? part.RefId : "Loading..."}{" "}
          </p>
        </div>
        <div className="int-right">
          <h3>
            {bill.subTotal
              ? currencyFormatter(
                Number(
                  bill.subTotal + (bill.tax ? bill.subTotal / bill.tax : 0)
                ).toFixed(2)
              )
              : "Loading..."}
          </h3>
          {/* <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Show Quote Summary
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>

      <div className="dashboard-holder">
        <div className="sm-container">
          <div className="common-bx">
            <div className="payment-option">
              <div className="pay-heading">
                <div className="rd-box">
                  <input
                    type="radio"
                    id="test1"
                    name="radio-group"
                    onClick={() => setSelectedPaymentMethod("card")}
                  />
                  <label
                    htmlFor="test1"
                    onClick={async () => {
                      await fetchPaymentMethod(location.state.billing);
                      setSelectedPaymentMethod("card");
                    }}
                  >
                    Pay Now
                  </label>
                </div>
              </div>

              {selectedPaymentMethod == "card" && (
                <div>
                  <br />
                  <br />
                  <div className="addresses">
                    {paymentMethods?.length > 0 && (
                      <div className="addressTitle">My Cards</div>
                    )}
                    <div className="cardContainer">
                      {paymentLoading ? (
                        <div className="card">
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            loading...
                          </div>
                        </div>
                      ) : (
                        paymentMethods?.map((cardDetails, index) => {
                          const { card, id } = cardDetails;
                          const { exp_month, exp_year, last4, brand } = card;
                          // console.log("card", card)
                          return (
                            <div
                              key={index}
                              onClick={() => setSelectedCard(id)}
                              className="card"
                              style={{
                                boxShadow: "3px 3px 12px 10px #00000010",
                                borderRadius: 10,
                                padding: 16,
                                cursor: "pointer",
                                position: "relative",
                                borderColor:
                                  id == selectedCard ? "blue" : "white",
                              }}
                            >
                              <div
                                className="cardContainer"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "100%",
                                  margin: 0,
                                }}
                              >
                                <div
                                  className="card-no"
                                  style={{ fontSize: 20, color: "black" }}
                                >
                                  XXXX-XXXX-XXXX-{last4}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className="card-exp"
                                    style={{ fontSize: 16, color: "black" }}
                                  >
                                    {exp_month} / {exp_year}
                                  </div>
                                  <div
                                    className="card-brand"
                                    style={{ fontSize: 20, color: "black" }}
                                  >
                                    {brand}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="buttons"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "50%",
                                  bottom: "50%",
                                  transform: "translate(50%, 50%)",
                                }}
                              >
                                <span style={{ color: "#00000050" }}>
                                  Select to pay
                                </span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                  <Button onClick={() => setAddNewCard(true)}>Add Card</Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={!selectedCard}
                    onClick={() =>
                      placingInProcess
                        ? null
                        : handlePaymentIntent(selectedCard)
                    }
                  >
                    {!placingInProcess
                      ? `Pay ${currencyFormatter(
                        (
                          bill.subTotal +
                          Number(bill.tax ? bill.subTotal / bill.tax : 0)
                        ).toFixed(2)
                      )}`
                      : `Placing Order...`}
                  </Button>
                  {paymentIntent?.client_secret && addNewCard && (
                    <Modal show={true} onHide={() => setAddNewCard(false)}>
                      <Modal.Body>
                        <div className="card-list">
                          <Elements
                            stripe={stripePromise}
                            options={{
                              clientSecret: paymentIntent.client_secret,
                              appearance: {
                                theme: "stripe",
                                variables: {
                                  fontWeightNormal: "500",
                                  fontSizeLg: "16px",
                                },
                              },
                            }}
                          >
                            <StripeWrapper />
                          </Elements>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="later-box">
            <div
              className="rd-box"
              onClick={() => setSelectedPaymentMethod("later")}
            >
              <input type="radio" id="test2" name="radio-group" />
              <label htmlFor="test2">Pay Later</label>
            </div>

            {selectedPaymentMethod == "later" && (
              <div className="pay-later-doc">
                <div className="pl-form">
                  <div>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <label>Purchase Order Number</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter"
                            disabled={ordr.checkbx}
                            value={ordr.ordrNbr}
                            onChange={(e) => {
                              setOrdr((val) => ({
                                ...val,
                                ordrNbr: e.target.value,
                              }));
                              setError((val) => ({ ...val, ordrNbr: "" }));
                            }}
                          />
                          <span className="error">{error.ordrNbr}</span>
                        </div>
                      </Col>
                      <Col md={9}>
                        <div className="mb-3 flex items-center h-20 gap-x-2">
                          <div>
                            <label>Document</label>
                            {!ordr.file && (
                              <div className="ul-files  ">
                                <div className="file-button">
                                  <input
                                    type="file"
                                    name="file-input"
                                    id="file-input"
                                    className="file-input__input"
                                    onChange={(e) => {
                                      setOrdr((val) => ({
                                        ...val,
                                        file: e.target.files[0],
                                      }));
                                    }}
                                  />
                                  <label htmlFor="file-input">
                                    {" "}
                                    <span>Browse</span>
                                  </label>
                                </div>
                              </div>
                            )}
                            <div className="flex justify-center  items-center gap-x-2  ">
                              {ordr.file ? (
                                <span className="text-primaryColor text-sm ">
                                  {ordr.file.name}
                                </span>
                              ) : (
                                ""
                              )}
                              {ordr.file && (
                                <div className="flex gap-x-2">
                                  <a
                                    href={URL.createObjectURL(ordr.file)}
                                    download={ordr.file.name}
                                  >
                                    <DownloadIcon />
                                  </a>
                                  <div
                                    onClick={(e) => {
                                      setOrdr((val) => ({
                                        ...val,
                                        file: "",
                                      }));
                                    }}
                                  >
                                    <DeleteIcon
                                      additionalClasses={"h-5 text-red-500"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="apply-check">
                    <input
                      type="checkbox"
                      id="sign-check"
                      name="checkbox"
                      value="Apple"
                      onChange={() =>

                        setOrdr((val) => {
                          if (val.checkbx) {
                            return { ...val, checkbx: !val.checkbx, file: "", ordrNbr: "" }
                          }

                          return ({ ...val, checkbx: !val.checkbx })
                        })

                      }
                    />
                    <label htmlFor="sign-check"></label>
                    <p>Purchase order will be sent later by email</p>
                  </div>

                  <div className="later-btns">
                    <Link
                      to={"/get-instant-quote/checkout/" + _id}
                      state={{
                        billing: location.state.billing,
                        part: location.state.part,
                      }}
                      className="back-btn"
                    >
                      Back
                    </Link>
                    <button className="place-btn" onClick={handleSubmit}>
                      Place Order
                    </button>
                  </div>

                  {loading ? <Loading /> : ""}
                </div>

                <div></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payments;
