import process from "../../images/process.svg";
import settingw from "../../images/setting-white.svg";
import jwt_decode from "jwt-decode";
import Header from "../../components/Header/header";
import { Tabs, Tab, Nav, Modal, Button, Table } from "react-bootstrap";
import tickgrey from "../../images/tick-grey.svg";
import { fetchQuoteByRefId } from "../../utils/actions/allactions";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { processType } from "../../utils/constant";
import QuoteTable from '../../components/QuoteTable/QuoteTable';
import _ from 'lodash';
import Chat from "../../Chat";
import qs from 'query-string';
import { ChatRefContext } from '../../context/chatRef.context';
import moment from "moment";
import { currencyFormatter } from "../../utils/helper";
import { Loading } from "../../components/Loading";
import { CodeIcon } from "../../components/Icons";


function QuoteVersion() {
    let { RefId } = useParams();
    let token = localStorage.getItem("Token");
    const [searchParams, setSearchParams] = useSearchParams();
    const requestedVersion = searchParams.get("version");
    let [showModal, setShowModal] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState({});
    const [allVersionQuote, setAllVersionQuote] = useState([]);
    const [numberOfVersion, setNumberOfVersion] = useState(0);
    const [otherVersionSelected, setOtherVersionSelected] = useState();
    const [isValidUser, setIsValidUser] = useState(true);
    const userEmail = localStorage.getItem("email");
    const [loading, setLoading] = useState(false);
    const { search, } = window.location;
    const param = qs.parse(search);
    const [open, setOpen] = useState(param.chat);
    const { selectedChatRefId, setSelectedChatRefId } = useContext(ChatRefContext);
    let userId = localStorage.getItem('_id');
    const [key, setKey] = useState("version1");
    const navigate = useNavigate();

    const showDrawer = () => {
        setOpen(true);
        setSelectedChatRefId(selectedQuote.RefId)
    };

    const onClose = () => {
        setOpen(false);
        setSelectedChatRefId(null)
    };

;


    const fetchAllVersionOfQuote = async () => {
        try {
            setLoading(true)
            let fetchedQuoteVersion = await fetchQuoteByRefId(RefId, token);
            console.log('fetchedQuoteData', fetchedQuoteVersion.data);
            setAllVersionQuote(fetchedQuoteVersion.data.data);
            setNumberOfVersion(fetchedQuoteVersion.data.data.length);
            fetchedQuoteVersion.data.data.forEach((quoteItem) => {
                if (quoteItem.version === (fetchedQuoteVersion.data.data.length - 1)) {
                    // if (quoteItem.userId.email.split("@")[1] != userEmail.split("@")[1]) {
                    //     setIsValidUser(false);
                    // }
                    setSelectedQuote(quoteItem);
                }
            })
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }

    }

    const handleClose = () => {
        setShowModal(false);
        setKey('version1');
        allVersionQuote.map((quoteItem) => {
            if (quoteItem?.version === allVersionQuote?.length - 1) {
                setSelectedQuote(quoteItem);
            }
        });
    }


    const handleShowAllVersion = (key) => {
        if (key === "all-versions") {
            setShowModal(true)
        }
        if (key === "version2") {
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version === selectedQuote.version - 1 && quoteItem.version >= numberOfVersion - 2) {
                    setSelectedQuote(quoteItem)
                }
            })
        }

        if (key === "version1") {
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version === allVersionQuote.length - 1) {
                    setSelectedQuote(quoteItem);
                }
            })
        }
    }

    const handleQuoteVersionChangeInModal = (otherQuoteSelected) => {
        setShowModal(false)
        if (otherQuoteSelected.version === numberOfVersion - 1) {
            setKey('version1')
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version === allVersionQuote.length - 1) {
                    setSelectedQuote(quoteItem);
                }
            })
        }
        else if (otherQuoteSelected.version === numberOfVersion - 2) {
            setKey('version2')
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version === allVersionQuote.length - 1) {
                    setSelectedQuote(quoteItem);
                }
            })
        } else {
            setKey('other-versions')
            setOtherVersionSelected(otherQuoteSelected)
        }
    }



    useEffect(() => {
        if (!token) {
            navigate('/')
        } else {
            let decode = jwt_decode(token);
            if (!decode) {
                navigate("/login");
                toast.error("Invalid Token found!");
            } else if (decode) {
                if (decode.userType == "user") {
                    fetchAllVersionOfQuote();
                } else if (decode.userType == "admin") {
                    navigate("/admin");
                    // toast.error('Unauthorized Access!');
                } else {
                    navigate("/login");
                    // toast.error('Unauthorized Access!');
                }
            }
        }
    }, []);
    useEffect(() => {
        if (requestedVersion && allVersionQuote.length && numberOfVersion) {
            const val = allVersionQuote.find((item) => item.version == requestedVersion);
            val && handleQuoteVersionChangeInModal(val);
        }
    }, [requestedVersion, allVersionQuote, numberOfVersion]);

    return (
        <div className="content-wrapper">
            <Header activeTab="/saved-quote" />
            {loading ? <><div >
                <Loading />
            </div></> : <></>}
            {isValidUser ?
                <>
                    <div className="dashboard-holder">
                        <div className="sm-container">
                            <Chat RefId={RefId} open={open} onDrawerClose={onClose} />
                            <div className="common-bx">
                                <div className="flex items-center gap-x-2 my-3   ">
                                    <div className="flex text-xl">
                                        <h1>Project Name : </h1>
                                        <h1>{selectedQuote?.projectName || "NA"}</h1>
                                    </div>
                                </div>
                                <div className="process-bar-bx green-line">
                                    <span className="process-ico">
                                        <img src={process} alt="process" />
                                    </span>
                                    <h5>Selected Process </h5>
                                    <span className="box-in-quote-version" style={{ color: '333333' }}>
                                        {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}
                                    </span>

                                </div>

                                <div className="process-bar-bx grey-line">
                                    <span className="process-ico green-fill">
                                        <img src={settingw} />
                                    </span>
                                    <h5> Requirement</h5>
                                    {/* <span className="box-in-quote-version" style={{ color: '333333' }}>
                                        {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}
                                    </span> */}

                                    <div className="version-tabs">
                                        {numberOfVersion && numberOfVersion > 2 ?
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => {
                                                    setKey(k)
                                                    handleShowAllVersion(k)
                                                }}
                                                className="mb-3 qoute-version-tabs"
                                            >
                                                <Tab eventKey="version1" title={`Quotation Version ${numberOfVersion - 1}`}>
                                                    {selectedQuote.selectedProcess ?
                                                        <>
                                                            <QuoteTable setLoading={setLoading} isLoading={loading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </> : null
                                                    }
                                                </Tab>
                                                <Tab eventKey="version2" title={`Quotation Version ${numberOfVersion - 2}`}>
                                                    {selectedQuote.selectedProcess ?
                                                        <>
                                                            <QuoteTable setLoading={setLoading} isLoading={loading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </> : null
                                                    }
                                                </Tab>
                                                <Tab eventKey="other-versions" title={otherVersionSelected ? `Quotation Version ${otherVersionSelected.version}` : null} >
                                                    {
                                                        otherVersionSelected ?
                                                            <>
                                                                <QuoteTable setLoading={setLoading} isLoading={loading} showDrawer={showDrawer} selectedQuote={otherVersionSelected} setSelectedQuote={setOtherVersionSelected} />
                                                            </> : null
                                                    }
                                                </Tab>

                                                <Tab eventKey="all-versions"
                                                    title="Show all versions">
                                                </Tab>

                                            </Tabs>
                                            : numberOfVersion === 2 ?
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => {

                                                        setKey(k)
                                                        handleShowAllVersion(k)
                                                    }}
                                                    className="mb-3 qoute-version-tabs"
                                                >
                                                    <Tab eventKey="version1" title={`Quotation Version ${numberOfVersion - 1}`}>
                                                        {selectedQuote.selectedProcess ?
                                                            <>
                                                                <QuoteTable setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                            </> : null
                                                        }
                                                    </Tab>


                                                    <Tab eventKey="version2" title={`Quotation Version ${numberOfVersion - 2}`}>
                                                        {selectedQuote.selectedProcess ?
                                                            <>
                                                                <QuoteTable setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                            </> : null
                                                        }
                                                    </Tab>
                                                </Tabs>
                                                : <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => {
                                                        setKey(k)
                                                        handleShowAllVersion(k)
                                                    }}
                                                    className="mb-3 qoute-version-tabs"
                                                >
                                                    <Tab eventKey="version1" title={`Quotation Version ${numberOfVersion - 1}`}>
                                                        <>
                                                            <QuoteTable setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </>
                                                    </Tab>
                                                </Tabs>
                                        }
                                    </div>
                                </div>



                                <Modal
                                    size="lg"
                                    dialogClassName="qoute-version-modal"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={showModal}
                                    onHide={handleClose}

                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                                            Quotation Versions
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h5>Selected Process : {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}</h5>

                                        <div>
                                            {selectedQuote.selectedProcess > 2 ?
                                                <>
                                                    <table className="qoute-version-table">
                                                        <tbody>
                                                            <tr className='heading-row'>
                                                                <th>Title</th>
                                                                <th>Quotation Number</th>
                                                                <th>Last Edited</th>
                                                                <th>Parts</th>
                                                                <th>Option 1</th>
                                                                <th>Option 2</th>
                                                                <th>Option 3</th>
                                                                <th>Status</th>
                                                            </tr>
                                                            {
                                                                allVersionQuote ? allVersionQuote.map((quoteItem, i) =>
                                                                    <tr key={i} className='data-row' >
                                                                        <td>Version {quoteItem.version}</td>

                                                                        <td className="quote-version-link" onClick={() => handleQuoteVersionChangeInModal(quoteItem)}>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>


                                                                        <td>{
                                                                            moment(quoteItem.updatedAt).format("DD MMM YYYY")
                                                                            // new Date(quoteItem.updatedAt).toLocaleDateString('en-US')
                                                                        }</td>

                                                                        <td>{quoteItem.partsData.length} Parts</td>
                                                                        <td> {quoteItem.price ? <p>{currencyFormatter(quoteItem.price.toFixed(2))}<br />
                                                                            {`${Math.floor(quoteItem.leadTime1 / 7)}-${Math.floor(quoteItem.leadTime1 / 7) + 1}`}  {" "}weeks
                                                                        </p> : 0}
                                                                        </td>
                                                                        <td> {quoteItem.price1 ? <p>{currencyFormatter(quoteItem.price1.toFixed(2))}<br />
                                                                            {`${Math.floor(quoteItem.leadTime2 / 7)}-${Math.floor(quoteItem.leadTime2 / 7) + 1}`}  {" "}weeks
                                                                        </p> : 0}</td>
                                                                        <td>
                                                                            {
                                                                                quoteItem.sheetAndInjectionManualPrice ?
                                                                                    <p>{currencyFormatter(quoteItem.sheetAndInjectionManualPrice.toFixed(2))}
                                                                                        <br />
                                                                                        {`${Math.floor(quoteItem.leadTime3 / 7)}-${Math.floor(quoteItem.leadTime3 / 7) + 1}`}  {" "}weeks
                                                                                    </p>
                                                                                    : 0
                                                                            }
                                                                        </td>
                                                                        <td>{quoteItem.myorders ? "ORDERED" : "SAVED"}</td>
                                                                    </tr>
                                                                ) : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </> :
                                                <>
                                                    <table className="qoute-version-table">
                                                        <tbody>
                                                            <tr className='heading-row'>
                                                                <th>Title</th>
                                                                <th>Quotation Number</th>
                                                                <th>Last Edited</th>
                                                                <th>Parts</th>
                                                                <th>Option 1</th>
                                                                <th>Option 2</th>
                                                                <th>Option 3</th>
                                                                <th>Status</th>
                                                            </tr>
                                                            {
                                                                allVersionQuote ? allVersionQuote.map((quoteItem, i) =>
                                                                    <tr key={i} className='data-row' >
                                                                        <td>Version {quoteItem.version}</td>
                                                                        {(quoteItem.selectedProcess >= 3) && quoteItem.version === 0 ?
                                                                            <td>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>
                                                                            : <td className="quote-version-link" onClick={() => handleQuoteVersionChangeInModal(quoteItem)}>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>
                                                                        }
                                                                        <td>{
                                                                            moment(quoteItem.updatedAt).format("DD MMM YYYY")
                                                                            // new Date(quoteItem.updatedAt).toLocaleDateString('en-US')
                                                                        }</td>
                                                                        <td>{quoteItem.partsData.length} Parts</td>
                                                                        <td> {quoteItem.price ? <p>{currencyFormatter(quoteItem.price.toFixed(2))}<br />
                                                                            {`${Math.floor(quoteItem.leadTime1 / 7)}-${Math.floor(quoteItem.leadTime1 / 7) + 1}`}  {" "}weeks
                                                                        </p> : 0}
                                                                        </td>
                                                                        <td> {quoteItem.price1 ? <p>{currencyFormatter(quoteItem.price1.toFixed(2))}<br />
                                                                            {`${Math.floor(quoteItem.leadTime2 / 7)}-${Math.floor(quoteItem.leadTime2 / 7) + 1}`}  {" "}weeks
                                                                        </p> : 0}</td>


                                                                        <td> {quoteItem.stManualPrice ? <p>{currencyFormatter(quoteItem.stManualPrice.toFixed(2))}<br />
                                                                            {`${Math.floor(quoteItem.leadTime3 / 7)}-${Math.floor(quoteItem.leadTime3 / 7) + 1}`}  {" "}weeks
                                                                        </p> : 0}</td>
                                                                        <td>{quoteItem.myorders ? "ORDERED" : "SAVED"}</td>
                                                                    </tr>
                                                                ) : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                            }

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={handleClose} className="req-quote-btn">Close</Button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="process-bar-bx order-pay-box">
                                    <span className="order-ico">
                                        <img src={tickgrey} />
                                    </span>
                                    <h5>Order & Pay</h5>
                                </div>

                            </div>
     
                        </div>
                    </div >
                </>
                :
                loading ? <>
                    <div >
                        <Loading />
                    </div>
                </>
                    : <>
                        <div className="dashboard-holder">
                            <div className="sm-container">
                                <div className="dashboard-option">
                                    <div className="dash-left">
                                        <div className="ds-heading">
                                            <Link to="/saved-quote">
                                                <p className="req-quote-btn">Go To Saved Quotes  </p>
                                            </Link>
                                        </div>
                                    </div>

                                    <div style={{ textAlign: 'center' }}>
                                        <h2>Quotation information is not available</h2>
                                        <br />
                                        <p>Your dont have access to this quote version details.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div >
    );
}

export default QuoteVersion