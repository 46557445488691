import React from 'react';
import { useParams } from 'react-router-dom';
import loader from "../../../../assets/static/logo/loader.gif";
import ShufflingText from '../ShufflingText/ShufflingText';
import * as loaderLottie from '../../../../assets/8parts-logo.json'
import Lottie from 'react-lottie';
import animationData from "../../../../assets/JsonFiles/8xpartsLogoJson.json";



const QuotationLoader = ({ processing, selectedProcess, globeLoader, isLoading }) => {



  return (
    <>
      {
        (processing || globeLoader || isLoading) ? (
          <div className="processing1" style={{ zIndex: 2 }}>
            <div>
              {/* <img src={loader} alt="instant quote" /> */}
              <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: loaderLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
                width={300}
              />
            </div>
            <div>
              {/* <h2 className='lg:text-xl'>Loading...</h2> */}
            </div>
            <div style={{minHeight: 90}}>
              <ShufflingText />
            </div>
          </div>
        ) : ""
      }
    </>
  );
};
export default QuotationLoader