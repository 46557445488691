import React, { useContext, useState, useEffect } from "react";
// import { OrdersContext } from '../context/orders.context';
import { processType } from "../../utils/constant";
import moment from "moment";
import ChatButton from "../../Chat/ChatButton";
import { ChatRefContext } from "../../context/chatRef.context";
import Chat from "../../Chat/index";
import { currencyFormatter, isShowPriceAccordingToProcess } from "../../utils/helper";
import tickwhite from "../../images/tick-white.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import editImage from "../../images/edit.svg";
import downloadImage from "../../images/download.png";
import {
  downloadPdf,
  handleCommonDeleteAddressForUser,
} from "../../utils/actions/allactions";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import { Link } from "react-router-dom";
import redCancelImage from "../../images/close_red.svg";
import tickGreenImage from "../../images/tick-green.svg";
import { handleProjectNameChange } from "../../utils/actions/commanActions";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import GlobalProjectNameComponent from "../GlobalProjectNameComponent/index";
import { BrainMachine, CalenderIcon, DownloadIcon, LucidLoader, UserIcon } from "../Icons"; import { downloadQuotationPdf } from "../../api/customer/quotation/commonQuotationApi";
import moreIcon from "../../images/dots.svg";
import { checkGenericUser } from "../../utils/actions/userActions";
import DownloadQuotationModal from "../DownloadQuotationComponent/DownloadQuotationModal";

const MyQuoteTable = (props) => {
  let token = localStorage.getItem("Token");
  const {
    quotationsList,
    selectedShipMethodMap,
    myQuotesDispatch,
    MY_QUOTE_ACTIONS,
    addressValues,
    setAddressValues,
    setShowAddressModal,
    setIsEdit,
    setEditId,
    fetchQuotesForUser,
    myQuotesState,
    versionData,
    activeTab,
  } = props;
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [showDQModal, setShowDQModal] = useState(false);
  const [isDownloadingQuote, setIsDownloadingQuote] = useState(false);
  const [selectedQuoteForDownload, setSelectedQuoteForDownload] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(-1);
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });
  const [editIndex, setEditIndex] = useState(-1);
  let email = localStorage.getItem("email");

  const [selectedShippingMethodForDownloadQuote, setSelectedShippingMethodForDownloadQuote] = useState(1);
  const [isGenericUser, setIsGenericUser] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        const id = localStorage.getItem("_id");
        const response = await checkGenericUser(id);
        if (response.success) {
          const { isGeneric } = response;
          console.log("Is Generic User :", isGeneric);
          setIsGenericUser(isGeneric);
        }
      } catch (error) {

      }
    })()

  }, []);

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {

      // window?.dataLayer?.push({
      //   event: 'chatOpen',
      //   userId: userId,
      //   quoteRefId: id,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleShipMethodChange = (id, shipMethodAtIndex) => {
    setSelectedShippingMethodForDownloadQuote(shipMethodAtIndex);
    let localShipMethodMap = selectedShipMethodMap;
    localShipMethodMap.set(id, shipMethodAtIndex);
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_SELECTED_SHIP_MAP,
      payload: localShipMethodMap,
    });
  };

  const handleTeamModal = async (selectedQuote) => {
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'teamCollaboration',
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const updateQuoteData = (updatedQuote) => {
    let localQuotationsList = [...quotationsList];

    localQuotationsList.forEach((item) => {
      if (item.RefId == updatedQuote.RefId) {
        item.followers = updatedQuote.followers;
      }
    });
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: localQuotationsList,
    });
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    if (i > -1) {
      const addressToDelete = addressValues.address[i];
      let localAllAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      const addressDeleteResponse = await handleCommonDeleteAddressForUser(
        token,
        addressToDelete._id
      );
      if (addressDeleteResponse.status) {
        localAllAddress.splice(i, 1);
        setAddressValues({ ...addressValues, address: localAllAddress });
      }
    }
  };

  let handleDownload = async () => {
    setIsDownloadingQuote(true);
    try {
      if (!addressValues.shippingAddress) {
        toast.error(
          "Please select a valid shipping address to download quotation."
        );
        return;
      }
      let data = {
        quoteId: selectedQuoteForDownload?._id,
        selectedShipMethod: selectedShippingMethodForDownloadQuote,
        shippingAddress: addressValues.shippingAddress,
        RefId: selectedQuoteForDownload?.RefId,
        price: selectedShippingMethodForDownloadQuote == 1 ? selectedQuoteForDownload?.price1 : selectedShippingMethodForDownloadQuote == 2 ? selectedQuoteForDownload?.price2 : selectedQuoteForDownload?.price3
      };


      let response = await downloadQuotationPdf(data);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedQuoteForDownload?.RefId}_V${selectedQuoteForDownload.version}_${data.price}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);


    } catch (err) {
      console.log("Error downloading Quotation", err)
    } finally {
      setIsDownloadingQuote(false);
    }
  };

  const handleVersionDropDownClick = (event, index) => {
    let quoteToChange = myQuotesState.quotesToShow[index];
    let localQuotationsList = myQuotesState.quotesToShow;
    for (let i = 0; i < localQuotationsList.length; i++) {
      if (localQuotationsList[i].RefId == quoteToChange.RefId) {
        let newQuoteData = myQuotesState.quotationsRelatedToRefIds.filter(
          (element) =>
            element.RefId == localQuotationsList[i].RefId &&
            element.version == event.target.value
        );
        if (newQuoteData.length == 0) {
          toast.error("Something went wrong please refresh the page.");
          return;
        } else {
          localQuotationsList[i] = newQuoteData[0];
        }
      }
    }
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: localQuotationsList,
    });
  };

  const handleEditClick = (index) => {
    if (editIndex !== -1) {
      alert("Cannot edit multiple projects at once");
      return;
    }

    let project = myQuotesState.quotesToShow[index];
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );
    setEditIndex(index);
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index
        ? { ...quote, isEditing: true, previousProjectName: quote.projectName, editableProjectName: editableText }
        : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
  };

  const handleEditableChange = (e, index) => {
    const { value } = e.target;
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index ? { ...quote, editableProjectName: value } : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
  };

  const handleUpdateClick = async (index) => {

    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) => {
      if (i === index && quote.editableProjectName.trim() === "") {
        alert("Project name cannot be empty"); // Show an alert or handle the error message
        return quote; // Return the original quote without changes
      } else if (i === index) {
        return {
          ...quote,
          isEditing: false,
          projectName: `${quote.projectName.split("_")[0]
            }_${quote.editableProjectName.trim()}`,
        };
      }
      return quote;
    });



    let updatedProject = updatedProjects[index];

    let data = {
      quoteId: updatedProject._id,
      projectName: updatedProject.projectName,
    };
    console.log("Data Going :", data);

    try {
      const response = await handleProjectNameChange(token, data);


      if (!response.success) {
        throw new Error("")
      }
      toast.success(response.message);
      fetchQuotesForUser();

    } catch (error) {
      toast.error("Sorry, we are unable to update the project name at the moment. Please use the chat for further assistance.");
    } finally {

      setEditIndex(-1);
    }
  };

  const handleCancelClick = (index) => {
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index
        ? { ...quote, isEditing: false, editableProjectName: "" }
        : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
    setEditIndex(-1);
  };

  const renderProjectName = (project, index) => {
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const serialNumber = project.projectName.substring(0, firstUnderscoreIndex);
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );
    if (project.isEditing) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              fontSize: "13px",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                width: "100px",
              }}
            >
              {serialNumber}_
            </span>
            <Form.Control
              type="text"
              value={project.editableProjectName}
              onChange={(e) => handleEditableChange(e, index)}
              style={{
                margin: " 0px 4px",
                height: "25px",
                cursor: "pointer",
                fontSize: "13px",
              }}
            />
            <span onClick={() => handleUpdateClick(index)}>
              <img
                src={tickGreenImage}
                style={{
                  margin: " 0px 4px",
                  height: "13px",
                  cursor: "pointer",
                }}
              />
            </span>

            <span onClick={() => handleCancelClick(index)}>
              <img
                src={redCancelImage}
                style={{
                  margin: " 0px 4px",
                  height: "13px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        </>
      );
    }
    return (
      <>
        <Link to={`/quote-versions/${project.RefId}`}>
          <span>{serialNumber}_</span>
          <span>{editableText}</span>
        </Link>

        <span onClick={() => handleEditClick(index)}>
          <img
            src={editImage}
            style={{
              marginLeft: "10px",
              marginBottom: "4px",
              height: "16px",
              cursor: "pointer",
            }}
          />
        </span>
      </>
    );
  };


  return (
    <div className="table-container">
      <Chat
        RefId={selectedChatRefId}
        open={selectedChatRefId?.length > 0}
        onDrawerClose={onClose}
      />
      <TeamCollaboration
        teamMemberModalShow={teamMemberModalShow}
        selectedQuoteForTeam={selectedQuoteForTeam}
        setTeamMemberModalShow={setTeamMemberModalShow}
        fetchDataForUser={fetchQuotesForUser}
        updateQuoteData={updateQuoteData}
      />
      <DownloadQuotationModal
        show={showDQModal}
        selectedQuote={selectedQuoteForDownload}
        setShow={setShowDQModal}

      />

      <table>
        <thead>
          <tr>
            <th className="info-text" style={{ width: "240px", borderLeft: "none" }}>Project Name </th>
            <th className="info-text" style={{ width: "280px" }}>Process</th>
            <th className="info-text">Owner</th>
            <th className="info-text">Created On</th>
            <th className="info-text" style={{ width: "120px", minWidth: '120px' }}>Version</th>
            <th className="info-text">Rapid</th>
            <th className="info-text">Standard</th>
            <th className="info-text">Economy</th>
            <th className="text-center" style={{ width: "80px" }}>Options</th>
          </tr>
        </thead>
        <tbody>
          {quotationsList.length > 0 &&
            quotationsList.map((item, i) => {
              console.log("Main Item :", item);

              return (
                <tr key={i}>
                  <td style={{ borderLeft: "none" }}>
                    <GlobalProjectNameComponent project={item} index={i} handleEditClick={handleEditClick} handleEditableChange={handleEditableChange} handleUpdateClick={handleUpdateClick} handleCancelClick={handleCancelClick} />
                    {/* {renderProjectName(item,i)} */}
                  </td>
                  <td>
                    <p className="text">
                      {item?.selectedProcess ? processType[item?.selectedProcess - 1].name : null}
                    </p>
                    {/* <BrainMachine /> */}
                  </td>
                  <td>
                    <p className="text">{item?.userId?.firstName + " " + item?.userId?.lastName}</p>
                    {/* <UserIcon /> */}
                  </td>
                  <td>
                    <p className="text text-center">{moment(item.createdAt).format("MMM DD, YYYY")}</p>
                    {/* <CalenderIcon /> */}
                  </td>
                  <td>
                    <Form.Select
                      style={{ fontSize: "13px", fontWeight: "400" }}
                      value={item.version}
                      onChange={(e) => handleVersionDropDownClick(e, i)}
                    >
                      {versionData[activeTab][item.RefId]?.map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    {isShowPriceAccordingToProcess(item) ? (
                      <>
                        {item.price1 > 0 ? (
                          <>
                            <p className="text">
                              {item.price1
                                ? currencyFormatter(
                                  (item.price1 + item.shippingCharge1 + item?.orderCertificationsCost).toFixed(
                                    2
                                  )
                                )
                                : 0}
                            </p>
                            <p className="info-text">
                              {`${Number(item?.leadTime1 + item.shippingDays1)} `} Business days
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {isShowPriceAccordingToProcess(item) ? (
                      <>
                        {item.price2 > 0 ? (
                          <>
                            <p className="text">
                              {item.price2
                                ? currencyFormatter(
                                  (
                                    item.price2 + item.shippingCharge2 + item?.orderCertificationsCost
                                  ).toFixed(2)
                                )
                                : 0}
                            </p>
                            <p className="info-text">
                              {`${Number(item?.leadTime2 + item.shippingDays2)} `} Business days
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {isShowPriceAccordingToProcess(item) ? (
                      <>
                        {item.price3 > 0 ? (
                          <>
                            <p className="text">
                              {item.price3
                                ? currencyFormatter(
                                  (
                                    item.price3 + item.shippingCharge3 + item?.orderCertificationsCost
                                  ).toFixed(2)
                                )
                                : 0}
                            </p>
                            <p className="info-text">
                              {`${Number(item?.leadTime3 + item.shippingDays3)} `} Business days
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>

                  <td className="text-center">
                    <Dropdown className="options-dropdown">
                      <Dropdown.Toggle
                        className="link-btn"
                      >
                        <img src={moreIcon} alt="More" width={4} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <ChatButton
                            RefId={item.RefId}
                            showDrawer={() => showDrawer(item.RefId)}
                            isTextHide={true}
                            imageHeight={16}
                          />
                        </Dropdown.Item>
                        {!isGenericUser ? <Dropdown.Item onClick={() => handleTeamModal(item)}>
                          <img
                            src={teamImage}
                            style={{
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                          <p className="item">Team</p>
                        </Dropdown.Item> : null}
                        {(isShowPriceAccordingToProcess(item)) ?
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedShippingMethodForDownloadQuote(1);
                              setSelectedQuoteForDownload(item);
                              setShowDQModal(true);
                            }}
                          >
                            <span>
                              <DownloadIcon additionalClass={'text-primaryColor'} />
                            </span>
                            <p className="item">Download</p>
                          </Dropdown.Item> : null
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>

                  {/* <td className="text-center ">
                    <ChatButton
                      RefId={item.RefId}
                      showDrawer={() => showDrawer(item.RefId)}
                      isTextHide={true}
                      imageHeight={16}
                    />
                  </td>
                  <td className="">
                    <span onClick={() => handleTeamModal(item)} className="flex items-center justify-center">
                      <img
                        src={teamImage}
                        style={{
                          // marginRight: "5px",
                          // marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td>}
                  <td style={{ textAlign: 'center' }}>
                  </td> */}
                  {/* <td style={{ textAlign: 'center' }}>
                    {(isShowPriceAccordingToProcess(item)) ?
                      <>
                        <span
                          onClick={() => {
                            setSelectedShippingMethodForDownloadQuote(1);
                            setSelectedQuoteForDownload(item);
                            setShowDQModal(true);
                          }}
                          className="grid place-items-center"
                        >
                          <DownloadIcon additionalClass={'text-primaryColor'} />
                        </span>
                      </>
                      :
                      <>
                      </>
                    }

                  </td>  */}
                </tr>
              );
            })}
        </tbody>
      </table>

      {quotationsList.length == 0 ? (
        <div
          className="text"
          style={{
            width: "100%",
            borderTop: "1px solid #ededed",
            minHeight: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {myQuotesState.searchQuery.length > 0
            ? "Oops! We couldn't find any quotes matching your search query."
            : "No Data Found"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyQuoteTable;
