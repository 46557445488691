import React, { useState, useEffect } from "react";
import "./ShufflingText.css";

const ShufflingText = () => {
    const lines = [
        "Infinite Possibilities, Engineered for You.",
        "Endless Parts. Infinite Solutions.",
        "Building Infinite Possibilities, One Part at a Time.",
        "Where Innovation Meets Infinite Possibilities.",
        "Infinite Possibilities, Precision Delivered.",
        "Parts That Power Infinite Possibilities.",
        "Infinite Possibilities, Seamlessly Manufactured.",
        "Infinite Potential, One Precision Part Away.",
        "Your Vision. Our Parts. Infinite Possibilities.",
        "Infinite Possibilities, Engineered with Excellence.",
    ];

    const [currentText, setCurrentText] = useState("");
    const [fullText, setFullText] = useState(lines[Math.floor(Math.random() * lines.length)]);
    const [charIndex, setCharIndex] = useState(0);
    const [previousText, setPreviousText] = useState("");  // New state to store the previous text

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (charIndex < fullText.length) {
                setCurrentText((prev) => prev + fullText[charIndex]);
                setCharIndex((prev) => prev + 1);
            }
        }, 50); // Typing speed (100ms per character)

        return () => clearTimeout(typingTimeout);
    }, [charIndex, fullText]);

    useEffect(() => {
        if (charIndex === fullText.length) {
            const shuffleTimeout = setTimeout(() => {
                setCurrentText(""); // Clear the text for new typing
                setCharIndex(0); // Reset character index
                let nextText = lines[Math.floor(Math.random() * lines.length)];
                
                // Ensure the new text is not the same as the previous one
                while (nextText === previousText) {
                    nextText = lines[Math.floor(Math.random() * lines.length)];
                }
                
                setFullText(`${nextText}...`); // Add three dots to the new text
                setPreviousText(nextText); // Store the current text as the previous text
            }, 2000 + Math.random() * 1000); // Wait before switching text

            return () => clearTimeout(shuffleTimeout);
        }
    }, [charIndex, fullText, previousText]);  // Add previousText as a dependency

    return <div className="shuffle-text">{currentText}</div>;
};

export default ShufflingText;
