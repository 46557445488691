import React, { useEffect, useState } from 'react';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { getMachineOptions, upsertMachine } from '../../utils/actions/adminActions';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackButton from '../../components/BackButton/BackButton';

const MachineOptions = () => {





    const [formData, setFormData] = useState({
        cnc_set_up_feature_cost1: '',
        cnc_min_set_up_cost1: '',
        cnc_set_up_feature_cost2: '',
        cnc_min_set_up_cost2: '',
        cnc_set_up_feature_cost3: '',
        cnc_min_set_up_cost3: '',
        cnc_program_feature_cost1: '',
        cnc_min_program_cost1: '',
        cnc_program_feature_cost2: '',
        cnc_min_program_cost2: '',
        cnc_program_feature_cost3: '',
        cnc_min_program_cost3: '',
        cnc_per_thread_cost1: '',
        cnc_per_thread_cost2: '',
        cnc_per_thread_cost3: '',
        cnc_per_hr_cost1: '',
        cnc_per_hr_cost2: '',
        cnc_per_hr_cost3: '',
    });
    const [isReload, setIsReload] = useState(false);
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        const { state } = location;
        if (!state) return;
        const { _id } = state;
        if (!_id) return;
        (async () => {

            try {
                const response = await getMachineOptions(_id);
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                console.log("Data :", data);
                setFormData({ ...formData, ...data });
            } catch (error) {
                toast.error("Unable To Fetch Data At The Moment");
            }
        })()
    }, [isReload, location.search])



    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleSubmit = async () => {
        try {
            const { state } = location;
            if (!state) return;
            const { _id } = state;
            if (!_id) return;
            const response = await upsertMachine({ ...formData, _id });

            if (!response || !response.success) {
                throw new Error();
            }
            toast.success("Data Updated Succesfully");
            setIsReload(pre => !pre);

        } catch (error) {
            console.log(error);
            toast.error("Unable To Update Data At The Moment");
        }
    }

    return (
        <div className="p-6 w-screen mx-auto">
            <BackButton
                additionalClasses='mx-2 my-2'
            />
            <h1 className="text-2xl text-center font-bold mb-4">Machine Options</h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {/* Setup Costs */}
                <div>
                    <h2 className="text-lg font-semibold mb-2">Setup Costs $</h2>
                    {[1, 2, 3].map((level) => (
                        <div key={level} className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                Set Up Feature Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_set_up_feature_cost${level}`]}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        handleChange(`cnc_set_up_feature_cost${level}`, e.target.value)
                                    }

                                }}
                                className="border p-2 w-full rounded"
                            />
                            <label className="block text-sm font-medium mb-1 mt-2">
                                Min Set Up Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_min_set_up_cost${level}`]}
                                onChange={(e) => handleChange(`cnc_min_set_up_cost${level}`, e.target.value)}
                                className="border p-2 w-full rounded"
                            />
                        </div>
                    ))}
                </div>

                {/* Program Costs */}
                <div>
                    <h2 className="text-lg font-semibold mb-2">Program Costs $</h2>
                    {[1, 2, 3].map((level) => (
                        <div key={level} className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                Program Feature Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_program_feature_cost${level}`]}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        handleChange(`cnc_program_feature_cost${level}`, e.target.value)
                                    }
                                }}
                                className="border p-2 w-full rounded"
                            />
                            <label className="block text-sm font-medium mb-1 mt-2">
                                Min Program Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_min_program_cost${level}`]}
                                onChange={(e) => handleChange(`cnc_min_program_cost${level}`, e.target.value)}
                                className="border p-2 w-full rounded"
                            />
                        </div>
                    ))}
                </div>
                {/* Machine Cost */}
                <div>
                    <h2 className="text-lg font-semibold mb-2">Machine Cost $</h2>
                    {[1, 2, 3].map((level) => (
                        <div key={level} className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                Per Hour Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_per_hr_cost${level}`]}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        handleChange(`cnc_per_hr_cost${level}`, e.target.value)
                                    }
                                }
                                }
                                className="border p-2 w-full rounded"
                            />
                        </div>
                    ))}
                </div>

                {/* Thread Costs */}
                <div>
                    <h2 className="text-lg font-semibold mb-2">Thread Costs $</h2>
                    {[1, 2, 3].map((level) => (
                        <div key={level} className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                Per Thread Cost {level}:
                            </label>
                            <input
                                type="number"
                                value={formData[`cnc_per_thread_cost${level}`]}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        handleChange(`cnc_per_thread_cost${level}`, e.target.value)
                                    }




                                }
                                }
                                className="border p-2 w-full rounded"
                            />
                        </div>
                    ))}
                </div>
            </div>

            <button
                onClick={() => handleSubmit()}
                className="bg-primaryColor text-white px-4 py-2 rounded mt-6"
            >
                Submit
            </button>
            <BackButton
                additionalClasses={'mx-2 bg-slate-500 text-white'}
            />
        </div>
    );
};

export default AdminDashboardComponents(MachineOptions);
