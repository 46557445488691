import React from "react";
import PhotoViewer from "../../../components/PhotoViewer/PhotoViewer";
import img1 from "../ProjectsImages/CNCMachined/img1 (1).jpg";
import img2 from "../ProjectsImages/CNCMachined/img1 (2).jpg";
import img3 from "../ProjectsImages/CNCMachined/img1 (3).jpg";
import img4 from "../ProjectsImages/CNCMachined/img1 (4).jpg";
import img5 from "../ProjectsImages/CNCMachined/img1 (5).jpg";
import img6 from "../ProjectsImages/CNCMachined/img1 (6).jpg";
import img7 from "../ProjectsImages/CNCMachined/img1 (7).jpg";
import img8 from "../ProjectsImages/CNCMachined/img1 (8).jpg";
import img9 from "../ProjectsImages/CNCMachined/img1 (9).jpg";
import img10 from "../ProjectsImages/CNCMachined/img1 (10).jpg";
import img11 from "../ProjectsImages/CNCMachined/img1 (11).jpg";
import img12 from "../ProjectsImages/CNCMachined/img1 (12).jpg";
import img13 from "../ProjectsImages/CNCMachined/img1 (13).jpg";
import img14 from "../ProjectsImages/CNCMachined/img1 (14).jpg";
import img15 from "../ProjectsImages/CNCMachined/img1 (15).jpg";
import img16 from "../ProjectsImages/CNCMachined/img1 (16).jpg";
import img17 from "../ProjectsImages/CNCMachined/img1 (17).jpg";
import img18 from "../ProjectsImages/CNCMachined/img1 (18).jpg";
import img19 from "../ProjectsImages/CNCMachined/img1 (19).jpg";
import img20 from "../ProjectsImages/CNCMachined/img1 (20).jpg";
import img21 from "../ProjectsImages/CNCMachined/img1 (21).jpg";
import img22 from "../ProjectsImages/CNCMachined/img1 (22).jpg";
import img23 from "../ProjectsImages/CNCMachined/img1 (23).jpg";
import img24 from "../ProjectsImages/CNCMachined/img1 (24).jpg";
import img25 from "../ProjectsImages/CNCMachined/img1 (25).jpg";
import img26 from "../ProjectsImages/CNCMachined/img1 (26).jpg";
import img27 from "../ProjectsImages/CNCMachined/img1 (27).jpg";
import img28 from "../ProjectsImages/CNCMachined/img1 (28).jpg";
import img29 from "../ProjectsImages/CNCMachined/img1 (29).jpg";
import img30 from "../ProjectsImages/CNCMachined/img1 (30).jpg";
import img31 from "../ProjectsImages/CNCMachined/img1 (31).jpg";
import img32 from "../ProjectsImages/CNCMachined/img1 (32).jpg";
import img33 from "../ProjectsImages/CNCMachined/img1 (33).jpg";
import img34 from "../ProjectsImages/CNCMachined/img1 (34).jpg";
import img35 from "../ProjectsImages/CNCMachined/img1 (35).jpg";
import img36 from "../ProjectsImages/CNCMachined/img1 (36).jpg";
import img37 from "../ProjectsImages/CNCMachined/img1 (37).jpg";
import img38 from "../ProjectsImages/CNCMachined/img1 (38).jpg";
import img39 from "../ProjectsImages/CNCMachined/img1 (39).jpg";
import img40 from "../ProjectsImages/CNCMachined/img1 (40).jpg";
import img41 from "../ProjectsImages/CNCMachined/img1 (41).jpg";
import img42 from "../ProjectsImages/CNCMachined/img1 (42).jpg";
import img43 from "../ProjectsImages/CNCMachined/img1 (43).jpg";
import img44 from "../ProjectsImages/CNCMachined/img1 (44).jpg";
import img45 from "../ProjectsImages/CNCMachined/img1 (45).jpg";
import img46 from "../ProjectsImages/CNCMachined/img1 (46).jpg";
import img47 from "../ProjectsImages/CNCMachined/img1 (47).jpg";
import img48 from "../ProjectsImages/CNCMachined/img1 (48).jpg";
import img49 from "../ProjectsImages/CNCMachined/img1 (49).jpg";
import img50 from "../ProjectsImages/CNCMachined/img1 (50).jpg";
import img51 from "../ProjectsImages/CNCMachined/img1 (51).jpg";
import img52 from "../ProjectsImages/CNCMachined/img1 (52).jpg";
import img53 from "../ProjectsImages/CNCMachined/img1 (53).jpg";
import img54 from "../ProjectsImages/CNCMachined/img1 (54).jpg";
import img55 from "../ProjectsImages/CNCMachined/img1 (55).jpg";
import img56 from "../ProjectsImages/CNCMachined/img1 (56).jpg";
import img57 from "../ProjectsImages/CNCMachined/img1 (57).jpg";
import img58 from "../ProjectsImages/CNCMachined/img1 (58).jpg";
import img59 from "../ProjectsImages/CNCMachined/img1 (59).jpg";
// import img60 from "../ProjectsImages/CNCMachined/img1 (60).jpg";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.svg";


const CNCMachiningProjects = () => {
  const imageArr = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    // img60,
  ];
  return (
    <>
      <div className=" flex  bg-primaryColor justify-between items-center px-10 ">
        <Link to="">
          <img className="h-24" src={logo} />
        </Link>{" "}
        <Link to="/login" className=" px-3 py-1 bg-white rounded">
          Back
        </Link>
      </div>
      <div className="csr-section">
        <h4 style={{ textAlign: "center" }}>CNC Machining Projects</h4>
        <span>
          <PhotoViewer images={imageArr} altText={`image`} />
        </span>
      </div>
    </>
  );
};

export default CNCMachiningProjects;
