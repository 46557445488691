import { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
import { getUsers, getallAddressAdmin, handleDownloadUsersData } from '../../utils/actions/allactions';
import _ from 'lodash';
import { getToken, currencyFormatter, extractURLAndEncodedFilename } from "../../utils/helper";
import { Modal, Button } from "react-bootstrap";
import moment from 'moment';
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import useDocumentTitle from '../../utils/useDocumentTitle';
import Pagination from "react-paginate";
import { LucidLoader } from "../../components/Icons";

const UserMaster = () => {
    const navigate = useNavigate();
    useDocumentTitle('Customer User Master')
    let [sortStatus, setSortStatus] = useState(false);
    let [sortField, setSortField] = useState('');
    let [showFltr, setShowFltr] = useState(false);
    let [fltrValues, setFltrValues] = useState({
        userName: '',
    });
    const [paginatedUser, setPaginatedUser] = useState([]);
    let [showModal, setShowModal] = useState(false);
    const [allAddress, setAllAddress] = useState([]);
    const [userAddress, setUserAddress] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [isExcelFileDownloading, setExcelFileDownloading] = useState(false);
    let token = getToken();
    let decode;

    const handleClose = () => setShowModal(false);

    let pageSize = 10;

    const handleSort = (field) => {
        const users = [...allUsers]
        setSortField(field);
        setSortStatus(!sortStatus);

        if (field === 'userName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a['email'] < b['email'] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a['email'] > b['email'] ? -1 : 1;
                }
            })
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'firstName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            });
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'lastName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            });
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }

        else if (field === 'phoneNumber') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            });
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'accountStatus') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field == 'lastPoDate') {
            let data = [...users].sort((a, b) => {
                const momentA = a.lastPoDate ? moment(a.lastPoDate) : null;
                const momentB = b.lastPoDate ? moment(b.lastPoDate) : null;
                if (momentA === null && momentB === null) {
                    return 0;
                } else if (momentA === null) {
                    return sortStatus ? -1 : 1;
                } else if (momentB === null) {
                    return sortStatus ? 1 : -1;
                } else {
                    if (momentA.isBefore(momentB)) {
                        return sortStatus ? -1 : 1;
                    } else if (momentA.isAfter(momentB)) {
                        return sortStatus ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
            })
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        } else if (field == 'purchaseValueYearToDate') {
            let data = [...users].sort((a, b) => {
                if (sortStatus == true) {
                    return a[field] < b[field] ? -1 : 1
                }
                else if (sortStatus == false) {
                    return a[field] > b[field] ? -1 : 1
                }
            });
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1
                }
                else if (sortStatus === false) {
                    return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
                }
            })
            setAllUsers(data);
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
    }

    const handleRefresh = () => {
        handleSubmit();
        setFltrValues({
            userName: '',
        })
        setShowFltr(false);
    }

    const handleUserNameFilter = (e) => {
        const users = [...allUsers];
        setFltrValues((val) => ({
            ...val,
            userName: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let userNames = users.map((val, i) => {
            return val.email;
        });

        userNames.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        });

        let filteredData = users.filter((val) => newFltrArr.includes(val.email));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0);
        setPaginatedUser(_(filteredData).slice(0).take(pageSize).value());
    }


    const handleSubmit = async () => {
        try {
            const allUsers = await getUsers(token);
            setAllUsers(allUsers);
            setPaginatedUser(_(allUsers).slice(0).take(pageSize).value());
            setPageCount(allUsers ? Math.ceil(allUsers.length / pageSize) : 0)

            let response = await getallAddressAdmin(token);
            setAllAddress(response);
        }
        catch (err) {
            let message = err && err.message ? err.message : "Something went wrong during fetching companyData";
            return false;
        }
    };

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        else {
            decode = jwt_decode(token);
            if (!decode) {
                navigate('/');
                toast.error('Invalid Token found!');
            }
            else if (decode) {
                if (decode.userType === 'admin') {
                    handleSubmit();
                }
                else if (decode.userType === 'user') {
                    navigate('/saved-quote');
                    // toast.error('Unauthorized Access!');
                }
                else {
                    navigate('/');
                    // toast.error('Unauthorized Access!');
                }
            }
        }
    }, []);



    const handleCreateNew = () => {
        navigate('/admin/add-user')
    }

    const handleEditUser = (userToBeEdited) => {
        // localStorage.setItem("userToBeEdited", JSON.stringify(userToBeEdited));
        navigate(`/admin/add-user/${userToBeEdited._id}`);
    }



    const handleViewAddresses = (userId) => {
        setShowModal(true);
        let localAddress = [];

        allAddress.map((address) => {
            if (address?.userId?._id === userId) {
                localAddress.push(address);
            }
        })
        setUserAddress(localAddress);
    }

    const handleExcelDownload = async () => {
        setExcelFileDownloading(true);
        try {
            const response = await handleDownloadUsersData(token);
            if (response.status) {
                let url = `${config.backEnd}/${response.data}`;
                download("User_Information.xlsx", url);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading the excel file.")
            console.log("err", err);
        } finally {
            setExcelFileDownloading(false);
        }
    }

    const download = async (name, url) => {
        const data = extractURLAndEncodedFilename(url);
        let fileUrl = `${data.url}/${data.encodedFilename}`;
        fileSaver.saveAs(fileUrl, name);
    };


    let handleActivePage = async (value) => {
        let pageNum = value.selected + 1;
        setPageNum(pageNum)
        let localUsers = [...allUsers]
        let limit = 10;
        let offset = (pageNum - 1) * limit;
        let paginatedUser = localUsers.slice(offset, offset + limit);
        setPaginatedUser(paginatedUser);
    };

    return (
        <div className="admin-container">
            <Sidebar />
            <div className="right-container">
                <div className="center-heading">
                    <h1 className="font-bold text-xl">  User Master </h1>

                    <Modal
                        size="sm"
                        dialogClassName="qoute-version-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        onHide={handleClose}

                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                                User Addresses
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="addresses">
                                <div className="cardContainer">
                                    {userAddress?.map((elem, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="card">
                                                    <div className="addressContainer" >
                                                        <div className="name">{elem.firstName} {elem?.lastName}</div>
                                                        <div className="address">
                                                            {elem?.addressLineOne}
                                                            <div className="landMark">
                                                                {" "}
                                                                {elem?.addressLineTwo}
                                                            </div>
                                                            <div className="state">{elem.city} {elem?.state} </div>
                                                            <div className="country">{elem?.country} {elem?.postalCode}</div>
                                                            <div className="mobile">
                                                                {elem?.phoneNo ? `Mobile: +${elem.dialCode}-${elem?.phoneNo}` : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleClose} className="req-quote-btn">Close</Button>
                        </Modal.Footer>
                    </Modal>


                    <div className="search-bar">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">Email</label>
                                    <input type="text" value={fltrValues.userName} placeholder="Email" className="form-control" onChange={handleUserNameFilter} />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="search-btn">
                                    {showFltr ?
                                        <button onClick={handleRefresh} className="button-search">Reset all filters</button> : ''}
                                    <button onClick={handleExcelDownload} className="button-search min-w-[150px] h-10  flex items-center justify-center">
                                        {!isExcelFileDownloading ?
                                            <span>Download Excel</span>
                                            :
                                            <LucidLoader additionalClass={'h-5'} />
                                        }
                                    </button>
                                    <button onClick={handleCreateNew} className="pr-edit-btn">Create New User</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="quotes-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('userName')}>User Name {sortField === 'userName' ? '↓' : '↑'} </th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('firstName')}>First Name {sortField === 'firstName' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('lastName')}>Last name {sortField === 'lastName' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('phoneNumber')}>Phone Number {sortField === 'phoneNumber' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('accountStatus')}>Status {sortField === 'accountStatus' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('purchaseValueYearToDate')}>PVYTD {sortField === 'purchaseValueYearToDate' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('lastPoDate')}>Last_PO_Date {sortField === 'lastPoDate' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }}>Delivery Address </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedUser?.length > 0 &&
                                    paginatedUser.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.email ? item.email : ''}</td>
                                                <td>{item.firstName ? item.firstName : ''}</td>
                                                <td>{item.lastName ? item.lastName : ''}</td>
                                                <td>{item.phoneNumber ? item.phoneNumber : ''}</td>
                                                <td>{item.accountStatus ? item.accountStatus : ''}</td>
                                                <td>{item.purchaseValueYearToDate ? currencyFormatter(Number(item.purchaseValueYearToDate).toFixed(2)) : ''}</td>
                                                <td>{item.lastPoDate ? moment(item.lastPoDate).format('DD MMM YYYY') : ''}</td>
                                                <td><button className="button-search" onClick={(e) => handleViewAddresses(item._id)}>View Addresses</button></td>
                                                <td> <button className="button-edit" onClick={() => { handleEditUser(item) }}>Edit/Deactivate</button></td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="floatRight">
                    <Pagination
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handleActivePage}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>
        </div>

    )
}

export default UserMaster;