import logo from "../../images/logo.png";
import tick from "../../images/tick.svg";
import cornerone from "../../images/corner-one.svg";
import cornertwo from "../../images/corner-two.svg";
import cornerthree from "../../images/corner-three.svg";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import {
    checkEmail,
    loginViaPassword,
    triggerLoginOTP,
    loginViaOTP,
    registerNewUser,
    forgotPassword,
} from "../../utils/actions/loginactions";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import jwt_decode from "jwt-decode";
import qs from "query-string";
import { numberInputOnWheelPreventChange } from "../../utils/helper";
import { firebaseSignOnLoginSuccess } from "../../utils/firebase";
import moment from "moment";
import { CircleAlertDFM, CrossIcon, SuccessIcon } from "../Icons";
import { checkCreateUser, handleUpdateLogFlag, transferQuote } from "../../utils/actions/userActions";
import { Modal } from "react-bootstrap";

const SignInModal = ({ show, onSignIn, onClose, userEmail, showNotEmailOption }) => {
    const {quoteId} = useParams();

    if (!show) return null;
    const navigate = useNavigate();
    let token = localStorage.getItem("Token");
    let decode;

    const [values, setValues] = useState({
        email: userEmail,
        password: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companySiteId: "",
        companySite: "",
        otp: "",
    });


    useEffect(() => {
        if (userEmail && show) {
            checkIsEmail();
        }
    }, [show, userEmail])

    const [emailCheck, setEmailCheck] = useState({});
    const [isEmailCheck, setIsEmailCheck] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [otpTriggered, setOtpTriggered] = useState(false);
    const [check, setCheck] = useState(true);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isNotYouEmailClicked, setIsNotYourEmailClicked] = useState(false);
    const [firstUserId, setFirstUserId] = useState("");
    const [secondUserId, setSecondUserId] = useState("");

    const { search } = window.location;
    const param = qs.parse(search);

    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });
    const [isloading, setIsLoading] = useState(false);

    function handleErrorSuccess(text) {
        if (text == 'success') {
            setIsSuccess(true);
            setIsError(false);
        }
        else if (text == 'error') {
            setIsSuccess(false);
            setIsError(true);
        }
        else {
            setIsSuccess(false);
            setIsError(false);
        }
    }



    const checkIsEmail = async () => {

        const id = localStorage.getItem("_id");
        const token = localStorage.getItem("Token");
        setIsLoading(true);
        if (!id || !token) {
            const response = await checkCreateUser(values.email);
            if (!response || !response.success) {
                throw new Error();
            };
            const { data } = response;
            const { _id, token } = data;
            if (_id) {

                localStorage.setItem("_id", _id);
                if (!isNotYouEmailClicked) {
                    setFirstUserId(_id);
                }
                else {
                    setSecondUserId(_id);
                }
            }
            if (token) {
                localStorage.setItem("Token", JSON.stringify(token));
            }
        }


        try {
            const payload = {
                email: values.email,
            };
            const response = await checkEmail({ payload });
            if (response.statuscode !== 200) {
                // toast.error(response.message);
                setMessage(response.message);
                handleErrorSuccess('error');
                setIsLoading(false);
                return;
            }
            setEmailCheck(response.data);
            setValues({
                ...values,
                companySiteId: response.data?.company?._id,
                companySite: response.data?.sites[0],
            });
            if (response.data.isAdmin || !response.data.isVerified) {
                if (!response.data.isNewUser) {
                    triggerOTP();
                    setOtpTriggered(true);
                    setShowOtp(true);
                }
            }
            setIsEmailCheck(true);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            // toast.error(message);
            setMessage(message);
            handleErrorSuccess('error');
            return false;
        }
    };
    const loginPassword = async () => {
        setIsLoading(true);
        try {
            let response;
            if (otpTriggered) {
                const payload = {
                    email: values.email,
                    otp: values.otp,
                    signedInFor7Days: check,
                };
                if (!values.otp) {
                    toast.error("Please enter the OTP");
                    setIsLoading(false);
                    return;
                }

                response = await loginViaOTP({ payload });
            } else {
                const payload = {
                    email: values.email,
                    password: values.password,
                    signedInFor7Days: check,
                };

                if (!values.password) {
                    // toast.error();
                    setMessage("Please enter the password");
                    handleErrorSuccess('success');
                    setIsLoading(false);
                    return;
                }
                response = await loginViaPassword({ payload });
            }
            if (response.statuscode === 200) {
                // toast.success(response.message);
                setMessage(response.message);
                handleErrorSuccess('success');
                if (quoteId) {
                    await handleUpdateLogFlag(quoteId);
                }

                localStorage.setItem("Token", JSON.stringify(response?.data?.token));
                localStorage.setItem("firstName", response?.data?.firstName);
                localStorage.setItem("lastName", response?.data?.lastName);
                localStorage.setItem("email", response?.data?.email);
                localStorage.setItem("_id", response?.data?._id);
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("7DayLogin", check);
                localStorage.setItem(
                    "tokenValidTill",
                    new Date(Date.now() + 86400000 * 30).valueOf()
                );
                // if (isNotYouEmailClicked && secondUserId && firstUserId) {
                // await transferQuote()
                // }
                const isUserNotLoggedIn = localStorage.getItem("isLoggedIn");

                onSignIn();
                onClose();
            } else {
                setMessage("Unable to log in. Please verify your credentials.");
                handleErrorSuccess('error');
                // toast.error(response.message);
            }
            setIsLoading(false);
        } catch (err) {
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            setMessage(message);
            handleErrorSuccess('error');
            // toast.error();
            setIsLoading(false);
            return false;
        }
    };

    const triggerOTP = async () => {
        setShowOtp(true);
        setIsLoading(true);
        try {
            const payload = {
                email: values.email,
            };
            const response = await triggerLoginOTP({ payload });
            setOtpTriggered(true);
            setMessage("We've sent a One-Time Password (OTP) to the email address you entered.");
            handleErrorSuccess('success');
            setIsLoading(false);
        } catch (err) {
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            setMessage(message);
            handleErrorSuccess('error');
            setIsLoading(false);
            return false;
        }
    };

    function checkPassword(password) {
        if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@@#\$%\^&\*])(?=.{6,})/.test(
                password
            )
        ) {
            return true;
        }
        return false;
    }

    const registerUser = async () => {

        setIsLoading(true);
        try {
            let payload;
            const tempUserId = localStorage.getItem("_id");
            if (values.companySiteId) {
                const {
                    email,
                    password,
                    firstName,
                    lastName,
                    companySiteId,
                    companySite,
                } = values;
                payload = {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    companySiteId: companySiteId,
                    userCompanySite: companySite,
                    _id: tempUserId,
                };
                if (
                    !password ||
                    !firstName ||
                    !lastName ||
                    !companySiteId ||
                    !companySite
                ) {
                    // toast.error("Please enter the required details");
                    setMessage("Please enter the required details");
                    handleErrorSuccess('error');
                    setIsLoading(false);
                    return;
                }

                if (!checkPassword(password)) {
                    setMessage("Please enter valid password");
                    handleErrorSuccess('error');
                    setIsLoading(false);
                    return;
                }
            } else if (emailCheck.isAdmin) {
                const { email, firstName, lastName } = values;
                payload = {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                };
                if (!firstName || !lastName) {
                    // toast.error("Please enter the required details");
                    setMessage("Please enter the required details");
                    handleErrorSuccess('error');
                    setIsLoading(false);
                    return;
                }
            } else {
                const { email, password, firstName, lastName, companyName } = values;
                const tempUserId = localStorage.getItem("_id");
                payload = {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    companyName: companyName,
                    _id: tempUserId,

                };
                const isEmailValid = isValidEmail(payload.email);

                if (payload?.email && payload?.email.split("@")[1] == '8xparts.com') {
                    // toast.error("The requested domain is not allowed.");
                    setMessage("The requested domain is not allowed.");
                    handleErrorSuccess('error');
                    return;
                }



                if (!isEmailValid) {
                    setMessage("Please Enter A Valid Email Id");
                    handleErrorSuccess('error');
                    return;
                }



                if (!password || !firstName || !lastName) {
                    // toast.error("Please enter the required details");
                    setMessage("Please enter the required details");
                    handleErrorSuccess('error');
                    setIsLoading(false);
                    return;
                }
                if (!checkPassword(password)) {

                    // toast.error("Please enter valid password");
                    setMessage("Please enter valid password");
                    handleErrorSuccess('error');
                    setIsLoading(false);
                    return;
                }
            }

            const response = await registerNewUser({ payload });
            // toast.success();
            setMessage(response.message);
            handleErrorSuccess('success');

            setShowOtp(true);
            setOtpTriggered(true);
            setIsLoading(false);
            setIsRegistered(true);
        } catch (err) {
            console.log("Error :", err);
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            // toast.error(message);
            setMessage(message);
            handleErrorSuccess('error');
            setIsLoading(false);
            return false;
        }
    };


    const isShow = () => {
        const { isNewUser, isAdmin } = emailCheck;

        if (!isNewUser && isEmailCheck) {
            if (showPassword || showOtp) {
                return false;
            }
            return true;
        }

        if (!isNewUser && isAdmin) return true;
    };

    const handleonChange = (e) => {
        setValues({ ...values, companySite: e.target.value });
    };

    const onKeyDown = (e) => {
        const code = e.keyCode ? e.keyCode : e.which;
        if (code == 13) {
            e.preventDefault();
            if (!emailCheck.isNewUser) {
                if (showPassword || showOtp) loginPassword();
                else {
                    checkIsEmail();
                }
            } else {
                if ((otpTriggered || !emailCheck.isAdmin) && showOtp) {
                    loginPassword();
                } else registerUser();
            }
        }
    };

    const resetPage = () => {
        setIsEmailCheck(false);
        setValues({
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companySiteId: "",
            companySite: "",
            otp: "",
        });
        setEmailCheck({});
        setIsEmailCheck(false);
        setShowPassword(false);
        setShowOtp(false);
        setIsRegistered(false);
        setOtpTriggered(false);
        setCheck(true);
        setErrors({
            email: "",
            password: "",
        });
        setIsLoading(false);
    };

    const triggerSetPassword = async () => {
        try {
            const payload = {
                email: values.email,
            };
            const response = await forgotPassword({ payload });
            // toast.success(response.message);
            setMessage(response.message);
            handleErrorSuccess('success');
            // console.log(response);
        } catch (err) {
            console.log(
                "error in loginjs 508 on" +
                moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
                err
            );
            // toast.error(response.message);
        }
    };

    // reset password validation
    const [validation, setValidation] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        specialChar: false,
    });

    const validatePassword = (password) => {
        setValidation({
            length: password.length >= 6,
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            digit: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*()_+~]/.test(password),
        });
    };

    const isButtonDisabled = !Object.values(validation).every(Boolean);

    console.log("Is New User :", emailCheck);


    return (
        <Modal
            show={show}
            onHide={() => onClose()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Sign in to your account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="login-form-bx">
                    <div className="mb-3">
                        <div className="email-and-change-container">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label-email"
                            >
                                Email
                            </label>
                            {(isEmailCheck) && (
                                <div onClick={() => {
                                    resetPage()
                                    handleErrorSuccess();
                                    setIsNotYourEmailClicked(true);
                                }}>
                                    {" "}
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label-email"
                                    >
                                        Not your email ? Click here
                                    </label>
                                </div>
                            )}
                        </div>
                        <input
                            autoComplete="username"
                            type="email"
                            className="form-control"
                            id="exampleInputEmails1"
                            placeholder="Enter here"
                            aria-describedby="emailHelp"
                            value={values.email}
                            disabled={isEmailCheck}
                            onChange={(e) => {
                                setValues({ ...values, email: e.target.value });
                            }}
                            onKeyDown={(e) => onKeyDown(e)}
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                    </div>


                    {emailCheck.isNewUser && !showOtp ? (
                        <div className="names-field">
                            <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter here"
                                    value={values.firstName}
                                    onChange={(e) => {
                                        setValues({ ...values, firstName: e.target.value });
                                    }}
                                />
                                {errors.password && (
                                    <span className="error">{errors.password}</span>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter here"
                                    value={values.lastName}
                                    onChange={(e) => {
                                        setValues({ ...values, lastName: e.target.value });
                                    }}
                                />
                                {errors.password && (
                                    <span className="error">{errors.password}</span>
                                )}
                            </div>

                            {(isError) ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-red-100 border border-red-300 text-red-800 rounded-md px-3 py-2`}>

                                <CircleAlertDFM
                                    additionalClasses={'!h-4'}
                                />
                                <span className="font-medium">{message}</span>
                            </div> : null}
                        </div>
                    ) : null}

                    {(isEmailCheck &&
                        emailCheck.isNewUser &&
                        !emailCheck.isAdmin &&
                        !showOtp) ||
                        showPassword ? (
                        <div className="mb-3">
                            {(isSuccess) ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-green-100 border border-green-300 text-green-800  rounded-md px-3 py-2`}>
                                <SuccessIcon
                                    additionalClasses={'h-5'}
                                />

                                <span className="font-medium">{message}</span>
                            </div> : null}
                            <div className="email-and-change-container">

                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label"
                                >
                                    {emailCheck.isNewUser ? "Set Password" : "Password"}
                                </label>
                                {!emailCheck.isNewUser && (
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label-email"

                                    >
                                        Forgot Password ? <span className="text-primaryColor" onClick={triggerSetPassword}>Click here</span>
                                    </label>
                                )}
                            </div>

                            <input
                                autoComplete="current-password"
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter here"
                                value={values.password}
                                onChange={(e) => {
                                    setValues({ ...values, password: e.target.value });
                                    validatePassword(e.target.value)
                                }}
                                onKeyDown={(e) => onKeyDown(e)}
                            />
                            {emailCheck.isNewUser && (
                                <div className="password-validation">
                                    <ul>
                                        <li style={{ color: validation.length ? "green" : "red" }}>
                                            Must be at least 6 characters long
                                        </li>
                                        <li style={{ color: validation.lowercase ? "green" : "red" }}>
                                            Must contain at least one lowercase letter
                                        </li>
                                        <li style={{ color: validation.uppercase ? "green" : "red" }}>
                                            Must contain at least one uppercase letter
                                        </li>
                                        <li style={{ color: validation.digit ? "green" : "red" }}>
                                            Must contain at least one digit
                                        </li>
                                        <li style={{ color: validation.specialChar ? "green" : "red" }}>
                                            Must contain at least one special character (~!@#$%^&*()_+)
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : null}

                    {showOtp ? (
                        <div className="mb-3">
                            {(isSuccess) ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-green-100 border border-green-300 text-green-800  rounded-md px-3 py-2`}>
                                <SuccessIcon
                                    additionalClasses={'h-5'}
                                />

                                <span className="font-medium">{message}</span>
                            </div> : null}
                            <label className="form-label">Enter OTP</label>
                            <input
                                type="number"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter here"
                                value={values.otp}
                                onWheel={numberInputOnWheelPreventChange}
                                onChange={(e) => {
                                    setValues({ ...values, otp: e.target.value });
                                }}
                                onKeyDown={(e) => onKeyDown(e)}
                            />
                            <div className="my-2 ">
                                <span className="resend-otp" onClick={triggerOTP}>
                                    Resend OTP
                                </span>
                            </div>
                            {errors.password && (
                                <span className="error">{errors.password}</span>
                            )}
                        </div>
                    ) : null}
                    {isShow() && (
                        <div className=" flex gap-x-2 my-2">
                            {emailCheck?.isVerified ? (
                                <div
                                    className="bg-primaryColor text-white py-1 px-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setShowPassword(true)
                                        handleErrorSuccess();
                                    }}
                                >
                                    Login via Password
                                </div>
                            ) : null}
                            <div
                                className="bg-primaryColor text-white py-1 px-2"
                                style={{ cursor: "pointer" }}
                                onClick={triggerOTP}
                            >
                                Login via OTP
                            </div>
                        </div>
                    )}

                    {(isError) ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-red-100 border border-red-300 text-red-800 rounded-md px-3 py-2`}>

                        <CircleAlertDFM
                            additionalClasses={'!h-4'}
                        />
                        <span className="font-medium">{message}</span>
                    </div> : null}
                    <div className="mb-3">
                        <div className="check-signup">
                            <p>
                                By acknowledging, you confirm your agreement to our {" "}
                                <a
                                    href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                                    className="text-primaryColor underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Service
                                </a>
                                {" "} and{" "}
                                <a
                                    href={process.env.REACT_APP_PRIVACY_URL}
                                    className="text-primaryColor underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>.
                            </p>

                        </div>
                    </div>
                    {isloading ? (
                        <Loading />
                    ) : !emailCheck.isNewUser ? (
                        !isShow() && (
                            <button
                                href="#"
                                style={{ cursor: "pointer" }}
                                className="primary-btn mt-2"
                                onClick={
                                    showPassword || showOtp ? loginPassword : checkIsEmail
                                }
                            >
                                Submit
                            </button>
                        )
                    ) : (
                        <button
                            href="#"
                            style={{ cursor: "pointer" }}
                            className="primary-btn mt-2"
                            onClick={
                                (otpTriggered || !emailCheck.isAdmin) && showOtp
                                    ? loginPassword
                                    : registerUser
                            }
                            disabled={isButtonDisabled}
                        >
                            Submit
                        </button>
                    )}
                </form>
                {(showPassword || showOtp) && (
                    <div className="change-login">
                        <div className="signin-week">
                            <input
                                type="checkbox"
                                checked={check}
                                onClick={() => setCheck(!check)}
                            />
                            <div className="signin-text">Stay signed in for a week</div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            {isEmailCheck &&
                                (showPassword || showOtp) &&
                                !emailCheck.isNewUser &&
                                !emailCheck.isAdmin && (
                                    <div
                                        onClick={
                                            !showPassword
                                                ? () => {
                                                    setShowPassword(true);
                                                    handleErrorSuccess();
                                                    setShowOtp(false);
                                                    setOtpTriggered(false);
                                                }
                                                : () => {
                                                    triggerOTP();
                                                    setShowPassword(false);
                                                }
                                        }
                                        className="login-text"
                                    >
                                        {!showPassword && !emailCheck.isNewUser
                                            ? "Login via Password"
                                            : "Login via OTP"}
                                    </div>
                                )}
                        </div>
                    </div>
                )}


            </Modal.Body>
        </Modal>
    );
};

export default SignInModal;



function isValidEmail(email) {
    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is a string and matches the pattern
    return typeof email === "string" && emailRegex.test(email);
}