export const processType = [
    { id: 1, name: 'CNC Machining' },
    { id: 2, name: '3D Printing' },
    { id: 3, name: 'Sheet Metal Fabrication' },
    { id: 4, name: 'Injection Molding/Casting' },
    { id: 5, name: 'Complete Assembly' },
    { id: 6, name: 'Vacuum Casting' },
    { id: 7, name: 'Jigs and Fixtures' },
    { id: 8, name: 'Off-the-shelf parts (OEM)' },
    { id: 9, name: '3D Printing' },
    { id: 10, name: 'CNC Machining' },
    { id: 11, name: 'Other' },
    { id: 12, name: 'Reverse Engineering' }
]
export const commanFields = {
    cncHeatTreatment: "",
    cncInternalCorner: "",
    cncMaterial: "",
    cncParkMarking: "",
    cncReviewMyDesign: "",
    cncSurfaceFinish: "",
    cncTolerance: "",
    Notes: "",
    noOfThread: 0,
    Qty: 1,
    price1: 0,
    price2: 0,
    price3: 0,
    XDimensionValue: 0,
    YDimensionValue: 0,
    ZDimensionValue: 0,
    zAxisOfPrinting: 0,
    partVolume: 0,
    threeDTechnology: "",
    threeDMachine: "",
    threeDMaterial: "",
    threeDPostProcessing: "",
    thumbnail: "",
    stagingPartDataId: "",
    masterPartsDataCNCId: "",
    masterPartsDataThreeDId: "",
    s3QrFileUrl: "",
    jsonFileUrl: "",
    gltfPlainFileUrl: "",
    gltfColoredFileUrl: "",
    stagingPartsDataId: "",
    isOutOfSpec: false,
}


export const raiseIssueSteps = ["Select parts with issues", "Select issue(s)", "Issue Description"]
export const qualityRelatedIssue = ["Wrong part(s)", "Wrong Dimensions", "Missing Parts", "Wrong Material"]
export const orderRelatedIssue = ["Wrong billing address", "Wrong shipping address", "Missing Parts", "Wrong Material"]
export const quoteVersionDescriptions = [
    { id: 'CC', value: 'Customer Created' },
    { id: 'CE', value: 'Customer Edits' },
    { id: 'DU', value: 'Design Update' },
    { id: 'AR', value: 'Admin Review' },
    { id: 'CU', value: 'Cost Updated' },
    { id: 'RO', value: 'Repeat Order' },
    { id: 'SP', value: 'Spare Parts' },
    { id: 'AC', value: 'Admin Created' },
]

export const pathNameForPublicAccess = ["/Sustainability-CSR", "/privacy-policy", "/terms-of-service", "/view-order-details/"];
export const projectCategory = [
    { name: "3D Printing", path: "/projects/three-d-printing" },
    { name: "Casting", path: "/projects/casting" },
    { name: "CNC Machining", path: "/projects/cnc-machining" },
    { name: "Completed Assembly", path: "/projects/complete-assembly" },
    // {name:"Packaging",path:"/projects/packaging"}
];

export const calculatePriceOfCertifications = (quote) => {
    if (!quote) return 0;
    let { certifications: certificationArray } = quote;
    const { partsData } = quote;
    let totalCost = 0;
    certificationArray = Array.isArray(certificationArray) ? certificationArray : [];
    certificationArray.map((cert) => {
        const { price, certificationType } = cert;
        if (certificationType == 'per order') {
            totalCost += price;
        }
        if (certificationType == 'per line item') {
            totalCost += (partsData.length * price);
        }
    })
    return totalCost;

}

export const MATERAILS_VALID_FOR_ML = ['Aluminum', "Nylon", "PEEK", "PMMA", "Polycarbonate", "PTFE", "Stainless Steel",]

export const miliMeterToInchFactor = 25.4;

export const maxFileUploadSize = 104857600;


export function isValidEmail(email) {

    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
}

export function extractNameAndDomain(email) {
    // Validate the email format
    if (typeof email !== 'string' || !email.includes('@')) {
        throw new Error("Invalid email address");
    }

    const username = email.split('@')[0];

    const domain = email.split('@')[1];

    const formattedName = username
        .replace(/[\._-]/g, ' ')  // Replace special characters with spaces
        .split(' ')               // Split into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' ');               // Rejoin as a single string

    // Return both the formatted name and domain
    return {
        name: formattedName,
        domain: domain
    };
}


export function generateRandomString(length = 8) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
        randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
}
export function calculateVolume(x, y, z, type) {

    let volumeMm3 = x * y * z;
    if (type === "mm") {
        return volumeMm3;
    } else if (type === "in") {
        // Convert volume to cubic inches (1 inch = 25.4 mm)
        let volumeIn3 = parseFloat(volumeMm3 / (25.4 ** 3)); // Convert mm^3 to in^3
        console.log("New Volume :", volumeIn3);
        return volumeIn3;
    } else {
        return "Invalid type. Please use 'mm' or 'in'.";
    }
}


// export const samplePartsData = [
//     {
//         name:"Central Hub.stp",
//         s3Url:''
//     },
//     {
//         name:"Housing Base.STEP",
//         s3Url:''
//     },
//     {
//         name:"Product Casing.step",
//         s3Url:''
//     },
//     {
//         name:'Tool Holder.stp',
//         s3Url:''
//     }
// ]

export const samplePartsData = [
    {
        name: "Housing Base.STEP",
        thumbnail: "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Housing+Base.png",
        stepFileUrl: 'https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Housing+Base.stp',
        title: "Housing Base",
        description: "Highlights the precision milling capabilities for producing highly accurate components.",
        selectedProcess:1,
    },
    {
        name: "Central Hub.stp",
        thumbnail: "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Central+Hub.png",
        stepFileUrl: 'https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Central+Hub.stp',
        title: "Central Hub",
        description: "Showcases the expertise in creating turned-milling parts with superior quality.",
        selectedProcess:1,
    },
    {
        name:'Tool Holder.stp',
        thumbnail: "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Tool+Holder.png",
        stepFileUrl: 'https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Tool+Holder.stp',
        title: "Tool Holder",
        description: "A lightweight plastic prototype showcasing 3D printing for scalable production volumes.",
        selectedProcess:2,
    },
    {
        name:"Product Casing.step",
        thumbnail: "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Product+Casing.png",
        stepFileUrl: 'https://dainstamakeimages.s3.eu-west-1.amazonaws.com/Product+Casing.step',
        title: "Product Casing",
        description: " Demonstrates the use of 3D printing for efficient and cost-effective rapid prototyping.  ",
        selectedProcess:2,
    }
]