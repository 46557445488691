import React, { useEffect, useState } from 'react'
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents'
import { getAllInternalCorner, getAllPartMarking, getAllReviewDesign, getAllSurfaceFinish, getAllTolerance, getMaterialDataWithPopulate, upsertMaterial } from '../../utils/actions/adminActions';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form'
import { getPartMaking, updateMaterial } from '../../utils/actions/allactions';
import { getAllHeatTreatment } from '../../utils/actions/commanActions';
import BackButton from '../../components/BackButton/BackButton';

const SelectSubMaterials = () => {
    const [selectedItem, setSelectedItem] = useState(1);
    const [data, setData] = useState({});
    const [isReload, setIsReload] = useState(false);
    const location = useLocation();
    const [allSurfaceFinish, setAllSurfaceFinish] = useState([]);
    const [allHeatTreatMent, setAllHeatTreatMent] = useState([]);
    const [allTolerance, setAllTolerance] = useState([]);
    const [partMarking, setAllPartMarking] = useState([]);
    const [allInternalCorner, setAllInternalCorner] = useState([]);
    const [allReviewDesign, setAllReviewDesign] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        const state = location.state;
        if (!state) return;
        const { _id } = state;
        (async () => {
            const response = await getMaterialDataWithPopulate(_id);
            if (!response || !response.success) {
                throw new Error();
            };
            const { data } = response;
            setData(data);
        })()
    }, [isReload, location.search]);


    useEffect(() => {
        (async () => {
            try {

                const response = await getAllSurfaceFinish();
                if (!response || !response.success) {
                    throw new Error();
                };

                const { data } = response;
                setAllSurfaceFinish(data);

            } catch (error) {
                toast.error("Unable To Fetch Surface Finish Data At The Moment");
            }
        })()

    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllHeatTreatment();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                setAllHeatTreatMent(data);
            } catch (error) {
                toast.error("Unable To Fetch Heat Treatment At The Moment");
            }
        })()

    }, []);

    useEffect(() => {
        (async () => {
            const response = await getAllTolerance();
            if (!response || !response.success) {
                throw new Error();
            }
            const { data } = response;
            setAllTolerance(data);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            try {

                const response = await getAllPartMarking();

                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                setAllPartMarking(data);

            } catch (error) {
                toast.error("Unable To Fetch Part Marking AT The Moment");
            }
        })()

    }, [])

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllInternalCorner();

                if (!response || !response.success) {
                    throw new Error();
                };

                const { data } = response;
                setAllInternalCorner(data);

            } catch (error) {
                toast.error("Unable To Get Part Marking Data AT The Moment");
            }
        })()

    }, []);

    useEffect(() => {
        (async () => {

            try {
                const response = await getAllReviewDesign();
                if (
                    !response || !response.success
                ) {
                    throw new Error();
                }
                const { data } = response;
                setAllReviewDesign(data);

            } catch (error) {
                toast.error("Unable To Get Review Design At The Moment");
            }



        })()

    }, [])


    useEffect(() => {
        if (!data?._id) return;
        let intervalId;
        setTimeout(async () => {
            await upsertMaterial(data);
        }, 2000);
        return () => clearTimeout(intervalId);
    }, [data])



    return (
        <div className='w-screen'>
            <BackButton
                additionalClasses='mx-2 my-2'
            />
            <h2 className='text-center my-2 text-2xl font-bold'>
                Parameters
            </h2>
            <div className='px-5 '>
                <div className='flex justify-around'>
                    <button
                        onClick={() => setSelectedItem(1)}
                        className={`px-2 py-1 text-white ${selectedItem !== 1 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 1}
                    >
                        Surface Finish
                    </button>
                    <button
                        onClick={() => setSelectedItem(2)}
                        className={`px-2 py-1 text-white ${selectedItem !== 2 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 2}
                    >
                        Heat Treatment
                    </button>
                    <button
                        onClick={() => setSelectedItem(3)}
                        className={`px-2 py-1 text-white ${selectedItem !== 3 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 3}
                    >
                        Tolerance
                    </button>
                    <button
                        onClick={() => setSelectedItem(4)}
                        className={`px-2 py-1 text-white ${selectedItem !== 4 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 4}
                    >
                        Part Marking
                    </button>
                    <button
                        onClick={() => setSelectedItem(5)}
                        className={`px-2 py-1 text-white ${selectedItem !== 5 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 5}
                    >
                        Internal Corner
                    </button>
                    <button
                        onClick={() => setSelectedItem(6)}
                        className={`px-2 py-1 text-white ${selectedItem !== 6 ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor'}`}
                        disabled={selectedItem === 6}
                    >
                        Review Design
                    </button>
                </div>
                <div className='my-5'>
                    {
                        selectedItem == 1 && <div>
                            <table className="table-auto w-full border-collapse">
                                <thead className="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th className="px-4 py-2 border border-gray-200">CheckBox</th>
                                        <th className="px-4 py-2 border border-gray-200">Name</th>
                                        <th className="px-4 py-2 border border-gray-200">Manual Review</th>
                                        <th className="px-4 py-2 border border-gray-200">Per Kg Cost 1 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Min Cost 1 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Lead Time 1 (days)</th>
                                        <th className="px-4 py-2 border border-gray-200">Per Kg Cost 2 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Min Cost 2 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Lead Time 2 (days)</th>
                                        <th className="px-4 py-2 border border-gray-200">Per Kg Cost 3 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Min Cost 3 $</th>
                                        <th className="px-4 py-2 border border-gray-200">Lead Time 3 (days)</th>
                                    </tr>
                                </thead>
                                {/* Table Body */}
                                <tbody>
                                    {allSurfaceFinish.length ? (
                                        allSurfaceFinish.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200 text-center">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_surface_finish?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_surface_finish];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_surface_finish: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200 text-sm">{item.cnc_surface_finish }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_review ? "true" : "false"}</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_per_kg_cost1 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_min_cost1 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_lead_time1 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_per_kg_cost2 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_min_cost2 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_lead_time2 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_per_kg_cost3 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_min_cost3 }</td>
                                                <td className="px-4 py-2 border border-gray-200">{item.cnc_sf_lead_time3 }</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="12" className="text-center text-gray-500 px-4 py-2 border border-gray-200">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                    }
                    {
                        selectedItem == 2 && <div>
                            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                                        <th class="px-4 py-2 border border-gray-200 ">Name</th>
                                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                                        <th class="px-4 py-2 border border-gray-200">Per Kg Cost 1 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Min Cost 1 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Lead Time 1 (days)</th>
                                        <th class="px-4 py-2 border border-gray-200">Per Kg Cost 2 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Min Cost 2 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Lead Time 2 (days)</th>
                                        <th class="px-4 py-2 border border-gray-200">Per Kg Cost 3 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Min Cost 3 $</th>
                                        <th class="px-4 py-2 border border-gray-200">Lead Time 3 (days)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allHeatTreatMent.length
                                        ? allHeatTreatMent.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    } hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_heat_treatment?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_heat_treatment];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_heat_treatment: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_heat_treatment }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_review ? "true" : "false"}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_per_kg_cost1 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_min_cost1 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_lead_time1 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_per_kg_cost2 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_min_cost2 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_lead_time2 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_per_kg_cost3 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_min_cost3 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_ht_lead_time3 }
                                                </td>
                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>

                        </div>
                    }
                    {
                        selectedItem == 3 && <div>
                            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th class="px-4 py-2 border border-gray-200 ">Checkbox </th>
                                        <th class="px-4 py-2 border border-gray-200 ">Tolerance </th>
                                        <th class="px-4 py-2 border border-gray-200">Per Instance  Factor (%) </th>
                                        <th class="px-4 py-2 border border-gray-200">Is 2D Drawing Required </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTolerance.length
                                        ? allTolerance.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    } hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_tolerance?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_tolerance];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_tolerance: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_tolerance }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_tol_per_instance_pc_factor }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_tol_2D_dwg_required ? "true" : "false"}
                                                </td>
                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        selectedItem == 4 && <div>
                            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                                        <th class="px-4 py-2 border border-gray-200 ">Part Marking</th>
                                        <th class="px-4 py-2 border border-gray-200">2D DWG Required </th>
                                        <th class="px-4 py-2 border border-gray-200">Option 1 $ </th>
                                        <th class="px-4 py-2 border border-gray-200">Option 2 $ </th>
                                        <th class="px-4 py-2 border border-gray-200">Option 3 $ </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partMarking.length
                                        ? partMarking.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    } hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_part_marking?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_part_marking];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_part_marking: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_part_marking ? "true" : "false"}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_pm_2D_dwg_required ? "true" : "false"}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_pm_per_instance_cost1 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_pm_per_instance_cost2 }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_pm_per_instance_cost3 }
                                                </td>
                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        selectedItem == 5 && <div>
                            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                                        <th class="px-4 py-2 border border-gray-200 ">Internal Corner</th>
                                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {allInternalCorner.length
                                        ? allInternalCorner.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    } hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_internal_corners?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_internal_corners];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_internal_corners: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_internal_corner }
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_internal_corners_review ? "true" : "false"}
                                                </td>

                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        selectedItem == 6 && <div>
                            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                                    <tr>
                                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                                        <th class="px-4 py-2 border border-gray-200 ">Review My Design</th>
                                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allReviewDesign.length
                                        ? allReviewDesign.map((item, index) => (
                                            <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                                    } hover:bg-gray-200 transition duration-200`}
                                            >
                                                <td className="px-4 py-2 border border-gray-200">
                                                    <Form.Check
                                                        type='checkbox'
                                                        id={`checkbox-${index}`}
                                                        label=''
                                                        checked={data?.cnc_review_my_design?.includes(item?._id)}
                                                        onChange={(e) => {
                                                            setData((prevData) => {
                                                                const newSurfaceTreatment = [...prevData.cnc_review_my_design];
                                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                                if (itemIndex > -1) {
                                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                                } else {
                                                                    newSurfaceTreatment.push(item?._id);
                                                                }

                                                                return {
                                                                    ...prevData,
                                                                    cnc_review_my_design: newSurfaceTreatment,
                                                                };
                                                            });
                                                        }}


                                                    />
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_review_my_design ? "true" : "false"}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-200">
                                                    {item.cnc_design_review ? "true" : "false"}
                                                </td>
                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default AdminDashboardComponents(SelectSubMaterials);