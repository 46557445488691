import React, { useState } from 'react'
import { processType } from '../../../../utils/constant';
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import { updateSelectedProcess } from '../../../../context/create.quote.actions';

const ProcessTabs = ({ name }) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
    
    const [currentProcessId, setCurrentProcessId] = useState(
        name === "CNC Machining" ? 1 :
            name === "3D Printing" ? 2 :
                processType.find((process) => process.name === name)?.id || null
    );

    const handleProcessChange = (processId) => {
        updateSelectedProcess(processId, createQuoteDispatch, createQuoteState);
    }

    return (
        <div className="process-tab">
            <label
                className={
                    createQuoteState?.commanValues?.selectedProcess === currentProcessId
                        ? "label selected"
                        : "label"
                }
                htmlFor={`btnradio-${currentProcessId}`}
                onClick={() => handleProcessChange(currentProcessId)}
            >
                {`${name} (${currentProcessId<=2?"Instant Quote":"RFQ"})`}
            </label>
        </div>
    )
}

export default ProcessTabs