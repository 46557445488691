// src/pages/admin/ShippingRates.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { toast } from 'react-toastify';
import { deleteShippingRates, getAllShippingRates, getShippingRates, upsertShippingRates } from '../../utils/actions/adminActions';
import BackButton from '../../components/BackButton/BackButton';

const emptyRow = {
    order_min_weight_kgs: '',
    order_max_weight_kgs: '',
    from_zone1_US_cost1: '',
    from_zone2_EU_UK_cost2: '',
    from_zone3_IN_MY_CH_cost3: '',
    from_zone1_US_per_kg_cost1: '',
    from_zone2_EU_UK_per_kg_cost2: '',
    from_zone3_IN_MY_CH_per_kg_cost3: '',
    zone_1_lead_time: '',
    zone_2_lead_time: '',
    zone_3_lead_time: '',
};

const ShippingRates = () => {

    const [shippingRatesArray, setShippingRatesArray] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await getShippingRates();
                console.log('Response :', response);
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                if (data && data?.rowData) {
                    setShippingRatesArray(data.rowData);
                } else {
                    setShippingRatesArray([emptyRow]);
                }
            } catch (error) {
                console.log(error);
                toast.error("Unable To Fetch Shipping Rates At The Moment");
                setShippingRatesArray([emptyRow]); // Initialize with one empty row
            }
        })()
    }, [])

    const addRow = () => {
        setShippingRatesArray([...shippingRatesArray, { ...emptyRow }]);
    };

    const handleChange = (index, field, value) => {
        const updatedRates = shippingRatesArray.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );
        setShippingRatesArray(updatedRates);
    };

    const validateRanges = () => {
        // Sort the array based on `min` weight for sequential validation
        const sortedRates = [...shippingRatesArray].sort(
            (a, b) => a.order_min_weight_kgs - b.order_min_weight_kgs
        );

        for (let i = 0; i < sortedRates.length; i++) {
            const current = sortedRates[i];

            // Unique identifier for error messages
            const identifier = `Min weight: ${current.order_min_weight_kgs}, Max weight: ${current.order_max_weight_kgs}`;

            // Validate mandatory fields: 'order_min_weight_kgs' and 'order_max_weight_kgs'
            if (
                current.order_min_weight_kgs === undefined ||
                current.order_min_weight_kgs === null ||
                current.order_min_weight_kgs === '' ||
                current.order_max_weight_kgs === undefined ||
                current.order_max_weight_kgs === null ||
                current.order_max_weight_kgs === ''
            ) {
                toast.error(`Entry (${identifier}): Min and Max weights are required.`);
                return false;
            }

            // Ensure min and max are valid numbers
            const min = parseFloat(current.order_min_weight_kgs);
            const max = parseFloat(current.order_max_weight_kgs);

            if (isNaN(min) || isNaN(max)) {
                toast.error(`Entry (${identifier}): Min and Max weights must be valid numbers.`);
                return false;
            }

            // Ensure min < max for the current row
            if (min >= max) {
                toast.error(`Entry (${identifier}): Min weight (${min}) must be less than Max weight (${max}).`);
                return false;
            }

            // Validate conditional fields for each zone
            const zones = [
                {
                    costField: 'from_zone1_US_cost1',
                    perKgField: 'from_zone1_US_per_kg_cost1',
                    leadTimeField: 'zone_1_lead_time',
                    zone: 1
                },
                {
                    costField: 'from_zone2_EU_UK_cost2',
                    perKgField: 'from_zone2_EU_UK_per_kg_cost2',
                    leadTimeField: 'zone_2_lead_time',
                    zone: 2
                },
                {
                    costField: 'from_zone3_IN_MY_CH_cost3',
                    perKgField: 'from_zone3_IN_MY_CH_per_kg_cost3',
                    leadTimeField: 'zone_3_lead_time',
                    zone: 3
                }
            ];

            for (const zone of zones) {
                const { costField, perKgField, leadTimeField, zone: zoneNumber } = zone;
                if (
                    current[costField] || current[perKgField] || current[leadTimeField]
                ) {
                    if (
                        !current[costField] ||
                        !current[perKgField] ||
                        !current[leadTimeField]
                    ) {
                        toast.error(`Entry (${identifier}): All fields for Zone ${zoneNumber} must be provided if any field for that zone is present.`);
                        return false;
                    }

                    // Ensure lead time is not zero
                    if (parseFloat(current[leadTimeField]) === 0) {
                        toast.error(`Entry (${identifier}): Lead time for Zone ${zoneNumber} cannot be zero.`);
                        return false;
                    }
                }
            }

            if (i > 0) {
                // Ensure continuity: current min should equal previous max
                const prevMax = parseFloat(sortedRates[i - 1].order_max_weight_kgs);
                if (min !== prevMax) {
                    toast.error(
                        `Entry (${identifier}): Min weight (${min}) must match the previous row's Max weight .`
                    );
                    return false;
                }
            } else {
                // Ensure the first row starts with Min weight of 0
                if (min !== 0) {
                    toast.error(`Entry (${identifier}): First row's Min weight must start at 0.`);
                    return false;
                }
            }
        }

        // If all validations pass
        return true;
    };

    console.log("Shipping Rates :", shippingRatesArray);


    const handleSubmit = async () => {
        if (!validateRanges()) return;

        setIsSubmitting(true);
        try {
            const response = await upsertShippingRates(shippingRatesArray);
            if (!response || !response.success) {
                throw new Error();
            };
            toast.success("Data Inserted Succesfully");
        } catch (error) {
            console.log("Shipping errors :", error);
            toast.error("Failed to save shipping rates.");
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <div className='w-screen relative p-4'>
            <div className='flex justify-between px-3 items-center'>
                <BackButton
                    additionalClasses='mx-2'
                />
                <h1 className='text-center text-2xl font-bold '>Shipping Rates</h1>
                <button
                    className='bg-primaryColor px-2 py-2 rounded text-white text-sm '
                    onClick={addRow}
                >
                    Create A Row
                </button>
            </div>
            <div className="overflow-x-auto mt-10">
                <table className="min-w-full bg-white border">
                    <thead>
                        <tr>

                            <th className="py-2 px-2 border ">Min Weight (kgs)</th>
                            <th className="py-2 px-2 border ">Max Weight (kgs)</th>
                            <th className="py-2 px-2 border ">Zone 1 US Fixed Cost  $</th>
                            <th className="py-2 px-2 border ">Zone 2 EU/UK Fixed Cost  $</th>
                            <th className="py-2 px-2 border ">Zone 3 IN/MY/CH Fixed Cost  $</th>
                            <th className="py-2 px-2 border ">Zone 1 US Per Kg Cost $</th>
                            <th className="py-2 px-2 border ">Zone 2 EU/UK Per Kg Cost $</th>
                            <th className="py-2 px-2 border ">Zone 3 IN/MY/CH Per Kg Cost $</th>
                            <th className="py-2 px-2 border ">Zone 1 Lead Time Days</th>
                            <th className="py-2 px-2 border ">Zone 2 Lead Time Days</th>
                            <th className="py-2 px-2 border ">Zone 3 Lead Time Days</th>
                            <th className="py-2 px-2 border ">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shippingRatesArray.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        step="0.01"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.order_min_weight_kgs}
                                        onChange={(e) => handleChange(index, 'order_min_weight_kgs', e.target.value)}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        step="0.01"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.order_max_weight_kgs}
                                        onChange={(e) => handleChange(index, 'order_max_weight_kgs', e.target.value)}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone1_US_cost1}
                                        onChange={(e) => {

                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone1_US_cost1', e.target.value)
                                            }
                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone2_EU_UK_cost2}
                                        onChange={(e) => {

                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone2_EU_UK_cost2', e.target.value)
                                            }
                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone3_IN_MY_CH_cost3}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone3_IN_MY_CH_cost3', e.target.value)
                                            }

                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        step="0.01"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone1_US_per_kg_cost1}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone1_US_per_kg_cost1', e.target.value)

                                            }


                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        step="0.01"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone2_EU_UK_per_kg_cost2}
                                        onChange={(e) => {

                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone2_EU_UK_per_kg_cost2', e.target.value)
                                            }
                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        step="0.01"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.from_zone3_IN_MY_CH_per_kg_cost3}
                                        onChange={(e) => {

                                            const value = e.target.value;
                                            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                                handleChange(index, 'from_zone3_IN_MY_CH_per_kg_cost3', e.target.value)
                                            }
                                        }}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.zone_1_lead_time}
                                        onChange={(e) => handleChange(index, 'zone_1_lead_time', e.target.value)}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.zone_2_lead_time}
                                        onChange={(e) => handleChange(index, 'zone_2_lead_time', e.target.value)}
                                    />
                                </td>
                                <td className="py-2 px-2 border">
                                    <input
                                        type="number"
                                        className="w-full px-2 py-1 border rounded"
                                        value={row.zone_3_lead_time}
                                        onChange={(e) => handleChange(index, 'zone_3_lead_time', e.target.value)}
                                    />
                                </td>
                                <td className="py-2 px-2 border text-center">
                                    <button
                                        className="text-red-500 hover:text-red-700"
                                        onClick={() => {
                                            const updatedRates = shippingRatesArray.filter((_, i) => i !== index);
                                            setShippingRatesArray(updatedRates);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-6 flex justify-center">
                <button
                    className={`bg-primaryColor px-6 py-2 rounded text-white font-semibold  ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Saving...' : 'Update'}
                </button>
            </div>
        </div>
    );
};

export default AdminDashboardComponents(ShippingRates);
