import React, { useState, useEffect, useContext, useReducer, useRef } from "react";
import {
  getAdminUsers,
  changeSalesOrderAdminOwner,
  handleChangeCEDD,
  getAllUsers,
  getCompanies,
} from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from "moment";
import Pagination from 'react-paginate';
import ChatButton from '../../Chat/ChatButton';
import Chat from "../../Chat";
import waitingImage from '../../images/waiting.svg';
import tickGreenImage from '../../images/tick-green.svg';
import deliveryImage from '../../images/delivery.svg';
import invoiceImage from '../../images/invoice-icon.svg';
import shippingImage from '../../images/shippingIconBlack.svg';
import cancelImage from '../../images/close_red.svg';
import issueImage from '../../images/issueRaised.png';
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { useAdminContext, ADMIN_ACTIONS } from "../../context/admin.context";
import { calculatePriceBasedOnData, currencyFormatter, validateAdmin } from '../../utils/helper';
import { Form, FormControl } from "react-bootstrap";
import useDocumentTitle from '../../utils/useDocumentTitle';
import { fetchFilteredOrders, fetchOrdersForRefIdSearch } from '../../utils/actions/adminActions';
import { Loading } from "../../components/Loading";
import { fetchAttachPurchaseOrders } from "../../utils/actions/supplierActions";
import allValuesImage from "../../images/all-entity.svg";

const Order = () => {
  let navigate = useNavigate();
  useDocumentTitle('Sales Orders')
  const { state, dispatch } = useAdminContext();
  const { selectedChatRefId, setSelectedChatRefId } = useContext(ChatRefContext);
  let token = localStorage.getItem('Token');
  const [adminUsers, setAdminUsers] = useState([]);
  const [directOrderResult, setDirectOrderResult] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCustomerUsers, setAllCustomerUsers] = useState([]);
  const [selectedCustomerForFilter, setSelectedCustomerForFilter] = useState(new Set());
  const [filteredCustomerUser, setFilteredCustomerUser] = useState([]);
  const [isUserDropDownVisible, setIsUserDropDownVisible] = useState(false);

  const userDropDownRef = useRef(null);
  const companyDropDownRef = useRef(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] = useState(false);



  const initialOrderState = {
    createdFrom: "",
    createdTo: "",
    userName: "",
    adminOwnerId: "",
    companyName: '',
    po: '',
    cedd: '',
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    orderList: [],
    orderCount: {}
  };

  const ORDER_ACTIONS = {
    UPDATE_CREATED_FROM: "UPDATE_CREATED_FROM",
    UPDATE__CREATED_TO: "UPDATE__CREATED_TO",
    UPDATE_USER_NAME: "UPDATE_USER_NAME",
    UPDATE_ADMIN_OWNER_ID: "UPDATE_ADMIN_OWNER_ID",
    UPDATE_SORT_BY: "UPDATE_SORT_BY",
    UPDATE_SORT_ORDER: "UPDATE_SORT_ORDER",
    UPDATE_PAGE_NUM: "UPDATE_PAGE_NUM",
    UPDATE_ADMIN_OWNER_ID: "UPDATE_ADMIN_OWNER_ID",
    UPDATE_TOTAL_PAGE: "UPDATE_TOTAL_PAGE",
    UPDATE_ORDER_LIST: "UPDATE_ORDER_LIST",
    UPDATE_ORDER_COUNT: "UPDATE_ORDER_COUNT",
    UPDATE_CEDD: "UPDATE_CEDD",
    UPDATE_PO: "UPDATE_PO",
    UPDATE_COMPANY_NAME: "UPDATE_COMPANY_NAME",
    UPDATE_ORDER_FILTER: "UPDATE_ORDER_FILTER",
  }

  const orderReducer = (state, action) => {
    switch (action.type) {
      case ORDER_ACTIONS.UPDATE_CREATED_FROM: return { ...state, createdFrom: action.payload };
      case ORDER_ACTIONS.UPDATE__CREATED_TO: return { ...state, createdTo: action.payload };
      case ORDER_ACTIONS.UPDATE_USER_NAME: return { ...state, userName: action.payload };
      case ORDER_ACTIONS.UPDATE_ADMIN_OWNER_ID: return { ...state, adminOwnerId: action.payload };
      case ORDER_ACTIONS.UPDATE_SORT_BY: return { ...state, sortBy: action.payload };
      case ORDER_ACTIONS.UPDATE_SORT_ORDER: return { ...state, sortOrder: action.payload };
      case ORDER_ACTIONS.UPDATE_PAGE_NUM: return { ...state, pageNum: action.payload };
      case ORDER_ACTIONS.UPDATE_TOTAL_PAGE: return { ...state, totalPages: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_LIST: return { ...state, orderList: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_COUNT: return { ...state, orderCount: action.payload };
      case ORDER_ACTIONS.UPDATE_CEDD: return { ...state, cedd: action.payload };
      case ORDER_ACTIONS.UPDATE_PO: return { ...state, po: action.payload };
      case ORDER_ACTIONS.UPDATE_COMPANY_NAME: return { ...state, companyName: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_FILTER: return { ...state, ...action.payload };
      default: return state;
    }
  }

  const [orderState, orderDispatch] = useReducer(orderReducer, initialOrderState);

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token).then(response => setAdminUsers(response)).catch(toast.error);
        getAllUsers(token).then(response => setAllCustomerUsers(response)).catch(toast.error);
        getCompanies(token).then(companyResponse => setAllCompanies(companyResponse)).catch(toast.error);
      }
      fetchCommonData();
    }
  }, []);


  useEffect(() => {
    fetchData();
  }, [
    orderState.createdFrom,
    orderState.createdTo,
    orderState.adminOwnerId,
    orderState.sortBy,
    orderState.sortOrder,
    orderState.pageNum,
    state.orderActiveFilter,
    selectedCustomerForFilter.size,
    selectedCompanies.size,
    orderState.po,
    orderState.cedd,
  ]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let data = {
        createdFrom: orderState.createdFrom,
        createdTo: orderState.createdTo,
        userName: selectedCustomerForFilter.size ? [...selectedCustomerForFilter].join(',') : "",
        adminOwnerId: orderState.adminOwnerId,
        companyName: selectedCompanies.size ? [...selectedCompanies].join(',') : "",
        po: orderState.po,
        cedd: orderState.cedd,
        sortBy: orderState.sortBy,
        sortOrder: orderState.sortOrder,
        pageNum: orderState.pageNum,
        orderActiveFilter: state.orderActiveFilter,
      }
      const response = await fetchFilteredOrders(token, data);
      let salesOrderIdArr = [];
      response?.data?.orders?.map((item) => {
        if (item._id) {
          salesOrderIdArr.push(item._id);
        }
      })

      const attachedPurchaseOrdersResponse = await fetchAttachPurchaseOrders(token, salesOrderIdArr);
      if (attachedPurchaseOrdersResponse.status && attachedPurchaseOrdersResponse.data.length > 0) {

        response?.data?.orders?.forEach(soItem => {
          let attachedPurchaseOrder = attachedPurchaseOrdersResponse.data.filter(
            (item) => item.salesOrderId == soItem._id
          );
          if (attachedPurchaseOrder) {
            soItem.purchaseOrdersAttached = attachedPurchaseOrder;
          }
        })
      }

      orderDispatch({ type: ORDER_ACTIONS.UPDATE_ORDER_LIST, payload: response?.data?.orders })
      let orderBucketCount = response?.data?.orderCategoryCount;
      orderDispatch({ type: ORDER_ACTIONS.UPDATE_ORDER_COUNT, payload: orderBucketCount });
      let totalPages = Math.ceil(Number(state.orderActiveFilter == 'progress' ? orderBucketCount.progressCount : state.orderActiveFilter == 'shipped' ? orderBucketCount.shippedCount : state.orderActiveFilter == 'delivered' ? orderBucketCount.deliveredCount : state.orderActiveFilter == 'completed' ? orderBucketCount.completedCount : state.orderActiveFilter == 'cancelled' ? orderBucketCount.cancelledCount : state.orderActiveFilter == 'issue' ? orderBucketCount.issueCount : orderBucketCount.allCount) / 10);
      orderDispatch({ type: ORDER_ACTIONS.UPDATE_TOTAL_PAGE, payload: totalPages || 0 })
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err.message != "canceled") {
        toast.error(err.message)
        setLoading(false);
      }
      console.log("err", err)
    }

  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (userDropDownRef.current && !userDropDownRef.current.contains(event.target)) {
        setIsUserDropDownVisible(false);
      }

      if (companyDropDownRef.current && !companyDropDownRef.current.contains(event.target)) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleUserFocus = () => {
    setIsUserDropDownVisible(true);
  }


  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  }


  const searchUser = (e) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_USER_NAME,
      payload: e.target.value,
    })
    // setUserFilterValue(e.target.value);
    let localFilteredUser = [];

    if (e.target.value.length > 0) {
      localFilteredUser = allCustomerUsers.filter((item) =>
        (item.firstName + " " + item.lastName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredUser.length > 0) {
      setIsUserDropDownVisible(true)
    }
    setFilteredCustomerUser(localFilteredUser);
  };

  const searchCompany = (e) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    })
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        (item.companyName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true)
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCustomerFilterChange = (action, item) => {
    try {
      let customerId = item;
      if (!customerId) {
        return;
      }

      let localSelectedCustomerForFilter = selectedCustomerForFilter;
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_USER_NAME,
        payload: "",
      })
      setFilteredCustomerUser([]);

      if (action == "push") {
        localSelectedCustomerForFilter.add(customerId);
      } else if (action == "pop") {
        localSelectedCustomerForFilter.delete(customerId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
        payload: 'all-order',
      });
      setSelectedCustomerForFilter(localSelectedCustomerForFilter);
    } catch (err) {
      console.log("err", err);
    }
  };


  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      })
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
        payload: 'all-order',
      });
      setSelectedCompanies(localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };


  const showDrawer = (id) => {
    setSelectedChatRefId(id);
  };
  const onClose = () => {
    setSelectedChatRefId(null);
  };



  const handleAdminOwnerChange = async (e, orderId) => {
    try {
      let adminUserSelected = adminUsers.filter(item => item._id == e.target.value)[0];
      let adminResponse = confirm(`Are you sure you want to assign this sales order to ${adminUserSelected.firstName + " " + adminUserSelected.lastName} ?`);
      if (!adminResponse) {
        return;
      }
      let data = {
        orderId: orderId,
        adminId: e.target.value,
      }
      let salesOrderResponse = await changeSalesOrderAdminOwner(token, data);
      if (salesOrderResponse.status) {
        fetchData();
        toast.success("Admin owner changed successfully.");
      }
    }
    catch (err) {
      console.log("Error in order js on 109 on" + moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'), err);
    }
  }

  let handleActivePage = async (value) => {
    let pageNum = (value?.selected + 1) || 1;
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_PAGE_NUM, payload: pageNum })
  };



  const handleBucketFilterClick = (field) => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER, payload: field })
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_ORDER_FILTER, payoad: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        companyName: '',
        po: '',
        cedd: '',
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0
      }
    })
  }




  let handleSort = (field) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    })

    let sortStatus = orderState.sortBy == field ? orderState.sortOrder == 1 ? -1 : 1 : 1;
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    })
  };




  let handlePOFltr = (e) => {
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_PO, payload: e.target.value });
  }


  let handleAdminOwnerFilter = (e) => {
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_ADMIN_OWNER_ID, payload: e.target.value });
  }


  let handleCEDDFilter = (e) => {
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_CEDD, payload: e.target.value });
  }

  let handleCreatedFltr = (e, field) => {
    if (field == 'createdFrom') {
      orderDispatch({ type: ORDER_ACTIONS.UPDATE_CREATED_FROM, payload: e.target.value });
    } else {
      orderDispatch({ type: ORDER_ACTIONS.UPDATE__CREATED_TO, payload: e.target.value });
    }
  }

  let handleRefresh = () => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_ORDER_FILTER, payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        companyName: '',
        po: '',
        cedd: '',
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0
      }
    })

    dispatch({
      type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
      payload: 'all-order',
    });
    setSelectedCustomerForFilter(new Set());
    setSelectedCompanies(new Set());
  }

  const handleCeddChange = async (e, order) => {
    try {
      if (order.customerExpectedDeliveryDate == e.target.value) {
        return;
      }

      let data = {
        orderId: order._id,
        customerExpectedDeliveryDate: e.target.value,
      }

      let response = await handleChangeCEDD(token, data);

      if (response.status) {
        fetchData();
      }

    } catch (err) {
      console.log("Error in handle cedd change", err)
    }
  }

  const handleOrderSearch = async (e) => {
    try {
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectOrderResult([])
        return;
      }

      let response = await fetchOrdersForRefIdSearch(token, e.target.value);

      if (response.status) {
        setDirectOrderResult(response.data)
      } else {
        setDirectOrderResult([])
        toast.error("Something went wrong while fetching the data.")
      }

    } catch (err) {
      console.log('error', err)
    }
  }



  return (
    <div className="admin-container">
      <Sidebar />
      <div style={{ height: 'auto' }}>
        {loading ?
          <Loading />
          : ""}
      </div>
      <span style={{ width: '100%' }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold my-2">Sales Orders</h1>
        </div>
        <div className="right-container" >

          <div className="bucketFilterDiv">

            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'all-order' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('all-order')}>
              <span className="textBox">
                <p>All Sales Order</p>
                <h5>{orderState?.orderCount?.allCount || 0}</h5>
              </span>
              <img className='filterIcon' src={allValuesImage} />
            </div>


            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'progress' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('progress')}>
              <span className="textBox">
                <p>In Progress</p>
                <h5>{orderState?.orderCount?.progressCount || 0}</h5>
              </span>
              <img className='filterIcon' src={waitingImage} />
            </div>

            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'shipped' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('shipped')}>
              <span className="textBox">
                <p>Shipped</p>
                <h5>{orderState?.orderCount?.shippedCount || 0}</h5>
              </span>
              <img className='filterIcon' src={shippingImage} />
            </div>


            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'delivered' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('delivered')}>
              <span className="textBox">
                <p>Delivered</p>
                <h5>{orderState?.orderCount?.deliveredCount || 0}</h5>
              </span>
              <img className='filterIcon' src={deliveryImage} />
            </div>

            <div className={`bucketFilterOptions `} onClick={() => navigate('/admin/customer-invoices')}>
              <span className="textBox">
                <p>Invoiced</p>
                <h5>{orderState?.orderCount?.invoicedCount || 0}</h5>
              </span>
              <img className='filterIcon' src={invoiceImage} />
            </div>


            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'completed' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('completed')}>
              <span className="textBox">
                <p>Completed</p>
                <h5>{orderState?.orderCount?.completedCount || 0}</h5>
              </span>
              <img className='filterIcon' src={tickGreenImage} />
            </div>

            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'issue' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('issue')}>
              <span className="textBox">
                <p>Issues</p>
                <h5>{orderState?.orderCount?.issueCount || 0}</h5>
              </span>
              <img className='filterIcon' src={issueImage} />
            </div>


            <div className={`bucketFilterOptions ${state.orderActiveFilter == 'cancelled' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('cancelled')}>
              <span className="textBox">
                <p>Cancelled</p>
                <h5>{orderState?.orderCount?.cancelledCount || 0}</h5>
              </span>
              <img className='filterIcon' src={cancelImage} />
            </div>
          </div>

          <Chat RefId={selectedChatRefId} open={selectedChatRefId?.length > 0} onDrawerClose={onClose} />
          <div className="search-bar">
            <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>

              <div className="search-block">
                <label className="sr-label">Admin Owner</label>
                <Form.Select
                  aria-label="Default select example"
                  value={orderState?.adminOwnerId || 'default'}
                  style={{ width: '100%' }}
                  onChange={
                    (e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
                        payload: 'all-order',
                      });
                      handleAdminOwnerFilter(e)
                    }}>
                  <option value='default' disabled > Select owner</option>
                  {adminUsers.map((val, i) => {
                    return <option key={i} value={val._id}>{val.firstName + " " + val.lastName}</option>
                  })}
                </Form.Select>
              </div>


              <div className="search-block">
                <label className="sr-label">
                  Ref ID
                </label>
                <input type="text" className="form-control" value={directFilterVal} placeholder="Search Ref ID" onChange={handleOrderSearch} />
                {(directFilterVal?.trim()?.length > 0 && directOrderResult.length > 0) ?
                  <div className="direct-search-drop-down-div ">
                    <table className="search-table">
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>PO#</th>
                          <th>Created Date</th>
                          <th>Status</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {directOrderResult.length > 0 && directOrderResult?.map((val, i) => {
                          return <tr key={i} className="direct-search-drop-down" onClick={() => window.open('/admin/orders/' + val._id, '_blank')}>
                            <td>
                              {val.RefId.toUpperCase()}
                            </td>
                            <td>
                              {val.purchaseOrderNumber ? val.purchaseOrderNumber : 'Not Found'}
                            </td>
                            <td>
                              {
                                moment(val.createdAt).format("DD MMM YYYY")
                              }
                            </td>
                            <td>
                              {val.status == "refunded" ||
                                val.status == "cancelled"
                                ? "Refunded/Cancelled"
                                : val.issuesForOrder?.some(issue => !issue.isIssueClosed) ? "Issues Order" :
                                  val.isCompleted
                                    ? "Order Completed"
                                    : (val.expectedDelivery || val.invoicesForOrder?.some(invoiceItem => invoiceItem.deliveryDate))
                                      ? `Delivered`
                                      : (val.shippedDate || val.invoicesForOrder?.some(invoiceItem => invoiceItem.shippedDate))
                                        ? "Order Shipped"
                                        : "Order Processing"}
                            </td>
                            <td>
                              {calculatePriceBasedOnData(val)}
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                  : <></>
                }
              </div>

              <div className="search-block">
                <label className="sr-label">User Filter</label>
                <div className="filter-item">
                  <div className="share-quote-div">
                    <input
                      type="text"
                      className="form-control"
                      value={orderState.userName}
                      placeholder={`${selectedCustomerForFilter.size} users selected`}
                      onChange={(e) => searchUser(e)}
                      onFocus={handleUserFocus}
                    />

                    {isUserDropDownVisible ? (
                      <div
                        className="follower-drop-down-div"
                        style={{
                          width: "200px",
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                          // padding: ".375rem .75rem",
                          maxHeight: "200px",
                          overflowY: "auto",
                          fontSize: "12px",
                          textAlign: 'left'
                        }}
                        ref={userDropDownRef}
                      >
                        {filteredCustomerUser.length > 0 ?
                          filteredCustomerUser.map((item, i) => {
                            return (
                              <div
                                key={i + "fil"}
                                className="follower-drop-down"
                                style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={"sign-check" + i + 'fil'}
                                  name="checkbox"
                                  value={item._id}
                                  checked={selectedCustomerForFilter.has(item._id)}
                                  onClick={() =>
                                    handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                />
                                <label htmlFor={"sign-check" + i + 'file'} style={{ marginLeft: '5px', fontSize: '11px' }}
                                  onClick={() =>
                                    handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                >
                                  {item.firstName + " " + item.lastName}
                                </label>
                              </div>
                            )
                          }) :
                          allCustomerUsers.length > 0 && allCustomerUsers.map((item, i) => {
                            return (
                              <div
                                key={i + 'all'}
                                className="follower-drop-down"
                                style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={"sign-check" + i + 'all'}
                                  name="checkbox"
                                  value={item._id}
                                  checked={selectedCustomerForFilter.has(item._id)}
                                  onClick={() =>
                                    handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                />
                                <label htmlFor={"sign-check" + i + 'all'} style={{ marginLeft: '5px', fontSize: '11px' }}>
                                  {item.firstName + " " + item.lastName}
                                </label>
                              </div>
                            );
                          })
                        }
                      </div>
                    ) : (
                      <></>
                    )}

                  </div>
                </div>
              </div>

              <div className="search-block">
                <label className="sr-label">Company Filter</label>
                <div className="filter-item">
                  <div className="share-quote-div">
                    <input
                      type="text"
                      className="form-control"
                      value={orderState.companyName}
                      placeholder={`${selectedCompanies.size} companies selected`}
                      onChange={(e) => searchCompany(e)}
                      onFocus={handleCompanyFocus}
                    />

                    {isCompanyDropDownVisible ? (
                      <div
                        className="follower-drop-down-div"
                        style={{
                          width: "200px",
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                          // padding: ".375rem .75rem",
                          maxHeight: "200px",
                          overflowY: "auto",
                          fontSize: "12px",
                          textAlign: 'left'
                        }}
                        ref={companyDropDownRef}
                      >
                        {filteredCompanies.length > 0 ?
                          filteredCompanies.map((item, i) => {
                            return (
                              <div
                                key={i + "fil-com"}
                                className="follower-drop-down"
                                style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={"sign-check" + i + 'fil-com'}
                                  name="checkbox"
                                  value={item._id}
                                  checked={selectedCompanies.has(item._id)}
                                  onClick={() =>
                                    handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                />
                                <label htmlFor={"sign-check" + i + 'fil-com'} style={{ marginLeft: '5px', fontSize: '11px' }}>
                                  {item.companyName}
                                </label>
                              </div>
                            )
                          }) :
                          allCompanies?.length > 0 && allCompanies.map((item, i) => {
                            return (
                              <div
                                key={i + 'all-com'}
                                className="follower-drop-down"
                                style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={"sign-check" + i + 'all-com'}
                                  name="checkbox"
                                  value={item._id}
                                  checked={selectedCompanies.has(item._id)}
                                  onClick={() =>
                                    handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                />
                                <label htmlFor={"sign-check" + i + 'all'} style={{ marginLeft: '5px', fontSize: '11px' }}
                                  onClick={() =>
                                    handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                  }
                                >
                                  {item.companyName}
                                </label>
                              </div>
                            );
                          })
                        }
                      </div>
                    ) : (
                      <></>
                    )}

                  </div>
                </div>
              </div>




              <div className="search-block">
                <label className="sr-label">
                  PO Number
                </label>
                <input type="text" className="form-control" value={orderState.po} placeholder="PO#" onChange={
                  (e) => {
                    dispatch({
                      type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
                      payload: 'all-order',
                    });
                    handlePOFltr(e)
                  }} />
              </div>

              <div className="search-block">
                <label className="sr-label">SO Created From</label>
                <input type="date" value={orderState.createdFrom} className="form-control"
                  onChange={(e) => {
                    dispatch({
                      type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
                      payload: 'all-order',
                    });
                    handleCreatedFltr(e, 'createdFrom')
                  }} />
              </div>

              <div className="search-block">
                <label className="sr-label">SO Created To</label>
                <input type="date" value={orderState.createdTo} className="form-control" onChange={(e) => {
                  dispatch({
                    type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
                    payload: 'all-order',
                  });
                  handleCreatedFltr(e, 'createdTo')
                }} />
              </div>

              {state.orderActiveFilter == 'progress' ?
                <>
                  <div className="search-block">
                    <label className="sr-label">
                      CEDD
                    </label>
                    <Form.Select onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
                        payload: 'all-order',
                      });
                      handleCEDDFilter(e)
                    }} defaultValue={orderState.cedd}>
                      <option disabled value='' selected={orderState.cedd == ''}>Select CEDD</option>
                      <option value="no-cedd" selected={orderState.cedd == 'no-cedd'}>{`No CEDD`}</option>
                      <option value="cedd-overdue" selected={orderState.cedd == 'cedd-overdue'}> {`CEDD overdue`}</option>
                      <option value="less-than-seven" selected={orderState.cedd == 'less-than-seven'}> {`Less than 7 Days`}</option>
                      <option value="less-than-fourteen" selected={orderState.cedd == 'less-than-fourteen'}>{`7-14 Days`}</option>
                      <option value="others" selected={orderState.cedd == 'others'}>{`Greater than 14 Days`}</option>
                    </Form.Select>
                  </div>
                </>
                :
                <>
                </>}
            </div>
            <div className="search-btn" style={{ marginTop: '10px' }}>
              <button onClick={handleRefresh} className="button-search">Reset all filters</button>
            </div>


          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('RefId')}>Order ID {orderState.sortBy == 'RefId' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>
                  <th scope="col" style={{ cursor: 'pointer' }}>Purchase Order ID</th>
                  <th scope="col" style={{ cursor: 'pointer' }}> Admin Owner </th>
                  <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSort('purchaseOrderNumber')}>PO#/Card  {orderState.sortBy == 'purchaseOrderNumber' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>
                  <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('createdAt')}>SO Date  {orderState.sortBy == 'createdAt' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>

                  {(state.orderActiveFilter == 'progress' || state.orderActiveFilter == 'all-order') ?
                    <>
                      <th scope="col" >ESD</th>
                      <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('customerExpectedDeliveryDate')} >CEDD  {orderState.sortBy == 'customerExpectedDeliveryDate' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>
                    </>
                    :
                    <>
                      <th scope="col" style={{ cursor: 'pointer' }} >Shipped Date </th>
                      <th scope="col" style={{ cursor: 'pointer' }} >Delivery Date</th>
                    </>
                  }
                  <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('firstName')}>UserName  {orderState.sortBy == 'firstName' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>
                  <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('subTotal')} >Total Price  {orderState.sortBy == 'subTotal' ? orderState.sortOrder == 1 ? "↓" : "↑" : "↓"}</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {orderState?.orderList?.length > 0 ? orderState?.orderList.map((val, i) => (
                  <tr key={i}>
                    <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal', width: '150px' }}>
                      <div className="flex items-center">


                        <Link to={'/admin/orders/' + val._id} className="text-primaryColor">
                          {val.quoteId.versionDescription ? val.quoteId.versionDescription + "-" : ''}{val.RefId.toUpperCase()}
                        </Link>
                        <HoverInfo content={val.adminComment} />
                      </div>
                      {state.orderActiveFilter == 'all-order' && val?.orderStatus
                        ? <><br />{`(${val?.orderStatus})`}</>
                        : ""}
                      <br />
                      <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                        {val?.isConfirmationSent ?
                          <p style={{ color: 'green' }}>
                            (Confirmation sent)
                          </p>
                          :
                          <p style={{ color: 'red' }}>
                            (Confirmation pending)
                          </p>
                        }
                      </span>

                    </td>
                    <td
                      style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                        width: '150px'
                      }}
                    >

                      {val?.purchaseOrdersAttached?.length > 0 ?
                        <div className="text-primaryColor ">
                          {val?.purchaseOrdersAttached?.map((item, i) => {
                            let contentOnHover = `Status : ${item?.status} 
ESD: ${item?.expectedShippingDates?.length > 0 ? `${moment(item?.expectedShippingDates.reduce((maxObj, currentObj) => {
                              if (currentObj.updatedDate > maxObj.updatedDate) {
                                return currentObj;
                              }
                              return maxObj;
                            }).updateValue).format("DD MMM YYYY")
                                }` : item.poAcceptanceDate ? `${moment(item.poAcceptanceDate).add(item?.supplierRFQId?.leadTime, "days").format("DD MMM YYYY")}` : 'NA'}                         
`;
                            return <div style={{ margin: '3px 0px 0px 0px' }}
                              className="flex justify-center gap-x-2"

                            >
                              <Link to={'/admin/purchase-order-details/' + item?._id}>
                                {item?.pORefId?.toUpperCase()}
                              </Link>
                              <HoverInfo content={contentOnHover} />

                            </div>
                          })}
                        </div>
                        :
                        <>
                          NA
                        </>}
                    </td>
                    <td>
                      <Form.Select aria-label="Default select example" value={val?.adminOwnerId || 'default'} style={{ width: '100%' }} onChange={(e) => handleAdminOwnerChange(e, val._id)}>
                        <option value='default' disabled > Select owner</option>
                        {adminUsers.map((item, i) => {
                          return <option key={i} value={item._id}>{item.firstName + " " + item.lastName}</option>
                        })}
                      </Form.Select>
                    </td>
                    <td>{val.purchaseOrderNumber ? val.purchaseOrderNumber : 'Not Found'}</td>
                    <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal', width: '100px' }}>
                      {
                         moment(val.createdAt).format("MMM DD, YYYY")
                      }
                    </td>
                    {(state.orderActiveFilter == 'progress' || state.orderActiveFilter == 'all-order') ?
                      <>
                        <td>
                          {
                            val?.purchaseOrdersAttached?.length > 0 ? (
                              <>
                                {

                                  val?.purchaseOrdersAttached?.map((item, i) => {
                                    let localESD = item?.expectedShippingDates?.length > 0 ? moment(item?.expectedShippingDates.reduce((maxObj, currentObj) => {
                                      if (currentObj.updatedDate > maxObj.updatedDate) {
                                        return currentObj;
                                      }
                                      return maxObj;
                                    }).updateValue).format("DD MMM YYYY") : item.poAcceptanceDate ? moment(item.poAcceptanceDate).add(item?.supplierRFQId?.leadTime, "days").format("DD MMM YYYY") : 'NA';
                                    return localESD;
                                  }).reduce((maxDate, currentDate) => {
                                    if (moment(currentDate, "DD MMM YYYY").isAfter(maxDate)) {
                                      return currentDate;
                                    }
                                    return maxDate;
                                  })
                                }
                              </>
                            ) : (
                              <>NA</>
                            )
                          }

                        </td>
                        <td>
                          <FormControl
                            type='date'
                            style={{
                              border: `2px solid ${(moment(val.customerExpectedDeliveryDate).diff(moment(Date.now()), 'days') < 5 &&
                                moment(val.customerExpectedDeliveryDate).diff(moment(Date.now()), 'days') > 0) ? '#F0DE36' :
                                (moment(val.customerExpectedDeliveryDate).diff(moment(Date.now()), 'days') <= 0) ? 'red' : 'white'
                                }`, backgroundColor: `${!val.customerExpectedDeliveryDate ? "#ffc0c0" : 'white'}`
                            }}
                            onChange={(e) => handleCeddChange(e, val)}
                            value={val?.customerExpectedDeliveryDate ? new Date(val?.customerExpectedDeliveryDate).toISOString().slice(0, 10) : ''}
                          />
                        </td>
                      </>
                      :
                      <>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                          {
                            val.invoicesForOrder.map((invoiceItem, index) => (
                              invoiceItem.shippedDate ? (
                                <div key={index}>{moment(invoiceItem.shippedDate).format('DD MMM YYYY')}</div>
                              ) : (
                                null
                              )
                            ))
                          }
                        </td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                          {
                            val.invoicesForOrder.map((invoiceItem, index) => (
                              invoiceItem.deliveryDate ? (
                                <div key={index}>{moment(invoiceItem.deliveryDate).format('DD MMM YYYY')}</div>
                              ) : (
                                null
                              )
                            ))
                          }
                        </td>
                      </>
                    }

                    <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                      {val.userId.firstName + ' ' + val.userId.lastName}
                      <br />
                      {val?.userId ? `(${val?.userId?.email?.split('@')[0]})` : ""}
                    </td>
                    <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                      {calculatePriceBasedOnData(val)
                      }</td>
                    <td>

                      <div>
                        <button
                          type="button"
                          className="up-button h-7 w-[100px] px-2"

                        // style={{ marginTop: '1px', marginRight: '5px', background: 'white', color: '#2B96DC', width: '100px', border: '1px solid #a8e5ff' }}
                        >
                          <ChatButton RefId={val.RefId} showDrawer={() => showDrawer(val.RefId)} />

                        </button>
                      </div>
                    </td>
                  </tr>
                )) : null
                }

              </tbody>
            </table>
          </div>
          <div className="floatRight">
            <Pagination
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              pageCount={orderState.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handleActivePage}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          </div>


        </div>
      </span>
    </div>
  )
}
export default Order;