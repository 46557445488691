import React from "react";
import logo from "../../../images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { projectCategory } from "../../../utils/constant";

const ProjectCategoryGrid = () => {
  const navigate = useNavigate();
  const handleCategoryCardClick = (path) => {
    navigate(path)
  };

  return (
    <>
      <div className=" flex  bg-primaryColor justify-between items-center px-10 ">
        <Link to="">
          <img className="h-24" src={logo} />
        </Link>{" "}
        <Link to="/login" className=" px-3 py-1 bg-white rounded">
          Back
        </Link>
      </div>
      <div className="csr-section">
        <div style={{ textAlign: "center" }}>
          <h4>Take a look at some of the project that we have manufactured</h4>
          <div className="project-category-div">
            {projectCategory.map((item,i) => {
              return (
                <span key={i}>
                  <div
                    class="project-category-card"
                    onClick={()=>handleCategoryCardClick(item.path)}
                  >
                    {item.name}
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCategoryGrid;
