import React, { useEffect, useState } from 'react'
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import { currencyFormatter } from '../../../../utils/helper';
import ShowPriceBox from '../ShowPriceBox/ShowPriceBox';
import { ArrowIcon, DocIcon, DownloadIcon, ForwardToPurchaseIcon, MessageIcon, PlusIcon, ProccedToCheckOutIconComponent, ShareIcon, UpdateQuoteIcon, UsersIcon, ThreeDotsIcon } from '../../../../components/Icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getAllCertifications } from '../../../../utils/actions/commanActions';
import { toast } from 'react-toastify';
import CertificationModal from '../../../../components/CertificationModal/CertificationModal';
import { calculatePriceOfCertifications } from '../../../../utils/constant';
import PriceOptions from "../../../../components/PricingComponent/PriceingOptions";
import { setSelectedShippingMethod } from '../../../../context/create.quote.actions';
import { getAllCncCertifications, getAllthreeDCertifications } from '../../../../utils/actions/adminActions';
import { changeSelectedShippingMethod, checkGenericUser } from '../../../../utils/actions/userActions';
import DownloadQuotationModal from '../../../../components/DownloadQuotationComponent/DownloadQuotationModal';

const RightSection = ({
    updateCommanValueOnChange,
    onSubmit,
    handleDraftQuoteFunctionality,
    selectedQuote,
    selectedProcess,
    isQuoteChanged,
    handleQuoteUpdate
}) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
    console.log("certificates", createQuoteState?.commanValues.certifications);

    const token = localStorage.getItem("Token");

    let props = createQuoteState;
    const [certificationsData, setCertificationData] = useState([]);
    const [allCncCertification, setCncCertification] = useState([]);
    const [allThreeDCertification, setThreeDCertification] = useState([]);
    const [allGenericCertification, setGenericCertification] = useState([]);

    const { quoteId } = useParams();
    let _id = quoteId;


    const [searchParams] = useSearchParams();
    const funcName = searchParams.get("functionalityName");
    const [isOpen, setIsOpen] = useState(false);
    const repeatOrder = searchParams.get("RepeatOrder") == "true" ? true : false;
    const navigate = useNavigate();
    const [isCertificationsModalOpen, setIsCertificationModalOpen] = useState(false);
    const [isAllCertificationLoaded, setIsAllCertificationLoaded] = useState(false);
    const [isGenericUser, setIsGenericUser] = useState(true);

    const [isShowDownloadQuotationModal, setIsShowDownloadQuotationModal] = useState(false);


    useEffect(() => {

        (async () => {
            try {
                const id = localStorage.getItem("_id");
                const response = await checkGenericUser(id);
                if (response.success) {
                    const { isGeneric } = response;
                    setIsGenericUser(isGeneric);
                }
            } catch (error) {


            }
        })()

    }, []);




    useEffect(() => {
        (async () => {
            if (!quoteId) return;
            try {
                const response = await Promise.all([getAllCertifications(), getAllthreeDCertifications(), getAllCncCertifications()]);
                console.log("Response Received :", response);
                const [genericCertification, threeDCertification, cncCertifications] = response
                if (!genericCertification.success || !threeDCertification.success || !cncCertifications.success) {
                    throw new Error();
                };
                const { data: genericCertificationData } = genericCertification;
                const { data: threeDCertificationData } = threeDCertification;
                const { data: cncCertificationsData } = cncCertifications;
                setGenericCertification(genericCertificationData);
                setThreeDCertification(threeDCertificationData);
                setCncCertification(cncCertificationsData);
                setIsAllCertificationLoaded(true);

            } catch (error) {
                console.error('Failed to fetch certifications', error);
                toast.error('Unable to fetch certifications. Please try again later.');
            }
        })();

    }, [quoteId]);


    useEffect(() => {
        if (isAllCertificationLoaded) {
            console.log("Selected Process :", selectedProcess);

            if (selectedProcess === 1) {
                setCertificationData(allCncCertification);
            }
            else if (selectedProcess === 2) {
                setCertificationData(allThreeDCertification);
            }
            else {
                setCertificationData(allGenericCertification);
            }
        }


    }, [selectedProcess, isAllCertificationLoaded]);



    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleUpdateSelectedShipMethod = async (shippingMethod) => {
        setSelectedShippingMethod(shippingMethod, createQuoteDispatch, createQuoteState);

        try {
            const response = await changeSelectedShippingMethod({ quoteId, selectedShipMethod: shippingMethod }, token);
            if (!response || !response.success) {
                throw new Error("Failed");
            }
        } catch (error) {

        }


    }

    const handleSelect = async (cert) => {
        // updateCommanValueOnChange('isQuoteChanged', true);

        createQuoteDispatch({
            type: "ADD_REMOVE_CERTIFICATES",
            payload: cert,
        });
        setIsCertChange(true);
    };

    return (
        <div className="border-l border-gray-300 right-section">
            <>

                <div className="price-tabs flex flex-col">
                    <div>
                        {(
                            (createQuoteState?.commanValues.price1 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                            )) && !createQuoteState?.commanValues?.hidePrice && createQuoteState?.commanValues.selectedProcess <= 2 ?
                            <PriceOptions
                                price1={createQuoteState?.commanValues.price1 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)}
                                price2={createQuoteState?.commanValues.price2 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)}
                                price3={createQuoteState?.commanValues.price3 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)}
                                leadTime1={createQuoteState?.commanValues?.leadTime1}
                                leadTime2={createQuoteState?.commanValues?.leadTime2}
                                leadTime3={createQuoteState?.commanValues?.leadTime3}
                                shippingCharge1={createQuoteState?.commanValues?.shippingCharge1}
                                shippingCharge2={createQuoteState?.commanValues?.shippingCharge2}
                                shippingCharge3={createQuoteState?.commanValues?.shippingCharge3}
                                shippingDays1={createQuoteState?.commanValues?.shippingDays1}
                                shippingDays2={createQuoteState?.commanValues?.shippingDays2}
                                shippingDays3={createQuoteState?.commanValues?.shippingDays3}
                                selectedShippingMethod={createQuoteState?.commanValues?.selectedShipMethod}
                                setSelectedShippingMethod={handleUpdateSelectedShipMethod}
                            />
                            : <></>}
                        {(isQuoteChanged) ?
                            <button
                                onClick={handleQuoteUpdate}
                                // className="border cursor-pointer col-span-3 bg-primaryColor text-white p-2 w-full items-center flex justify-center gap-x-2 mt-1"
                                className='primary-btn update-btn checkout-btn'
                            >
                                Save configuration
                                <UpdateQuoteIcon
                                    additionalClasses={'icon'}
                                />
                            </button> : null}
                        {(isQuoteChanged && createQuoteState?.commanValues.selectedProcess > 2) ? <button
                            onClick={handleQuoteUpdate}
                            // className="border cursor-pointer col-span-3 bg-primaryColor text-white p-2 w-full items-center flex justify-center gap-x-2 mt-1"
                            className='primary-btn update-btn checkout-btn mt-2'
                        >
                            <span>
                                Update
                            </span>
                            <UpdateQuoteIcon
                                additionalClasses={'h-5'}
                            />
                        </button> : null}

                        {/* {
                            (createQuoteState?.commanValues?.hidePrice && !isQuoteChanged) ?
                                <p className='text-red-600 font-medium'>
                                    Review required.
                                    Please use the chat functionality for further assistance.
                                </p> : null
                        } */}

                        <div className='certification-section'>
                            <button onClick={() => setIsCertificationModalOpen(true)} className='border-btn certification-btn'>
                                Add Certifications
                                <PlusIcon additionalClass="icon" />
                            </button>
                            {
                                createQuoteState?.commanValues.certifications.length > 0 &&
                                <>
                                    <ol>
                                        {createQuoteState?.commanValues.certifications.map((certification, index) => (
                                            <li key={index}>
                                                <p className='text'>
                                                    {index + 1}. {certification?.name}
                                                </p>
                                                <p className="text">
                                                    <b>${certification?.price}</b>
                                                    <span className='ml-4 cursor-pointer' onClick={() => handleSelect(certification)}>x</span>
                                                </p>
                                            </li>
                                        ))
                                        }
                                    </ol>
                                </>
                            }
                        </div>
                    </div>



                    <CertificationModal
                        show={isCertificationsModalOpen}
                        certifications={certificationsData}
                        onClose={() => setIsCertificationModalOpen(false)}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                    />
                    {console.log('createQuoteState?.commanValues',createQuoteState?.commanValues?.hidePrice)}
                    {console.log('isQuoteChanged',!isQuoteChanged)}

                    <div className="right-col-cta-section">
                        {(((createQuoteState?.commanValues?.hidePrice && !isQuoteChanged)
                            || createQuoteState?.commanValues.selectedProcess >= 3)
                        ) ?

                            <button
                                onClick={(e) => onSubmit(e, { saveQuote: true })}
                                // className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                                className='primary-btn checkout-btn'
                            >
                                <span>
                                    {"Request Review"}
                                </span>
                                <ForwardToPurchaseIcon />
                            </button> : null
                        }
                        {(selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders) ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                // className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2"
                                className='grey-btn forward-btn checkout-btn'
                            >
                                <span>
                                    Submit Target Cost
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                // className="border col-span-6 cursor-pointer bg-slate-500 text-white h-10  w-full items-center flex justify-center gap-x-2"
                                className='grey-btn forward-btn checkout-btn'
                            >
                                <span>
                                    Target Cost Submitted
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : null
                        }

                        {(
                            (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined))
                        ) && !isGenericUser ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'ftp'
                                })}
                                // className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 mt-1"
                                className='grey-btn forward-btn checkout-btn'
                            >
                                Forward To Purchaser <ForwardToPurchaseIcon />
                            </button> : null
                        }
                        {(
                            (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                            )) && !createQuoteState?.commanValues?.hidePrice ?

                            <button
                                onClick={(e) => onSubmit(e, { ptc: true })}
                                // className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                                className='primary-btn checkout-btn'
                            >
                                Proceed To Checkout
                                <ProccedToCheckOutIconComponent additionalClass="icon" />
                            </button> : null
                        }
                        {

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'chat'
                                })}
                                // className="border cursor-pointer col-span-3 bg-primaryColor text-white p-2 w-full items-center flex justify-center gap-x-2 mt-1"
                                className='border-btn chat-btn checkout-btn'
                            >
                                <span>
                                    Chat
                                </span>
                                <MessageIcon />
                            </button>
                        }


                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                // className="w-full  h-10 px-2 flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none mt-1"
                                className='border-btn more-options-btn'
                                aria-label="More options"
                            >
                                <ThreeDotsIcon additionalClasses="icon" />
                            </button>

                            {isOpen ? (
                                <div className='more-options-dropdown'>
                                    {/* Conditional Download Option */}
                                    {((props.commanValues.price1 && props.commanValues.leadTime1) ||
                                        (props.commanValues.price2 && props.commanValues.leadTime2) ||
                                        (props.commanValues.price3 && props.commanValues.leadTime3)) ? (
                                        <button
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'dq' })}
                                            className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                        >
                                            Download
                                            <DownloadIcon additionalClass={'icon'} />
                                        </button>
                                    ) : null}
                                    {/* Team Option */}
                                    {!isGenericUser && (
                                        <button
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'teams' })}
                                        // className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                        >
                                            Team
                                            <UsersIcon additionalClasses={'icon'} />
                                        </button>
                                    )}
                                    {((props.commanValues.price1 && props.commanValues.leadTime1) ||
                                        (props.commanValues.price2 && props.commanValues.leadTime2) ||
                                        (props.commanValues.price3 && props.commanValues.leadTime3)) ? (
                                        <button
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'share' })}
                                        // className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                        >
                                            Share
                                            <ShareIcon additionalClass={'icon'} />
                                        </button>
                                    ) : null}
                                    {
                                        _id && !funcName ? selectedQuote.isCancelled ?
                                            <button
                                                onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'move-to-progress' })}
                                            // className="w-full  h-10 px-2 flex items-center hover:bg-slate-300 text-black justify-center border rounded-none"
                                            >
                                                Move To Progress
                                                <ArrowIcon additionalClasses="icon" />
                                            </button> :
                                            <button
                                                onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'archive' })}
                                            // className="w-full  h-10 px-2 flex items-center  hover:bg-slate-300 text-black justify-center  rounded-none"
                                            >
                                                Archive
                                                <ArrowIcon additionalClasses="icon" />
                                            </button> :
                                            null

                                    }
                                </div>

                            ) : null}
                        </div>


                    </div>
                </div>
            </>
        </div>
    )
}

export default RightSection