import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";

const FullScreenFileDrop = (props) => {

        const {OnDropFunction} = props
  const [isDragActive, setDragActive] = useState(false); // State to track drag activity

  useEffect(() => {
    // Add global event listeners for drag actions
    const handleDragOver = (event) => {
      event.preventDefault(); // Prevent default behavior (e.g., file opening in browser)
      setDragActive(true); // Show dropzone
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      setDragActive(false); // Hide dropzone
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setDragActive(false); // Hide dropzone
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      // Cleanup event listeners when component unmounts
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleFileDrop = (acceptedFiles) => {
    console.log("Files dropped:", acceptedFiles); // Log or process dropped files
    setDragActive(false); // Hide the dropzone
  };

  return (
    <div>
      {/* Render Dropzone only when isDragActive is true */}
      {isDragActive && (
        <Dropzone onDrop={OnDropFunction}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: "24px",
                zIndex: 1000,
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <p className="sub-header text-white">Drop your files here...</p>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default FullScreenFileDrop;
