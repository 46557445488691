import React, { useEffect, useState } from "react";
import { useCreateQuoteContext } from "../../context/create.quote.context";
import { formatNumberWithCommas } from "../../utils/helper";
import { useParams } from "react-router-dom";
import { updatedCertificationForQuote } from "../../utils/actions/commanActions";

const AdminCertificationComponent = ({ show, onClose, certifications, handleSelect, selectedQuote }) => {
    const [isScrollable, setIsScrollable] = useState(false);





    useEffect(() => {
        setIsScrollable(certifications?.length > 6);
    }, [certifications]);

    if (!show) return null;

    return (
        <>
            {/* Fixed Backdrop */}
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            {/* Modal Content */}
            <div
                className="relative bg-white rounded-lg shadow-lg w-11/12 max-w-3xl mx-auto mt-20 z-50"
                style={{
                    position: "absolute", // Positioned relative to the flow of the page
                    left: "50%",
                    top: "10px",
                    transform: "translateX(-50%)",
                }}
            >
                {/* Modal Header */}
                <div className="flex justify-between items-center p-3 border-b border-gray-200">
                    <h2 className="text-xl font-semibold text-gray-800">Select Certifications</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={onClose}
                    >
                        ✕
                    </button>
                </div>

                {/* Modal Body */}
                <div className="p-6">
                    <p className="text-sm text-gray-600 mb-4">
                        Please select the certifications you'd like to add to your quote. Prices are listed below.
                    </p>
                    <div
                        className={`border rounded-md p-4 space-y-3 bg-gray-50 ${isScrollable ? "2xl:h-[600px] max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-100" : ""
                            }`}
                    >
                        {certifications.map((cert) => (
                            <div
                                key={cert._id}
                                className="flex items-center justify-between p-3 bg-white rounded-md shadow-sm hover:shadow-md transition duration-200 border border-gray-200"
                            >
                                <div className="flex items-center space-x-3">

                                    <input
                                        type="checkbox"
                                        id={cert._id}
                                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                        checked={selectedQuote?.certifications.some((item) => item?._id === cert._id)}
                                        onChange={() => handleSelect(cert)}
                                    />


                                    <label htmlFor={cert._id} className="text-sm text-gray-700">
                                        {cert.name}
                                    </label>
                                </div>
                                <div className="text-right">
                                    <span className="text-sm text-gray-800 font-medium">${formatNumberWithCommas(cert?.price)}</span>
                                    <p className="text-xs text-gray-500">({cert?.certificationType})</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminCertificationComponent;
