import React, { useState, useEffect, useReducer } from "react";
import search from "../../images/search.svg";
import { toast } from "react-toastify";
import { Tabs, Tab, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import Header from "../../components/Header/header";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import moment from "moment";
import { getAllAddress, handleCommonAddAddressForUser } from "../../utils/actions/allactions";
import jwt_decode from "jwt-decode";
import { useUserContext, USER_ACTIONS } from "../../context/user.context";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import activeQuoteImg from "../../images/activeOrders.svg";
import readyToOrderImg from "../../images/readyToOrder.svg";
import cancelledImage from "../../images/unapprovedQuotes.svg";
import allValuesImage from "../../images/all-entity.svg";
import completedImage from "../../images/closedOrders.svg";
import MyQuoteTable from "../../components/MyQuoteTable/MyQuoteTable";
import { fetchQuotesForParameters } from "../../utils/actions/userActions";
import Pagination from "react-bootstrap/Pagination";
import { ReviewTargetCostIcon, SubmitedTargetCost, TargetCostRequestedIcon } from "../../components/Icons";
import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";

import allQuotes from '../../images/All-Quotes.svg';
import draft from '../../images/Draft.svg';
import readtToOrder from '../../images/Ready-to-Order.svg';
import submitTargetCost from '../../images/Submit-Target-Cost.svg';
import completed from '../../images/Completed.svg';
import archived from '../../images/Archived.svg';

const Myquotes = () => {
  let token = localStorage.getItem("Token");
  let user_id = localStorage.getItem("_id");
  const { state, dispatch } = useUserContext();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const loggedInUser = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const initialMyQuoteState = {
    quotesToShow: [],
    loading: false,
    activeTab: "all-quote-tab",
    pageNum: 1,
    isLoading: false,
    searchQuery: "",
    createdFromFilter: "",
    createdToFilter: "",
    partNameFilter: '',
    selectedShipMethodMap: new Map(),
    totalPages: 0,
    completedQuotationsCount: 0,
    inProgressQuotationCount: 0,
    totalNumberOfQuotes: 0,
    submitTargetCost: 0,
    quotationsRelatedToRefIds: [],
    quoteVersionsMap: new Map(),
    versionData: {},
  };

  const MY_QUOTE_ACTIONS = {
    UPDATE_ACTIVE_TAB: "update-active-tab",
    UPDATE_QUOTES_TO_SHOW: "update-quote-to-show",
    UPDATE_SEARCH_QUERY: "update-search-query",
    UPDATE_LOADING: "update-loading",
    UPDATE_SELECTED_SHIP_MAP: "update-selected-ship-map",
    UPDATE_TOTAL_PAGES: "update-total-pages",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_NUMBER_QUOTES: "update-total-number-quotes",
    UPDATE_DATA: "update-data",
    UPDATE_VERSION_DATA: "update-version-data",
  };

  const myQuoteReducer = (state, action) => {
    switch (action.type) {
      case MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW:
        return { ...state, quotesToShow: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_ACTIVE_TAB:
        return { ...state, activeTab: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_SEARCH_QUERY:
        return { ...state, searchQuery: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_LOADING:
        return { ...state, isLoading: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_SELECTED_SHIP_MAP:
        return { ...state, selectedShipMethodMap: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_TOTAL_PAGES:
        return { ...state, totalPages: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_TOTAL_NUMBER_QUOTES:
        return { ...state, totalNumberOfQuotes: action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_DATA:
        return { ...state, ...action.payload };
      case MY_QUOTE_ACTIONS.UPDATE_VERSION_DATA:
        return { ...state, versionData: action.payload };
      default:
        return state;
    }
  };

  const [myQuotesState, myQuotesDispatch] = useReducer(
    myQuoteReducer,
    initialMyQuoteState
  );


  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("isLoggedIn");
    if (!token || !isUserLoggedIn) {
      navigate("/");
    } else {
      let decode = jwt_decode(token);
      if (!decode) {
        navigate("/");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "user") {
          fetchCommanData();
        } else if (decode.userType == "admin") {
          navigate("/admin");
        } else {
          navigate("/");
        }
      }
    }
  }, []);

  useEffect(() => {
    try {
      fetchQuotesForUser();
    } catch (err) {
      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
      console.log("Err", err);
    }
  }, [
    myQuotesState.pageNum,
    myQuotesState.activeTab,
    myQuotesState.searchQuery,
    myQuotesState.createdFromFilter,
    myQuotesState.createdToFilter,
    myQuotesState.partNameFilter,
    state?.quoteFilterActive,
  ]);

  const fetchQuotesForUser = async () => {
    try {
      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_LOADING,
        payload: true,
      });
      let data = {
        pageNum: myQuotesState.pageNum,
        activeTab: myQuotesState.activeTab,
        filterProjectName: myQuotesState.searchQuery,
        quoteFilterActive: state?.quoteFilterActive,
        userId: user_id,
        createdFromFilter: myQuotesState.createdFromFilter,
        createdToFilter: myQuotesState.createdToFilter,
        partNameFilter: myQuotesState.partNameFilter,
      };
      const response = await fetchQuotesForParameters(token, data, true);
      console.log('response', response)
      if (response.status) {
        myQuotesDispatch({
          type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
          payload: response.data.quotationsData,
        });

        myQuotesDispatch({
          type: MY_QUOTE_ACTIONS.UPDATE_TOTAL_PAGES,
          payload: response.data.totalPages,
        });

        myQuotesDispatch({
          type: MY_QUOTE_ACTIONS.UPDATE_TOTAL_NUMBER_QUOTES,
          payload: response.data.totalNumberOfQuotes,
        });

        myQuotesDispatch({
          type: MY_QUOTE_ACTIONS.UPDATE_VERSION_DATA,
          payload: response.data.versionData,
        });

        let localQuoteVersionMap = new Map();
        response.data.quotationsData.forEach((item) => {
          localQuoteVersionMap.set(item.RefId, item.version);
        });

        let dataToUpdate = {
          quotationsRelatedToRefIds: response.data.quotationsRelatedToRefIds,
          quoteVersionsMap: localQuoteVersionMap,
        };

        console.log("Data To Update :",dataToUpdate);

        myQuotesDispatch({
          type: MY_QUOTE_ACTIONS.UPDATE_DATA,
          payload: dataToUpdate,
        });

        let data = {
          quoteCount:
            response.data.inProgressCount + response.data.readyToOrderCount + response.data.completedCount,
          inProgressQuoteCount: response.data.inProgressCount,
          completedQuoteCount: response.data.completedCount,
          readyToOrderQuoteCount: response.data.readyToOrderCount,
          allQuotesTabCount: response.data.allQuotesTabCount,
          submitTargetCost: response.data.submitTargetCost,
        };

        dispatch({ type: USER_ACTIONS.UPDATE_QUOTE_COUNT, payload: data });
      }

      let localShipMethodMap = new Map();
      response.data.quotationsRelatedToRefIds.forEach((item) => {
        localShipMethodMap.set(item._id, item?.selectedShipMethod);
      });

      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_SELECTED_SHIP_MAP,
        payload: localShipMethodMap,
      });

      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
    } catch (error) {
      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
      console.log("Error", error);
    }
  };

  async function fetchCommanData() {
    try {
      if (!user_id) return;
      const fetchedAddresses = await getAllAddress(token);
      let localUserAddresses = [];
      fetchedAddresses?.data?.map((address) => {
        if (address?.userId === user_id) {
          localUserAddresses.push(address);
        }
      });

      setAddressValues({ ...addressValues, address: [...localUserAddresses] });
    } catch (err) {
      console.log(
        "err in my quote 179 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
        err
      );
      let msg =
        err && err.message
          ? err.message
          : "Something went wrong during fetch quotes";
      toast.error(msg);
    }
  }

  const handleQuoteTypeChange = (e) => {
    try {
      if (e == "my-quote") {
        dispatch({
          type: USER_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "my-quote",
        });
      } else if (e == "team-quote") {
        dispatch({
          type: USER_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "team-quote",
        });
      } else if (e == "all-quote") {
        dispatch({
          type: USER_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "all-quote",
        });
      }
      myQuotesDispatch({
        type: MY_QUOTE_ACTIONS.UPDATE_PAGE_NUM,
        payload: 1,
      });
    } catch (err) {
      console.log(
        "err in my quote 570 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value || 1;
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_PAGE_NUM,
      payload: pageNum,
    });
  };

  const handledAddAddress = async (addressData) => {
    let data = {
      userId: user_id,
      addressData,
    };
    const response = await handleCommonAddAddressForUser(token, data);
    if (response.status) {
      let localAllUserAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      localAllUserAddress.push(response.data);
      setAddressValues({ ...addressValues, address: localAllUserAddress });
      setShowDQModal(true);
    }
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues?.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    const response = await handleCommonEditAddressForUser(token, address);
    if (response.status) {
      allAddresses[index] = response.data;
      let addressValueData = {};
      if (addressValues.shippingAddress == response.data._id) {
        addressValueData = {
          addressline1: response.data.addressLineOne,
          addressline2: response.data.addressLineTwo,
          city: response.data.city,
          country: response.data.country,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          state: response.data.state,
          zipCode: response.data.postalCode,
        };
      }
      setAddressValues({
        ...addressValues,
        ...addressValueData,
        address: allAddresses,
      });
      setShowDQModal(true);
    }
  };

  const items = [];
  for (let number = 1; number <= myQuotesState.totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === myQuotesState.pageNum}
        onClick={() => handleActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleStartDateChange = (e) => {
    let data = { createdFromFilter: e };
    myQuotesDispatch({ type: MY_QUOTE_ACTIONS.UPDATE_DATA, payload: data });
  };

  const handleEndDateChange = (e) => {
    let data = { createdToFilter: e};
    myQuotesDispatch({ type: MY_QUOTE_ACTIONS.UPDATE_DATA, payload: data });
  };

  const handleResetFilter = () => {
    let data = { createdToFilter: "", createdFromFilter: "", searchQuery: "", partNameFilter: "" };
    myQuotesDispatch({ type: MY_QUOTE_ACTIONS.UPDATE_DATA, payload: data });
  };


  return (
    <div className="content-wrapper my-quotes-div">
      <Header activeTab="/saved-quote" />
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          recentAddress={addressValues?.address}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          id={editId}
          values={addressValues}
          handledAddAddress={handledAddAddress}
          handleAddressOnEdit={handleAddressOnEdit}
        />
      )}
      {loggedInUser ? (
        <div>
          <div style={{ position: "sticky", top: "64px", zIndex: 1 }}>
            <div className="intro-bar" style={{ padding: '24px', justifyContent: 'center' }}>
              <div className="updated-search-bar">
                <Input
                  type="text "
                  placeholder="Search by project name"
                  value={myQuotesState.searchQuery}
                  onChange={(e) =>
                    myQuotesDispatch({
                      type: MY_QUOTE_ACTIONS.UPDATE_SEARCH_QUERY,
                      payload: e.target.value,
                    })
                  }
                  suffix={<img src={search} />}
                />
                <Input
                  type="text"
                  placeholder="Filter by part name"
                  value={myQuotesState.partNameFilter}
                  onChange={(e) =>
                    myQuotesDispatch({
                      type: MY_QUOTE_ACTIONS.UPDATE_DATA,
                      payload: { partNameFilter: e.target.value },
                    })
                  }
                  suffix={<img src={search} />}
                />

                <Select
                  style={{width: "100%"}}
                  defaultValue="all-quote"
                  onChange={handleQuoteTypeChange}
                >
                  <Select.Option value="all-quote">All Projects</Select.Option>
                  <Select.Option value="my-quote">My Projects</Select.Option>
                  <Select.Option value="team-quote">Team Projects</Select.Option>
                </Select>

                <DatePicker
                  style={{width: "100%"}}
                  value={myQuotesState.createdFromFilter || ""}
                  onChange={handleStartDateChange}
                  placeholder="Created from"
                />

                <DatePicker
                  style={{width: "100%"}}
                  value={myQuotesState.createdToFilter || ""}
                  onChange={handleEndDateChange}
                  placeholder="Created to"
                />

                <button
                  className="link-btn"
                  onClick={handleResetFilter}
                  style={{minWidth: "fit-content"}}
                >
                  Reset Filters
                </button>

              </div>
            </div>

            <div className="tabsContainer">
              <Tabs
                id="controlled-tab-example"
                activeKey={myQuotesState.activeTab}
                onSelect={(k) => {
                  myQuotesDispatch({
                    type: MY_QUOTE_ACTIONS.UPDATE_ACTIVE_TAB,
                    payload: k,
                  });
                  myQuotesDispatch({
                    type: MY_QUOTE_ACTIONS.UPDATE_PAGE_NUM,
                    payload: 1,
                  });
                }}
                className="qoute-version-tabs"
                style={{ marginBottom: "-10px" }}
              >

                <Tab
                  eventKey={"all-quote-tab"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={allQuotes}
                        alt="all-quote-tab-img"
                      />
                      <p className="text">{`All Quotes (${state?.allQuotesTabCount || 0})`}</p>
                    </div>
                  }
                ></Tab>

                <Tab
                  eventKey={"inprogress"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={draft}
                        alt="inprogress-img"
                      />
                      <p className="text">{`Draft (${state?.inProgressQuoteCount || 0})`}</p>
                    </div>
                  }
                ></Tab>

                <Tab
                  eventKey={"ready-to-order"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={readtToOrder}
                        alt="ready-to-order-img"
                      />
                      <p className="text">{`Ready To Order (${state?.readyToOrderQuoteCount || 0})`}</p>
                    </div>
                  }
                ></Tab>
                <Tab
                  eventKey={"submit-target-cost"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={submitTargetCost}
                        alt="ready-to-order-img"
                      />
                      <p className="text">{`Submit Target Cost (${state?.submitTargetCost || 0})`}</p>
                    </div>
                  }
                ></Tab>

                <Tab
                  eventKey={"completed"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={completed}
                        alt="completed-img"
                      />
                      <p className="text">{`Completed(${state?.completedQuoteCount || 0})`}</p>
                      {/* {`Completed (${myQuotesState.activeTab === 'completed' ? state?.completedQuoteCount  : myQuotesState?.quotesToShow?.length || 0})`} */}
                    </div>
                  }
                ></Tab>
                <Tab
                  eventKey={"cancelled"}
                  title={
                    <div className="tab-div">
                      <img
                        className="tab-image"
                        src={archived}
                        alt="cancelled-img"
                      />
                      <p className="text">{`Archived`}</p>
                    </div>
                  }
                ></Tab>
              </Tabs>
            </div>
          </div>
          <div className="table-base-container">
            {myQuotesState.isLoading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <MyQuoteTable
                  quotationsList={myQuotesState.quotesToShow}
                  versionData={myQuotesState.versionData}
                  activeTab={myQuotesState.activeTab}
                  isLoading={myQuotesState.isLoading}
                  selectedShipMethodMap={myQuotesState.selectedShipMethodMap}
                  myQuotesDispatch={myQuotesDispatch}
                  MY_QUOTE_ACTIONS={MY_QUOTE_ACTIONS}
                  addressValues={addressValues}
                  setAddressValues={setAddressValues}
                  myQuotesState={myQuotesState}
                  showAddressModal={showAddressModal}
                  setShowAddressModal={setShowAddressModal}
                  setIsEdit={setIsEdit}
                  setEditId={setEditId}
                  fetchQuotesForUser={fetchQuotesForUser}
                />
              </>
            )}
            <div className="pagination-container">
              <p className="text show-total-number">
                Showing {(myQuotesState.pageNum - 1) * 10 + 1} -{" "}
                {Math.min(
                  myQuotesState.pageNum * 10,
                  myQuotesState.totalNumberOfQuotes
                )}{" "}
                of {myQuotesState.totalNumberOfQuotes}
              </p>
              <Pagination>
                <Pagination.Prev
                  onClick={() => handleActivePage(myQuotesState.pageNum - 1)}
                  disabled={myQuotesState.pageNum === 1}
                />
                {items}
                <Pagination.Next
                  onClick={() => handleActivePage(myQuotesState.pageNum + 1)}
                  disabled={
                    myQuotesState.pageNum === myQuotesState.totalPages
                  }
                />
              </Pagination>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

    </div>
  );
};
export default Myquotes;
