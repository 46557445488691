import React, { useEffect, useState } from "react";
import downloadImg from "../../images/download.svg";
import QuoteVersionPriceBox from "../QuoteVersionPriceBox/QuoteVersionPriceBox";
import { fetchQuoteByIdForQuoteVersion } from "../../utils/actions/allactions";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import {
  currencyFormatter,
  // downloadFileFromS3,
  extractURLAndEncodedFilename,
  isShowPriceAccordingToProcess,
  isShowPriceAccordingToProcessAtIndex,
  isValidFileName,
  roundToTwoDigits,
} from "../../utils/helper";
import downloadFileFromS3 from "../../utils/helper";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { CheckIconDFM, CircleAlertDFM, DownloadIcon, LucidLoader, SuccessIcon, WarningIcon } from "../Icons";
import { calculatePriceOfCertifications, calculateVolume, miliMeterToInchFactor } from "../../utils/constant";
import { load3DViewer } from "../../utils/trigger";
import { Button, Modal } from "react-bootstrap";
import Switch from "react-input-switch";
import { getPresignedUrlForFile } from "../../api/helperapi/helperApi";
import ThreeDViewerComponent from "../ViewerComponent/ThreeDViewerComponent";

function QuoteTable({
  selectedQuote,
  setSelectedQuote,
  fromOrderDetails,
  showDrawer,
  isLoading,
  setLoading
}) {

  if (isLoading) return null;



  const [fetchedQuoteDetails, setFetchedQuoteDetails] = useState();
  const [certificationsCost, setCertificationsCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  let token = localStorage.getItem("Token");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedShipMethod, setSelectedShipMethod] = useState(0);
  const [showCad, setShowCad] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isInchSelected, setIsInchSelected] = useState(() => {
    return localStorage.getItem("defaultUnit") ? localStorage.getItem("defaultUnit") == "inch" : false
  })

  const [errorShowArray, setErrorShowArray] = useState([]);

  const [is3DviewerLoadingIndex, setIs3DviewerLoadingIndex] = useState(-1);

  console.log("Is price Show :", isShowPriceAccordingToProcess(fetchedQuoteDetails));

  const fetchedQuoteDetailsById = async () => {
    try {
      setLoading(true);
      let details = await fetchQuoteByIdForQuoteVersion(
        selectedQuote._id,
        token
      );

      const certificationsCost = details?.data?.orderCertificationsCost;
      const shippedMethod = details.data.data.selectedShipMethod;
      if (shippedMethod != null && shippedMethod != undefined) {
        if (shippedMethod == 1) {
          setShippingCost(details.data.data.shippingCharge1)
        }
        else if (shippedMethod == 2) {
          setShippingCost(details.data.data.shippingCharge2)
        }
        else {
          setShippingCost(details.data.data.shippingCharge3)
        }
      }


      setCertificationsCost(certificationsCost);
      setSelectedShipMethod(details.data.data.selectedShipMethod);
      setFetchedQuoteDetails(details.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Unable To Fetch Quotation Details");
    } finally {
      setLoading(false);
    }
  };
  // console.log("Selected Quote :", selectedQuote);
  useEffect(() => {
    if (selectedQuote._id) {
      fetchedQuoteDetailsById();
    }

    if (selectedQuote.selectedProcess >= 3) {
      setSelectedPrice(selectedQuote.sheetAndInjectionManualPrice);
    } else {
      setSelectedPrice(selectedQuote.stManualPrice);
    }
  }, [selectedQuote]);



  // console.log("Fetch Quote Details :", fetchedQuoteDetails);

  let handleOnClickPriceBox = (price, selectedShipMethod) => {

    console.log("Selected Shipping Method :", selectedShipMethod);
    setSelectedPrice(price);
    setSelectedShipMethod(selectedShipMethod);
    // setSelectedQuote({ ...selectedQuote, selectedShipMethod: selectedShipMethod })
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  if (!fetchedQuoteDetails) {
    return null;
  }

  console.log('fetchedQuoteDetails',fetchedQuoteDetails)

  return (
    <div>
      {!fromOrderDetails && (
        <QuoteVersionPriceBox
          showDrawer={showDrawer}
          selectedQuote={selectedQuote}
          handleOnClickPriceBox={handleOnClickPriceBox}
          selectedPrice={selectedPrice}
          selectedShipMethod={selectedShipMethod}
          setSelectedShipMethod={setSelectedShipMethod}
          setSelectedQuote={setSelectedQuote}
        />
      )}


      <div className="relative overflow-x-auto">
        <table className="qoute-version-table">
          {fetchedQuoteDetails.selectedProcess >= 3 ? (
            <>
              <thead>
                <tr className="heading-row">
                  <th scope="col">Snapshot</th>
                  <th scope="col">File Name</th>
                  <th scope="col">Part Description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>


                {fetchedQuoteDetails.partsData?.length > 0 &&
                  fetchedQuoteDetails.partsData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="w-32">
                          <ThreeDViewerComponent
                            item={item}
                            selectedProcess={fetchedQuoteDetails?.selectedProcess}
                          />
                        </td>
                        <td
                          // className="downloadables"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "250px",
                          }}
                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                            <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                              {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                ?.length > 25
                                ? (item.selectedFile?.originalname
                                  ? item.selectedFile?.originalname
                                  : ""
                                ).substring(0, 25) + "..."
                                : item.selectedFile?.originalname
                                  ? item.selectedFile?.originalname
                                  : ""}
                              <a
                                onClick={() => downloadFileFromS3(item.selectedFile)}
                              >
                                <DownloadIcon />
                              </a>
                            </p>
                          </div>
                        </td>

                        <td
                          style={{
                            textAlign: "left",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                          className="w-32"
                        >
                          {item.description ? <div>
                            <span>Description : </span>
                            <span>{item.description}</span>
                          </div> : ""}
                        </td>

                        <td className="w-32">
                          {isShowPriceAccordingToProcessAtIndex(fetchedQuoteDetails,index) ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 1
                                      ? parseFloat(item?.price1 ).toFixed(2)
                                      : selectedShipMethod === 2
                                        ? parseFloat(item?.price2 ).toFixed(2)
                                        : parseFloat(item?.price3 ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td className="w-32">{item.Qty ? item.Qty : "No quantity found"}</td>

                        <td className="w-32">
                          {isShowPriceAccordingToProcessAtIndex(fetchedQuoteDetails,index) ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 1
                                      ? (
                                        parseFloat(item?.price1) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                      : selectedShipMethod === 2
                                        ? (
                                          parseFloat(item?.price2) * parseFloat(item?.Qty)
                                        ).toFixed(2)
                                        : parseFloat(
                                          parseFloat(item?.price3) * parseFloat(item?.Qty)
                                        ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {(fetchedQuoteDetails?.certifications?.length) ? (
                  <tr>
                    <td> </td>
                    <td>Certifications </td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(fetchedQuoteDetails?.orderCertificationsCost)}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                {isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td className='font-bold'>Shipping & Duties </td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(
                            selectedShipMethod == 1
                              ? fetchedQuoteDetails.shippingCharge1
                              : selectedShipMethod == 2
                                ? fetchedQuoteDetails.shippingCharge2
                                : fetchedQuoteDetails.shippingCharge3
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}


                {isShowPriceAccordingToProcess(fetchedQuoteDetails)
                  ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold">Total :</td>
                      <td className="font-bold">
                        {currencyFormatter(
                          selectedShipMethod === 1
                            ? (parseFloat(fetchedQuoteDetails?.price1) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                            : selectedShipMethod === 2
                              ? (parseFloat(fetchedQuoteDetails?.price2) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                              : (parseFloat(fetchedQuoteDetails?.price3) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                        )}
                      </td>
                    </tr>
                  ) : null
                }

              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr className="heading-row">
                  <th scope="col">Snapshot</th>
                  <th scope="col">File Name</th>
                  <th scope="col">2D files</th>
                  <th scope="col">Part Description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>

                {fetchedQuoteDetails.partsData.length > 0 &&
                  fetchedQuoteDetails.partsData.map((item, index) => {
                    // console.log("item", item?.selectedFile);
                    return (
                      <tr key={index}>
                        <td className="w-32">
                          <ThreeDViewerComponent
                            item={item}
                            selectedProcess={fetchedQuoteDetails?.selectedProcess}
                          />
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",

                          }}
                          className=" w-32"
                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                            <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                              {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                ?.length > 25
                                ? (item.selectedFile?.originalname
                                  ? item.selectedFile?.originalname
                                  : ""
                                ).substring(0, 25) + "..."
                                : item.selectedFile?.originalname
                                  ? item.selectedFile?.originalname
                                  : ""}
                              <a
                                onClick={() => downloadFileFromS3(item.selectedFile)}
                              >
                                <DownloadIcon />
                              </a>
                            </p>
                          </div>
                        </td>
                        {item?.twoDFile ? <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                          className="w-32"
                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                            <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                              {(item.twoDFile ? item?.twoDFile?.originalname : "")
                                ?.length > 25
                                ? (item.twoDFile
                                  ? item?.twoDFile?.originalname
                                  : ""
                                ).substring(0, 25) + "..."
                                : item.twoDFile
                                  ? item?.twoDFile?.originalname
                                  : ""}
                              <a
                                onClick={() => downloadFileFromS3(item.twoDFile)}
                              >
                                <DownloadIcon />
                              </a>
                            </p>
                          </div>
                        </td> : <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                          className="w-32"

                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >

                          </div>
                        </td>}


                        <td>
                          {/* {fetchedQuoteDetails.selectedProcess} */}
                          {fetchedQuoteDetails.selectedProcess == 1 ? <div className="desc-part-quote-version">
                            <ul>
                              {item?.description ? (
                                <li><span>Description :</span><span>{item?.description}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncMaterial ? (
                                <li> <span>Material :</span><span>{item?.cncMaterial?.cnc_material}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncMaterial ? (
                                <li> <span>Grade :</span><span>{item?.cncMaterial?.cnc_grade}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncSurfaceFinish ? (
                                <li><span>Finish :</span><span>{item?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncHeatTreatment ? (
                                <li><span>Treatment :</span><span>{item?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncTolerance ? (
                                <li><span>Tolerance :</span><span>{item?.cncTolerance.cnc_tolerance}</span></li>
                              ) : (
                                ""
                              )}
                              {item?.cncParkMarking ? (
                                <li><span>Marking :</span><span>{item?.cncParkMarking.cnc_part_marking ? "Yes" : "No"}</span></li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div> : <div className="desc-part-quote-version">
                            <ul>
                              {item?.description ? (
                                <li><span>Description :</span><span>{item?.description}</span></li>
                              ) : (
                                ""
                              )}
                              <li >
                                <span>Technology : </span>
                                <span>{item.threeDTechnology &&
                                  item.threeDTechnology?.name
                                }</span>
                              </li>

                              <li >
                                <span>Material : </span>
                                <span>{item.threeDMaterial &&
                                  item.threeDMaterial?.name
                                }</span>

                              </li>
                              <li>
                                <span>Post Process :</span>
                                <span>{item.threeDPostProcessing &&
                                  item.threeDPostProcessing?.name
                                }</span>

                              </li>
                              <li>
                                {item.XDimensionValue} mm x {item.YDimensionValue} mm x {item.ZDimensionValue} mm
                              </li>
                              <li>
                                {(item.XDimensionValue * 0.0394).toFixed(2)} in x {(item.YDimensionValue * 0.0394).toFixed(2)} in x {(item.ZDimensionValue * 0.0394).toFixed(2)} in
                              </li>
                              <li>
                                Volume : {calculateVolume(item.XDimensionValue,
                                  item.YDimensionValue,
                                  item.ZDimensionValue,
                                  'mm'

                                ).toFixed(2)} mm³
                              </li>
                              <li>
                                Volume : {calculateVolume(item.XDimensionValue,
                                  item.YDimensionValue,
                                  item.ZDimensionValue,
                                  'in'
                                ).toFixed(2)} in³
                              </li>
                            </ul>
                          </div>}
                        </td>

                        <td className="w-32">
                          {isShowPriceAccordingToProcessAtIndex(fetchedQuoteDetails,index) ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 1
                                      ? parseFloat(item?.price1).toFixed(2)
                                      : selectedShipMethod === 2
                                        ? parseFloat(item?.price2).toFixed(2)
                                        : parseFloat(item?.price3).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td className="w-20">
                          <p className="amt">{item?.Qty}</p>{" "}
                        </td>

                        <td className="w-32">
                          {isShowPriceAccordingToProcessAtIndex(fetchedQuoteDetails,index) ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 1
                                      ? (
                                        parseFloat(item?.price1) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                      : selectedShipMethod === 2
                                        ? (
                                          parseFloat(item?.price2) * parseFloat(item?.Qty)
                                        ).toFixed(2)
                                        : parseFloat(
                                          parseFloat(item?.price3) * parseFloat(item?.Qty)
                                        ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {(fetchedQuoteDetails?.certifications?.length
                  && isShowPriceAccordingToProcess(fetchedQuoteDetails)
                ) ? (
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Certifications </td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(fetchedQuoteDetails?.orderCertificationsCost)}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Shipping & Duties</td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(
                            selectedShipMethod == 1
                              ? fetchedQuoteDetails.shippingCharge1
                              : selectedShipMethod == 2
                                ? fetchedQuoteDetails.shippingCharge2
                                : fetchedQuoteDetails.shippingCharge3
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {
                  isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold">Total :</td>
                      <td className="font-bold">
                        {currencyFormatter(
                          selectedShipMethod === 1
                            ? (parseFloat(fetchedQuoteDetails?.price1) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                            : selectedShipMethod === 2
                              ? (parseFloat(fetchedQuoteDetails?.price2) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                              : (parseFloat(fetchedQuoteDetails?.price3) + fetchedQuoteDetails?.orderCertificationsCost + shippingCost).toFixed(2)
                        )}
                      </td>
                    </tr>
                  ) : null
                }

              </tbody>
            </>
          )}
        </table>
      </div>
    </div >
  );

}

export default QuoteTable;