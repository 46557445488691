import { createContext, useContext, useReducer } from "react";


const createQuotationContext = createContext();

const initialState = {
    values: [],
    commanValues: {
        projectNumber: 0,
        projectName: "UNTITLED PROJECT",
        projectNamePreFix: "GN",
        certifications: [],
        threeDCertifications: [],
        cncCertifications: [],
        manualCertifications: [],
        tempCertificationsCostForthreeD: 0,
        tempCertificationsCostForcnc: 0,
        tempCertificationsCostForManual: 0,
        selectedProcess: 1,
        isReadyToCreateQuote: false,
        price1: '',
        price2: '',
        price3: '',
        selectedShipMethod: 1,
        sheetAndInjectionDiscription: "",
        sheetAndInjectionQuantity: 0,
        hidePrice: false,
        isConformanceCertificateRequested: false,
        isDimensionalReportRequested: false,
        isQrTrackingRequested: false,
        additionalCost1: 0,
        additionalCost2: 0,
        additionalCost3: 0,
        shippingCharge1: 0,
        shippingCharge2: 0,
        shippingCharge3: 0,
        leadTime1: 10,
        leadTime2: 15,
        errors: {
            MaterialType: false,
            SubMaterial: false,
            sheetAndInjectionDiscription: false,
            sheetAndInjectionQuantity: false
        },
        certificationCost: 0,
        orderCertificationsCost: 0,
        isUpdatedByAdmin: false,
        myorders: false,
        sameForAllField: false,
        errors: {
            MaterialType: false,
            SubMaterial: false,
            sheetAndInjectionDiscription: false,
            sheetAndInjectionQuantity: false
        },
        isQuoteChanged: false,
        isProcessChanged: false,
    }
}

export const CREATE_QUOTE_ACTION = {
    UPDATE_VALUES: 'UPDATE_VALUES',
    UPDATE_COMMAN_VALUES: 'UPDATE_COMMAN_VALUES',
    ADD_REMOVE_CERTIFICATES: 'ADD_REMOVE_CERTIFICATES',
    UPDATE_PROJECT_NAME: 'UPDATE_PROJECT_NAME',
    UPDATE_PROJECT_NUMBER: 'UPDATE_PROJECT_NUMBER',
    READY_TO_CREATE_QUOTE: 'READY_TO_CREATE_QUOTE',
    RESET_CREATE_QUOTE_STATE: 'RESET_CREATE_QUOTE_STATE',
    RESET_VALUES_AS_PROCESS_CHANGE: 'RESET_VALUES_AS_PROCESS_CHANGE',
}

const reducer = (state, action) => {
    switch (action.type) {
        case CREATE_QUOTE_ACTION.UPDATE_VALUES:
            return { ...state, values: action.payload };
        case CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES:
            {

                console.log("Updated Inside Comman Values 10:", { commanValues: action.payload });

                return { ...state, commanValues: action.payload };
            }
        case CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE:
            {
                console.log("Updated Inside Comman Values 10:", { commanValues: { ...initialState.commanValues, selectedProcess: action.payload || 2 } });
                return { ...initialState, commanValues: { ...initialState.commanValues, selectedProcess: action.payload || 1 } }
            }
        case CREATE_QUOTE_ACTION.ADD_REMOVE_CERTIFICATES: {
            const { _id, certificationType, price } = action.payload;

            const data = action.payload;

            const orderCertificationsCost = state.commanValues.orderCertificationsCost;
            let costToAdjust = (() => {
                if (certificationType == 'per order') {
                    return price;
                }
                else if (certificationType == 'per line item') {
                    return state.values.length * price;
                }
                return 0;
            })()



            const isCertificationAlReadyExists = state.commanValues.certifications.find((item) => item?._id == _id);
            let updatedCertifications;
            let updatedOrderCertificationsCost;
            if (isCertificationAlReadyExists) {
                updatedCertifications = state.commanValues.certifications.filter((item) => item?._id != _id);
                updatedOrderCertificationsCost = orderCertificationsCost - costToAdjust;
            }
            else {
                updatedCertifications = [...state.commanValues.certifications, data];
                updatedOrderCertificationsCost = orderCertificationsCost + costToAdjust;
            }

            const selectedProcess = state.commanValues.selectedProcess;
            const obj = { updatedCertifications, updatedOrderCertificationsCost };
            localStorage.setItem(selectedProcess, JSON.stringify(obj));
            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    certifications: updatedCertifications,
                    orderCertificationsCost: updatedOrderCertificationsCost,
                },
            };
        }
        case CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME: {
            const updatedProjectName = action.payload;


            console.log("Updated Inside Comman Values 7:", {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectName: updatedProjectName,
                }
            });



            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectName: updatedProjectName,
                }
            }
        }
        case CREATE_QUOTE_ACTION.UPDATE_PROJECT_NUMBER: {
            const updatedProjectNumber = action.payload + 1;

            console.log("Updated Inside Comman Values 8:", {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectNumber: updatedProjectNumber,
                }
            });




            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectNumber: updatedProjectNumber,
                }
            }
        }
        case CREATE_QUOTE_ACTION.READY_TO_CREATE_QUOTE: {
            const payload = action.payload;

            console.log("Updated Inside Comman Values 9:", {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    isReadyToCreateQuote: payload,
                }
            });




            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    isReadyToCreateQuote: payload,
                }
            }
        }
        default:
            return state;
    }
};


export const CreateQuoteContextProvider = ({ children }) => {
    const [createQuoteState, createQuoteDispatch] = useReducer(reducer, initialState);
    return <createQuotationContext.Provider value={{ createQuoteState, createQuoteDispatch }}>{children}</createQuotationContext.Provider>
}

export const useCreateQuoteContext = () => {
    return useContext(createQuotationContext);
};
