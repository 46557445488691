import React, { useState, useEffect } from 'react'
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents'
import { deleteCncCertifications, getAllCncCertifications, upsertCncCertification } from '../../utils/actions/adminActions';
import { toast } from "react-toastify"
import BackButton from '../../components/BackButton/BackButton';

const CertificationsComponent = () => {
    const token = localStorage.getItem("Token");
    const [certifications, setCertifications] = useState([]);
    const [form, setForm] = useState({ id: "", name: "", price: "", certificationType: "", review_required: "", });
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        (async () => {
            const response = await getAllCncCertifications();
            if (!response || !response.success) {
                toast.error("Failed To Fetch Certifications At The Moment");
                return;
            }
            const { data } = response;
            setCertifications(data);
        })()
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((pre) => {
            if (name == 'review_required') {
                if (value == 'true') {
                    return { ...pre, review_required: true };
                }
                else {
                    return { ...pre, review_required: false };
                }
            }
            return { ...pre, [name]: value };
        })
    };
    console.log("Form Data :", form);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Certification :", form);
            const response = await upsertCncCertification(form);
            if (!response || !response) {
                toast.error("Unable To Create Certifications At The Moment");
                return;
            };
            const { data } = response;
            toast.success(`Certifications ${form._id ? "Updated" : 'Created'} Succesfully`);
            resetForm();
            if (!form._id) {
                setCertifications((pre) => {
                    return [...pre, data];
                })
                return;
            }
            setCertifications((pre) => {
                return pre.map((item) => {
                    if (item._id == form._id) return data;
                    return item;
                })
            })


        } catch (error) {
            console.error("Error saving certification", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const response = await deleteCncCertifications(id);
            console.log("Response :", response);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success('Deleted Succesfully');
            setCertifications((pre) => pre.filter((item) => item._id != id));
        } catch (error) {
            toast.error("Unable To Fetch Delete At The Moment");
        }
    }

    // Handle editing
    const handleEdit = (certification) => {
        setForm(certification);
        setIsEditing(true);
    };

    // Handle deletion


    // Reset the form
    const resetForm = () => {
        setForm({ id: "", name: "", price: "", certificationType: "", review_required: false});
        setIsEditing(false);
    };

    return (
        <div className="p-6 w-full mx-auto bg-white shadow-lg rounded-md">
            <BackButton />
            <h1 className="text-2xl text-center font-bold text-gray-800 mb-4">Certification Manager</h1>

            <form onSubmit={handleSubmit} className="mb-6 ">
                <input
                    type="text"
                    name="name"
                    placeholder="Certification Name"
                    value={form.name}
                    onChange={handleChange}
                    className="w-full my-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                />
                <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={form.price}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*(\.\d{0,2})?$/.test(value)) {

                            handleChange(e)
                        }
                    }
                    }
                    className="w-full my-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                />
                <select
                    name="certificationType"
                    value={form.certificationType}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                >
                    <option value="">Select Certification Type</option>
                    <option value="per order">Per Order</option>
                    <option value="per line item">Per Line Item</option>
                </select>
                <select
                    name="review_required"
                    value={form.review_required}
                    onChange={handleChange}
                    className="w-full my-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                >
                    <option value="">Is Review Required</option>
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                </select>
                <div className="flex space-x-4 my-2">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColor"
                    >
                        {isEditing ? "Update" : "Create"}
                    </button>
                    {isEditing && (
                        <button
                            type="button"
                            onClick={resetForm}
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    )}

                </div>
            </form>

            <h2 className="text-xl font-semibold text-gray-700 mb-4">Certifications</h2>
            <table className="w-full table-auto border-collapse border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">Name</th>
                        <th className="border border-gray-300 px-4 py-2">Price</th>
                        <th className="border border-gray-300 px-4 py-2">Type</th>
                        <th className="border border-gray-300 px-4 py-2">Review Required</th>
                        <th className="border border-gray-300 px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {certifications.map((certification) => (
                        <tr key={certification.id} className="text-center">
                            <td className="border border-gray-300 px-4 py-2">{certification?.name}</td>
                            <td className="border border-gray-300 px-4 py-2">{certification?.price}</td>
                            <td className="border border-gray-300 px-4 py-2">{certification?.certificationType}</td>
                            <td className="border border-gray-300 px-4 py-2">{certification?.review_required ? "true" : 'false'}</td>
                            <td className="border border-gray-300 px-4 py-2">
                                <div className="flex justify-center space-x-2">
                                    <button
                                        onClick={() => handleEdit(certification)}
                                        className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(certification._id)}
                                        className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminDashboardComponents(CertificationsComponent);