import jwt_decode from "jwt-decode";
import {
  Container,
  Tabs,
  Tab,
  Nav,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import chatico from "../images/chat.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { Drawer } from "antd";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { db } from "../utils/firebase";
import { MessageIcon } from "../components/Icons";

function ChatButton({
  open,
  onDrawerClose,
  showDrawer,
  color,
  RefId,
  isTextHide,
  imageHeight,
  additionalClass,
}) {
  if (RefId) {
    RefId = RefId.split(" ")[0];
    if (RefId.startsWith("S")) {
      RefId = RefId.slice(1);
    }
  }
  const userId = jwtDecode(localStorage.getItem("Token"))?._id;
  const decodedJwt = jwtDecode(localStorage.getItem("Token"));
  // let {  } = useParams();
  // const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isOpenedAnytime, setIsOpenedAnytime] = useState(false);
  const [fetchedCount, setFetchedCount] = useState(false);

  const onChange = (value) => {
    setMessage(value);
  };

  useEffect(() => {
    db.ref("/count/" + RefId).on("value", async (snapshot) => {
      setFetchedCount(true);
      let totalMessages = 0;
      const snapshotVal = await db.ref("/users/" + RefId + "/" + userId).get();
      if (snapshotVal.exists()) {
        const value = snapshotVal.val();
        totalMessages = value.count;
      }
      if (snapshot.exists()) {
        const value = snapshot.val();
        setUnreadMessages(value - totalMessages);
      }
    });

    db.ref("/users/" + RefId + "/" + userId).on("value", async (snapshot) => {
      setFetchedCount(true);
      let totalMessages = 0;
      const snapshotVal = await db.ref("/count/" + RefId).get();
      if (snapshotVal.exists()) {
        const value = snapshotVal.val();
        totalMessages = value;
      }
      if (snapshot.exists()) {
        const value = snapshot.val();
        setUnreadMessages(totalMessages - value.count);
      }
    });

    if (fetchedCount) {
      setUnreadMessages(0);
    }
  }, []);

  useEffect(() => {
    db.ref("/count/" + RefId).on("value", async (snapshot) => {
      setFetchedCount(true);
      let totalMessages = 0;
      const snapshotVal = await db.ref("/users/" + RefId + "/" + userId).get();
      if (snapshotVal.exists()) {
        const value = snapshotVal.val();
        totalMessages = value.count;
      }
      if (snapshot.exists()) {
        const value = snapshot.val();
        setUnreadMessages(value - totalMessages);
      }
    });

    db.ref("/users/" + RefId + "/" + userId).on("value", async (snapshot) => {
      let totalMessages = 0;
      setFetchedCount(true);
      const snapshotVal = await db.ref("/count/" + RefId).get();
      if (snapshotVal.exists()) {
        const value = snapshotVal.val();
        totalMessages = value;
      }
      if (snapshot.exists()) {
        const value = snapshot.val();
        setUnreadMessages(totalMessages - value.count);
      }
    });

    if (fetchedCount) {
      setUnreadMessages(0);
    }
  }, [RefId]);

  return (
    <div
      onClick={showDrawer}
      className={`h-full w-full relative text-sm  cursor-pointer rounded   flex items-center justify-center gap-x-3 ${
        !isTextHide && "bg-primaryColor"
      } text-white ${additionalClass && additionalClass}`}
    >
      <span className="ico relative flex items-center justify-start gap-2">
        {unreadMessages > 0 ? (
          <span className="px-1.5 rounded-sm text-xs bg-[#FF0000]  absolute bottom-[10px] right-[-7px]">
            <span className="text-[10px] flex items-center justify-center text-white ">
              {unreadMessages}
            </span>
          </span> 
        ) : null}
        <MessageIcon
          additionalClass={`${isTextHide ? "text-primaryColor" : ""}`}
        />
        <p
          style={{
            fontSize: 14,
            color: "white"
          }}
        >
          Chat
        </p>
      </span>
      
    </div>
  );
}

export default ChatButton;
